import { formatOdds } from '../utils/messages';
import { remapMinified } from '../utils/remappers';
import settings from '../settings.json';
import { parse } from '../utils';
import { DISABLED, FINISHED, LIVE, NOT_FINISHED, NOT_RUNNING, ACTIVE_EVENT_STATUSES } from '../consts';

const minificationEnabled = settings.socketMessagesMinification;

export const bufferState = {
	prematchEvents: new Map(),
	liveEvents: new Map(),
};
export const getBufferState = () => {
	return bufferState;
};

export const resetBufferState = () => {
	bufferState.liveEvents.clear();
	bufferState.prematchEvents.clear();
};

export const getEventById = (eventId, live) => {
	if (live) {
		return bufferState.liveEvents.get(eventId) ?? null;
	} else {
		return bufferState.prematchEvents.get(eventId) ?? null;
	}
};

export const updateEventById = (eventId, newEvent) => {
	if (newEvent.live) {
		if (bufferState.liveEvents.get(eventId)?.deleted) return;
		bufferState.liveEvents.set(eventId, newEvent);
	} else {
		if (bufferState.prematchEvents.get(eventId)?.deleted) return;
		bufferState.prematchEvents.set(eventId, newEvent);
	}
};

export const addNewEventToBufferState = (event) => {
	if (event.live) {
		bufferState.liveEvents.set(event.id, event);
	} else {
		bufferState.prematchEvents.set(event.id, event);
	}
};

export const deleteEventByIdFromBufferState = (eventId, live) => {
	if (!eventId) return;
	if (live) {
		bufferState.liveEvents.set(eventId, { deleted: true });
	} else {
		//live flag is false for FINISHED status
		bufferState.liveEvents.set(eventId, { deleted: true });
		bufferState.prematchEvents.set(eventId, { deleted: true });
	}
};

export const oddsChangeBufferStateHandler = (type, message) => {
	const parsed = parse(message);
	if (!parsed || typeof parsed !== 'object' || !parsed.data) return;

	let data = parsed.data;
	if (minificationEnabled) {
		data = remapMinified(type, data);
	}
	const { markets, id_event, live } = data || {};
	if (!Array.isArray(markets)) return;

	const event = getEventById(id_event, live);
	const odds = formatOdds(markets);
	const freezed = Object.freeze(odds);
	updateEventById(id_event, {
		...event,
		odds: freezed,
		live,
		odds_data_updated: new Date().toISOString().slice(0, -1),
	});
};

export const resultChangeBufferStateHandler = (type, message) => {
	const parsed = parse(message);
	if (!parsed || typeof parsed !== 'object' || !parsed.data) return;

	let data = parsed.data;
	if (minificationEnabled) {
		data = remapMinified(type, data);
	}

	const event = getEventById(data.id_event, true);

	const scores = Object.freeze({ ...data });

	updateEventById(data.id_event, { ...event, scores });
};

export const eventChangeBufferStateHandler = (type, message) => {
	const parsed = parse(message);

	if (!parsed || typeof parsed !== 'object' || !parsed.data) return;

	let data = parsed.data;
	if (minificationEnabled) {
		data = remapMinified(type, data);
	}

	const {
		id_event,
		status,
		live_status,
		current_time,
		manually_blocked,
		active,
		utc_scheduled,
		live,
		current_period: period,
	} = data || {};
	const event = getEventById(id_event, live);

	const utc_scheduled_time = utc_scheduled ? utc_scheduled : event?.utc_scheduled;

	if (status === FINISHED || status === NOT_FINISHED || !active) {
		deleteEventByIdFromBufferState(id_event, live);
	} else if (live_status === LIVE) {
		updateEventById(id_event, {
			...event,
			stopped: live && !ACTIVE_EVENT_STATUSES.includes(status),
			disabled: manually_blocked,
			current_time,
			utc_scheduled: utc_scheduled_time,
			live,
			status,
			period,
		});
	} else if (status !== NOT_RUNNING && live_status === DISABLED) {
		updateEventById(id_event, {
			...event,
			disabled: true,
			current_time,
			utc_scheduled: utc_scheduled_time,
			stopped: live && !ACTIVE_EVENT_STATUSES.includes(status),
			live,
			status,
			period,
		});
	} else {
		updateEventById(id_event, {
			...event,
			utc_scheduled: utc_scheduled_time,
			live,
			stopped: live && !ACTIVE_EVENT_STATUSES.includes(status),
			status,
			period,
			current_time,
			manually_blocked,
		});
	}
};

export const eventChangeLiveBufferStateHandler = (type, message) => {
	const parsed = parse(message);
	if (!parsed || typeof parsed !== 'object' || !parsed.data) return;

	let data = parsed.data;
	if (minificationEnabled) {
		data = remapMinified(type, data);
	}

	const {
		id_event: id,
		current_period: period,
		list_code: listCode,
		markets,
		utc_scheduled,
		start_time,
		current_time,
		status,
		manually_blocked,
		live,
		odds_data_updated,
		...rest
	} = data || {};

	const newMarkets = Array.isArray(markets) ? markets : [];

	const odds = Object.freeze(formatOdds(newMarkets));

	const newLiveEvent = {
		...rest,
		id,
		period,
		odds,
		listCode,
		live,
		current_time,
		status,
		stopped: live && !ACTIVE_EVENT_STATUSES.includes(status),
		manually_blocked,
		disabled: manually_blocked,
		utc_scheduled: utc_scheduled || start_time,
		odds_data_updated,
	};

	//delete prematch event, if exist
	deleteEventByIdFromBufferState(newLiveEvent.id, false);
	addNewEventToBufferState(newLiveEvent);
};

export const eventChangePrematchBufferStateHandler = (type, message) => {
	const parsed = parse(message);
	if (!parsed || typeof parsed !== 'object' || !parsed.data) return;

	let data = parsed.data;
	if (minificationEnabled) {
		data = remapMinified(type, data);
	}
	const { id_event: id, list_code: listCode, markets, utc_scheduled, start_time, active, live, ...rest } = data || {};

	if (id && !active) {
		deleteEventByIdFromBufferState(id, live ?? false);
	} else if (active) {
		if (!Array.isArray(markets)) return;
		const odds = Object.freeze(formatOdds(markets));
		const newEvent = {
			id,
			listCode,
			live,
			utc_scheduled: utc_scheduled || start_time,
			active,
			stopped: false,
			...rest,
			odds,
		};
		addNewEventToBufferState(newEvent);
	}
};
