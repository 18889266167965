<template>
	<Modal v-if="event" searchQuery :closeModal="closeLiveOddsModal">
		<LiveOddsHeader slot="modal-header" :event="event" />
		<LiveOddsList slot-scope="{ searchValue }" :searchValue="searchValue" :markets="markets" />
	</Modal>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import LiveOddsHeader from './LiveOddsHeader';
import LiveOddsList from './LiveOddsList';
import { separateMarkets, separateOddsInMarkets } from './utils';
import { parse, remapEventForUpdate } from '../../../utils';
import Modal from '../index';
import { ACTIVE, REMOVED, TEMPORARY_REMOVED } from '../../../consts';
import settings from '../../../settings.json';
import { remapMinified } from '../../../../workers/remapper.worker';
import { EVENT_CHANGE, ODDS_CHANGE, EVENT_RESULT } from '../../../consts/messages';
import { isEmpty } from 'lodash';

export default {
	components: { Modal, LiveOddsHeader, LiveOddsList },
	data() {
		return {
			minificationEnabled: settings.socketMessagesMinification,
		};
	},
	computed: {
		...mapGetters(['liveOddsModalData', 'marketsMap']),
		event() {
			const listCode = this.$store._modules.root.state.dataState.liveMapEventIdToListCode[
				this.liveOddsModalData.eventId
			];
			return this.$store._modules.root.state.dataState.liveEvents[listCode];
		},
		markets() {
			if (!this.event.odds || isEmpty(this.event.odds)) return;
			const activeOdds = Object.values(this.event.odds).filter(
				(odd) =>
					(odd.market_status === ACTIVE || odd.market_status === TEMPORARY_REMOVED) &&
					odd.oddtype_status === 1 &&
					odd.manual_status !== REMOVED
			);

			const groupedBy = activeOdds.reduce((prevValue, odd) => {
				const marketKey = odd.market_key?.split('|')[0];
				(prevValue[marketKey] = prevValue[marketKey] || []).push(odd);

				return prevValue;
			}, {});

			const separatedOdds = separateOddsInMarkets(groupedBy, this.marketsMap, this.event.sport.id);

			return separatedOdds;
		},
	},
	methods: {
		...mapMutations(['closeLiveOddsModal']),
	},
};
</script>
