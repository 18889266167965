export default {
	state: {
		isNotificationVisible: false,
	},
	getters: {
		isNotificationVisible: (state) => state.isNotificationVisible,
	},
	mutations: {
		isNotificationVisibleHandler(state, paylaod) {
			state.isNotificationVisible = paylaod;
		},
	},
	actions: {
		setIsNotificationVisible({ commit }, paylaod) {
			commit('isNotificationVisibleHandler', paylaod);
		},
	},
};
