<template>
	<div class="component">
		<!-- Save the last payin -->
		<div class="checkbox_custom">
			<label for="checkbox_f11" class="left">
				{{ $t("lastPayin") }}
				<strong>F11</strong>
			</label>

			<input @click="toggleSavePayin" type="checkbox" id="checkbox_f11" :checked="options.savePayin" />
			<i class="mdi mdi-checkbox-blank-outline unchecked"></i>
			<i class="mdi mdi-checkbox-marked checked"></i>
		</div>

		<!-- Save the last odd -->
		<div class="checkbox_custom">
			<label for="checkbox_f12" class="left">
				{{ $t("lastTip") }}
				<strong>F12</strong>
			</label>

			<input @click="toggleSaveOdd" type="checkbox" id="checkbox_f12" :checked="options.saveOdd" />
			<i class="mdi mdi-checkbox-blank-outline unchecked"></i>
			<i class="mdi mdi-checkbox-marked checked"></i>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { handleKey } from "../../utils";
import { keys } from "../../consts";

export default {
	computed: mapGetters(["options"]),
	methods: mapMutations(["toggleSavePayin", "toggleSaveOdd"]),
	created() {
		this.removeKeyListeners = [
			handleKey("keydown", keys.f11, this.toggleSavePayin),
			handleKey("keydown", keys.f12, this.toggleSaveOdd)
		];
	},
	destroyed() {
		const { removeKeyListeners = [] } = this;
		removeKeyListeners.forEach(f => f());
	}
};
</script>