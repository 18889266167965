<template>
	<table class="events">
		<tbody>
			<OddRow
				v-for="(odd, index) in visibleOdds"
				:key="odd.id"
				:odd="odd"
				:row="index"
				:rowSelected="currentOdd === index"
				:oddClick="oddClick"
				:selectOdd="onMouseOver"
				:bonus="activeTab === 'bonus'"
			/>
		</tbody>
	</table>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { keys } from 'keycodes-map';
import { computedEvent, createEditEvent, eventNavigation } from '../../../mixins';
import { scrollTo } from '../../../utils';
import OddRow from './OddRow';
import { isEmpty } from 'lodash';

export default {
	mixins: [computedEvent, createEditEvent, eventNavigation],
	props: ['searchValue', 'event', 'bonusEvent', 'activeTab'],
	components: { OddRow },
	data() {
		return {
			currentOdd: 0,
			observer: null,
			observerRoot: null,
			lastVisibleOddDOM: null,
			chunkSize: 30,
			chunkNumber: 1,
		};
	},
	watch: {
		searchValue() {
			this.observerRoot.scrollTop = 0;
			this.chunkNumber = 1;
		},
	},
	computed: {
		...mapGetters({ row: 'selectedRow', prematchOrderMap: 'prematchOrderMap' }),

		odds() {
			const { prematchOrderMap } = this;

			const {
				odds = [],
				sport: { id: sportId },
			} = this.event || {};

			const { odds: bonusOdds = [] } = this.bonusEvent || {};

			const eventOdds = this.activeTab === 'bonus' ? bonusOdds : odds;

			const sortedOdds = this.eventOddsData(eventOdds).sort(
				(a, b) => prematchOrderMap[sportId][a.id] - prematchOrderMap[sportId][b.id]
			);
			return sortedOdds;
		},
		filteredOdds() {
			const { odds, searchValue } = this;
			if (!odds) return [];
			const search = `${searchValue}`.toLowerCase();
			const filtered = odds
				.filter((odd) => odd.stake && odd.stake > 1 && odd.oddtype_status === 1)
				.filter((odd) => `${JSON.stringify(odd).toLowerCase()}`.includes(search));
			this.currentOdd = this.currentOdd || 0;
			return filtered;
		},
		visibleOdds() {
			return this.filteredOdds.slice(0, this.chunkSize * this.chunkNumber);
		},
	},
	methods: {
		...mapMutations(['closeOddsModal']),

		addOdd(selectedOdd) {
			if (isEmpty(selectedOdd)) return;

			const { systemIndex, isLastRow, event, eventIndex, addNewEmptyEvent, odd, activeTab } = this;

			const bonus = activeTab === 'bonus';
			const { id, stake, name = '', description = '', keyboardCode, specialValue, marketName, oddtype_status } =
				selectedOdd || {};

			const oddType = {
				id,
				stake,
				description,
				keyboardCode,
				specialValue,
				marketName,
				bonusType: bonus,
				name: bonus ? `B ${name}` : name,
				oddtype_status,
			};
			const { isNew, isEdit, odds: eventOdds } = this.ticketEvent(systemIndex, eventIndex) || {};

			const oddTypeCheck = this.oddTypeCanBeAddedCheck(eventOdds, oddType, odd, bonus);

			if (oddTypeCheck === 'existsInactive' && oddtype_status !== 1) return this.oddAlreadyExists('modal-search');
			if (oddTypeCheck === 'exists') return this.oddAlreadyExists('modal-search');
			if (oddTypeCheck === 'cannotCombine') return this.cannotCombineSame('modal-search');

			this.setLastOdd(oddType);

			if (isNew) {
				this.replaceTicketEvent({
					eventId: event.id,
					systemIndex,
					newEvent: {
						...event,
						isNew: false,
						isEdit,
						odds: [oddType],
					},
				});
			} else {
				this.replaceEventOdd({
					newOdd: oddType,
					oddId: odd.id,
					systemIndex,
					eventIndex,
				});
			}

			if (isLastRow) addNewEmptyEvent(systemIndex);
			else this.oddRight();
			this.closeOddsModal();
		},

		oddTypeCanBeAddedCheck(odds, oddType, odd, bonus) {
			const exists = odds.find(({ keyboardCode }) => keyboardCode === oddType.keyboardCode);
			if (exists) {
				if (Boolean(exists.bonusType) === Boolean(bonus) && exists.oddtype_status !== 1) return 'existsInactive';
				else if (Boolean(exists.bonusType) === Boolean(bonus)) return 'exists';
				else {
					if (odd.keyboardCode !== oddType.keyboardCode) return 'cannotCombine';
				}
			}

			return oddType;
		},

		oddClick(selectedOdd) {
			this.addOdd(selectedOdd);
		},

		oddEnter(e) {
			if (e.keyCode !== keys.enter) return;

			const { visibleOdds, currentOdd } = this;
			const selectedOdd = visibleOdds[currentOdd] || {};
			this.addOdd(selectedOdd);
		},

		onMouseOver(index) {
			this.currentOdd = index;
		},

		oddUp(e) {
			if (e.keyCode === keys.arrowUp && this.currentOdd > 0) scrollTo(`odds-modal:${--this.currentOdd}`);
		},
		oddDown(e) {
			if (e.keyCode === keys.arrowDown && this.currentOdd < this.visibleOdds.length - 1)
				scrollTo(`odds-modal:${++this.currentOdd}`);
		},
		handleScrollToBottom(e) {
			if (e[0].isIntersecting) {
				this.observer.unobserve(this.lastVisibleOddDOM);
				this.chunkNumber += 1;
			}
		},
	},
	mounted() {
		document.addEventListener('keydown', this.oddUp);
		document.addEventListener('keydown', this.oddDown);
		document.addEventListener('keyup', this.oddEnter);

		this.observerRoot = document.getElementById('event-list-container');

		this.observer = new IntersectionObserver(this.handleScrollToBottom, {
			root: this.observerRoot,
			rootMargin: '0px',
			// threshold: 0.5,
		});
		if (this.chunkNumber * this.chunkSize >= this.filteredOdds.length) return;
		this.lastVisibleOddDOM = document.getElementById(`odds-modal:${this.visibleOdds.length - 1}`);
		if (!this.lastVisibleOddDOM) return;
		this.observer.observe(this.lastVisibleOddDOM);
	},
	updated() {
		if (this.chunkNumber * this.chunkSize >= this.filteredOdds.length) {
			return;
		}
		const newLast = document.getElementById(`odds-modal:${this.chunkNumber * this.chunkSize - 1}`);
		if (this.lastVisibleOddDOM === newLast && newLast) return;

		if (!this.lastVisibleOddDOM) this.lastVisibleOddDOM = newLast;
		this.observer.unobserve(this.lastVisibleOddDOM);
		this.lastVisibleOddDOM = newLast;
		this.observer.observe(this.lastVisibleOddDOM);
	},
	beforeDestroy() {
		this.observer.disconnect();
	},
	destroyed() {
		document.removeEventListener('keydown', this.oddUp);
		document.removeEventListener('keydown', this.oddDown);
		document.removeEventListener('keyup', this.oddEnter);
	},
};
</script>
