export function createValidator(rules = {}, { computed = {}, validators = {} }) {
	return (ticket) => {
		const context = {};

		Object.entries(computed).forEach(([prop, generator]) => {
			context[prop] = generator(ticket, context, rules);
		});

		Object.entries(validators).forEach(([rule, getter]) => {
			if (!(rule in rules)) return;

			const expected = rules[rule];
			const err = getter(ticket, expected, context);
			if (err) throw err;
		});
	};
}

export const validateTicket = (ticket) => {
	const { ticketValidator } = window;
	if (typeof ticketValidator === 'function') return ticketValidator(ticket);
};
