import axios from 'axios';
import qs from 'qs';
import { devapiHost, bpapiHost } from './host';
import i18n from '../localisation/i18n.js';

export const devapi = setupHttpInstance({ baseURL: devapiHost });
export const bpapi = setupHttpInstance({ baseURL: bpapiHost });

function setupHttpInstance(options) {
	const instance = axios.create({
		withCredentials: true,
		headers: {
			'Content-Type': 'application/json',
			'Accept-Language': i18n.locale
		},
		paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
		...options
	});

	instance.interceptors.response.use(
		response => {
			return response.data;
		},
		error => {
			console.error(`[HTTP] - An error occured: ${error}`);
			return Promise.reject(error);
		}
	);

	return instance;
}
