<template>
	<div class="modal_head padding_left_20 col_border_bottom live">
		<span class="svg_icon tooltip_right">
			<!-- <svg class="sport_ico">
				<use :xlink:href="icon"></use>
			</svg> -->
			<span class="">{{ sportName.substring(0, 3) }}</span>
			<span class="tooltip_content">{{ sportName }}</span>
		</span>

		<div class="event_time">
			<span class="date">{{ eventStartTime }}</span>
			<span class="time_elapsed">{{ currentTime }}</span>
		</div>

		<div class="competetors">
			<span class="home">{{ home }}</span>
			<span class="-">vs</span>
			<span class="away">{{ away }}</span>
		</div>

		<div class="result">
			<div v-if="possession && running" class="event_part">
				<div class="home">
					<div class="tennis_ball" v-if="possession === 1"></div>
					<span v-else>{{ '\xa0' }}</span>
				</div>

				<div class="away">
					<div class="tennis_ball" v-if="possession === 2"></div>
					<span v-else>{{ '\xa0' }}</span>
				</div>
			</div>
			<div class="event_part margin_right_10" v-if="currentPoints && running">
				<div class="period_name"></div>
				<div class="home">{{ currentPoints.home }}</div>
				<div class="away">{{ currentPoints.away }}</div>
			</div>
			<div class="event_part total">
				<div class="period_name"></div>
				<div class="home">{{ currentScore.home }}</div>
				<div class="away">{{ currentScore.away }}</div>
			</div>

			<div v-for="(period, i) in periods" :key="i" class="event_part">
				<div class="period_name">{{ `${periodSymbol}${period.period}` }}</div>
				<div class="home">{{ period.score.home_score }}</div>
				<div class="away">{{ period.score.away_score }}</div>
			</div>
		</div>

		<div class="tournament">
			{{ tournament }}
		</div>
	</div>
</template>

<script>
import { computedEvent } from '../../../mixins';
import { formatDate } from '../../../utils';
import { sports } from '../../../utils/results/sports';
import { FINISHED } from '../../../consts';
export default {
	mixins: [computedEvent],
	props: ['event'],
	computed: {
		eventStartTime() {
			const {
				event: { utc_scheduled },
			} = this;
			return utc_scheduled ? formatDate(utc_scheduled, 'hours:minutes')[0] : '';
		},
		currentTime() {
			const { current_time } = this.event || {};
			if (!current_time) return '';
			const [minutes] = current_time.split(':');
			return `${minutes} '`;
		},
		currentScore() {
			const { current_score = {} } = this.event?.scores || {};
			if (!current_score) return { home: '', away: '' };
			const { home_score: home = '', away_score: away = '' } = current_score;
			return { home, away };
		},
		currentPoints() {
			const { current_points = {} } = this.event?.scores || {};
			const { sportId } = this;
			if (!current_points) return null;
			let { home_score: home = '', away_score: away = '' } = current_points;
			if (sportId === 'tn') {
				if (home === 50) home = 'AD';
				if (away === 50) away = 'AD';
			}
			return { home, away };
		},
		currentPeriod() {
			return this.event?.period ?? this.event?.currentPeriod ?? '';
		},
		running() {
			return this.currentPeriod !== FINISHED;
		},
		periods() {
			const { period_scores } = this.event.scores || {};
			return period_scores?.sort(({ period: periodA }, { period: periodB }) => periodA - periodB) || [];
		},
		possession() {
			const { stats = null } = this.event?.scores || {};
			return stats && stats.possession;
		},
		periodSymbol() {
			const { sport: { id: sportId = '' } = {} } = this.event || {};
			const data = sports[sportId];
			return data && data.period ? data.period : '';
		},
	},
};
</script>
