<template>
	<div class="info_header">
		<div class="top_bar flex_direction_row col_border_bottom padding_20">
			<input
				id="barcode"
				type="text"
				v-model="ticketBarcode"
				@keyup.enter="modalEnterHandler('ticketBarcode', 'pin')"
				class="search margin_right_10"
				:placeholder="$t('ticketCode')"
			/>
			<input
				id="pin"
				v-model="pin"
				type="number"
				:placeholder="$t('pin')"
				:disabled="!ticketBarcode"
				@keyup.enter="getTicketByBarcode"
				class="search short margin_right_10"
			/>

			<button :class="[{ disabled: !ticketBarcode || !pin }, 'btn md']" @click="getTicketByBarcode">
				{{ $t('show') }}
			</button>
			<template v-if="id">
				<template>
					<button
						:class="[{ disabled: resultStatus !== 'WINNING' || !pin || paidOut }, 'btn md']"
						@click="
							openConfirmationModal({
								title: $t('confirmations.general'),
								text: $t('confirmations.payout', {
									barcode: ticketBarcode,
									payIn: `${toMoneyFormat(originalAmount)} ${currency}`,
								}),
								confirmAction: () => payout(ticketBarcode, pin, id),
							})
						"
					>
						{{ $t('payout') }}
					</button>

					<button
						:class="[
							{
								disabled:
									status !== 'PLACED' ||
									resultStatus ||
									paidOut ||
									(cashout && cashout.payoutStatus === 'ACCEPTED'),
							},
							'btn md',
						]"
						@click="handleCashoutClick()"
					>
						{{ $t('refund') }}
					</button>
					<button
						class="btn md"
						:class="!cashoutAmount || cashoutRequested ? 'disabled' : ''"
						@click="
							openConfirmationModal({
								title: $t('confirmations.general'),
								text: $t('confirmations.cashout', {
									barcode: ticketBarcode,
									payIn: `${toMoneyFormat(originalAmount)} ${currency}`,
									cashout: `${toMoneyFormat(cashoutAmount)} ${currency}`,
								}),
								confirmAction: cashoutClicked,
							})
						"
					>
						Cashout
					</button>

					<button class="btn md" :class="{ green: acceptCashoutChanges }" @click="toggleAcceptChanges">
						{{ $t('acceptChanges') }}
					</button>
				</template>

				<button class="btn md margin_left_30" @click="printCopy">{{ $t('printTicketCopy') }}</button>
				<button
					class="btn md"
					@click="
						copyTicket();
						focusCodeAction();
						focusOddAction({ value: false });
					"
					@keydown.enter="
						focusCodeAction();
						focusOddAction({ value: false });
					"
				>
					{{ $t('copyTicket') }}
				</button>
			</template>
		</div>
		<!-- top_bar -->

		<div v-if="id" class="ticket_info">
			<div class="info_row">
				<div class="info_item">
					<label for="">{{ $t('payin') }}</label>
					<div class="value">
						{{ toMoneyFormat(originalAmount) }}
						<span class="currency">{{ currency }}</span>
					</div>
				</div>

				<div class="info_item ">
					<label for="">{{ $t('minOdd') }}</label>
					<div class="value">
						{{ oddsFormat(+minQuota) }}
					</div>
				</div>

				<div class="info_item">
					<label for="">{{ $t('minWin') }}</label>
					<div class="value">
						{{ toMoneyFormat(minGain - payOutTax.min_tax_value) }}
						<span class="currency">{{ currency }}</span>
					</div>
				</div>
				<div class="info_item">
					<label for="">{{ $t('cashout') }}</label>
					<div class="value">
						{{ cashoutAmount && cashoutAmount > 0 ? toMoneyFormat(cashoutAmount) : 'NA' }}
					</div>
				</div>
				<div class="info_item">
					<label for="">{{ $t('payinOperator') }}</label>
					<div class="value">{{ operator || 'NA' }}</div>
				</div>
				<div class="info_item">
					<label for="">{{ $t('giveaway') }}</label>
					<div class="value">NA</div>
				</div>
			</div>

			<div class="info_row">
				<!-- col_border_bottom -->
				<div class="info_item">
					<label for="">{{ $t('timePlaced') }}</label>
					<div class="value">{{ timePlaced }}</div>
				</div>

				<div class="info_item ">
					<label for="">{{ $t('maxOdd') }}</label>
					<div class="value">
						{{ oddsFormat(+maxQuota) }}
					</div>
				</div>

				<div class="info_item">
					<label for="">{{ $t('maxWin') }}</label>
					<div class="value">
						{{ toMoneyFormat(maxGain - payOutTax.max_tax_value) }}
						<span class="currency">{{ currency }}</span>
					</div>
				</div>

				<div class="info_item">
					<label for="">{{ `${$t('bonus')} (${+parseFloat(bonus * 100).toFixed(2)} %)` }}</label>
					<div class="value">
						{{ toMoneyFormat(bonusAmount) }} <span class="currency">{{ currency }}</span>
					</div>
				</div>

				<div class="info_item">
					<label for="">{{ $t('affectedOdd') }}</label>
					<div class="value">{{ (resultedQuota && oddsFormat(resultedQuota)) || 'NA' }}</div>
				</div>

				<div class="info_item">
					<label for="">{{ $t('taxPayin') }}</label>
					<div class="value">
						{{ toMoneyFormat(originalAmount - amount) }}<span class="currency">{{ currency }}</span>
					</div>
				</div>
			</div>
			<div class="info_row">
				<!-- col_border_bottom -->
				<div class="info_item ">
					<label for="">{{ $t('payoutTime') }}</label>
					<div class="value">{{ paidOutData.paidOutDate }}</div>
				</div>

				<div class="info_item" v-if="cashout">
					<label for="">{{ $t('payout') }}</label>
					<div class="value">
						{{ cashout ? toMoneyFormat(cashoutTaxedAmount) : toMoneyFormat(paidOutData.paidOutAmount) }}
						<span class="currency">{{ currency }}</span>
					</div>
				</div>
				<div class="info_item" v-else>
					<label for="">{{ $t('payout') }}</label>
					<div class="value">
						{{ toMoneyFormat(paidOutData.paidOutAmount) }}
						<span class="currency">{{ currency }}</span>
					</div>
				</div>

				<div class="info_item">
					<label for="">{{ $t('win') }}</label>
					<div class="value">
						{{ (gain && toMoneyFormat(gain)) || 'NA' }} <span class="currency">{{ gain ? currency : '' }}</span>
					</div>
				</div>

				<div class="info_item" v-if="cashout">
					<label for="">{{ $t('casoutTaxPayout') }}</label>
					<div class="value">
						{{ toMoneyFormat(cashoutTax || 0) }}<span class="currency">{{ currency }}</span>
					</div>
				</div>

				<div class="info_item" v-else-if="originalGain && !cashout">
					<label for="">{{ $t('taxPayout') }}</label>
					<div class="value">
						{{ toMoneyFormat(originalGain - gain) }}<span class="currency">{{ currency }}</span>
					</div>
				</div>

				<div class="info_item flex_direction_row align_center" v-else>
					<div class="margin_right_50">
						<label for="">{{ $t('minTaxPayout') }}</label>
						<div class="value">
							{{ toMoneyFormat(payOutTax.min_tax_value) }}<span class="currency">{{ currency }}</span>
						</div>
					</div>
					<div>
						<label for="">{{ $t('maxTaxPayout') }}</label>
						<div class="value">
							{{ toMoneyFormat(payOutTax.max_tax_value) }}<span class="currency">{{ currency }}</span>
						</div>
					</div>
				</div>

				<div class="info_item">
					<label for="">{{ $t('payoutOperator') }}</label>
					<div class="value">{{ payoutOperator || 'NA' }}</div>
				</div>

				<div class="info_item">
					<label for="">{{ $t('promotions') }}</label>
					<div class="value">{{ promotionsCount }} <span class="currency"></span></div>
				</div>
			</div>
		</div>

		<AuthConfirmation v-if="showCashoutRiskModal" :sendToRisk="requestCashout" :closeAuthModal="closeAuthModal" />
		<!-- ticket_info -->

		<div class="status_bar" :class="statusInfo.bgColor">
			<p class="margin_left_20">{{ systemsPreview }}</p>
			<div class="title_wrapper">
				<h2 class="status_title">{{ statusInfo.text }}</h2>
				<p class="status_info" />
			</div>
			<div @click="setSidebar((sidebar) => !sidebar)" class="ticket_list_toggle_icon">
				<i class="mdi mdi-menu-open"></i>
			</div>
		</div>

		<!-- status_bar -->
	</div>
</template>

<script>
import AuthConfirmation from '../../components/Sidebar/AuthConfirmation';
import { formatDate, getGeoParamsData } from '../../utils';
import { formatting, inputHandler } from '../../mixins';
import { statusesMap } from './helpers';
import { mapMutations, mapGetters, mapActions } from 'vuex';
import { SENT, ACCEPTED } from '../../consts';
import ticketSocket from '../../api/ticketSocket';
import { calculateTaxForPlayedTicket } from '../../utils/ticket/calculateTaxForPlayedTicket';

export default {
	mixins: [formatting, inputHandler],

	components: {
		AuthConfirmation,
	},

	props: [
		'id',
		'amount',
		'systems',
		'currency',
		'bonus',
		'status',
		'placedDatetime',
		'requestedDatetime',
		'authStatus',
		'barcode',
		'gain',
		'maxGain',
		'convertedAmount',
		'minQuota',
		'maxQuota',
		'setTicket',
		'setSidebar',
		'copyTicket',
		'printCopy',
		'refund',
		'ticketPin',
		'payout',
		'combinations',
		'resultedQuota',
		'cashout',
		'cashoutAmount',
		'paidOutDatetime',
		'paidOutAmount',
		'operator',
		'payoutOperator',
		'paidOut',
		'resultStatus',
		'winType',
		'originalAmount',
		'originalGain',
		'togglePromoPinModal',
		'promotionsCount',
	],
	data() {
		return {
			ticketBarcode: '',
			pin: '',
			cashoutLock: false,
			showCashoutRiskModal: false,
			acceptCashoutChanges: true,
		};
	},
	computed: {
		...mapGetters([
			'submitting',
			'cashoutTickets',
			'cashoutAmountTaxed',
			'cashoutPaidOutTaxed',
			'userInfo',
			'triggerType',
			'cashoutMessage',
		]),

		cashoutTax() {
			const decimalSeparator = this.paidOutData.paidOutAmount.at(-3);

			let paidOutAmountLocalNum = 0;

			if (decimalSeparator === ',') {
				paidOutAmountLocalNum = parseFloat(this.paidOutData.paidOutAmount.replaceAll('.', '').replaceAll(',', '.'));
			} else if (decimalSeparator === '.') {
				paidOutAmountLocalNum = parseFloat(this.paidOutData.paidOutAmount.replaceAll(',', ''));
			}

			if (this.cashoutAmountTaxed) {
				return paidOutAmountLocalNum - this.cashoutAmountTaxed;
			} else if (this.cashoutPaidOutTaxed) {
				return this.cashoutPaidOutTaxed - paidOutAmountLocalNum;
			}
		},

		cashoutTaxedAmount() {
			return this.cashoutAmountTaxed ? this.cashoutAmountTaxed : this.cashoutPaidOutTaxed - this.cashoutTax;
		},

		statusInfo() {
			let { status, paidOut, resultStatus, winType, cashout } = this;

			if (resultStatus === 'LOSING') status = 'LOSING';
			if (resultStatus === 'WINNING') status = 'WINNING';
			if (resultStatus === 'WITHDRAWN') status = 'REFUNDED';

			if (paidOut) status = 'PAID_OUT';

			if (winType === 'CASHBACK' && paidOut) status = 'CASHBACK';
			if (cashout && cashout.payoutStatus === 'ACCEPTED') status = 'CASHOUT';

			if (status === 'REFUNDED') status = 'REFUNDED';

			let currentStatus = statusesMap[status] || statusesMap['REQUESTED'];

			return currentStatus;
		},

		minGain() {
			const { minQuota, amount, combinations, bonusAmount, maxGain } = this;
			let minGain = (minQuota * amount) / combinations + bonusAmount;
			if (minGain > maxGain) minGain = maxGain;
			return minGain;
		},

		paidOutData() {
			const { paidOutDatetime, paidOutAmount, toMoneyFormat, paidOut, cashout } = this;
			if (cashout && cashout.payoutStatus === ACCEPTED) {
				const date = cashout.dtPaidout ? new Date(cashout.dtPaidout) : '';
				const amount = cashout.amount ? toMoneyFormat(cashout.amount) : 0;
				return {
					paidOutAmount: amount,
					paidOutDate: formatDate(date, 'date.month.year. hours:minutes:seconds')[0],
				};
			}
			if (!paidOutDatetime || !paidOut) return { paidOutAmount: 'NA', paidOutDate: 'NA' };
			const date = new Date(paidOutDatetime);
			const paidOutDate = formatDate(date, 'date.month.year. hours:minutes:seconds')[0];
			return {
				paidOutAmount: toMoneyFormat(paidOutAmount),
				paidOutDate,
			};
		},

		cashoutRequested() {
			const { id, cashoutTickets } = this;
			return cashoutTickets.some(({ id: ticketId }) => ticketId === id);
		},

		bonusAmount() {
			const { maxQuota, amount, bonus } = this;
			return bonus * (maxQuota * amount);
		},

		timePlaced() {
			const { placedDatetime, requestedDatetime } = this;
			const date = placedDatetime
				? new Date(placedDatetime)
				: requestedDatetime
				? new Date(requestedDatetime)
				: null;
			return date && formatDate(date, 'date.month.year. hours:minutes:seconds')[0];
		},

		systemsPreview() {
			if (!this.systems) return null;
			return this.systems
				.map(
					({ required, events }, i) =>
						`S${i + 1}: ${[...required].sort((a, b) => a - b).join(', ')} ${this.$t('from')} ${events.length}`
				)
				.join('  ');
		},

		payOutTax() {
			const { minQuota, amount, combinations, bonusAmount, maxGain } = this;
			let minGain = (minQuota * amount) / combinations + bonusAmount;
			if (minGain > maxGain) minGain = maxGain;

			let min_tax_value = 0;
			let max_tax_value = 0;

			const { minTax, maxTax } = calculateTaxForPlayedTicket(
				{ minValue: minGain, maxValue: +this.maxGain.toFixed(2) },
				amount,
				window.geoParams,
				'en',
				{
					parameterMappings: window.geoMappings,
				}
			);

			min_tax_value = minTax;
			max_tax_value = maxTax;

			return { min_tax_value, max_tax_value };
		},
	},
	watch: {
		barcode(newBarcode) {
			this.ticketBarcode = newBarcode;
			this.pin = this.ticketPin || '';

			const firstName = this.userInfo?.firstName;
			const trigger_payout = this.triggerType;

			if (trigger_payout === 'payout' && firstName && this.resultStatus === 'WINNING') {
				this.payout(newBarcode, this.ticketPin, this.id);
			}
			if (!trigger_payout || trigger_payout === '') this.resetTriggerStateAction();
		},
		cashoutAmount() {
			if (!this.cashoutAmount) return;
			const trigger_cashout = this.triggerType;
			const trigger_cashout_message = this.cashoutMessage;

			const firstName = this.userInfo?.firstName;

			if (trigger_cashout === 'request' && firstName) {
				this.requestCashout(trigger_cashout_message);
			} else if (trigger_cashout === 'accept' && firstName) {
				const { id, cashout, cashoutAmount, cashoutRequested, cashoutLock } = this;
				if (!cashoutAmount || cashoutRequested || cashoutLock) return;
				if (cashout) {
					const { authStatus, payoutStatus } = cashout;
					if (authStatus === ACCEPTED && payoutStatus === SENT) {
						this.$api.cashoutAccept(
							id,
							firstName
								? {
										firstName: this.userInfo?.firstName,
										lastName: this.userInfo?.lastName,
										nationalId: this.userInfo?.jmbg,
										country: this.userInfo?.country,
										passport_number: this.userInfo?.passportNumber,
								  }
								: null
						);
						ticketSocket.join(`alea.${id}`);
						this.resetTriggerStateAction();
						return;
					}
				}
			} else {
				this.resetTriggerStateAction();
			}
		},
	},
	methods: {
		...mapActions([
			'focusCodeAction',
			'focusOddAction',
			'deleteUserInfoAction',
			'setTriggerTypeAction',
			'setCashoutMessageAction',
			'resetTriggerStateAction',
		]),
		...mapMutations(['openConfirmationModal', 'setSubmitting', 'setTempCashoutTicket']),

		getTicketByBarcode() {
			const { setTicket, setSidebar, ticketBarcode: barcode, pin } = this;
			if (!pin) {
				this.copyTicket();
				this.focusCodeAction();
				return;
			}

			if (this.modalBarcodeScanCheck('pin')) return;
			this.resetTriggerStateAction();

			setTicket(barcode, pin);
			setSidebar(false);
		},

		handleCashoutClick() {
			if (this.promotionsCount) {
				this.togglePromoPinModal();
			} else {
				this.openConfirmationModal({
					title: this.$t('confirmations.general'),
					text: this.$t('confirmations.refund', {
						barcode: this.barcode,
						payIn: `${this.originalAmount} ${this.currency}`,
					}),
					confirmAction: () => this.refund(this.barcode, this.ticketPin, null),
				});
			}
		},

		async cashoutClicked() {
			const { id, cashout, cashoutAmount, cashoutRequested, cashoutLock, requestCashout, barcode, pin } = this;
			if (!cashoutAmount || cashoutRequested || cashoutLock) return;
			if (cashout) {
				const { authStatus, payoutStatus } = cashout;
				if (authStatus === ACCEPTED && payoutStatus === SENT) {
					const firstName = this.userInfo?.firstName;
					const lastName = this.userInfo?.lastName;
					const jmbg = this.userInfo?.jmbg;
					const country = this.userInfo?.country;
					const passportNumber = this.userInfo?.passportNumber;

					if (!jmbg && !passportNumber) {
						const ticketPayoutRequiresPlayerData = await this.$api.makeCheckTicketPayoutRequest(
							id,
							+cashoutAmount.toFixed(2)
						);

						if (ticketPayoutRequiresPlayerData === true) {
							window.AleaControlShell.openPlayerDetailsForm(`${barcode}+${pin}`);
							this.setTriggerTypeAction('accept');
							return;
						}
					}

					const userInfo =
						jmbg || passportNumber
							? { firstName, lastName, nationalId: jmbg, country, passport_number: passportNumber }
							: null;
					this.$api.cashoutAccept(id, userInfo);
					ticketSocket.join(`alea.${id}`);

					this.resetTriggerStateAction();

					return;
				}
			}

			const cashoutRiskLimit = getGeoParamsData(['BETTING', 'CASH_OUT', 'CASH_OUT_RISK_AUTHORIZATION_LIMIT']);
			if (cashoutRiskLimit && cashoutAmount > cashoutRiskLimit) {
				this.showCashoutRiskModal = true;
			} else {
				requestCashout('');
			}
		},

		closeAuthModal() {
			this.showCashoutRiskModal = false;
		},

		toggleAcceptChanges() {
			this.acceptCashoutChanges = !this.acceptCashoutChanges;
		},

		async requestCashout(message) {
			const {
				id,
				systems,
				amount,
				minQuota,
				maxQuota,
				maxGain,
				combinations,
				currency,
				barcode,
				cashoutAmount,
				cashoutLock,
				setTempCashoutTicket,
				placedDatetime,
				cashoutRequested,
				acceptCashoutChanges,
				resultedQuota,
				bonus,
				pin,
				originalAmount,
			} = this;
			if (!cashoutAmount || cashoutRequested || cashoutLock) return;
			try {
				this.cashoutLock = true;
				this.setSubmitting(true);
				const room = `alea.${id}`;
				ticketSocket.join(room);
				setTimeout(() => {
					if (this.submitting) this.setSubmitting(false);
				}, 10000);
				try {
					const firstName = this.userInfo?.firstName;
					const lastName = this.userInfo?.lastName;
					const jmbg = this.userInfo?.jmbg;
					const country = this.userInfo?.country;
					const passportNumber = this.userInfo?.passportNumber;

					if (!jmbg && !passportNumber) {
						const ticketPayoutRequiresPlayerData = await this.$api.makeCheckTicketPayoutRequest(
							id,
							+cashoutAmount.toFixed(2)
						);

						if (ticketPayoutRequiresPlayerData === true) {
							window.AleaControlShell.openPlayerDetailsForm(`${barcode}+${pin}`);

							this.setTriggerTypeAction('request');
							this.setCashoutMessageAction(message);

							return;
						}
					}

					await this.$api.cashoutTicket({
						id,
						barcode,
						amount: +cashoutAmount.toFixed(2),
						acceptChanges: acceptCashoutChanges,
						operatorMessage: message,
						user_info:
							jmbg || passportNumber
								? {
										firstName: firstName,
										lastName: lastName,
										nationalId: jmbg,
										country: country,
										passport_number: passportNumber,
								  }
								: null,
					});
					this.resetTriggerStateAction();
				} catch (err) {
					ticketSocket.leave(room);
					this.resetTriggerStateAction();
				}
				if (this.showCashoutRiskModal) {
					this.showCashoutRiskModal = false;
				}
				setTempCashoutTicket({
					id,
					systems,
					amount,
					minQuota,
					maxQuota,
					maxGain,
					combinations,
					cashout: cashoutAmount,
					status: SENT,
					currency,
					placedDatetime,
					operaterMessage: message,
					resultedQuota,
					barcode,
					bonus,
					originalAmount,
				});
			} catch (error) {
				console.error(error);
				this.resetTriggerStateAction();
			} finally {
				this.cashoutLock = false;
			}
		},
	},
};
</script>
