<template>
	<div class="app_nav">
		<router-link tag="button" class="btn" active-class=" active" to="/home" exact>{{ $t('home') }}</router-link>
		<router-link
			tag="button"
			class="btn"
			active-class=" active"
			to="/"
			@click.native="payInNavigation()"
			event
			exact
			>{{ $t('payin') }}</router-link
		>
		<router-link tag="button" class="btn" active-class="active" to="/tickets">{{ $t('preview') }}</router-link>

		<div class="drop_menu down z_1" @click="toggleIbet">
			<button class="btn" @blur="closeIbet">
				{{ $t('online.title') }}
				<i class=" mdi mdi-menu-down " />
			</button>
			<div class="drop_list left_0 z_2" :class="{ toggle: ibetExpanded }">
				<div @click="toggleRegistrationModal" class="drop_list_item font_400">{{ $t('online.registration') }}</div>
				<div @click="toggleDepositModal" class="drop_list_item font_400">{{ $t('online.depositShort') }}</div>
				<div @click="toggleWithdrawalModal" class="drop_list_item font_400">{{ $t('online.withdrawalShort') }}</div>
			</div>
		</div>
		<button
			class="btn"
			@click="
				toggleExpressTicketModal();
				focusOddAction({ value: false });
			"
		>
			{{ $t('expressTicket') }}
		</button>
		<router-link tag="button" class="btn" active-class="active" to="/balance">{{ $t('balance') }}</router-link>
		<Switcher />
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { getDefaultAmount } from '../../utils';
import Switcher from './Switcher.vue';
export default {
	data() {
		return {
			ibetExpanded: false,
		};
	},
	computed: {
		...mapGetters(['options']),
	},
	methods: {
		...mapActions(['focusOddAction']),
		...mapMutations([
			'toggleRegistrationModal',
			'toggleDepositModal',
			'toggleWithdrawalModal',
			'toggleExpressTicketModal',
			'clearAddedEvents',
			'addNewEmptySystem',
			'clearSystems',
			'updateTicketAmount',
		]),
		closeIbet() {
			const self = this;
			setTimeout(() => {
				self.ibetExpanded = false;
			}, 300);
		},
		toggleIbet() {
			this.ibetExpanded = !this.ibetExpanded;
		},
		payInNavigation() {
			const { clearSystems, clearAddedEvents, addNewEmptySystem, updateTicketAmount, options } = this;
			if (this.$router.currentRoute.path === '/') {
				clearSystems();
				clearAddedEvents();
				addNewEmptySystem();
				if (!options.savePayin) {
					updateTicketAmount(getDefaultAmount());
				}
			} else this.$router.push('/');
		},
	},
	components: { Switcher },
};
</script>
