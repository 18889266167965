import i18n from '../../localisation/i18n.js';

export const statusesMap = {
	REQUESTED: {
		text: i18n.t('REQUESTED'),
		bgColor: 'gray_darken_bg',
		number: 0,
	},
	PLACED: {
		text: i18n.t('PLACED'),
		bgColor: 'orange_bg',
		number: 1,
	},
	REFUNDED: {
		text: i18n.t('REFUNDED'),
		bgColor: 'gray_darken_bg',
		number: 2,
	},
	WINNING: {
		text: i18n.t('WINNING'),
		bgColor: 'green_bg',
		number: 3,
	},
	LOSING: {
		text: i18n.t('LOSING'),
		bgColor: 'redish_bg',
		number: 4,
	},
	INVALID: {
		text: i18n.t('INVALID'),
		bgColor: 'gray_darken_bg',
		number: 5,
	},
	DELETED: {
		text: i18n.t('DELETED'),
		bgColor: 'red_bg',
		number: 6,
	},
	PAID_OUT: {
		text: i18n.t('PAID_OUT'),
		bgColor: 'mb_green_bg',
		number: 7,
	},
	CASHBACK: {
		text: `${i18n.t('PAID_OUT')} (CASHBACK)`,
		bgColor: 'mb_green_bg',
		number: 3,
	},
	CASHOUT: {
		text: `${i18n.t('PAID_OUT')} (CASHOUT)`,
		bgColor: 'mb_green_bg',
		number: 3,
	},
	EXPIRED: {
		text: `${i18n.t('EXPIRED')}`,
		bgColor: 'redish_bg',
		number: 9,
	},
};
