import { formatDate } from '.';
import { calculateTaxForPlayedTicket } from './ticket/calculateTaxForPlayedTicket';
import { formatTicketPromotions } from './ticket/formatTicketPromotions';

/**
 * Transforms events utc_scheduled to local time
 */
export function transformTicketForPrint(data, marketsMap, isCopy = false, offerPlans = {}) {
	const systemString = data.systems
		.map(({ required, events }, i) => `S${i + 1}: ${required.sort((a, b) => a - b).join(', ')} od ${events.length}`)
		.join(';');

	const transformedSystems = data.systems.map(({ events, required }) => {
		const transformed = events.map(({ utc_scheduled, sport, odds, ...rest }) => {
			const [date, time] = formatDate(utc_scheduled, 'year-month-date', 'hours:minutes');
			Object.keys(odds).forEach((key) => {
				const marketId = key.split(':')[0] + ':' + key.split(':')[1];
				const market = marketsMap[sport.id].markets.markets[marketId];
				const marketName = market.name;
				const completeOdd = market.oddtypes[key] ?? market.oddtypes[key.split('|')[0]];

				const oddNameSplited = completeOdd ? completeOdd.name : key.split(':')[2];
				const oddName = `${odds[key] && odds[key].bonus_type ? 'B ' : ''}${oddNameSplited}`;
				const [leftOddName, rightOddName] = oddName.split('|');
				const valueFixed = odds[key].value?.toFixed(2);

				const oddDescription = completeOdd ? completeOdd.description : '';
				odds[key] = { ...odds[key], marketName, oddName: leftOddName, oddDescription, value: valueFixed };
			});
			return {
				odds,
				sport,
				...rest,
				utc_scheduled: `${date}T${time}:00`,
			};
		});

		return { events: transformed, required };
	});

	const ticket_type =
		data.combinations > 1 ? 'SYSTEM' : data.systems.some(({ events }) => events.length > 1) ? 'MULTI' : 'SINGLE';

	const date = new Date(data.dt_placed + 'Z').toLocaleString();
	const { amount, bonus, combinations, min_quota, max_quota, max_gain } = data;
	const bonus_amount = +(bonus * (max_quota * amount)).toFixed(2);
	const min_gain = +((amount * min_quota) / combinations + bonus_amount).toFixed(2);
	const base_max_gain = +((max_quota * amount) / combinations).toFixed(2);
	let offer_plan = '/';
	const { id_offer_plan = '' } = data;
	const offerPlan = offerPlans[id_offer_plan];
	if (offerPlan && offerPlan.name)
		offer_plan = offerPlan.name
			.toLowerCase()
			.replace('plan', '')
			.trim();

	const {
		onlineInstanceParameters: { DOMAIN_NAME: domainName = '' },
	} = JSON.parse(localStorage.getItem('POS_device_info') || '{onlineInstanceParameters: {}}') || {
		onlineInstanceParameters: {},
	};

	const qrtext = `${domainName}/ticket-preview/?ticket=${data.barcode ?? ''}`;

	let min_tax_value = 0;
	let max_tax_value = 0;
	if (window.geoParams && window.geoMappings) {
		const { minTax, maxTax } = calculateTaxForPlayedTicket(
			{ minValue: min_gain, maxValue: +data.max_gain.toFixed(2) },
			amount,
			window.geoParams,
			'en',
			{
				parameterMappings: window.geoMappings,
			}
		);
		min_tax_value = minTax;
		max_tax_value = maxTax;
	}

	const language = localStorage.getItem('language') ?? '';
	const companyName =
		JSON.parse(localStorage.getItem('POS_device_info'))?.onlineInstanceParameters?.EMAIL_TEMPLATE_COMPANY_NAME ?? '';

	const ticketPromotionPayload = data.promotion_data
		? formatTicketPromotions(data, language, offer_plan, date, companyName)
		: [];

	return {
		...data,
		min_quota: min_quota.toFixed(2),
		max_quota: max_quota.toFixed(2),
		max_gain: +(data.max_gain - max_tax_value).toFixed(2),
		dt_placed: date,
		systemString,
		isCopy,
		ticket_type,
		offer_plan,
		min_gain: min_gain < max_gain.toFixed(2) ? min_gain : max_gain.toFixed(2),
		bonus_amount,
		base_max_gain: base_max_gain < max_gain.toFixed(2) ? base_max_gain : max_gain.toFixed(2),
		systems: transformedSystems,
		qrtext,
		min_tax_value: +min_tax_value.toFixed(2),
		max_tax_value: +max_tax_value.toFixed(2),
		min_gain_after_tax: +(min_gain - min_tax_value).toFixed(2),
		base_max_gain_after_tax: +(base_max_gain - max_tax_value).toFixed(2),
		max_gain_after_tax: +(+max_gain.toFixed(2) - max_tax_value).toFixed(2),
		amount: (data.tax_data?.original_amount || data.original_amount) ?? data.amount,
		amount_tax_value: +(
			((data.tax_data?.original_amount || data.original_amount) ?? data.amount) - data.amount
		).toFixed(2),
		amount_after_tax: data.amount,
		TicketPromotion: ticketPromotionPayload,
	};
}
