<template>
	<tr :class="`table_row ${removed ? 'red_row disabled' : added ? 'green_row' : ''}`">
		<td class="" />
		<td class="small" />
		<td class="strong text_right padding_right_5" />
		<td class="padding_left_0 padding_right_0 small vs text_center" />
		<td class="strong text_left padding_left_5" />
		<td class="padding_right_10 text_right">{{ marketName }}</td>
		<td class="padding_left_0 strong">
			<span v-if="odd.special_value || (prevOdd && prevOdd.specialValue)" class="border_left">
				<div v-if="prevOdd && prevOdd.specialValue !== odd.special_value" class="margin_change tooltip_right">
					<div class="margin_new">{{ odd.special_value }}</div>
					<div class="margin_old">{{ prevOdd.specialValue }}</div>
					<span class="tooltip_content yellow">{{ $t('marginChanged') }}</span>
				</div>
				<div v-else-if="odd.special_value">{{ odd.special_value }}</div>
			</span>
		</td>
		<td class="strong">{{ `${odd.bonusType || odd.bonus_type ? `B ${oddName}` : oddName || ''} ` }}</td>
		<td />
		<!-- Stake type -->
		<td class="padding_right_10 text_right">
			<div v-if="prevOdd && prevOdd.stake !== odd.stake" class="odd_change margin_left_auto tooltip_left">
				<div class="odd_old">{{ oddsFormat(+prevOdd.stake) }}</div>
				<div class="odd_new odd_up" :class="`odd_new ${prevOdd.stake < odd.stake ? 'odd_up' : 'odd_down'}`">
					{{ oddsFormat(+odd.stake) }}
				</div>
				<span class="tooltip_content green" :class="prevOdd.stake < odd.stake ? 'green' : 'red'">
					{{ $t('oddChanged') }}
				</span>
			</div>

			<div v-else>
				{{ oddsFormat(+odd.stake) }}
			</div>
		</td>
		<td class="condition" />
	</tr>
</template>

<script>
import { memoize } from '../../Memoization';
import { formatting } from '../../../mixins';
import { mapGetters } from 'vuex';

export default {
	mixins: [memoize('event'), formatting],
	props: ['event', 'removed', 'added', 'oldEvent'],
	computed: {
		...mapGetters(['eventOddsData', 'marketsMap']),

		odd() {
			const { value: stake, ...rest } = this.event || {};
			return { stake, ...rest };
		},
		prevOdd() {
			const { value: stake, ...rest } = this.prev_event || {};

			if (!stake) {
				if (!this.oldEvent) return null;

				if (this.oldEvent.type === 'event') {
					const [{ value: stake, ...rest }] = Object.values(this.oldEvent.odds);
					return { stake, ...rest };
				}
				else
				{
					const { value: stake, ...rest } = this.oldEvent;
					return { stake, ...rest };
				}
			}
			return { stake, ...rest };
		},
		oddName() {
			const { id, sport, value, specialValue } = this.event;
			const oddData = { [id]: { value, specialValue } };
			const [odd] = this.eventOddsData(oddData);
			return odd ? odd.name : '';
		},
		marketName() {
			const {
				marketsMap,
				odd: { id: oddId },
			} = this;
			const {
				sport: { id: sportId },
			} = this.event;
			const parts = oddId.split(':');
			const marketId = parts.length ? parts[0] + ':' + parts[1] : null;
			const { name = '' } = marketId && marketsMap ? marketsMap[sportId].markets.markets[marketId] : {};
			return name;
		},
	},
};
</script>
