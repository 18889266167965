export function normalizeSystem(system, systemIndex) {
	if (!system || !system.events) return;
	const rows = [];

	system.events.forEach((event, eventIndex) => {
		const { odds = [], isNew, id: eventId, listCode: eventListCode } = event;

		const [firstOdd = {}, ...restOdds] = odds;

		// Create row for the empty new event
		if (isNew) {
			return rows.push({
				type: 'newEvent',
				eventId,
				eventIndex,
				systemIndex,
				rowIndex: rows.length,
				rowId: `row:new:event:${systemIndex}:${eventId}`,
				uid: `newEvent:${systemIndex}:${eventId}:${rows.length}`,
				event,
			});
		}

		// Create row for the event
		rows.push({
			type: 'event',
			eventId,
			eventIndex,
			systemIndex,
			eventListCode,
			live: event.live,
			rowIndex: rows.length,
			rowId: `row:${systemIndex}:${rows.length}`,
			uid: `${systemIndex}:${eventId}:${firstOdd.id}`,
			odd: firstOdd,
			event,
		});

		// Push each additional odd to the rows array
		restOdds.forEach((odd, index) => {
			if (odd.isNew) {
				// Create row for the empty new odd
				rows.push({
					type: 'newOdd',
					eventId,
					eventIndex,
					systemIndex,
					eventListCode,
					oddIndex: index + 1,
					rowIndex: rows.length,
					rowId: `row:new:odd:${systemIndex}:${eventId}`,
					uid: `newOdd:${systemIndex}:${eventId}:${rows.length}`,
					odd,
				});
			} else {
				// Create row for the odd
				rows.push({
					type: 'odd',
					eventId,
					eventIndex,
					systemIndex,
					eventListCode,
					live: event.live,
					oddIndex: index + 1,
					rowIndex: rows.length,
					rowId: `row:${systemIndex}:${rows.length}`,
					uid: `${systemIndex}:${eventId}:${odd.id}`,
					odd,
				});
			}
		});
	});

	return { ...system, index: systemIndex, rows };
}
