<template>
	<tr class="table_row">
		<td class="p_0 row_number" colspan="6">
			<span class="remove_row">
				<i
					class="mdi mdi-close-circle tooltip_right"
					@click="removeEventOdd({ systemIndex, eventIndex, oddId: odd.id })"
				>
					<span class="tooltip_content">{{ $t('deleteOddtype') }}</span>
				</i>
			</span>
		</td>
		<td class="p_0 game_input">
			<span class="tooltip_right">
				<input
					ref="odd"
					type="text"
					class="td_input"
					:value="odd.name"
					:id="`${row.rowId}:odd`"
					:name="`${row.rowId}:odd`"
					@focus="
						focusHandler(`${row.rowId}:odd`);
						resetfocusInputTimer();
					"
					@keyup.up="oddUp"
					@keyup.down="oddDown"
					@keyup.left="oddLeft"
					@keyup.right="oddRight"
					@keyup.enter="oddEnter"
					@keydown.enter="oddEnterHandler"
					@keyup="handleKeyUp"
					autocomplete="event-odd-no-autocomplete"
				/>

				<span class="tooltip_content">{{
					odd.id.startsWith('l')
						? `${getMarketDescription(odd)}${`${specialValue != null ? ` ${specialValue} ` : ''}`} | ${
								odd.description
						  }`
						: odd.description
				}}</span>
			</span>
		</td>
		<Stake :eventId="eventId" :oddId="odd.id" :bonus="bonusOdd" :live="live" />
		<!-- <td v-if="bonusOdd">
			<span class="svg_icon tooltip_right">
				<svg><use xlink:href="/svg/sprite_mb.svg#bonus_tip_18"></use></svg>
				<span class="tooltip_content">{{ $t('bonusOdd') }}</span>
			</span>
		</td>
		<SpecialValue v-else :eventId="eventId" :oddId="odd.id" :bonus="bonusOdd" /> -->
		<td class="pl_0 add_game"></td>
		<td class="pr_0 league" colspan="3">{{ odd.description }}</td>
		<td></td>
	</tr>
</template>

<script>
import { keys } from 'keycodes-map';
import {
	eventNavigation,
	computedEvent,
	createEditEvent,
	inputHandler,
	eventHelpers,
	focusInputConfig,
} from '../../mixins';
import Stake from './Stake';
import SpecialValue from './SpecialValue';
import { MAX_EVENT_ODDS } from '../../consts';

export default {
	mixins: [eventNavigation, computedEvent, createEditEvent, inputHandler, eventHelpers, focusInputConfig],
	props: ['row', 'system'],
	components: { Stake, SpecialValue },
	computed: {
		live() {
			const { row } = this;
			return row.live;
		},
	},

	methods: {
		oddEnterHandler() {
			this.focusInputTimer++;

			if (this.focusInputTimer === this.defaultFocusInputTimer) {
				this.oddEnter();
				this.resetfocusInputTimer();
			}
		},

		handleKeyUp(e) {
			if (this.scanning) return;
			if (e.keyCode === keys.insert) this.enableScan();

			this.oddStar(e);
			this.oddsModal(e);
		},
		oddEnter() {
			if (this.scanning) return;
			const { systemIndex, eventIndex, eventId } = this;
			const { odd, isLastRow } = this;
			const { listCode, odds: eventOdds, live } = this.ticketEvent(systemIndex, eventIndex) || {};

			let oddCode = this.getOddCode();
			if (live && !oddCode) return this.openLiveOddsModal(eventId);
			const oddId = odd.id;
			if (!oddCode) return;

			const oddCodeTrim = oddCode.replaceAll(' ', '');
			if (!this.panelBarcodeScanCheck(oddCodeTrim)) return;

			if (oddCode === odd.name) {
				if (!isLastRow) return this.oddRight();
				else return this.addNewEmptyEvent(systemIndex);
			}

			if (live && oddCode) return this.useLiveOfferNotify();

			const bonus = this.isBonusOddCode(oddCode);
			if (bonus) {
				oddCode = oddCode.slice(1);
			}

			const { sport = {}, odds = [] } =
				(bonus
					? this.$store.getters.bonusOddsEventData(listCode)
					: live
					? this.$store.getters?.liveEventData(listCode)
					: this.$store.getters?.prematchEventData(listCode)) || {};

			const oddType = this.oddTypeData({ oddCode, sportId: sport.id, odds, bonus });
			if (!oddType) return this.noOddTypeNotify();

			if (odd.keyboardCode !== oddType.keyboardCode) {
				// Check if we played that oddType
				const exists = eventOdds.find(({ keyboardCode }) => keyboardCode === oddType.keyboardCode);
				if (exists) {
					if (Boolean(exists.bonusType) === bonus) {
						return this.oddAlreadyExists();
					} else return this.cannotCombineSame();
				}
			}

			this.replaceEventOdd({
				newOdd: oddType,
				systemIndex,
				eventIndex,
				oddId,
			});

			if (isLastRow) this.addNewEmptyEvent(systemIndex);
			else this.oddRight();
		},
		oddStar(e) {
			if (e.keyCode !== keys.numpadMultiply) return;
			const { systemIndex, eventIndex } = this;
			const { $refs, odd, maxEventOddsNotify } = this;
			const oddValue = this.getOddCode();
			if (!oddValue) return;

			// Remove the star symbol from the oddCode
			// and update the input field
			let oddCode = oddValue.replace('*', '') || odd.name;
			$refs.odd.value = oddCode;

			const bonus = this.isBonusOddCode(oddCode);
			if (bonus) {
				oddCode = oddCode.slice(1);
			}

			const { listCode, odds: eventOdds, live } = this.ticketEvent(systemIndex, eventIndex) || {};

			if (eventOdds.length === MAX_EVENT_ODDS) {
				maxEventOddsNotify(`${this.row.rowId}:odd`);
				return;
			}

			if (oddCode === odd.name) return this.addNewEmptyOdd({ systemIndex, eventIndex });

			const { sport = {}, odds = [] } =
				(bonus
					? this.$store.getters.bonusOddsEventData(listCode)
					: live
					? this.$store.getters?.liveEventData(listCode)
					: this.$store.getters?.prematchEventData(listCode)) || {};
			const oddType = this.oddTypeData({ oddCode, sportId: sport.id, odds, bonus });
			if (!oddType) return this.noOddTypeNotify();

			if (odd.keyboardCode !== oddType.keyboardCode) {
				// Check if we played that oddType
				const exists = eventOdds.find(({ keyboardCode }) => keyboardCode === oddType.keyboardCode);
				if (exists) {
					if (Boolean(exists.bonusType) === bonus) {
						return this.oddAlreadyExists();
					} else return this.cannotCombineSame();
				}
			}
			this.replaceEventOdd({
				systemIndex,
				eventIndex,
				oddId: odd.id,
				newOdd: oddType,
			});
			this.addNewEmptyOdd({ systemIndex, eventIndex });
		},

		oddsModal(e) {
			if (e.keyCode !== keys.ctrl) return;
			const { eventId } = this;
			const liveEvent = this.$store.getters.liveEventDataById(eventId);
			if (liveEvent?.live) this.openLiveOddsModal(eventId);
			else this.openOddsModal();
		},
	},
};
</script>
