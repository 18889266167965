<template>
	<tr :class="[{ selected: rowSelected }, ' table-row']" :id="`odds-modal:${row}`" @click="clickedOdd" ref="row">
		<td class="typing_code_num strong padding_left_20">{{ bonus ? `B${keyboardCode}` : keyboardCode }}</td>
		<td class="odd_type_description">{{ description }}</td>
		<td class="strong">{{ bonus ? `B ${name}` : name }}</td>
		<td class="strong">{{ stake }}</td>
		<td v-for="(code, i) in altCodes" :key="`${code}:${i}`" class="typing_code">{{ code.toUpperCase() }}</td>
	</tr>
</template>

<script>
import { formatting } from '../../../mixins';

export default {
	mixins: [formatting],
	props: ['odd', 'row', 'rowSelected', 'oddClick', 'selectOdd', 'bonus'],

	computed: {
		keyboardCode() {
			const { keyboardCode } = this.odd;
			return keyboardCode;
		},
		description() {
			const { description = {} } = this.odd || {};
			return description || '';
		},
		name() {
			const { name = {} } = this.odd || {};
			return name || '';
		},
		stake() {
			const { stake, specialValue } = this.odd;
			if (specialValue) {
				return `${this.oddsFormat(+stake)} | ${specialValue}`;
			}
			return this.oddsFormat(+stake);
		},
		altCodes() {
			const { alternative_keyboard_codes, keyboardCode } = this.odd;
			if (!alternative_keyboard_codes) return [];
			const filteredAltCodes = alternative_keyboard_codes.filter((code) => code !== keyboardCode);
			const codes = [0, 1, 2, 3].map((index) => {
				return filteredAltCodes[index] || ' ';
			});
			return codes;
		},
	},
	methods: {
		clickedOdd() {
			this.oddClick(this.odd);
		},
	},
};
</script>
