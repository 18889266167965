import { bpapi } from './instance';
import { toCsv } from '../utils';
import i18n from '../localisation/i18n';
import { GET_CHECK_TICKET_PAY_OUT_PATH } from './types';

const eventFields = 'listCode,sport,category,tournament,competitors,odds,utc_scheduled';
const liveEventFields = 'listCode,sport,category,tournament,competitors,odds,utc_scheduled,event_status,scores,period';
const lang = i18n.locale || 'en';

export default {
	registerTempToken(token = '') {
		return bpapi.post(`/auth/device`, null, {
			params: {
				tempToken: token,
				lang,
			},
		});
	},
	getGeoParamsMapping() {
		return bpapi.get('/geo/params/mappings');
	},
	getSports({ lang = i18n.locale } = {}) {
		return bpapi.get(`/sports`, { params: { lang } });
	},

	getMarkets({ extended = true, lang = i18n.locale } = {}) {
		return bpapi.get(`/markets`, {
			params: {
				extended,
				lang,
				platform: 'cashier',
			},
		});
	},

	getOfferPlans(limit = 5, offset = 0) {
		return bpapi.get(`/offer_plans`, {
			params: {
				limit,
				offset,
			},
		});
	},

	getEvent(id) {
		return bpapi.get(`/event/list_code/-133769420?idEvent=${encodeURIComponent(id)}`, { params: { lang } });
	},

	getEventByListCode(listCode) {
		return bpapi.get(`/event/list_code/${listCode}`, { params: { lang } });
	},

	getEvents({ lang = i18n.locale, fields = eventFields, offset = 0, limit = 100, markets }) {
		const start = new Date();
		const end = new Date();
		end.setFullYear(start.getFullYear() + 100);

		return bpapi.get(`/events`, {
			params: {
				lang,
				offer_plan: true,
				live: false,
				fields: toCsv(fields),
				offset,
				limit,
				markets,
				start: start.toISOString(),
				end: end.toISOString(),
			},
		});
	},

	getCachedEvents({ lang = i18n.locale, fields = eventFields, offset = 0, limit = 100, markets }) {
		const start = new Date();
		const end = new Date();
		end.setFullYear(start.getFullYear() + 100);

		return bpapi.get(`/events_cached`, {
			params: {
				lang,
				offer_plan: true,
				live: false,
				fields: toCsv(fields),
				offset,
				limit,
				markets,
				start: start.toISOString(),
				end: end.toISOString(),
			},
		});
	},

	getMessagesForCachedEvents(params) {
		return bpapi.get('/events/messages', { params });
	},

	getEventsData({ lang = i18n.locale, fields = eventFields, offset = 0, limit = 100, events = [], markets = [] }) {
		return bpapi.get('/events', {
			params: {
				lang,
				fields: toCsv(fields),
				offset,
				limit,
				markets,
				events,
			},
		});
	},

	getLiveEvents({ lang = i18n.locale, fields = liveEventFields, offset = 0, limit = 100, markets }) {
		return bpapi.get(`/events`, {
			params: {
				lang,
				live: true,
				offer_plan: true,
				fields: toCsv(fields),
				offset,
				limit,
				markets,
			},
		});
	},

	getCachedLiveEvents({ lang = i18n.locale, fields = liveEventFields, offset = 0, limit = 100, markets }) {
		return bpapi.get(`/events_cached`, {
			params: {
				lang,
				live: true,
				offer_plan: true,
				fields: toCsv(fields),
				offset,
				limit,
				markets,
			},
		});
	},

	getBonusEvents(lang = i18n.locale, fields = eventFields, offset = 0, limit = 100, markets) {
		return bpapi.get(`/bonus`, {
			params: {
				lang,
				fields: toCsv(fields),
				limit,
				offset,
				markets,
			},
		});
	},

	getCachedBonusEvents(lang = i18n.locale, fields = eventFields, offset = 0, limit = 100, markets) {
		return bpapi.get(`/bonus_cached`, {
			params: {
				lang,
				fields: toCsv(fields),
				limit,
				offset,
				markets,
			},
		});
	},

	getBonusOddsForEvent(eventId) {
		return bpapi.get(`/event/bonus/${encodeURIComponent(eventId)}`, { params: { lang } });
	},

	getEventData(id) {
		return bpapi.get(`/event/${id}`, { params: { lang } });
	},

	getTickets({ offset = 0, limit = 100, statuses = [], request_date = '', device_filter = false }) {
		const { TIME_ZONE } = JSON.parse(localStorage.getItem('POS_device_info'));
		return bpapi.get(`/tickets/all`, {
			params: {
				offset,
				limit,
				statuses: statuses.join(','),
				request_date,
				timezone: TIME_ZONE ?? 'Europe/Belgrade',
				device_filter,
			},
		});
	},

	getTicket(id) {
		return bpapi.get(`/tickets/`, {
			params: {
				ticketId: id,
				lang,
			},
		});
	},
	getTicketByBarcode(barcode, pinCode) {
		return bpapi.get(`/tickets/`, {
			params: {
				ticketBarcode: barcode,
				pinCode,
				lang,
			},
		});
	},

	getPromoCodeValue(pinCode) {
		return bpapi.get(`/tickets/ticket/check_promotion/${pinCode}`, {
			params: {
				lang,
			},
		});
	},

	reserveTicketId() {
		return bpapi.get('/tickets/reserve_ticket_id/');
	},
	sendTicket(ticket) {
		return bpapi.post(`/tickets/`, ticket);
	},
	handleModifiedTicket(ticket) {
		return bpapi.post(`/tickets/resubmit_modified`, ticket);
	},
	withdrawTicket(id) {
		return bpapi.post(`/tickets/withdraw`, null, {
			params: {
				id_ticket: id,
				id_location: window.idLocation,
			},
		});
	},
	refundTicket(id, pin, promoPin) {
		return bpapi.post(`/tickets/refund`, promoPin, {
			params: {
				id_barcode: id,
				pin_code: pin,
				id_location: window.idLocation,
			},
		});
	},
	payOutTicket(id, pin, firstName, lastName, jmbg, country, passportNumber) {
		return bpapi.post(`/tickets/pay_out`, null, {
			params: {
				id_barcode: id,
				pin_code: pin,
				id_location: window.idLocation,
			},
			data: firstName
				? {
						firstName: firstName ? firstName : '',
						lastName: lastName ? lastName : '',
						nationalId: jmbg ?? null,
						country: country ?? null,
						passport_number: passportNumber ?? null,
				  }
				: undefined,
		});
	},
	createUser(user) {
		return bpapi.post(`/users/pos_registration/`, user);
	},
	getUserByEmail(email) {
		return bpapi.get(`/users/user/`, {
			params: {
				email,
				lang,
			},
		});
	},
	getUserByCode(code) {
		return bpapi.get(`/users/user/`, {
			params: {
				code,
				lang,
			},
		});
	},
	depositByEmail(email, amount) {
		return bpapi.post(`/wallet/addOnlineBalanceByEmail`, { email, amount });
	},
	depositByCode(code, amount) {
		return bpapi.post(`/wallet/addOnlineBalanceById`, { idUser: code, amount });
	},
	getReservationByEmail(email, pin) {
		return bpapi.get(`/wallet/reservationByUserEmail/${email}`, {
			params: {
				idLocation: window.idLocation,
				pin,
			},
		});
	},
	getReservationByCode(code, pin) {
		return bpapi.get(`/wallet/reservationByUserId/${code}`, {
			params: {
				idLocation: window.idLocation,
				pin,
			},
		});
	},
	withdrawMoney(idUser, pin, amount) {
		return bpapi.post(`/wallet/payout`, { idLocation: window.idLocation, pin, idUser, amount });
	},

	getEventsResults({ events = [] } = {}) {
		return bpapi.get(`/results`, { params: { event_ids: events.join(','), lang } });
	},

	cashoutTicket(cashoutParams) {
		return bpapi.post('/tickets/cashout', cashoutParams);
	},

	cashoutAccept(id, userInfo = null) {
		return bpapi.post('/tickets/cashout/accept', { id, payoutStatus: 'ACCEPTED', userInfo });
	},

	cashoutDeny(id) {
		return bpapi.post('/tickets/cashout/accept', { id, payoutStatus: 'DENIED' });
	},

	getTournamentRiskCategories(params) {
		return bpapi.get(`/tournament_risk_categories`, { params });
	},

	getExpressTicket(code) {
		return bpapi.get(`/tickets/ticket/express/${code}`, { params: { lang } });
	},
	getBalanceSums(summaryDate, deviceFilter = false) {
		return bpapi.get(`/reporting/summary`, {
			params: {
				summaryDate,
				deviceFilter,
			},
		});
	},
	getBalanceTransactions(date) {
		return bpapi.get(`/reporting/transactions?summaryDate=${date}`);
	},
	getIbetTransactions({ skip = 0, limit = 100, summaryDate = '', deviceFilter = false }) {
		return bpapi.get(`/reporting/transactions`, {
			params: {
				skip,
				limit,
				summaryDate,
				deviceFilter,
			},
		});
	},

	getOperaterInfo() {
		return bpapi.get('/users/operator_info');
	},

	/**
	 * @param {string} ticketid
	 * @param {number | undefined} cash_out_amount
	 * @returns {boolean} if data needs to be provided it will returns 'true'.
	 */
	makeCheckTicketPayoutRequest(ticketid, cash_out_amount) {
		return bpapi.get(GET_CHECK_TICKET_PAY_OUT_PATH, {
			params: {
				id_ticket: ticketid,
				cash_out_amount: cash_out_amount,
			},
		});
	},

	getCashedTicket(ticketId) {
		return bpapi.get(`/tickets/cached/${ticketId}`);
	},

	changeLanguage(language) {
		return bpapi.post(`/users/language?language=${language}`);
	},
};
