<template>
	<div class="component">
		<div class="count_row">
			<div class="label">{{ $t('bonus') }} ({{ (bonusPercentage * 100).toFixed(2) }} %)</div>
			<div class="value">{{ toMoneyFormat(bonus) }}</div>
		</div>
		<div class="count_row">
			<div v-if="bonusPercentage && bonusPercentage > 0 && bonus === 0" class="label redish_text font_12px font_600">
				{{ $t('bonusMinPayIn', { minPayIn: toMoneyFormat(minPayInValue) }) }}
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatting } from '../../mixins';

export default {
	mixins: [formatting],
	data() {
		return {
			minPayIn: 0,
		};
	},
	computed: {
		...mapGetters(['bonus', 'bonusPercentage', 'minPayInValue']),
	},
};
</script>
