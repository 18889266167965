<template>
	<td class="pl_0 odd_margin strong">
		<span class="" :class="{ colorClass, border_left: value }">{{ value || '' }}</span>
	</td>
</template>

<script>
import { memoize } from '../Memoization';

export default {
	mixins: [memoize('value', 3000)],
	props: ['specialValue'],
	computed: {
		value() {
			return this.specialValue;
		},

		colorClass() {
			const { value, prev_value } = this;
			if (value === '-') return 'down';
			if (!prev_value || prev_value === value) return '';
			return prev_value > value ? 'down' : 'up';
		},
	},
};
</script>
<style scoped>
.up {
	background-color: green;
	color: white;
}
.down {
	background-color: red;
	color: white;
}
.warn {
	background-color: orange;
	color: white;
}
</style>
