import { geoParser } from '../geoParser';
import { round } from 'lodash';

const ParameterMappings = {
	SOCIAL_NETWORK_TEXT: 'SOCIAL_NETWORK_TEXT',
	MAIN_FOOTER: 'MAIN_FOOTER',
	ADDITIONAL_FOOTER: 'ADDITIONAL_FOOTER',
	FOOTER_TEXT: 'FOOTER_TEXT',
	FOOTER_SOCIAL_NETWORKS: 'FOOTER_SOCIAL_NETWORKS',
	PAY_OUT_TAX_LEVELS: 'PAY_OUT_TAX_LEVELS',
};

export const calculateTaxForPlayedTicket = (ticketGain, ticketAmount, geoParams, language, globalData) => {
	const taxValue = { minTax: 0, maxTax: 0 };
	const taxes = geoParams?.REGULATIVE?.TAXES;
	const amountField =
		globalData.parameterMappings.PAY_OUT_TAX_LEVELS?.field_type_specifics?.pydantic_fields[0] ?? 'amount';
	const taxField = globalData.parameterMappings.PAY_OUT_TAX_LEVELS?.field_type_specifics?.pydantic_fields[1] ?? 'tax';
	if (!taxes) {
		return taxValue;
	}
	const nonTaxes = (taxes.DEDUCTIBLE_PAY_OUT_AMOUNT ?? 0) + (taxes.EXCLUDE_PAY_IN_FROM_PAY_OUT_TAX ? ticketAmount : 0);
	const maxGainWithTaxes = ticketGain.maxValue - nonTaxes;
	const minGainWithTaxes = ticketGain.minValue - nonTaxes;
	const parsedPayOutParams = geoParser(
		globalData,
		taxes.PAY_OUT_TAX_LEVELS ?? '',
		ParameterMappings.PAY_OUT_TAX_LEVELS
	).sort((tax1, tax2) => +tax2[amountField] - +tax1[amountField]);

	const taxMaxGain = parsedPayOutParams.find((amount) => +amount[amountField] <= maxGainWithTaxes);
	const taxMinGain = parsedPayOutParams.find((amount) => +amount[amountField] <= minGainWithTaxes);

	const roundedMaxPart = round(round(maxGainWithTaxes * (1 - +(taxMaxGain?.[taxField] ?? 0)), 10), 2);
	const finalMaxTax = round(maxGainWithTaxes - roundedMaxPart, 2);

	const roundedMinPart = round(round(minGainWithTaxes * (1 - +(taxMinGain?.[taxField] ?? 0)), 10), 2);
	const finalMinTax = round(minGainWithTaxes - roundedMinPart, 2);

	if (ticketGain.minValue > 0) {
		taxValue.minTax = finalMinTax > finalMaxTax ? finalMaxTax : finalMinTax;
	}

	taxValue.maxTax = finalMaxTax > 0 ? finalMaxTax : 0;

	taxValue.minTaxPercentage = taxMinGain?.[taxField] ? +taxMinGain?.[taxField] : 0;
	taxValue.maxTaxPercentage = taxMaxGain?.[taxField] ? +taxMaxGain?.[taxField] : 0;

	return taxValue;
};
