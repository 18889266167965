import { selectDelayed } from '../utils';
import { BARCODE_REGEX, BARCODE_LENGTH } from '../consts';

export default {
	methods: {
		/**
		 * function that checks is barcode scanner used while focus was on some input field on modal, and if it is,
		 *  removes last 21 characters from value binded with that input, else calls next callback
		 */

		modalBarcodeScanCheck(element) {
			if (typeof this[element] !== 'string') return false;
			const contentLength = this[element].length;
			if (contentLength >= BARCODE_LENGTH) {
				const barcode = `${this[element]}`.substring(contentLength - BARCODE_LENGTH);
				const scan = barcode.match(BARCODE_REGEX);
				if (scan) {
					this[element] = `${this[element]}`.substring(0, contentLength - BARCODE_LENGTH);
					return true;
				} else return false;
			}
			return false;
		},

		focusOn(elementId) {
			selectDelayed(elementId, 150);
		},

		modalEnterHandler(el, next) {
			if (!this.modalBarcodeScanCheck(el)) return this.focusOn(next);
		},

		/**
		 * function that checks is barcode scanner used while focus was on some input field on panel
		 */
		panelBarcodeScanCheck(element) {
			const contentLength = element.toString().length;
			if (contentLength >= BARCODE_LENGTH) {
				const barcode = element.substring(contentLength - BARCODE_LENGTH);

				return barcode.match(BARCODE_REGEX);
			}
			return true;
		},
	},
};
