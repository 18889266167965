<template>
	<notifications group="payin" position="top center" :style="{ top: '45vh' }">
		<template slot="body" slot-scope="props">
			<div
				class="modal_cover sm modal_10 notification green toggle"
				:class="{
					red: props.item.type === 'error',
					yellow: props.item.type === 'warn',
					green: props.item.type === 'success',
				}"
			>
				<div class="modal_content fadeInDown animated faster disable_overflow disable_max_height">
					<button class="transparent close toggle_modal_10" @click="props.close">
						<i class="mdi mdi-close" />
					</button>

					<div class="modal_body content_center direction_column disable_overflow">
						<img :src="`img/${props.item.type === 'success' ? 'check1_b' : 'ex1_b'}.png`" class="bg_image_1" />

						<div v-if="props.item.data && props.item.data.multiline">
							<h2 class="modal_title" style="font-size: 20px;">{{ props.item.title }}</h2>
							<br />
							<span class="font_15px preformatted" v-for="(segment, index) in props.item.text" :key="index"
								>{{ segment }}
								<br />
								<br />
							</span>
						</div>

						<div v-else>
							<h2 class="modal_title ">{{ props.item.title }}</h2>
							<br />
							<span class="font_15px preformatted">{{ props.item.text }}</span>
						</div>

						<div class="form_row content_end margin_top_10 margin_bottom_0">
							<button
								class="btn md outline toggle_modal_10"
								id="close-notification"
								@keyup.enter.stop="props.close"
								@mousedown.stop="props.close"
							>
								OK
							</button>
						</div>
					</div>
				</div>
				<Actions :customId="props.item.data ? props.item.data.id : null" />
			</div>
		</template>
	</notifications>
</template>

<script>
import Actions from './Actions';
export default {
	components: {
		Actions,
	},
};
</script>

<style scoped>
.disable_overflow {
	overflow: hidden !important;
}

.disable_max_height {
	max-height: none !important;
}

.preformatted {
	white-space: pre-wrap;
}
</style>
