<template>
	<div>
		<div class="app_main event_set">
			<div class="content_wrapper full_width">
				<!-- Content -->
				<div class="app_content">
					<!-- Header -->
					<div
						class="content_1280 align_center justify_center padding_top_40 padding_bottom_20 padding_left_10 padding_right_10"
					>
						<label for="date" class="input_label margin_right_10">{{ $t('day') }}</label>
						<date-picker
							v-model="selectedDate"
							format="DD.MM.YYYY"
							type="date"
							:placeholder="$t('selectDateTime')"
							:disabled-date="notAfterToday"
							:open.sync="open"
							@change="handleChange"
							:shortcuts="shortcuts"
						></date-picker>

						<!-- <label class="switch margin_left_20 margin_right_20" for="neki_check_id_14">
						<input
							class="switch_mark"
							id="neki_check_id_14"
							type="checkbox"
							:checked="locationOn"
							@change="handleCheckbox"
						/>

						<span class="checkbox_label">
							<span class="on_label font_700 mb_green_text">{{ $t('location') }}</span>
							<span class="off_label font_700">{{ $t('location') }}</span>
						</span>

						<span class="slider"></span>

						<span class="checkbox_label">
							<span class="on_label font_700">{{ $t('terminal') }}</span>
							<span class="off_label font_700 mb_green_text">{{ $t('terminal') }}</span>
						</span>
					</label> -->

						<button class="btn md dark_green margin_left_10" @click="getBalance">
							{{ $t('show') }}
						</button>
						<!-- Print -->
						<button class="btn md toggle_modal_07 margin_left_5" @click="printBalanceSlip">
							{{ $t('print') }}
						</button>
						<!-- <button class="btn md toggle_modal_07 toggle_modal_04">{{ $t('createDailyReport') }}</button> -->
					</div>
					<!-- Subheader -->
					<div class="content_1280 align_center justify_center padding_30">
						<h2 class="">
							<span class="font_400 font_16px">{{ $t('balance') }} :</span>
							{{ appliedDate }}
							<span class="padding_left_40 font_400 font_16px">{{ $t('lastTransaction') }} :</span>
							{{ transactionDate }}
						</h2>
					</div>
					<!-- Tables -->
					<div v-if="!loadingBalance" class="content_1280 justify_center margin_left_auto margin_right_auto">
						<div class="col_4 padding_10 ">
							<table class="events border_rlt">
								<thead>
									<tr class="table_row">
										<th class="text_left">{{ $t('tickets') }}</th>
										<th class="text_right">{{ currency }}</th>
										<th class="text_right">{{ $t('number') }}</th>
										<th class="text_right">
											<i
												class="mdi mdi-file-table mb_green_text accent_text_hover toggle_modal_01"
												@click="ticketHandlerClick"
											></i>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr class="table_row">
										<td class="text_left">{{ $t('payin') }}</td>
										<td class="text_right">
											{{ balance.payIn ? `${toMoneyFormat(balance.payIn.amount)}` : '' }}
										</td>
										<td class="text_right">{{ balance.payIn ? balance.payIn.count : '' }}</td>
										<td>&nbsp;</td>
									</tr>
									<tr class="table_row">
										<td class="text_left">{{ $t('payout') }}</td>
										<td class="text_right">
											{{ balance.payOut ? `${toMoneyFormat(balance.payOut.amount)}` : '' }}
										</td>
										<td class="text_right">{{ balance.payOut ? balance.payOut.count : '' }}</td>
										<td>&nbsp;</td>
									</tr>
									<tr class="table_row">
										<td class="text_left">{{ $t('difference') }}</td>
										<td class="text_right">
											{{ balance.payDifference ? `${toMoneyFormat(balance.payDifference)}` : '' }}
										</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
									</tr>
									<tr class="table_row">
										<td class="text_left">{{ $t('refunded') }}</td>
										<td class="text_right">
											{{ balance.cancelled ? `${toMoneyFormat(balance.cancelled.amount)}` : '' }}
										</td>
										<td class="text_right">{{ balance.cancelled ? balance.cancelled.count : '' }}</td>
										<td>&nbsp;</td>
									</tr>
									<tr class="table_row">
										<td class="text_left">{{ $t('notPaidout') }}</td>
										<td class="text_right">
											{{ balance.toPay ? `${toMoneyFormat(balance.toPay.amount)}` : '' }}
										</td>
										<td class="text_right">{{ balance.toPay ? balance.toPay.count : '' }}</td>
										<td>&nbsp;</td>
									</tr>
									<tr class="table_row">
										<td class="text_left">{{ $t('differencePrematch') }}</td>
										<td class="text_right">
											{{ balance.preDifference ? `${toMoneyFormat(balance.preDifference)}` : '' }}
										</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
									</tr>
									<tr class="table_row">
										<td class="text_left">{{ $t('differenceLive') }}</td>
										<td class="text_right">
											{{ balance.liveDifference ? `${toMoneyFormat(balance.liveDifference)}` : '' }}
										</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div class="col_4 padding_10 ">
							<table class="events border_rlt">
								<thead>
									<tr class="table_row">
										<th class="text_left">{{ $t('ibet') }}</th>
										<th class="text_right">{{ currency }}</th>
										<th class="text_right">{{ $t('number') }}</th>
										<th class="text_right">
											<i
												class="mdi mdi-file-table mb_green_text accent_text_hover toggle_modal_02"
												@click="ibetHandlerClick"
											></i>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr class="table_row">
										<td class="text_left">{{ $t('ibet') }} {{ $t('payin') }}</td>
										<td class="text_right">
											{{ balance.deposit ? `${toMoneyFormat(balance.deposit.amount)}` : '' }}
										</td>
										<td class="text_right">{{ balance.deposit ? balance.deposit.count : '' }}</td>
										<td>&nbsp;</td>
									</tr>
									<tr class="table_row">
										<td class="text_left">{{ $t('ibet') }} {{ $t('payout') }}</td>
										<td class="text_right">
											{{ balance.withdraw ? `${toMoneyFormat(balance.withdraw.amount)}` : '' }}
										</td>
										<td class="text_right">{{ balance.withdraw ? balance.withdraw.count : '' }}</td>
										<td>&nbsp;</td>
									</tr>
									<tr class="table_row">
										<td class="text_left">{{ $t('ibet') }} {{ $t('announced') }}</td>
										<td class="text_right">
											{{ balance.reservations ? `${toMoneyFormat(balance.reservations.amount)}` : '' }}
										</td>
										<td class="text_right">{{ balance.reservations ? balance.reservations.count : '' }}</td>
										<td>&nbsp;</td>
									</tr>
									<tr class="table_row">
										<td class="text_left">{{ $t('ibet') }} {{ $t('difference') }}</td>
										<td class="text_right">
											{{ balance.depositDifference ? `${toMoneyFormat(balance.depositDifference)}` : '' }}
										</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
									</tr>
								</tbody>
							</table>
						</div>

						<!-- Promotions -->
						<div class="col_4 padding_10 ">
							<table class="events border_rlt">
								<thead>
									<tr class="table_row">
										<th class="text_left">{{ $t('promo') }}</th>
										<th class="text_right">{{ currency }}</th>
										<th class="text_right">{{ $t('number') }}</th>
										<th class="text_right">
											<i
												class="mdi mdi-file-table mb_green_text accent_text_hover toggle_modal_03"
												@click="promotionHandlerClick"
											></i>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr class="table_row">
										<td class="text_left">{{ $t('promoNumber') }}</td>
										<td class="text_right"></td>
										<td class="text_right">
											{{ balance.promotions ? balance.promotions.totalCount : '' }}
										</td>
										<td class="text_right">&nbsp;</td>
									</tr>
									<tr
										v-if="balance.promotionTicketsSum > 0 && balance.promotionTicketsCount > 0"
										class="table_row"
									>
										<td class="text_left">{{ $t('bonusVoucherPayedIn') }}</td>
										<td class="text_right">
											{{
												balance.promotionTicketsSum ? `${toMoneyFormat(balance.promotionTicketsSum)}` : ''
											}}
										</td>
										<td class="text_right">
											{{ balance.promotionTicketsCount ? balance.promotionTicketsCount : '0' }}
										</td>
										<td class="text_right">&nbsp;</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div class="col_3 padding_10 ">
							<table class="events border_rlt">
								<thead>
									<tr class="table_row">
										<th class="text_left">{{ $t('total') }}</th>
										<th class="text_right">{{ currency }}</th>
									</tr>
								</thead>
								<tbody>
									<tr class="table_row">
										<td class="text_left">{{ $t('payin') }}</td>
										<td class="text_right">{{ `${toMoneyFormat(totalPayIn)}` }}</td>
									</tr>
									<tr class="table_row">
										<td class="text_left">{{ $t('payout') }}</td>
										<td class="text_right">{{ `${toMoneyFormat(totalPayOut)}` }}</td>
									</tr>
									<tr class="table_row">
										<td class="text_left">{{ $t('difference') }}</td>
										<td class="text_right">{{ totalDifference }}</td>
									</tr>
									<tr class="table_row">
										<td class="text_left">{{ $t('taxShort') }}</td>
										<td class="text_right">0.00</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div v-else>
						<loading />
					</div>
				</div>

				<!-- Footer -->
				<div class="app_footer">
					<!-- Authorization -->
					<AuthorizationLine />
				</div>
			</div>
		</div>

		<TicketsListModal
			:loading="loading"
			:closeTicketsModal="closeTicketsModal"
			:toggleTicketModal="toggleTicketModal"
			:ticketsData="ticketsData"
			:appliedDate="appliedDate"
			:loadTicketsData="loadTicketsData"
			:totalPages="totalPages"
			:perPage="perPage"
			:totalTickets="totalTickets"
		/>

		<IbetListModal
			:loading="loading"
			:closeIbetModal="closeIbetModal"
			:toggleIbetModal="toggleIbetModal"
			:ticketsData="ticketsData"
			:appliedDate="appliedDate"
			:ibetData="ibetData"
			:loadIbetData="loadIbetData"
			:totalPages="totalIbetPages"
			:perPage="perPage"
		/>

		<PromotionListModal
			:loading="loading"
			:closePromotionModal="closePromotionModal"
			:togglePromotionModal="togglePromotionModal"
			:ticketsData="ticketsData"
			:appliedDate="appliedDate"
			:promotionsData="promotionsData"
		/>
	</div>
</template>

<script>
import AuthorizationLine from '../components/AuthorizationLine';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { formatDate } from '../utils';
import { formatting } from '../mixins';
import { AleaControlShell } from '../utils/AleaControlShell';
import TicketsListModal from '../components/Modals/BalansModals/TicketsListModal.vue';
import IbetListModal from '../components/Modals/BalansModals/IbetListModal.vue';
import PromotionListModal from '../components/Modals/BalansModals/PromotionListModal.vue';
import Loading from '../components/Loading.vue';

import { mapGetters } from 'vuex';

export default {
	mixins: [formatting],
	components: {
		AuthorizationLine,
		DatePicker,
		TicketsListModal,
		IbetListModal,
		PromotionListModal,
		Loading,
	},
	data() {
		return {
			loadingBalance: false,
			loading: false,
			ticketsData: {},
			ibetData: {},
			totalTickets: 0,
			totalPages: 1,
			totalIbetPages: 1,
			totalPromotionPages: 1,
			closeTicketsModal: false,
			closeIbetModal: false,
			closePromotionModal: false,
			balanceData: {},
			selectedDate: new Date(),
			perPage: 15,
			appliedDate: null,
			transactionDate: '',
			locationOn: true,
			open: false,
			shortcuts: [
				{
					text: this.$t('today'),
					onClick() {
						const date = new Date();
						date.setHours(12, 0, 0, 0);
						return date;
					},
				},
				{
					text: this.$t('yesterday'),
					onClick() {
						const date = new Date();
						date.setTime(date.getTime() - 3600 * 1000 * 24);
						date.setHours(12, 0, 0, 0);
						return date;
					},
				},
			],
		};
	},

	computed: {
		...mapGetters(['isCheckedData']),

		currency() {
			return window.currency;
		},

		balance() {
			return this.balanceData;
		},

		totalPayIn() {
			if (!this.balance.payIn || !this.balance.deposit) return '';
			const { payIn, deposit } = this.balance;
			const total = payIn.amount + deposit.amount;
			return total;
		},

		totalPayOut() {
			if (!this.balance.payOut || !this.balance.withdraw) return '';
			const { payOut, withdraw } = this.balance;
			const total = payOut.amount + withdraw.amount;
			return total;
		},

		totalDifference() {
			const { totalPayIn, totalPayOut } = this;
			if (totalPayIn == null || totalPayOut == null) return '';
			const diff = totalPayIn - totalPayOut;
			return this.toMoneyFormat(diff);
		},

		promotionsData() {
			if (!this.balanceData?.promotions?.promotions) return [];
			let promotions = this.balanceData.promotions.promotions;
			let promoArr = [];

			for (const [key, value] of Object.entries(promotions)) {
				for (let val of value) {
					promoArr.push({ key: key, name: val.name, count: val.count });
				}
			}
			return promoArr;
		},
	},

	methods: {
		toggleTicketModal() {
			this.closeTicketsModal = !this.closeTicketsModal;
		},

		toggleIbetModal() {
			this.closeIbetModal = !this.closeIbetModal;
		},

		togglePromotionModal() {
			this.closePromotionModal = !this.closePromotionModal;
		},

		handleCheckbox(e) {
			this.locationOn = e.target.checked;
		},

		notAfterToday(date) {
			return date > new Date().setHours(new Date().getHours() + 1, 0, 0, 0);
		},

		handleChange(_, type) {
			if (type === 'date') {
				this.open = false;
			}
		},

		async getBalance() {
			this.loadingBalance = true;

			try {
				const [date] = formatDate(this.selectedDate || new Date(), 'year-month-date');
				const deviceFilter = this.isCheckedData;
				const { lastTransaction, ...rest } = await this.$api.getBalanceSums(date, deviceFilter);
				this.balanceData = rest;
				this.setDate(this.selectedDate || new Date());
				if (lastTransaction) {
					const [trDate, trTime] = formatDate(
						new Date(lastTransaction.dtCreated),
						'date.month.year',
						'hours:minutes'
					);
					this.transactionDate = `${trDate} ${trTime}`;
				} else {
					this.transactionDate = '';
				}
			} catch (err) {
				console.error(err);
			} finally {
				this.loadingBalance = false;
			}
		},

		setDate(date) {
			const [formatedDate] = formatDate(date, 'date.month.year');
			this.appliedDate = formatedDate;
		},

		printBalanceSlip() {
			const { lastTransaction, ...rest } = this.balance;
			const data = { location: window.shortCode, day: this.appliedDate, ...rest };
			const slip = JSON.stringify(data, null, 2);
			AleaControlShell.setPrintText(slip, 'BalanceSlip');
		},

		async loadTicketsData(offset, limit) {
			this.loading = true;
			try {
				const [date] = formatDate(this.selectedDate || new Date(), 'year-month-date');
				const deviceFilter = this.isCheckedData;
				const { navigation, summary, tickets } =
					(await this.$api.getTickets({
						offset: offset,
						limit: limit,
						statuses: ['PLACED', 'WINNING', 'LOSING', 'REFUNDED', 'PAID_OUT'],
						request_date: date,
						device_filter: deviceFilter,
					})) || {};

				this.ticketsData = {
					navigation,
					summary,
					tickets,
				};

				this.totalTickets = summary.total_tickets;
				this.totalPages = navigation.pages;
			} catch (err) {
				console.error(err);
			} finally {
				this.loading = false;
			}
		},

		async loadIbetData(skip, limit) {
			this.loading = true;
			try {
				const [date] = formatDate(this.selectedDate || new Date(), 'year-month-date');
				const deviceFilter = this.isCheckedData;
				const { total, transactions } =
					(await this.$api.getIbetTransactions({
						summaryDate: date,
						skip: skip,
						limit: limit,
						deviceFilter: deviceFilter,
					})) || {};

				this.ibetData = {
					total,
					transactions,
				};

				this.totalIbetPages = Math.ceil(total / limit);
			} catch (err) {
				console.error(err);
			} finally {
				this.loading = false;
			}
		},

		ticketHandlerClick() {
			this.toggleTicketModal();
			this.loadTicketsData(0, this.perPage);
		},

		ibetHandlerClick() {
			this.toggleIbetModal();
			this.loadIbetData(0, this.perPage);
		},

		promotionHandlerClick() {
			this.togglePromotionModal();
		},
	},

	async created() {
		this.selectedDate = new Date();
		this.selectedDate.setHours(12, 0, 0, 0);
		this.getBalance();
	},
};
</script>

<style>
.mx-calendar-content .cell.active {
	color: #fff;
	background-color: #038c03;
}
.mx-calendar-content .cell:hover {
	color: white;
	background-color: #038c03;
}
.mx-table-date .today {
	color: #038c03;
}
.mx-btn:hover {
	border-color: #038c03;
	color: #038c03;
}
</style>
