export const countryList = [
	{ id: 'rs', name: 'Serbia', areaCode: '381' },
	{ id: 'ba', name: 'Bosnia and Herzegovina', areaCode: '387' },
	{ id: 'me', name: 'Montenegro', areaCode: '382' },
	{ id: 'mk', name: 'Macedonia', areaCode: '389' },
	{ id: 'af', name: 'Afghanistan', areaCode: '93' },
	{ id: 'al', name: 'Albania', areaCode: '355' },
	{ id: 'dz', name: 'Algeria', areaCode: '213' },
	{ id: 'as', name: 'American Samoa', areaCode: '1-684' },
	{ id: 'ad', name: 'Andorra', areaCode: '376' },
	{ id: 'ao', name: 'Angola', areaCode: '244' },
	{ id: 'ai', name: 'Anguilla', areaCode: '1-264' },
	{ id: 'ar', name: 'Argentina', areaCode: '54' },
	{ id: 'am', name: 'Armenia', areaCode: '374' },
	{ id: 'aw', name: 'Aruba', areaCode: '297' },
	{ id: 'au', name: 'Australia', areaCode: '61' },
	{ id: 'at', name: 'Austria', areaCode: '43' },
	{ id: 'az', name: 'Azerbaijan', areaCode: '994' },
	{ id: 'bs', name: 'Bahamas', areaCode: '1-242' },
	{ id: 'bh', name: 'Bahrain', areaCode: '973' },
	{ id: 'bd', name: 'Bangladesh', areaCode: '880' },
	{ id: 'bb', name: 'Barbados', areaCode: '1-246' },
	{ id: 'by', name: 'Belarus', areaCode: '375' },
	{ id: 'be', name: 'Belgium', areaCode: '32' },
	{ id: 'bz', name: 'Belize', areaCode: '501' },
	{ id: 'bj', name: 'Benin', areaCode: '229' },
	{ id: 'bm', name: 'Bermuda', areaCode: '1-441' },
	{ id: 'bt', name: 'Bhutan', areaCode: '975' },
	{ id: 'bo', name: 'Bolivia', areaCode: '591' },
	{ id: 'bw', name: 'Botswana', areaCode: '267' },
	{ id: 'br', name: 'Brazil', areaCode: '55' },
	{ id: 'io', name: 'British Indian Ocean Territory', areaCode: '246' },
	{ id: 'bn', name: 'Brunei Darussalam', areaCode: '673' },
	{ id: 'bg', name: 'Bulgaria', areaCode: '359' },
	{ id: 'bf', name: 'Burkina Faso', areaCode: '226' },
	{ id: 'bi', name: 'Burundi', areaCode: '257' },
	{ id: 'kh', name: 'Cambodia', areaCode: '855' },
	{ id: 'cm', name: 'Cameroon', areaCode: '237' },
	{ id: 'ca', name: 'Canada', areaCode: '1' },
	{ id: 'cv', name: 'Cape Verde', areaCode: '238' },
	{ id: 'ky', name: 'Cayman Islands', areaCode: '1-345' },
	{ id: 'cf', name: 'Central African Republic', areaCode: '236' },
	{ id: 'td', name: 'Chad', areaCode: '235' },
	{ id: 'cl', name: 'Chile', areaCode: '56' },
	{ id: 'cn', name: 'China', areaCode: '86' },
	{ id: 'cx', name: 'Christmas Island', areaCode: '61' },
	{ id: 'co', name: 'Colombia', areaCode: '57' },
	{ id: 'km', name: 'Comoros', areaCode: '269' },
	{ id: 'cg', name: 'Congo', areaCode: '242' },
	{ id: 'cd', name: 'Congo, Democratic Republic', areaCode: '243' },
	{ id: 'ck', name: 'Cook Islands', areaCode: '682' },
	{ id: 'cr', name: 'Costa Rica', areaCode: '506' },
	{ id: 'hr', name: 'Croatia', areaCode: '385' },
	{ id: 'cu', name: 'Cuba', areaCode: '53' },
	{ id: 'cy', name: 'Cyprus', areaCode: '357' },
	{ id: 'cz', name: 'Czech Republic', areaCode: '420' },
	{ id: 'dk', name: 'Denmark', areaCode: '45' },
	{ id: 'dj', name: 'Djibouti', areaCode: '253' },
	{ id: 'dm', name: 'Dominica', areaCode: '1-767' },
	{ id: 'do', name: 'Dominican Republic', areaCode: '1-809' },
	{ id: 'ec', name: 'Ecuador', areaCode: '593' },
	{ id: 'eg', name: 'Egypt', areaCode: '20' },
	{ id: 'sv', name: 'El Salvador', areaCode: '503' },
	{ id: 'gq', name: 'Equatorial Guinea', areaCode: '240' },
	{ id: 'er', name: 'Eritrea', areaCode: '291' },
	{ id: 'ee', name: 'Estonia', areaCode: '372' },
	{ id: 'et', name: 'Ethiopia', areaCode: '251' },
	{ id: 'fk', name: 'Falkland Islands (Malvinas)', areaCode: '500' },
	{ id: 'fo', name: 'Faroe Islands', areaCode: '298' },
	{ id: 'fj', name: 'Fiji', areaCode: '679' },
	{ id: 'fi', name: 'Finland', areaCode: '358' },
	{ id: 'fr', name: 'France', areaCode: '33' },
	{ id: 'pf', name: 'French Polynesia', areaCode: '689' },
	{ id: 'ga', name: 'Gabon', areaCode: '241' },
	{ id: 'gm', name: 'Gambia', areaCode: '220' },
	{ id: 'ge', name: 'Georgia', areaCode: '995' },
	{ id: 'de', name: 'Germany', areaCode: '49' },
	{ id: 'gh', name: 'Ghana', areaCode: '233' },
	{ id: 'gi', name: 'Gibraltar', areaCode: '350' },
	{ id: 'gr', name: 'Greece', areaCode: '30' },
	{ id: 'gl', name: 'Greenland', areaCode: '299' },
	{ id: 'gd', name: 'Grenada', areaCode: '1-473' },
	{ id: 'gu', name: 'Guam', areaCode: '1-671' },
	{ id: 'gt', name: 'Guatemala', areaCode: '502' },
	{ id: 'gg', name: 'Guernsey', areaCode: '44-1481' },
	{ id: 'gn', name: 'Guinea', areaCode: '224' },
	{ id: 'gw', name: 'Guinea Bissau', areaCode: '245' },
	{ id: 'gy', name: 'Guyana', areaCode: '592' },
	{ id: 'ht', name: 'Haiti', areaCode: '509' },
	{ id: 'hn', name: 'Honduras', areaCode: '504' },
	{ id: 'hk', name: 'Hong Kong', areaCode: '852' },
	{ id: 'hu', name: 'Hungary', areaCode: '36' },
	{ id: 'is', name: 'Iceland', areaCode: '354' },
	{ id: 'in', name: 'India', areaCode: '91' },
	{ id: 'id', name: 'Indonesia', areaCode: '62' },
	{ id: 'ir', name: 'Iran', areaCode: '98' },
	{ id: 'iq', name: 'Iraq', areaCode: '964' },
	{ id: 'ie', name: 'Ireland', areaCode: '353' },
	{ id: 'im', name: 'Isle of Man', areaCode: '44-1624' },
	{ id: 'il', name: 'Israel', areaCode: '972' },
	{ id: 'it', name: 'Italy', areaCode: '39' },
	{ id: 'jm', name: 'Jamaica', areaCode: '1-876' },
	{ id: 'jp', name: 'Japan', areaCode: '81' },
	{ id: 'je', name: 'Jersey', areaCode: '44-1534' },
	{ id: 'jo', name: 'Jordan', areaCode: '962' },
	{ id: 'kz', name: 'Kazakhstan', areaCode: '7' },
	{ id: 'ke', name: 'Kenya', areaCode: '254' },
	{ id: 'ki', name: 'Kiribati', areaCode: '686' },
	{ id: 'kr', name: 'Korea', areaCode: '' },
	{ id: 'kw', name: 'Kuwait', areaCode: '965' },
	{ id: 'kg', name: 'Kyrgyzstan', areaCode: '996' },
	{ id: 'la', name: "Lao People's Democratic Republic" },
	{ id: 'lv', name: 'Latvia', areaCode: '371' },
	{ id: 'lb', name: 'Lebanon', areaCode: '961' },
	{ id: 'ls', name: 'Lesotho', areaCode: '266' },
	{ id: 'lr', name: 'Liberia', areaCode: '231' },
	{ id: 'ly', name: 'Libya', areaCode: '218' },
	{ id: 'li', name: 'Liechtenstein', areaCode: '423' },
	{ id: 'lt', name: 'Lithuania', areaCode: '370' },
	{ id: 'lu', name: 'Luxembourg', areaCode: '352' },
	{ id: 'mo', name: 'Macau', areaCode: '853' },
	{ id: 'mg', name: 'Madagascar', areaCode: '261' },
	{ id: 'mw', name: 'Malawi', areaCode: '265' },
	{ id: 'my', name: 'Malaysia', areaCode: '60' },
	{ id: 'mv', name: 'Maldives', areaCode: '960' },
	{ id: 'ml', name: 'Mali', areaCode: '223' },
	{ id: 'mt', name: 'Malta', areaCode: '356' },
	{ id: 'mh', name: 'Marshall Islands', areaCode: '692' },
	{ id: 'mr', name: 'Mauritania', areaCode: '222' },
	{ id: 'mu', name: 'Mauritius', areaCode: '230' },
	{ id: 'yt', name: 'Mayotte', areaCode: '262' },
	{ id: 'mx', name: 'Mexico', areaCode: '52' },
	{ id: 'fm', name: 'Micronesia', areaCode: '691' },
	{ id: 'md', name: 'Moldova', areaCode: '373' },
	{ id: 'mc', name: 'Monaco', areaCode: '377' },
	{ id: 'mn', name: 'Mongolia', areaCode: '976' },
	{ id: 'ms', name: 'Montserrat', areaCode: '1-664' },
	{ id: 'ma', name: 'Morocco', areaCode: '212' },
	{ id: 'mz', name: 'Mozambique', areaCode: '258' },
	{ id: 'mm', name: 'Myanmar', areaCode: '95' },
	{ id: 'na', name: 'Namibia', areaCode: '264' },
	{ id: 'nr', name: 'Nauru', areaCode: '674' },
	{ id: 'np', name: 'Nepal', areaCode: '977' },
	{ id: 'nl', name: 'Netherlands', areaCode: '31' },
	{ id: 'an', name: 'Netherlands Antilles', areaCode: '599' },
	{ id: 'nc', name: 'New Caledonia', areaCode: '687' },
	{ id: 'nz', name: 'New Zealand', areaCode: '64' },
	{ id: 'ni', name: 'Nicaragua', areaCode: '505' },
	{ id: 'ne', name: 'Niger', areaCode: '227' },
	{ id: 'ng', name: 'Nigeria', areaCode: '234' },
	{ id: 'nu', name: 'Niue', areaCode: '683' },
	{ id: 'no', name: 'Norway', areaCode: '47' },
	{ id: 'om', name: 'Oman', areaCode: '968' },
	{ id: 'pk', name: 'Pakistan', areaCode: '92' },
	{ id: 'pw', name: 'Palau', areaCode: '680' },
	{ id: 'ps', name: 'Palestinian Territory', areaCode: '970' },
	{ id: 'pa', name: 'Panama', areaCode: '507' },
	{ id: 'pg', name: 'Papua New Guinea', areaCode: '675' },
	{ id: 'py', name: 'Paraguay', areaCode: '595' },
	{ id: 'pe', name: 'Peru', areaCode: '51' },
	{ id: 'ph', name: 'Philippines', areaCode: '63' },
	{ id: 'pn', name: 'Pitcairn', areaCode: '64' },
	{ id: 'pl', name: 'Poland', areaCode: '48' },
	{ id: 'pt', name: 'Portugal', areaCode: '351' },
	{ id: 'pr', name: 'Puerto Rico', areaCode: '1-787' },
	{ id: 'qa', name: 'Qatar', areaCode: '974' },
	{ id: 're', name: 'Reunion', areaCode: '262' },
	{ id: 'ro', name: 'Romania', areaCode: '40' },
	{ id: 'ru', name: 'Russian Federation', areaCode: '7' },
	{ id: 'rw', name: 'Rwanda', areaCode: '250' },
	{ id: 'bl', name: 'Saint Barthelemy', areaCode: '590' },
	{ id: 'sh', name: 'Saint Helena', areaCode: '290' },
	{ id: 'kn', name: 'Saint Kitts and Nevis', areaCode: '1-869' },
	{ id: 'lc', name: 'Saint Lucia', areaCode: '1-758' },
	{ id: 'mf', name: 'Saint Martin', areaCode: '590' },
	{ id: 'pm', name: 'Saint Pierre and Miquelon', areaCode: '508' },
	{ id: 'vc', name: 'Saint Vincent and Grenadines', areaCode: '1-784' },
	{ id: 'ws', name: 'Samoa', areaCode: '685' },
	{ id: 'sm', name: 'San Marino', areaCode: '678' },
	{ id: 'st', name: 'Sao Tome and Principe', areaCode: '239' },
	{ id: 'sa', name: 'Saudi Arabia', areaCode: '966' },
	{ id: 'sn', name: 'Senegal', areaCode: '221' },
	{ id: 'sc', name: 'Seychelles', areaCode: '248' },
	{ id: 'sl', name: 'Sierra Leone', areaCode: '232' },
	{ id: 'sg', name: 'Singapore', areaCode: '65' },
	{ id: 'sk', name: 'Slovakia', areaCode: '421' },
	{ id: 'si', name: 'Slovenia', areaCode: '386' },
	{ id: 'sb', name: 'Solomon Islands', areaCode: '677' },
	{ id: 'so', name: 'Somalia', areaCode: '252' },
	{ id: 'za', name: 'South Africa', areaCode: '27' },
	{ id: 'ss', name: 'South Sudan', areaCode: '211' },
	{ id: 'es', name: 'Spain', areaCode: '34' },
	{ id: 'lk', name: 'Sri Lanka', areaCode: '94' },
	{ id: 'sd', name: 'Sudan', areaCode: '249' },
	{ id: 'sr', name: 'Suriname', areaCode: '597' },
	{ id: 'sj', name: 'Svalbard And Jan Mayen', areaCode: '47' },
	{ id: 'sz', name: 'Swaziland', areaCode: '268' },
	{ id: 'se', name: 'Sweden', areaCode: '46' },
	{ id: 'ch', name: 'Switzerland', areaCode: '41' },
	{ id: 'sy', name: 'Syrian Arab Republic', areaCode: '963' },
	{ id: 'tw', name: 'Taiwan', areaCode: '886' },
	{ id: 'tj', name: 'Tajikistan', areaCode: '992' },
	{ id: 'tz', name: 'Tanzania', areaCode: '255' },
	{ id: 'th', name: 'Thailand', areaCode: '66' },
	{ id: 'tg', name: 'Togo', areaCode: '228' },
	{ id: 'tk', name: 'Tokelau', areaCode: '690' },
	{ id: 'to', name: 'Tonga', areaCode: '676' },
	{ id: 'tt', name: 'Trinidad and Tobago', areaCode: '1-868' },
	{ id: 'tn', name: 'Tunisia', areaCode: '216' },
	{ id: 'tr', name: 'Turkey', areaCode: '90' },
	{ id: 'tm', name: 'Turkmenistan', areaCode: '993' },
	{ id: 'tc', name: 'Turks and Caicos Islands', areaCode: '1-649' },
	{ id: 'tv', name: 'Tuvalu', areaCode: '688' },
	{ id: 'ug', name: 'Uganda', areaCode: '256' },
	{ id: 'ua', name: 'Ukraine', areaCode: '380' },
	{ id: 'ae', name: 'United Arab Emirates', areaCode: '971' },
	{ id: 'gb', name: 'United Kingdom', areaCode: '44' },
	{ id: 'us', name: 'United States', areaCode: '1' },
	{ id: 'uy', name: 'Uruguay', areaCode: '598' },
	{ id: 'uz', name: 'Uzbekistan', areaCode: '998' },
	{ id: 'vu', name: 'Vanuatu', areaCode: '678' },
	{ id: 've', name: 'Venezuela', areaCode: '58' },
	{ id: 'vn', name: 'Vietnam', areaCode: '84' },
	{ id: 'wf', name: 'Wallis And Futuna', areaCode: '681' },
	{ id: 'eh', name: 'Western Sahara', areaCode: '212' },
	{ id: 'ye', name: 'Yemen', areaCode: '967' },
	{ id: 'zm', name: 'Zambia', areaCode: '260' },
	{ id: 'zw', name: 'Zimbabwe', areaCode: '263' },
];
