import { sports, ADDITION, INCREMENT } from './sports';

const additionCalculation = (periods) => {
	let home = 0,
		away = 0;
	periods.forEach(({ score: { homeScore = 0, awayScore = 0 } = { homeScore: 0, awayScore: 0 } }) => {
		home += homeScore;
		away += awayScore;
	});
	return { home, away };
};
const incrementCalculation = (periods) => {
	let home = 0,
		away = 0;
	periods.forEach(({ score }) => {
		home = score.homeScore > score.awayScore ? home + 1 : home;
		away = score.awayScore > score.homeScore ? away + 1 : away;
	});
	return { home, away };
};

const calculations = {
	[ADDITION]: additionCalculation,
	[INCREMENT]: incrementCalculation,
};

export const calculateFinalScore = (periods, sportId) => {
	if (!sports[sportId] || !periods || periods.length === 0) return null;
	const { calcFinalScore } = sports[sportId];

	const calculation = calculations[calcFinalScore];
	if (!calculation || typeof calculation !== 'function') return null;
	const sortedPeriods = [...periods]
		.filter(({ score }) => score.homeScore !== null && score.awayScore !== null)
		.sort((a, b) => a.period - b.period);
	return calculation(sortedPeriods);
};
