import Vue from 'vue';
import PortalVue from 'portal-vue';
import VCalendar from 'v-calendar';
import Notifications from 'vue-notification';
import { customNotifications } from './components/Notifications';
import VueCountdownTimer from 'vuejs-countdown-timer';
import App from './App.vue';
import router from './router.js';
import store from './store';
import i18n from './localisation/i18n';
import apiPlugin, { api } from './api';
import { createValidator } from './utils';
import { Schema } from './validation';
import { defaultLocation } from './consts';

Vue.use(apiPlugin);
Vue.use(Notifications);
Vue.use(VCalendar);
Vue.use(customNotifications);
Vue.use(VueCountdownTimer);
Vue.use(PortalVue);
Vue.config.productionTip = false;

const app = new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
});

function setLocationParameters(deviceInfo) {
	const { idLocation, shortCode, geoParams, currency } = deviceInfo;

	const rulesObject = { ...geoParams.BETTING.PREMATCH, ...geoParams.BETTING.LIVE };
	window.rules = rulesObject;
	window.idLocation = idLocation;
	window.shortCode = shortCode;
	window.geoParams = geoParams;
	window.currency = currency;
	window.ticketValidator = createValidator(rulesObject, Schema);
}

function setGeoMappings(geoMappings) {
	window.geoMappings = geoMappings;
}

(async function initAppBasedOnEnvironment() {
	// Set default location that is used in local development outside shell app
	window.idLocation = defaultLocation;

	try {
		const deviceInfo = localStorage.getItem('POS_device_info');

		if (deviceInfo) {
			setLocationParameters(JSON.parse(deviceInfo));
			const geoMappings = localStorage.getItem('GEO_mappings');
			setGeoMappings(JSON.parse(geoMappings));
		} else {
			const { href } = window.location;

			// Get token from the params or alternatively from url body
			let token = (href.split('TempToken=')[1] || '').split(/\?|\/|\#/g)[0];
			token = token.split('&')[0];

			// If token exists send it to the server before mounting the app
			if (token) {
				let res = await api.registerTempToken(token);
				res.TIME_ZONE = 'Europe/Belgrade';
				setLocationParameters(res);
				const geoMappings = await api.getGeoParamsMapping();
				setGeoMappings(geoMappings);
				localStorage.setItem('POS_device_info', JSON.stringify(res));
				localStorage.setItem('GEO_mappings', JSON.stringify(geoMappings));
			}
		}
	} catch (err) {
		console.error(err);
	} finally {
		app.$mount('#app');
	}
})();
