<template>
	<!-- Modals -->
	<div class="modal_cover xxl modal_01" :class="{ toggle: closeIbetModal }">
		<!-- .toggle .notification -->
		<div class="modal_content fadeInDown animated faster">
			<button
				class="btn transparent close toggle_modal_01"
				@click="
					toggleIbetModal();
					resetPaginationPage(1);
				"
			>
				<i class="mdi mdi-close"></i>
			</button>

			<!-- .col_border_bottom/top/right/left -->
			<!-- .content_start/end/center/separate -->
			<!-- .direction_row/column -->

			<div class="modal_head col_border_bottom">
				<h2 class="modal_title font_500 flex_0">
					{{ $t('previewIBetPayInPayOut') }} <span class="font_700 padding_left_5">{{ appliedDate }}</span>
				</h2>

				<Paggination
					v-if="ibetData.total != 0"
					:totalPages="totalPages"
					:perPage="perPage"
					:currentPage="currentPage"
					@pagechanged="onPageChange"
				/>
			</div>

			<div class="modal_body content_start direction_column">
				<table v-if="!loading" class="events">
					<thead>
						<tr class="table_row">
							<th class="padding_left_20 text_left">{{ $t('time') }}</th>
							<th class="text_left">{{ $t('deviceName') }}</th>
							<th class="text_right">{{ $t('payin') }}</th>
							<th class="text_right">{{ $t('payout') }}</th>
							<th class="text_center">{{ $t('operator') }}</th>
							<th class="padding_right_20 text_right">{{ $t('emailUser') }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="ibet in ibetData.transactions" :key="ibet.id" class="table_row ">
							<td v-if="ibet.dtCreated" class="padding_left_20 gray_darken_text">
								{{ getTime(ibet.dtCreated) }}
							</td>
							<td v-else class="padding_left_20"></td>

							<td class="text_left">{{ ibet.deviceName }}</td>

							<td v-if="ibet.walletIn" class="strong text_right">{{ toMoneyFormat(ibet.walletIn) }}</td>
							<td v-else class=" text_right"></td>

							<td v-if="ibet.walletOut" class="strong text_right">{{ toMoneyFormat(ibet.walletOut) }}</td>
							<td v-else class=" text_right"></td>

							<td class="text_center">{{ ibet.operator }}</td>

							<td class="text_right padding_right_20">{{ ibet.email }}</td>
						</tr>
					</tbody>
				</table>
				<loading v-else />
				<!-- <Loading error="!loading && $t('scanOrSelectTicket')" /> -->
			</div>
			<!-- .modal_body -->
		</div>
		<!-- .modal_content -->
	</div>
</template>

<script>
import Paggination from '../../General/Paggination.vue';
import Loading from '../../Loading.vue';
import { mapGetters } from 'vuex';
import { formatDate } from '../../../utils';
import { formatting } from '../../../mixins';
export default {
	mixins: [formatting],
	name: 'IbetListModal',
	props: {
		loading: Boolean,
		closeIbetModal: Boolean,
		toggleIbetModal: Function,
		loadIbetData: Function,
		ibetData: Object,
		appliedDate: String,
		loadIbetData: Function,
		totalPages: Number,
		perPage: Number,
	},

	components: {
		Loading,
		Paggination,
	},
	data() {
		return {
			currentPage: 1,
		};
	},
	computed: {
		...mapGetters(['loadingData']),
	},

	methods: {
		getTime(date) {
			return date && formatDate(new Date(date), 'hours:minutes:seconds')[0];
		},
		onPageChange(page) {
			this.loadIbetData((page - 1) * this.perPage, this.perPage);
			this.currentPage = page;
		},
		resetPaginationPage(page) {
			this.currentPage = page;
		},
	},
};
</script>
