import i18n from '../localisation/i18n';

export const MIN_DEPOSIT_FOR_SINGLE_TICKET = ({ amount, currency }, expected, { isSingle, isLive }) =>
	isSingle && !isLive && amount < expected && i18n.t('minPayInSingle', { minPayIn: expected.toFixed(2), currency });

export const MAX_DEPOSIT_FOR_SINGLE_TICKET = ({ amount, currency }, expected, { isSingle, isLive }) =>
	isSingle && !isLive && amount > expected && i18n.t('maxPayInSingle', { maxPayIn: expected.toFixed(2), currency });

export const MIN_LIVE_DEPOSIT_FOR_SINGLE_TICKET = ({ amount, currency }, expected, { isSingle, isLive }) =>
	isSingle && isLive && amount < expected && i18n.t('minPayInSingleLive', { minPayIn: expected.toFixed(2), currency });

export const MAX_LIVE_DEPOSIT_FOR_SINGLE_TICKET = ({ amount, currency }, expected, { isSingle, isLive }) =>
	isSingle && isLive && amount > expected && i18n.t('maxPayInSingleLive', { minPayIn: expected.toFixed(2), currency });
