<template v-slot:modal-header>
	<label class="switch on_off margin_left_20">
		<input class="switch_mark" type="checkbox" :value="started" @click="toggleStarted" />

		<span class="checkbox_label ">
			<span class="on_label tooltip_right"> </span>
			<span class="off_label tooltip_right"> </span>
		</span>

		<span class="slider"></span>

		<span class="checkbox_label">
			<span class="on_label mb_green_text font_700 tooltip_right">
				{{ $t('started') }}
				<span class="tooltip_content font_400 green">{{ $t('showStarted') }}</span></span
			>
			<span class="off_label redish_text font_700 tooltip_right">
				{{ $t('stopped') }}
				<span class="tooltip_content font_400 red">{{ $t('showStopped') }}</span>
			</span>
		</span>
	</label>
</template>

<script>
export default {
	props: ['started', 'toggleStarted'],
};
</script>
