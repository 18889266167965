<template>
	<div class="modal_cover sm" :class="{ toggle: closePromoPinModal }">
		<div class="modal_content fadeInDown animated faster">
			<button
				data-testid="close-btn"
				class="btn transparent close"
				@click="
					togglePromoPinModal();
					promoPinsReset();
				"
			>
				<i class="mdi mdi-close"></i>
			</button>
			<div class="modal_head padding_left_20">
				<h2 class="modal_title ">{{ $i18n.t('promoPin') }}</h2>
			</div>

			<div class="modal_body content_center direction_column small_form">
				<div
					v-for="(promo, index) in promoPins"
					:key="index"
					class="form_row direction_row content_center margin_bottom_5"
				>
					<input
						type="text"
						id="pin"
						class="regular h_xl mw_140 "
						:placeholder="$i18n.t('pin')"
						v-model="promo.promoPin"
						ref="pin"
						@keydown.enter="focusNextPin(index)"
					/>
				</div>
			</div>

			<div data-testid="modal-footer" class="modal_footer">
				<button
					id="cancel"
					class="btn lg round outline"
					@click="
						togglePromoPinModal();
						promoPinsReset();
					"
				>
					{{ $t('cancel') }}
				</button>
				<button
					id="confirm"
					ref="confirm"
					class="btn lg round green"
					@click="
						confirm();
						openConfirmationModal({
							title: $t('confirmations.general'),
							text: $t('confirmations.refund', {
								barcode: ticket.barcode,
								payIn: `${ticket.originalAmount} ${ticket.currency}`,
							}),
							confirmAction: () => refund(ticket.barcode, ticket.ticketPin, promoArr),
						});
						togglePromoPinModal();
					"
				>
					{{ $t('confirm') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations } from 'vuex';
import { formatting, inputHandler } from '../../mixins';

export default {
	name: 'PromoPinModal',
	mixins: [formatting, inputHandler],
	props: {
		togglePromoPinModal: Function,
		closePromoPinModal: Boolean,
		refund: Function,
		ticket: Object,
	},

	data() {
		return {
			promoPins: [
				{
					promoPin: '',
				},
			],

			promoArr: [''],
			pinScan: '',
		};
	},
	watch: {
		closePromoPinModal(value) {
			if (value === true) {
				this.promoPinsReset();
				const n = this.ticket.promotionsCount || 0;
				for (let i = 0; i < n; i++) {
					this.addInputs();
				}
				setTimeout(() => {
					this.$refs['pin'][0].focus();
				}, 200);
			}
		},
	},
	methods: {
		...mapMutations(['openConfirmationModal']),

		addInputs() {
			this.promoPins.push({ promoPin: '' });
		},

		focusNextPin(i) {
			this.pinScan = '';
			const scanedPromo = this.promoPins[i].promoPin;

			if (scanedPromo.length == 13 && scanedPromo.includes('+')) {
				[this.barcodeScan, this.pinScan] = scanedPromo.split('+');
				this.promoPins[i].promoPin = this.pinScan;
			}

			setTimeout(() => {
				if (this.promoPins.length > i + 1) {
					this.$refs['pin'][i + 1].focus();
				} else {
					this.$refs.confirm.focus();
				}
			}, 200);
		},

		confirm() {
			let promoPinValue = this.promoPins.map((promo) => {
				return promo.promoPin;
			});

			const promoPinValuef = promoPinValue.filter((promo) => {
				return promo !== '';
			});

			if (promoPinValuef.length === 0) {
				this.promoArr = null;
			} else {
				this.promoArr = promoPinValuef;
			}
		},

		promoPinsReset() {
			this.promoPins = [];
			this.promoArr = [];
		},
	},
};
</script>
<style scoped>
.mw_140 {
	max-width: 140px !important;
}
</style>
