import {formatOdds} from '../../utils/messages'
import { DISABLED, FINISHED, LIVE, NOT_FINISHED, NOT_RUNNING, ACTIVE_EVENT_STATUSES } from '../../consts';


export const applyBonusOddsChangeMessageOnBonusEvents = (events, bonusEvents, message) => {
	
	const { markets, id_event } = message || {};
	if (!Array.isArray(markets)) return;

	const bonusOddsEvent = bonusEvents.find((event) => event.id === id_event)
	const odds = formatOdds(markets);

	const newOdds = Object.freeze(odds);

	if (!bonusOddsEvent) {
		const event = events.find((event) => event.id === id_event)
		if (!event) return;

		const { active_market_count, active_oddtype_count, odds, ...rest } = event;

		bonusEvents.push({ ...rest, odds: newOdds })
	} else {
		bonusOddsEvent.odds = newOdds
	}
}


export const applyOddsChangeMessageOnEvents = (events, message) => {
	const eventIndex = events.findIndex(event => event.id === message.id_event)
	if(eventIndex === -1) return

	const event = events[eventIndex]
	let { markets, live } = message || {};
	if (!!live !== !!event.live) return; 

	const odds = formatOdds(Array.isArray(markets) ? markets : []);
	const freezed = Object.freeze(odds);
	const newEvent = {
		...event,
		odds: freezed,
		live,
		odds_data_updated: new Date().toISOString().slice(0, -1),
	}
	events[eventIndex] = newEvent
}


export const applyEventChangeMessageOnEvents = (events, message) => {
	const eventIndex = events.findIndex(event => event.id === message.id_event)
	if(eventIndex === -1) return

	const event = events[eventIndex]
	const {
		id_event,
		status,
		live_status,
		current_time,
		manually_blocked,
		active,
		utc_scheduled,
		live,
		current_period: period,
	} = message || {};

	const utc_scheduled_time = utc_scheduled ? utc_scheduled : event?.utc_scheduled;

	if (status === FINISHED || status === NOT_FINISHED || !active) {
		events.splice(eventIndex, 1)
	} else if (live_status === LIVE) {
		events[eventIndex] = {
			...event,
			stopped: live && !ACTIVE_EVENT_STATUSES.includes(status),
			disabled: manually_blocked,
			current_time,
			utc_scheduled: utc_scheduled_time,
			live,
			status,
			period,
		}
	} else if (status !== NOT_RUNNING && live_status === DISABLED) {
		events[eventIndex] =  {
			...event,
			disabled: true,
			current_time,
			utc_scheduled: utc_scheduled_time,
			stopped: live && !ACTIVE_EVENT_STATUSES.includes(status),
			live,
			status,
			period,
		}
	} else {
		events[eventIndex] =  {
			...event,
			utc_scheduled: utc_scheduled_time,
			live,
			stopped: live && !ACTIVE_EVENT_STATUSES.includes(status),
			status,
			period,
			current_time,
			manually_blocked,
		}
	}
	
}


export const applyResultChangeMessageOnLiveEvents = (events, message) => {
	const eventIndex = events.findIndex(event => event.id === message.id_event)
	if(eventIndex === -1) return

	const event = events[eventIndex]

	const scores = Object.freeze({ ...message });

	 events[eventIndex] = { ...event, scores };
}


export const applyEventChangeLiveOnLiveEvents = (events, message) => {
	const {
		id_event: id,
		current_period: period,
		list_code: listCode,
		markets,
		utc_scheduled,
		start_time,
		current_time,
		status,
		manually_blocked,
		live,
		odds_data_updated,
		...rest
	} = message || {};

	const newMarkets = Array.isArray(markets) ? markets : [];

	const odds = Object.freeze(formatOdds(newMarkets));

	const newLiveEvent = {
		...rest,
		id,
		period,
		odds,
		listCode,
		live,
		current_time,
		status,
		stopped: live && !ACTIVE_EVENT_STATUSES.includes(status),
		manually_blocked,
		disabled: manually_blocked,
		utc_scheduled: utc_scheduled || start_time,
		odds_data_updated,
	};	
	events.push(newLiveEvent)
}


export const applyEventChangePrematchOnLiveEvents = (events, message) => {
	const eventIndex = events.findIndex(event => event.id === message.id_event)
	events.splice(eventIndex, 1)
}


export const applyEventChangeLiveOnPrematchEvents = (events, message) => {
	const eventIndex = events.findIndex(event => event.id === message.id_event)
	events.splice(eventIndex, 1)
}


export const applyEventChangePrematchOnPrematchEvents = (events, message) => {
	let { id_event: id, list_code: listCode, markets, utc_scheduled, start_time, active, live, ...rest } = message || {};

	if (active) {
		if (!Array.isArray(markets)) return;
		const odds = Object.freeze(formatOdds(markets));
		const newEvent = {
			id,
			listCode,
			live,
			utc_scheduled: utc_scheduled || start_time,
			active,
			stopped: false,
			...rest,
			odds,
		};
		const eventIndex = events.findIndex(event => event.id === id)
		eventIndex !== -1 ? events.push(newEvent) : events[eventIndex] = newEvent
	}
}