<template>
	<Modal searchQuery :closeModal="closeOddsModal">
		<template v-slot:modal-header>
			<OddsModalTabs v-if="bonusEvent" :activeTab="activeTab" :setActiveTab="setActiveTab" />
			<OddsHeader :event="event" :row="row" />
		</template>
		<OddsList
			slot-scope="{ searchValue }"
			:searchValue="searchValue"
			:event="event"
			:bonusEvent="bonusEvent"
			:activeTab="activeTab"
		/>
	</Modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Modal from '../index';
import OddsHeader from './OddsHeader';
import OddsList from './OddsList';
import OddsModalTabs from './OddsModalTabs.vue';
import { createEditEvent } from '../../../mixins';

export default {
	mixins: [createEditEvent],
	components: { Modal, OddsHeader, OddsList, OddsModalTabs },
	data() {
		return {
			activeTab: 'regular',
		};
	},
	computed: {
		...mapGetters({ row: 'selectedRow' }),

		event() {
			const { eventId } = this.row;
			return this.$store.getters?.prematchEventDataById(eventId) || {};
		},

		bonusEvent() {
			const { id } = this.event;
			return this.$store.getters?.bonusEventDataById(id);
		},
	},
	methods: {
		...mapMutations(['closeOddsModal']),

		setActiveTab(id) {
			this.activeTab = id;
		},
	},
};
</script>
