import { ACTIVE } from '../../../consts';

const isNumber = (str) => {
	const regex = /^[+-]?\d+(\.\d+)?$/;

	return regex.test(str);
};

export function separateMarkets(markets, marketsMap, sportId) {
	let separatedMarkets = [];
	markets.forEach(({ id, odds, status }, index) => {
		// Find market data form map
		const { name, oddtypes, order } = marketsMap[sportId]?.markets?.markets[id];

		const hasBoundaries = marketsMap[sportId]?.markets?.markets[id]?.hasBoundaries;

		// Return market as it is with added odds data from map
		if (!hasBoundaries) {
			const fullOdds = odds
				.filter(({ marketStatus }) => marketStatus === ACTIVE || status === ACTIVE)
				.map((odd) => ({ ...odd, ...oddtypes[odd.id] }));
			separatedMarkets.push({
				id,
				key: `${id}:${index}`,
				odds: fullOdds,
				status: (fullOdds[0] && fullOdds[0].marketStatus) || status,
				name,
				hasBoundaries,
				order,
			});
		}

		// Separate oddtypes by boundaries and form each set make new market
		else {
			const fullOdds = odds.map(({ id, ...rest }) => {
				const [oddId] = id.split('|');
				const oddMap = oddtypes[oddId];
				return { ...oddMap, id, ...rest };
			});

			const oddsByBoundaries = fullOdds.reduce((acc, current) => {
				acc[current.specialValue] = acc[current.specialValue] ? [...acc[current.specialValue], current] : [current];
				return acc;
			}, {});

			const oddSets = Object.values(oddsByBoundaries);
			const tmpMarkets = [];
			oddSets.forEach((odds, i) => {
				tmpMarkets.push({
					id,
					key: `${id}:${index}:${i}`,
					odds,
					status: (odds[0] && odds[0].marketStatus) || status,
					name: `${name}|${odds[0].specialValue}`,
					hasBoundaries,
					order,
					specialValue: odds[0].specialValue,
				});
			});
			tmpMarkets.sort((a, b) => {
				if (typeof a.specialValue === 'number') return a.specialValue - b.specialValue;
				if (isNumber(a.specialValue)) {
					const val1 = parseFloat(a.specialValue);
					const val2 = parseFloat(b.specialValue);
					return val1 - val2;
				} else {
					return a.specialValue.localeCompare(b.specialValue);
				}
			});
			separatedMarkets.push(...tmpMarkets);
		}
	});

	return separatedMarkets.sort((a, b) => a.order - b.order) || [];
}

export function separateOddsInMarkets(groupedOddsByMarket, marketsMap, sportId) {
	let separatedMarkets = [];
	Object.keys(groupedOddsByMarket).forEach((id_market, index) => {
		// Find market data form map
		const { name, oddtypes, order, status } = marketsMap[sportId]?.markets?.markets[id_market];
		const hasBoundaries = marketsMap[sportId]?.markets?.markets[id_market]?.hasBoundaries;

		// Return market as it is with added odds data from map
		if (!hasBoundaries) {
			const fullOdds = groupedOddsByMarket[id_market].map((odd) => ({ ...odd, ...oddtypes[odd.id ?? odd.oddtype_key] }));
			separatedMarkets.push({
				id: id_market,
				key: `${id_market}:${index}`,
				odds: fullOdds,
				status: (fullOdds[0] && fullOdds[0].market_status) || status,
				name,
				hasBoundaries,
				order,
			});
		}

		// Separate oddtypes by boundaries and form each set make new market
		else {
			const fullOdds = groupedOddsByMarket[id_market].map(({ id, oddtype_key, ...rest }) => {
				const [oddId] = id?.split('|') ?? oddtype_key?.split('|');
				const oddMap = oddtypes[oddId];
				return { ...rest, ...oddMap, id, oddtype_key };
			});

			const oddsByBoundaries = fullOdds.reduce((acc, current) => {
				acc[current.special_value] = acc[current.special_value]
					? [...acc[current.special_value], current]
					: [current];
				return acc;
			}, {});

			const oddSets = Object.values(oddsByBoundaries);
			const tmpMarkets = [];
			oddSets.forEach((odds, i) => {
				tmpMarkets.push({
					id: id_market,
					key: `${id_market}:${index}:${i}`,
					odds,
					status: (odds[0] && odds[0].market_status) || status,
					name: `${name}|${odds[0].special_value}`,
					hasBoundaries,
					order,
					specialValue: odds[0].special_value,
				});
			});
			tmpMarkets.sort((a, b) => {
				if (typeof a.specialValue === 'number') return a.specialValue - b.specialValue;
				if (isNumber(a.specialValue)) {
					const val1 = parseFloat(a.specialValue);
					const val2 = parseFloat(b.specialValue);
					return val1 - val2;
				} else {
					return a.specialValue.localeCompare(b.specialValue);
				}
			});
			separatedMarkets.push(...tmpMarkets);
		}
	});

	return separatedMarkets.sort((a, b) => a.order - b.order) || [];
}
