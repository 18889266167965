import {
	transformTicketForApi,
	transformTicketForPrint,
	validateTicket,
	formatTicket,
	getDefaultAmount,
	parse,
	formatDate,
	getGeoParamsData,
} from '../utils';
import { ticketReceived } from '../utils/loggers';
import { api } from '../api';
import { removeEmptyEvents } from '../utils/transformTicketForApi';
import i18n from '../localisation/i18n.js';
import {
	SENT,
	ACCEPTED,
	PLACED_NUM,
	REFUNDED_NUM,
	INVALID_NUM,
	DELETED_NUM,
	EXPIRED_NUM,
	REJECTED_AUTH_NUM,
	MODIFIED_AUTH_NUM,
	WITHDRAWN_NUM,
	REQUESTED_AUTH_NUM,
	REQUESTED_NUM,
} from '../consts';
import { isEmpty } from 'lodash';
import { deleteEventByIdFromBufferState, updateEventById } from './bufferState';
import { formatTicketInvalidMessage } from '../utils/formatTicketInvalidMessage.js';

export default {
	state: {
		submitting: false,
		ticketsResponse: [],
		loadingTimeout: null,
		loadingTimeoutValue: 20000,
		tempCashoutTicket: {},
		authTickets: [],
		cashoutTickets: [],
		cashoutAmount: null,
		cashoutPaidOutAmount: null,
		riskTickets: [],
		lastRiskTicket: {},
	},
	getters: {
		authTickets: ({ authTickets }) => authTickets,
		cashoutTickets: ({ cashoutTickets }) => cashoutTickets,
		submitting: ({ submitting }) => submitting,
		ticketsResponse: ({ ticketsResponse }) => ticketsResponse,
		tempCashoutTicket: ({ tempCashoutTicket }) => tempCashoutTicket,
		cashoutAmountTaxed: ({ cashoutAmount }) => cashoutAmount,
		cashoutPaidOutTaxed: ({ cashoutPaidOutAmount }) => cashoutPaidOutAmount,
		loadingTimeoutValue: ({ loadingTimeoutValue }) => loadingTimeoutValue,
		riskTickets: ({ riskTickets }) => riskTickets,
		lastRiskTicket: ({ lastRiskTicket }) => lastRiskTicket,
	},
	mutations: {
		setTicketsResponse(state, ticketsResponse) {
			state.ticketsResponse = ticketsResponse;
		},

		removeTicketFromTicketResponse(state, ticketId) {
			state.ticketsResponse = state.ticketsResponse.filter((ticket) => ticket !== ticketId);
		},

		addTicketFromTicketResponse(state, ticketId) {
			state.ticketsResponse.push(ticketId);
		},

		setSubmitting(state, submitting) {
			state.submitting = submitting;
		},

		setDelayOnSubmittingLoader(state) {
			state.loadingTimeout = setTimeout(() => {
				state.submitting = false;
			}, state.loadingTimeoutValue);
		},

		clearDelayOnSubmittingLoader(state) {
			if (state.loadingTimeout) {
				clearTimeout(state.loadingTimeout);
				state.loadingTimeout = null;
			}
		},

		addTicket(state, ticket) {
			state.authTickets = [...state.authTickets, { ...ticket }];
		},
		removeTicket(state, ticketId) {
			state.authTickets = state.authTickets.filter(({ id }) => id !== ticketId);
		},

		addRiskTicket(state, ticketId) {
			state.riskTickets.push(ticketId);
		},

		removeRiskTicket(state, ticketId) {
			state.riskTickets = state.riskTickets.filter((id) => id !== ticketId);
		},

		lastRiskTicket(state, payload) {
			state.lastRiskTicket = payload;
		},

		updateAuthLine(state, { formatted }) {
			state.authTickets = state.authTickets.map((ticket) => {
				if (ticket.id !== formatted.id) return ticket;
				const { systems, status, authStatus, ...rest } = formatted;
				if (authStatus === MODIFIED_AUTH_NUM && status === WITHDRAWN_NUM) return ticket;
				const timestamp = authStatus === MODIFIED_AUTH_NUM && status === REQUESTED_NUM ? Date.now() : null;
				return {
					...rest,
					status,
					authStatus,
					timestamp,
					// If ticket is invalid use systems from state
					systems: status !== INVALID_NUM ? systems : ticket.systems,
					prev: status != EXPIRED_NUM ? ticket : ticket.prev,
				};
			});
		},

		cashoutAcceptedMutation(state, payload) {
			state.cashoutAmount = payload.value;
		},

		cashoutPaidOutMutation(state, payload) {
			state.cashoutPaidOutAmount = payload.value;
		},

		removeFromAuthLine(state, { formatted }) {
			state.authTickets = state.authTickets.filter((ticket) => {
				return ticket.id !== formatted.id;
			});
		},
		deleteFromAuthLine(state, { formatted }) {
			state.authTickets = state.authTickets.filter((ticket) => ticket.id !== formatted.id);
		},

		setTempCashoutTicket(state, ticket) {
			state.tempCashoutTicket = ticket;
		},

		addCashoutTicket(state, ticket) {
			state.cashoutTickets = [...state.cashoutTickets, { ...ticket }];
		},
		removeCashoutTicket(state, ticketId) {
			state.cashoutTickets = state.cashoutTickets.filter((ticket) => ticket.id !== ticketId);
		},
		updateCashoutLine(state, updated) {
			state.cashoutTickets = state.cashoutTickets.map((ticket) => {
				if (ticket.id !== updated.id) return ticket;
				const { approvedAmount, authStatus, systemMessage, riskMessage } = updated;
				const timestamp = authStatus === ACCEPTED ? Date.now() : null;
				return {
					...ticket,
					timestamp,
					authStatus,
					approvedCashout: approvedAmount,
					systemMessage,
					riskMessage,
					prev: ticket,
				};
			});
		},
	},
	actions: {
		removeRiskTicketAction({ commit }, payload) {
			commit('removeRiskTicket', payload);
		},

		setSubmittingAction({ commit }, payload) {
			commit('setSubmitting', payload);
		},

		setTicketsResponseAction({ commit }, payload) {
			commit('setTicketsResponse', payload);
		},
		async updateTicketData(
			{ rootState, commit, state, dispatch, rootGetters },
			[update, notify, leave, print = false]
		) {
			commit('removeTicketFromTicketResponse', update.data.id);

			if (state.riskTickets.find((id) => id === update.data.id)) {
				commit('removeRiskTicket', update.data.id);
			}

			if (typeof update === 'string') {
				try {
					update = parse(update);
				} catch (err) {
					console.error('Error while parsing ticket payload', err);
					return;
				}
			}
			const { data = {} } = update || {};
			ticketReceived(update);
			const formatted = formatTicket(data);

			// Print successful ticket and remove it from the authorization line
			if (formatted.status === PLACED_NUM || formatted.status === 1) {
				const { dataState: { offerPlans = {} } = {} } = rootState;
				if (this.getters.bonusVoucherTicketIdMap[data.id]) {
					this.commit('bonusVoucherTicketIdMapRemove', data.id);
					data.placedWithBonus = true;
				}
				const transformedData = transformTicketForPrint(data, rootState.dataState.marketsMap, false, offerPlans);
				const ticketString = JSON.stringify(transformedData);
				AleaControlShell.setPrintText(ticketString, 'PosBettingTicket');
				commit('removeFromAuthLine', { formatted });
				commit('closeAuthModal');
				commit('removeTicket', formatted.id);
				const successText = [`${i18n.t('ticketBarcode')}: ${data.barcode}`];
				formatted.operaterMessage && successText.push(`${i18n.t('operaterMessage')}: ${formatted.operaterMessage}`);
				formatted.riskMessage && successText.push(`${i18n.t('riskMessage')}: ${formatted.riskMessage}`);
				notify({
					title: i18n.t('ticketPayinSuccessful'),
					text: successText,
					type: 'success',
					data: { id: document.activeElement.id, multiline: true },
				});
				if (formatted.authStatus === null) {
					commit('setSubmitting', false);
					commit('clearSystems');
					commit('addNewEmptySystem');
					commit('clearAddedEvents');
					commit(
						'updateTicketAmount',
						rootState.options.savePayin ? rootState.ticketState.amount : getDefaultAmount()
					);
				}

				const { calculatorEnabled } = rootState.options;
				const { numOfTickets, sum } = rootState.calculator;

				// Update calculator
				if (calculatorEnabled) {
					const newValues = { numOfTickets: numOfTickets + 1, sum: sum + formatted.tax_data.original_amount };
					commit('updateCalculator', newValues);
				}

				leave(`alea.${formatted.id}`);
			} else if (formatted.status === EXPIRED_NUM || (formatted.status === 9 && formatted.authStatus === 6)) {
				this.commit('bonusVoucherTicketIdMapRemove', data.id);
				commit('removeTicket', formatted.id);
				const text = [];
				formatted.systemMessage && text.push(` ${formatted.systemMessage}`);
				formatted.operaterMessage && text.push(`${i18n.t('operaterMessage')}: ${formatted.operaterMessage}`);
				formatted.riskMessage && text.push(`${i18n.t('riskMessage')}: ${formatted.riskMessage}`);
				notify({
					title: `${i18n.t('ticket')} ${data.barcode ? data.barcode : ''} ${i18n.t('isExpired')}`,
					text,
					type: 'error',
					data: { id: document.activeElement.id, multiline: true },
				});
				leave(`alea.${formatted.id}`);
				commit('setSubmitting', false);
			} else if (
				formatted.authStatus === REJECTED_AUTH_NUM ||
				formatted.status === INVALID_NUM ||
				formatted.status === 6
			) {
				this.commit('bonusVoucherTicketIdMapRemove', data.id);
				commit('removeTicket', formatted.id);
				if (formatted.updatedEvents && formatted.updatedEvents.length > 0) {
					try {
						const events = formatted.updatedEvents.join(',');
						const uniqueMarkets = [];
						formatted.systems.forEach((system) =>
							system.events.forEach(({ odds }) => {
								const oddsKeys = Object.keys(odds);
								return oddsKeys.forEach((key) => {
									const parts = key.split(':');
									const marketId = `${parts[0]}`;
									if (!uniqueMarkets.includes(marketId)) uniqueMarkets.push(marketId);
								});
							})
						);

						const markets = uniqueMarkets.join(',');

						const { events: eventsData } = await api.getEventsData({ events, markets });
						eventsData.forEach(async (event) => {
							updateEventById(event.id, event);
						});

						formatted.updatedEvents.map((event) => {
							if (!eventsData.find(({ id }) => id === event)) {
								deleteEventByIdFromBufferState(event);
							}
						});
					} catch (err) {
						console.error(err);
					}
					leave(`alea.${formatted.id}`);
				}
				const text = [];

				if (formatted.updatedEvents) {
					const notFoundEvents = [];
					const { systems: rootSystems } = rootState.ticketState;
					for (let system of rootSystems) {
						for (let event of system?.events) {
							for (let updatedEvent of formatted?.updatedEvents) {
								if (updatedEvent === event.id) {
									notFoundEvents.push({
										listCode: event.listCode || '',
										home: event.competitors[0].name || '',
										away: event.competitors[1].name || '',
									});
								}
							}
						}
					}

					for (let i in notFoundEvents) {
						formatted.updatedEvents &&
							text.push(
								`${notFoundEvents[i].listCode} \u00A0\u00A0\u00A0 ${notFoundEvents[i].home} - ${notFoundEvents[i].away}`
							);
					}
				}

				if (formatted.systemMessage && formatted.systemMessage === 'Events not found.') {
					text.push(i18n.t('noEventsFound'));
				} else if (formatted.systemMessage) {
					text.push(` ${formatted.systemMessage}`);
				}
				formatted.operaterMessage && text.push(`${i18n.t('operaterMessage')}: ${formatted.operaterMessage}`);
				formatted.riskMessage && text.push(`${i18n.t('riskMessage')}: ${formatted.riskMessage}`);
				const formatedErr = formatTicketInvalidMessage(formatted.systemMessage, rootState.dataState, rootState.ticketState);
				notify({
					title: `${i18n.t('ticket')} ${data.barcode ? data.barcode : ''} ${i18n.t('declined')}`,
					text: [formatedErr] || text,
					type: 'error',
					data: { id: document.activeElement.id, multiline: true },
				});
				commit('setSubmitting', false);
				leave(`alea.${formatted.id}`);
			} else if (formatted.status === DELETED_NUM || formatted.status === WITHDRAWN_NUM) {
				this.commit('bonusVoucherTicketIdMapRemove', data.id);
				commit('removeTicket', formatted.id);
				commit('closeAuthModal');
				commit('deleteFromAuthLine', { formatted });
				leave(`alea.${formatted.id}`);
			}

			commit('clearDelayOnSubmittingLoader');

			// Update ticket on the authorization line
			commit('updateAuthLine', { formatted });
		},
		async submitTicket(
			{ rootState, commit, dispatch, state },
			{ forceRisk, authMessage, errorNotification, ticketId, promotionCodes }
		) {
			commit('addTicketFromTicketResponse', ticketId);

			const { manualAuthorization } = rootState.options;
			const { systems, amount } = rootState.ticketState;
			const { submitting } = rootState.authorizationState;
			const numOfEvents = systems.reduce((num, { events }) => num + events.filter(({ isNew }) => !isNew).length, 0);
			if (!numOfEvents || !amount || typeof amount != 'number' || submitting) return;

			try {
				commit('setSubmitting', true);
				const updatedSystems = await Promise.all(
					systems.map(async ({ events, required }, systemIndex) => {
						const filteredEvents = removeEmptyEvents(events);

						required.forEach((req) => {
							if (req > filteredEvents.length) {
								throw { text: i18n.t('systemInvalid'), id: `sys:${systemIndex}:0` };
							}
						});

						const eventsUpdateList = [];
						const marketsUpdateList = [];

						let updatedEvents = [];
						let row = 0;
						filteredEvents.map(async (event) => {
							const upToDateEvent = event.live
								? this.getters.liveEventData(event.listCode)
								: this.getters.prematchEventData(event.listCode);

							const upToDateBonusEvent = this.getters.bonusOddsEventData(event.listCode);

							if (isEmpty(upToDateEvent) && isEmpty(upToDateBonusEvent)) {
								eventsUpdateList.push(event.id);
								event.odds.forEach((odd) => {
									const parts = odd.id.split(':');
									const market = `${parts[0]}`;
									if (!marketsUpdateList.includes(market)) marketsUpdateList.push(market);
								});
							} else {
								event.odds.forEach((odd) => {
									const upToDateOdd = odd.bonusType
										? upToDateBonusEvent.odds[odd.id]
										: upToDateEvent.odds[odd.id];
									if (isEmpty(upToDateOdd)) {
										const parts = odd.id.split(':');
										eventsUpdateList.push(event.id);
										const market = `${parts[0]}`;
										if (!marketsUpdateList.includes(market)) marketsUpdateList.push(market);
									}
								});
							}
						});
						if (!isEmpty(eventsUpdateList) && !isEmpty(marketsUpdateList)) {
							const eventsPayload = eventsUpdateList.join(',');
							const marketsPayload = marketsUpdateList.join(',');

							const { events: eventsData } = await api.getEventsData({
								events: eventsPayload,
								markets: marketsPayload,
							});
							await Promise.all(
								eventsData.map(async (event) => {
									await commit('updateEvent', [event.listCode, event.live, event], { root: true });
								})
							);
						}
						filteredEvents.forEach((event) => {
							// Find fresh event in events state, if event is not active throw exeption
							const upToDateEvent = event.live
								? this.getters.liveEventData(event.listCode)
								: this.getters.prematchEventData(event.listCode);

							const upToDateBonusEvent = this.getters.bonusOddsEventData(event.listCode);
							if (!upToDateEvent || upToDateEvent.disabled || upToDateEvent.stopped)
								throw {
									text: i18n.t('eventUnavailable', { listCode: event.listCode }),
									id: `row:${systemIndex}:${row}:code`,
								};

							// Update ticket event odds with fresh values and trow exeption if one of the oddtypes is not active
							let updatedOdds = [];
							event.odds.forEach((odd) => {
								const upToDateOdd = odd.bonusType
									? upToDateBonusEvent.odds[odd.id]
									: upToDateEvent.odds[odd.id];
								if (!upToDateOdd || upToDateOdd.value <= 1 || upToDateOdd.oddtype_status !== 1)
									throw {
										text: i18n.t('eventOddUnavailable', { listCode: event.listCode, odd: odd.name }),
										id: `row:${systemIndex}:${row}:odd`,
									};
								updatedOdds.push({
									...odd,
									stake: upToDateOdd.value,
									specialValue: upToDateOdd.specialValue,
									special_value: upToDateOdd.special_value,
								});
								row++;
							});
							updatedEvents.push({ ...event, odds: updatedOdds });
						});
						return { events: updatedEvents, required };
					})
				);

				const ticket = transformTicketForApi(
					updatedSystems,
					amount,
					forceRisk,
					authMessage,
					ticketId,
					promotionCodes
				);

				validateTicket({ ...ticket, systems: updatedSystems, combinations: rootState.ticketState.combinations });

				const [{ id }] = await api.sendTicket(ticket);

				commit('setDelayOnSubmittingLoader');
				// Disable manual authorization
				if (manualAuthorization) {
					commit('toggleManualAuthorization');
				}

				return id;
			} catch (error) {
				console.log(error);
				const { text, id } = typeof error === 'string' ? { text: error, id: null } : error;

				errorNotification({
					title: i18n.t('ticketPayinError'),
					type: 'error',
					data: { id },
					text,
				});
				commit('removeTicketFromTicketResponse', ticketId);

				if (state.riskTickets.find((id) => id === ticketId)) {
					commit('removeRiskTicket', ticketId);
				}

				commit('setSubmitting', false);
				commit('clearDelayOnSubmittingLoader');
			}
		},
		ticketToRisk({ rootState, commit }, payload) {
			let update = {};
			try {
				update = parse(payload) || {};
			} catch (err) {
				console.error('Error while parsing ticket payload', err);
			}
			const { data = {} } = update || {};

			commit('removeTicketFromTicketResponse', data.id);

			if (data && data.id) {
				const isLive = data.systems.some(({ events }) => events.some(({ live }) => live));

				const riskTimeoutPrematch =
					getGeoParamsData(['RISK', 'RISK_ACCEPT_TIME', 'PREMATCH_RISK_ACCEPT_TIME']) * 1000;
				const riskTimeoutLive = getGeoParamsData(['RISK', 'RISK_ACCEPT_TIME', 'LIVE_RISK_ACCEPT_TIME']) * 1000;

				const timeout = isLive ? riskTimeoutLive : riskTimeoutPrematch;

				commit('lastRiskTicket', { ticketId: data.id, timeout });

				const ticketId = payload.data.id;

				commit('addRiskTicket', ticketId);

				const preview = createPreviewTicket(formatTicket(data));
				commit('addTicket', preview);

				// Clear systems and add new empty system
				commit('clearSystems');
				commit('addNewEmptySystem');
				commit('clearAddedEvents');
				commit(
					'updateTicketAmount',
					rootState.options.savePayin ? rootState.ticketState.amount : getDefaultAmount()
				);

				commit('clearDelayOnSubmittingLoader');
				commit('setSubmitting', false);
			}
		},

		async cashoutPaidOut({ state, commit }, [payload, notify]) {
			commit('cashoutAcceptedMutation', { value: null });

			const parsed = parse(payload);
			const {
				data: { barcode, cashout_amount: cashoutAmount, id, cashout_original_amount, tax_agency_url },
				ts,
			} = parsed;

			let ticket = state.tempCashoutTicket;
			if (!ticket) {
				ticket = await api.getTicket(id);
			}
			const { amount, currency, placedDatetime, resultedQuota, originalAmount } = ticket;

			const payoutTime = formatDate(
				new Date(ts * 1000).toISOString().slice(0, -1),
				'hours:minutes date.month.year.'
			)[0];

			commit('cashoutPaidOutMutation', { value: cashout_original_amount });

			notify({
				title: i18n.t('cashoutPaidOut'),
				text: [
					`${i18n.t('ticketNumber')}: ${barcode}`,
					`${i18n.t('payin')}: ${
						(originalAmount && originalAmount.toFixed(2)) || (amount && amount.toFixed(2))
					} ${currency}`,
					`${i18n.t('payout')}: ${cashoutAmount.toFixed(2)}  ${currency}`,
					`${i18n.t('casoutTaxPayout')}: ${(cashout_original_amount - cashoutAmount).toFixed(2)} ${currency}`,
					`${i18n.t('timePlaced')}: ${formatDate(placedDatetime, 'hours:minutes date.month.year.')}`,
					`${i18n.t('payoutTime')}: ${payoutTime}`,
				],
				type: 'success',
				data: { id: 'barcode', multiline: true },
			});

			const cashoutAmountForSlip = +(cashout_original_amount || cashoutAmount).toFixed(2);
			const cashout_amount_after_taxForSlip = cashoutAmount && +cashoutAmount.toFixed(2);

			printCashoutSlip({
				cashoutAmount: cashoutAmountForSlip,
				cashout_amount_after_tax: cashout_amount_after_taxForSlip,
				resultedQuota: +resultedQuota,
				barcode,
				date: payoutTime,
				currency,
				tax_value: cashoutAmountForSlip - cashout_amount_after_taxForSlip,
				tax_agency_url,
			});

			commit('setSubmitting', false);
			commit('clearDelayOnSubmittingLoader');
		},

		async cashoutAccepted({ state, commit }, [payload, notify, leave]) {
			commit('cashoutPaidOutMutation', { value: null });

			const parsed = parse(payload);
			const {
				data: {
					id,
					cashout_amount: cashoutAmount,
					approved_amount,
					barcode,
					cashout_original_amount,
					tax_agency_url,
				},
				ts,
			} = parsed;
			leave(`alea.${id}`);

			commit('cashoutAcceptedMutation', { value: cashoutAmount });

			let ticket = state.cashoutTickets.find(({ id: ticketId }) => ticketId === id);
			if (!ticket) {
				ticket = await api.getTicket(id);
			}
			const {
				amount,
				originalAmount,
				currency,
				placedDatetime,
				resultedQuota = null,
				operaterMessage,
				riskMessage,
			} = ticket;
			const payoutTime = formatDate(
				new Date(ts * 1000).toISOString().slice(0, -1),
				'hours:minutes date.month.year.'
			)[0];
			const notificationText = [
				`${i18n.t('ticketNumber')}: ${barcode}`,
				`${i18n.t('payin')}: ${
					(originalAmount && originalAmount.toFixed(2)) || (amount && amount.toFixed(2))
				} ${currency}`,
				`${i18n.t('payout')}: ${cashoutAmount && cashoutAmount.toFixed(2)} ${currency}`,
				`${i18n.t('timePlaced')}: ${formatDate(placedDatetime, 'hours:minutes date.month.year.')}`,
				`${i18n.t('payoutTime')}: ${payoutTime}`,
			];
			operaterMessage && notificationText.push(`${i18n.t('operaterMessage')}: ${operaterMessage}`);
			riskMessage && notificationText.push(`${i18n.t('riskMessage')}: ${riskMessage}`);
			notify({
				title: i18n.t('cashoutPaidOut'),
				text: notificationText,
				type: 'success',
				data: { id: 'barcode', multiline: true },
			});
			const cashoutAmountForSlip = +(approved_amount || cashout_original_amount || cashoutAmount).toFixed(2);
			const cashout_amount_after_taxForSlip = cashoutAmount || approved_amount || cashout_original_amount;
			const cashoutTaxValue = +(cashoutAmountForSlip - cashout_amount_after_taxForSlip).toFixed(2);

			printCashoutSlip({
				cashoutAmount: cashoutAmountForSlip,
				cashout_amount_after_tax: cashout_amount_after_taxForSlip,
				resultedQuota: +resultedQuota,
				barcode,
				date: payoutTime,
				currency,
				tax_value: cashoutTaxValue,
				tax_agency_url,
			});
			commit('closeAuthModal');
			commit('removeCashoutTicket', id);
		},

		async cashoutDenied({ state, commit }, [payload, notify, leave]) {
			const parsed = parse(payload);
			const {
				data: {
					id,
					barcode,
					approved_amount: approvedAmount,
					cashout_amount: cashoutAmount,
					system_message: systemMessage,
					auth_status: authStatus,
					operator_message: operatorMessage,
					risk_manager_message: riskMessage,
				},
			} = parsed;
			leave(`alea.${id}`);
			let ticket = state.cashoutTickets.find(({ id: ticketId }) => ticketId === id);
			if (!ticket) {
				ticket = await api.getTicket(id);
			}
			const { amount, currency, originalAmount } = ticket;
			let notificationText = [];
			if (systemMessage) notificationText = systemMessage.split('\n');
			else {
				notificationText.push(`${i18n.t('ticketNumber')}: ${barcode}`);
				notificationText.push(
					`${i18n.t('payin')}: ${
						(originalAmount && originalAmount.toFixed(2)) || (amount && amount.toFixed(2))
					} ${currency}`
				);
				notificationText.push(
					`${i18n.t('requestedCashout')}: ${cashoutAmount && cashoutAmount.toFixed(2)} ${currency}`
				);
				operatorMessage && notificationText.push(`${i18n.t('operaterMessage')}: ${operatorMessage}`);
				riskMessage && notificationText.push(`${i18n.t('riskMessage')}: ${riskMessage}`);
			}
			notify({
				title: i18n.t('cashoutDenied'),
				text: notificationText,
				type: 'error',
				data: { multiline: true },
			});
			commit('updateCashoutLine', { id, approvedAmount, authStatus, systemMessage, riskMessage });
		},

		async cashoutInvalid({ state, commit }, [payload, notify, leave]) {
			const parsed = parse(payload);
			const {
				data: { id, barcode, cashout_amount: cashoutAmount, system_message: systemMessage },
			} = parsed;
			const ticket = state.tempCashoutTicket;
			const { amount = null, currency = '' } = ticket || {};
			leave(`alea.${id}`);
			notify({
				title: i18n.t('cashoutInvalid'),
				text: systemMessage
					? systemMessage.split('\n')
					: [
							`${i18n.t('ticketNumber')}: ${barcode}`,
							`${i18n.t('payin')}: ${amount && amount.toFixed(2)} ${currency}`,
							`${i18n.t('requestedCashout')}: ${cashoutAmount && cashoutAmount.toFixed(2)} ${currency}`,
					  ],
				type: 'error',
				data: { multiline: true },
			});
			commit('setSubmitting', false);
			commit('setTempCashoutTicket', null);
			commit('clearDelayOnSubmittingLoader');
		},

		//cashout_sent message is sent in two situations: when ticket goes to risk and when the risk operator responds
		//first situation can be identified by auth_status and status values (both must be SENT)
		async ticketToCashoutRisk({ state, commit }, payload) {
			const parsed = parse(payload);
			const {
				data: {
					id,
					cashout_amount: cashoutAmount,
					approved_amount: approvedAmount,
					auth_status: authStatus,
					system_message: systemMessage,
					risk_manager_message: riskMessage,
					user_info: userInfo,
				},
			} = parsed;
			if (authStatus === SENT) {
				const ticket = { ...state.tempCashoutTicket, authStatus, riskMessage, userInfo };
				commit('addCashoutTicket', ticket);
				commit('setSubmitting', false);
				commit('clearDelayOnSubmittingLoader');
				commit('setTempCashoutTicket', null);
				return;
			}

			if (cashoutAmount === approvedAmount) {
				await api.cashoutAccept(id, userInfo);
			}
			commit('updateCashoutLine', { id, approvedAmount, authStatus, systemMessage, riskMessage });
		},
	},
};

// Helper function for creating a preview ticket
const createPreviewTicket = (ticket) => {
	const {
		id,
		systems,
		amount,
		minQuota,
		maxQuota,
		maxGain,
		combinations,
		operaterMessage,
		bonus,
		tax_data,
		tax_parameters,
	} = ticket;

	const adjusted = systems.map(({ events, required }) => ({
		events: events.map(({ odds, ...rest }) => {
			for (let key in odds) {
				odds[key].bonusType = odds[key].bonus_type;
				odds[key].specialValue = odds[key].special_value;
			}
			return {
				...rest,
				odds,
			};
		}),
		required,
	}));

	const { currency = 'RSD' } = JSON.parse(localStorage.getItem('POS_device_info') || '{}') || {};

	return {
		id,
		systems: adjusted,
		bonus,
		amount,
		minQuota,
		maxQuota,
		maxGain,
		status: 0,
		authStatus: 0,
		currency,
		combinations,
		operator: window.AleaControlShell.getCurrentOperator(),
		operaterMessage,
		requestedDatetime: new Date().toISOString().slice(0, -1),
		tax_data,
		tax_parameters,
	};
};

function printCashoutSlip(data) {
	const payload = JSON.stringify(data);
	AleaControlShell.setPrintText(payload, 'PosBettingCashOut');
}
