<template>
	<div>
		<td v-if="periodStatus === 'INTERRUPTED'" class="without_border">
			<span class="mdi mdi-information tooltip_right live_event" style="color: #fd9800 ;"
				><span class="tooltip_content">{{ $t('eventStatusInterrupted') }}</span></span
			>
		</td>
		<td v-else class="without_border"></td>
	</div>
</template>

<script>
export default {
	props: ['eventId', 'live'],
	computed: {
		periodStatus() {
			const { eventId, live } = this;
			let period;
			if (live) {
				const listCode = this.$store._modules.root.state.dataState.liveMapEventIdToListCode[eventId];
				period = this.$store._modules.root.state.dataState.liveEvents[listCode]?.period;
			} else {
				const listCode = this.$store._modules.root.state.dataState.prematchMapEventIdToListCode[eventId];
				period = this.$store._modules.root.state.dataState.prematchEvents[listCode]?.period;
			}
			return period ?? '';
		},
	},
};
</script>
