<template>
	<div class="date-selector">
		<Dropdown :label="$t('date')" :dontAutoselect="true" :value="day" :items="days" :callback="updateValue('day')" />
		<Dropdown
			:label="$t('month')"
			:dontAutoselect="true"
			:value="month"
			:items="months"
			:callback="updateValue('month')"
		/>
		<Dropdown
			:label="$t('year')"
			:dontAutoselect="true"
			:value="year"
			:items="years"
			:callback="updateValue('year')"
		/>
	</div>
</template>

<script>
import Dropdown from './Dropdown';
import { optionsFromRange } from '../../utils';

const thisYear = new Date().getFullYear();

export default {
	props: ['value', 'onChange'],
	components: { Dropdown },
	data() {
		return {
			days: optionsFromRange(1, 31),
			months: optionsFromRange(1, 12),
			years: optionsFromRange(thisYear - 98, thisYear - 18, 'reversed'),
			day: 0,
			month: 0,
			year: 0,
		};
	},
	methods: {
		triggerChange() {
			let { day, month, year, onChange } = this;

			// Prevent trigering change if all values aren't set
			if (!day || !month || !year) return;

			// Prevent errors like February 30. or April 31.
			const numDays = new Date(year, month, 0).getDate();
			if (day > numDays) this.day = day = numDays;

			// Trigger change from parent component
			onChange(day, month, year);
		},
		updateValue(name) {
			const self = this;
			return (value) => {
				self[name] = value;
				self.triggerChange();
			};
		},
		setDateBasedOnValue() {
			const [day, month, year] = this.value || [0, 0, 0];
			this.day = day;
			this.month = month;
			this.year = year;
		},
	},
	watch: {
		value() {
			const { day, month, year, setDateBasedOnValue } = this;
			!day && !month && !year && setDateBasedOnValue();
		},
	},
	created() {
		this.setDateBasedOnValue();
	},
};
</script>

<style scoped>
.date-selector {
	display: flex;
}
</style>
