import { parse } from '../utils';
import { messageHandlers } from '../consts/messages';
import settings from '../settings.json';
import { remapMinified } from '../../workers/remapper.worker';
import { getBufferState } from './bufferState';
import { cloneDeep } from 'lodash';

const defaultRefreshInterval = settings.socketMessagesConsumerTimeout || 2000;

const minificationEnabled = settings.socketMessagesMinification;

const INACTIVE_REFRESH_INTERVAL = settings.stopInactiveLiveEvents.timeToCheckEvents || 5000;

export default {
	state: {
		updateIntervalId: null,
		updateCheckingIntervalId: null,
	},
	actions: {
		startProcessing({ state, dispatch }, refreshInterval = defaultRefreshInterval) {
			state.updateIntervalId = setInterval(() => {
				const bufferState = getBufferState();
				dispatch('updateEvents', bufferState);
			}, refreshInterval);
		},

		stopProcessing({ state }) {
			const { updateIntervalId } = state;
			if (updateIntervalId != null) {
				clearInterval(updateIntervalId);
				state.updateIntervalId = null;
			}
		},
		checkInactiveEvents({ state, dispatch }, refreshInterval = INACTIVE_REFRESH_INTERVAL) {
			state.updateCheckingIntervalId = setInterval(() => {
				dispatch('checkInactiveLiveEvents');
			}, refreshInterval);
		},

		stopCheckingInactiveEvents({ state }) {
			const { updateCheckingIntervalId } = state;
			if (updateCheckingIntervalId != null) {
				clearInterval(updateCheckingIntervalId);
				state.updateCheckingIntervalId = null;
			}
		},
	},
};
