import BA_MAP from './maps/ba.json';
import RS_MAP from './maps/rs.json';
import ME_MAP from './maps/me.json';
import { Serbia, Bosnia, Montenegro } from '../jmbg/index';

const COUNTRY_MAP = {
	[Serbia]: RS_MAP,
	[Bosnia]: BA_MAP,
	[Montenegro]: ME_MAP,
};

export const getZipCode = (country, city) => {
	return (COUNTRY_MAP[country] && COUNTRY_MAP[country][city]) || '';
};
