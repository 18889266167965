import { mapGetters } from 'vuex';

// regex for matching ids
const REGEX_MATCHER = /(([\w\d-]+:){1,3}[\w\d-]+)/g;


const findTournamentNameById = (id, ticketState) => {
	let result = id;

	try {
			for(let system of ticketState.systems){
				for(let event of system.events){
					if (event.tournament.id === id) {
						result = event.tournament.name;
						return
					}
				};
			};

		ticketState.systems
	} catch (err) {
		console.error(err)
	} finally {
		return result;
	}
};


const findCompetitorNameById = (id, ticketState) => {
	let result = id;

	try {
		for(let system of ticketState.systems){
			for(let event of system.events){
				const competitor = event?.competitors.find((comp) => comp.id === id);
				if (competitor) {
					result = competitor.name;
					return
				}

			};
		};
	} catch (err) {
		console.error(err)
	} finally {
		return result;
	}
};


const findEventNameById = (id, ticketState) => {
	let result = id;

	try {
		for(let system of ticketState.systems){
			for(let event of system.events){
				if (event.id === id) {
					result = `${event?.competitors?.[0]?.name ?? ''} - ${event?.competitors?.[1]?.name ?? ''}`;
					return
				}


			};
		};
	} catch (err) {
		console.error(err)
	} finally {
		return result;
	}
};


const findOddById = (id, dataState) => {
	const markets = dataState.marketsMap;
	let result = id;

	try {
		const parts = id.split(':');
		const marketId = parts.length ? parts[0] + ':' + parts[1] : null;
		const sportId = parts[0].slice(1)
		const name = markets[sportId].markets.markets[marketId].oddtypes[id].name;
		result = name;
	} catch (err) {
		console.error(err)
	} finally {
		return result;
	}
};


const getMatchFormat = (match) => {
	let type = undefined;

	try {
		switch (true) {
			case match.startsWith('t:'):
				type = 'TOURNAMENT';
				break;
			case match.startsWith('c:'):
				type = 'COMPETITOR';
				break;
			case match.startsWith('e:') || match.startsWith('se:'):
				type = 'EVENT';
				break;
			default:
				type = 'ODD';
		}
	} catch (error) {
	} finally {
		return type;
	}
};


const getValueForFormat = (match, format, dataState, ticketState) => {
	let value = match;

	try {
		switch (format) {
			case 'TOURNAMENT':
				value = findTournamentNameById(match, ticketState);
				break;
			case 'COMPETITOR':
				value = findCompetitorNameById(match, ticketState);
				break;
			case 'EVENT':
				value = findEventNameById(match, ticketState);
				break;
			case 'ODD':
				value = findOddById(match, dataState);
				break;
		}
	} catch (error) {
	} finally {
		return value;
	}
};


export const formatTicketInvalidMessage = (message, dataState, ticketState) => {
	let result = message

	try {
		const matches = result.match(REGEX_MATCHER);
		matches.forEach((match) => {
			const matchFormat = getMatchFormat(match);
			const replacementValue = getValueForFormat(match, matchFormat, dataState, ticketState);
			result = result.replace(match, replacementValue);
		});
	} catch (error) {
	} finally {
		return result;
	}
};


export default {
	computed: {
		...mapGetters(['eventOddsData', 'marketsMap']),
	},
};
