const zeroPrefix = (num) => (num > 9 ? num : `0${num}`);

const formatDynamicMessageFunctionsMap = {
	datetime: (datetime) => {
		const date = new Date(datetime + 'Z');
		const dateTimeString = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${zeroPrefix(
			date.getHours()
		)}:${zeroPrefix(date.getMinutes())}:${zeroPrefix(date.getSeconds())}`;
		return dateTimeString;
	},
	time: (datetime) => {
		const date = new Date(datetime + 'Z');
		const timeString = `${zeroPrefix(date.getHours())}:${zeroPrefix(date.getMinutes())}:${zeroPrefix(
			date.getSeconds()
		)}`;
		return timeString;
	},
};

export const parseDynamicMessage = (message, dynamics) => {
	if (!dynamics) return message;
	let tempMessage = message;
	for (const key in dynamics) {
		let formatFunction = formatDynamicMessageFunctionsMap[key];
		if (!formatFunction) {
			console.warn(`Format dynamic function  for key "${key}" not supported!`);
			formatFunction = (message) => message; // set no-op function
		}
		tempMessage = tempMessage.replace(`$(${key})`, formatFunction(dynamics[key]));
	}

	return tempMessage;
};
