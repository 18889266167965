// Sort actions
export const descending = 'descending';
export const ascending = 'ascending';
export const unset = 'unset';

export const sortIconHelper = {
	unset: 'mdi-menu-swap',
	descending: 'mdi-menu-down',
	ascending: 'mdi-menu-up',
};
export const sortTypeHelper = {
	unset: 'ascending',
	descending: 'ascending',
	ascending: 'descending',
};

export function timeSort(list, order) {
	list.sort(function(item1, item2) {
		const a = item1.utc_scheduled;
		const b = item2.utc_scheduled;
		if (order === 'ascending') return Date.parse(a) - Date.parse(b);
		else return Date.parse(b) - Date.parse(a);
	});
}

export function numberSort(list, order) {
	list.sort(function(item1, item2) {
		const a = item1.listCode;
		const b = item2.listCode;
		if (order === 'ascending') return a - b;
		else return b - a;
	});
}
