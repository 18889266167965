<template>
	<div class="action_sidebar">
		<div class="component">
			<div v-if="ticket.barcode" class="column_row padding_bottom_20">
				<div class="label">{{ $t('ticketBarcode') }}</div>
				<div class="value">{{ ticket.barcode }}</div>
			</div>

			<div v-if="ticket.timestamp" class="column_row">
				<div class="label">{{ $t('timeForPayment') }}</div>
				<vue-countdown-timer
					@end_callback="endCallBack()"
					:start-time="ticket.timestamp"
					:end-time="endAt"
					:interval="1000"
					:minutes-txt="'minutes'"
					:seconds-txt="'seconds'"
					:end-text="'00:00'"
				>
					<template slot="countdown" slot-scope="scope">
						<div class="value">{{ scope.props.minutes }}:{{ scope.props.seconds }}</div>
					</template>
				</vue-countdown-timer>
			</div>
		</div>
		<div v-if="timeIsUp" class="component warning">
			<div class="column_row">
				<div class="value red_text">{{ $t('timeIsUp') }}</div>
			</div>
		</div>

		<div class="component flex_direction_col flex_grow_1">
			<span v-if="ticket.systemMessage">{{ $t('systemMessage') }}</span>
			<textarea
				v-if="ticket.systemMessage"
				class="bookmaker_comment disable_resize margin_top_5 margin_bottom_10"
				:value="ticket.systemMessage"
				cols="30"
				rows="3"
				readonly
			></textarea>
			<span>{{ $t('operaterMessage') }}</span>
			<textarea
				class="bookmaker_comment disable_resize margin_top_5 margin_bottom_10"
				:value="ticket.operaterMessage"
				cols="30"
				rows="4"
				readonly
			></textarea>
			<span>{{ $t('riskMessage') }}</span>
			<textarea
				class="bookmaker_comment disable_resize margin_top_5"
				:value="ticket.riskMessage"
				cols="30"
				rows="4"
				readonly
			></textarea>
		</div>

		<div class="component payment margin_top_auto">
			<label class="input_label" for="payment_amount_1">{{ ticket.cashout ? $t('cashout') : $t('payment') }}</label>

			<div class="count_row">
				<div v-if="ticket.cashout" class="payment_amount_form margin_right_0">
					<input
						class="lg"
						type="number"
						id="payment_amount_1"
						placeholder="0.00"
						readonly
						:value="ticket.prev ? ticket.prev.cashout : ticket.cashout"
					/>
					<span class="input_label">{{ ticket.currency }}</span>
				</div>
				<div v-else class="payment_amount_form margin_right_0">
					<input
						class="lg"
						type="number"
						id="payment_amount_1"
						placeholder="0.00"
						readonly
						:value="ticketPrevAmount"
					/>

					<span class="input_label">{{ ticket.currency }}</span>
				</div>
			</div>
			<label
				v-if="ticket.authStatus === ACCEPTED && ticket.approvedCashout != ticket.cashout"
				class="input_label"
				for="payment_amount_2"
			>
				{{ $t('approvedCashout') }}
			</label>

			<div v-if="ticket.authStatus === ACCEPTED" class="count_row margin_bottom_20">
				<div class="payment_amount_form margin_right_0">
					<input
						class="lg"
						:class="ticket.cashout != ticket.approvedCashout ? 'inputClass' : ''"
						type="number"
						id="payment_amount_2"
						placeholder="0.00"
						readonly
						:value="ticket.approvedCashout"
					/>
					<span class="input_label" :class="ticket.cashout != ticket.approvedCashout ? 'inputClass' : ''">{{
						ticket.currency
					}}</span>
				</div>
			</div>

			<label v-if="ticket.authStatus === 3" class="input_label" for="payment_amount_2">
				{{ $t('allowedPayment') }}
			</label>

			<div v-if="ticket.authStatus === 3" class="count_row margin_bottom_20">
				<div class="payment_amount_form margin_right_0">
					<input
						class="lg"
						:class="amountChanged ? 'inputClass' : ''"
						type="number"
						id="payment_amount_2"
						placeholder="0.00"
						readonly
						:value="paymentInputValue"
					/>
					<span class="input_label" :class="amountChanged ? 'inputClass' : ''">{{ ticket.currency }}</span>
				</div>
			</div>

			<div class="count_row">
				<button class="btn lg orange full_width margin_left_0" @click="copyTicket">
					{{ $t('copy') }}
				</button>
				<button
					v-if="
						!ticket.cashout ||
							(ticket.cashout && timeIsUp) ||
							ticket.authStatus === EXPIRED ||
							ticket.authStatus === DENIED ||
							ticket.status === EXPIRED_NUM
					"
					class="btn lg red full_width margin_right_0"
					ref="delete"
					id="authorizationDelete"
					@click="deleteTicket"
				>
					{{ $t('delete') }}
				</button>
				<button
					v-if="
						!ticket.cashout &&
							!timeIsUp &&
							ticket.authStatus === MODIFIED_AUTH_NUM &&
							ticket.status !== EXPIRED_NUM
					"
					class="btn lg dark_green full_width margin_right_0"
					@click="acceptTicket"
					ref="accept"
					id="authorizationAccept"
				>
					{{ $t('accept') }}
				</button>
				<button
					v-if="!timeIsUp && ticket.authStatus === ACCEPTED"
					class="btn lg dark_green full_width margin_right_0"
					@click="acceptCashout"
					ref="acceptCashout"
					id="authorizationAcceptCashout"
				>
					{{ $t('accept') }}
				</button>
				<button
					v-if="!timeIsUp && ticket.authStatus === ACCEPTED"
					class="btn lg red full_width margin_right_0"
					@click="declineCashout"
					ref="acceptCashout"
					id="declineCashout"
				>
					{{ $t('decline') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { selectDelayed, getGeoParamsData } from '../../../utils';
import { ACCEPTED, EXPIRED, DENIED, INVALID_NUM, MODIFIED_AUTH_NUM, EXPIRED_NUM } from '../../../consts';
import ticketSocket from '../../../api/ticketSocket';

export default {
	props: ['ticket'],

	data() {
		return {
			timeIsUp: false,
			ACCEPTED,
			EXPIRED,
			DENIED,
			EXPIRED_NUM,
			MODIFIED_AUTH_NUM,
		};
	},
	computed: {
		...mapGetters(['authModalData', 'authTickets', 'promotionCodes']),

		ticketPrevAmount() {
			if (this.ticket.prev && this.ticket.prev !== undefined) {
				return this.ticket.prev.tax_data.original_amount;
			} else if (this.ticket.tax_data) {
				return this.ticket.tax_data.original_amount;
			} else {
				return this.ticket.amount || 0;
			}
		},

		paymentInputValue() {
			if (this.ticket.tax_data && this.ticket.tax_data !== undefined) {
				return this.ticket.tax_data.original_amount;
			} else {
				return this.ticket.amount;
			}
		},

		amountChanged() {
			if (this.ticket.authStatus !== 3) return;
			const { prev, tax_data } = this.ticket;
			return tax_data.original_amount !== prev.amount;
		},

		endAt() {
			const { timestamp, cashout, systems } = this.ticket;
			const someLive = systems.some(({ events }) => events.some(({ live }) => live));

			const liveRiskAcceptTime = getGeoParamsData(['RISK', 'RISK_ACCEPT_TIME', 'LIVE_USER_ACCEPT_TIME']);
			const prematchRiskAcceptTime = getGeoParamsData(['RISK', 'RISK_ACCEPT_TIME', 'PREMATCH_USER_ACCEPT_TIME']);

			if (cashout) {
				const cashoutAcceptTime = getGeoParamsData(['BETTING', 'CASH_OUT', 'CASH_OUT_PLAYER_ACCEPT_TIME_LIMIT']);
				const cashoutAcceptTimeLive = getGeoParamsData([
					'BETTING',
					'CASH_OUT',
					'CASH_OUT_PLAYER_ACCEPT_TIME_LIMIT_WITH_STARTED_MATCHES',
				]);

				return timestamp + (someLive ? cashoutAcceptTimeLive : cashoutAcceptTime) * 1000;
			}
			return timestamp + (someLive ? liveRiskAcceptTime : prematchRiskAcceptTime) * 1000;
		},

		originalAmountValue() {
			if (this.ticket.tax_data && this.ticket.tax_data != undefined) {
				return this.ticket.tax_data.original_amount;
			} else if (this.ticket.originalAmount && this.ticket.originalAmount !== undefined) {
				return this.ticket.originalAmount;
			} else {
				return this.ticket.amount;
			}
		},
	},
	methods: {
		...mapMutations(['closeAuthModal', 'removeTicket', 'removeCashoutTicket']),
		...mapActions(['populateTicketWithCopy', 'updateTicketData', 'vouchersArrReset', 'promotionCodesAction']),

		async getCashedTicketHandler(ticketId) {
			ticketSocket.leave(`alea.${ticketId}`);

			try {
				const ticketData = await this.$api.getCashedTicket(ticketId);

				this.updater(ticketData);
			} catch (error) {
				this.setSubmittingAction(false);
				console.log(error);

				error = error.response.data.detail[0].msg || error.response.data.detail;
				const { text, id } = typeof error === 'string' ? { text: error, id: null } : error;

				this.$notifications.info({
					title: this.$t('error'),
					type: 'error',
					data: error,
					text,
				});
			}
		},

		async acceptTicket() {
			try {
				const { ticket } = this;
				await this.$api.handleModifiedTicket({
					...ticket,
					accept_changes: true,
					operater_message: ticket.operaterMessage,
					risk_manager_message: ticket.riskMessage,
					amount: this.originalAmountValue,
					promotion_codes: this.promotionCodes,
				});

				this.closeAuthModal();

				setTimeout(() => {
					if (this.authTickets.find((authTicket) => authTicket.id === ticket.id)) {
						this.getCashedTicketHandler(ticket.id);
					}
				}, 3000);
				this.promotionCodesAction({ value: null });
				this.vouchersArrReset();
			} catch (error) {
				console.error(error);
			}
		},

		async acceptCashout() {
			try {
				const {
					ticket: { id, userInfo },
				} = this;
				await this.$api.cashoutAccept(id, userInfo);
				this.closeAuthModal();
				this.removeCashoutTicket(id);
			} catch (error) {
				console.error(error);
			}
		},
		async declineCashout() {
			try {
				const {
					ticket: { id },
				} = this;
				await this.$api.cashoutDeny(id);
				this.closeAuthModal();
				this.removeCashoutTicket(id);
			} catch (error) {
				console.error(error);
			}
		},

		copyTicket() {
			const { ticket } = this;

			this.populateTicketWithCopy({
				...ticket,
				amount: this.originalAmountValue,
			});
			this.closeAuthModal();
		},
		async deleteTicket() {
			const { ticket } = this;
			if (ticket.cashout) {
				this.closeAuthModal();
				this.removeCashoutTicket(ticket.id);
				ticketSocket.leave(`alea.${ticket.id}`);
				return;
			}
			if (!ticket.authStatus && ticket.status !== INVALID_NUM) return await this.$api.withdrawTicket(ticket.id);
			if (ticket.authStatus === MODIFIED_AUTH_NUM && ticket.status !== EXPIRED_NUM) {
				await this.$api.handleModifiedTicket(ticket);
			}
			this.closeAuthModal();
			ticketSocket.leave(`alea.${ticket.id}`);
			this.removeTicket(ticket.id);
		},
		endCallBack() {
			this.timeIsUp = true;
		},

		updater(data) {
			this.updateTicketData([data, this.$notifications.info, ticketSocket.leave]);
		},
	},
	mounted() {
		if (this.$refs.accept) {
			selectDelayed('authorizationAccept', 100);
		} else if (this.$refs.acceptCashout) {
			selectDelayed('authorizationAcceptCashout', 100);
		} else if (this.$refs.delete) {
			selectDelayed('authorizationDelete', 100);
		}
	},
};
</script>

<style scoped>
.inputClass {
	color: #c41821 !important;
	border-color: #c41821 !important;
}
.disable_resize {
	resize: none;
}
</style>
