<template>
	<div class="modal_cover md toggle" @keyup.esc="toggleRegistrationModal">
		<div class="modal_content fadeInDown animated faster">
			<form
				name="register_form"
				class="modal_content fadeInDown animated faster"
				autocomplete="off"
				v-on:keydown.enter.prevent
				@submit.prevent="submitForm"
			>
				<!-- Close modal -->
				<button class="btn transparent close" @click="toggleRegistrationModal">
					<i class="mdi mdi-close"></i>
				</button>
				<!-- Header -->
				<div class="modal_head padding_left_20 col_border_bottom live">
					<h2 class="modal_title ">{{ $t('playerRegistration') }}</h2>
				</div>

				<div
					id="registration_modal_body"
					class="modal_body content_start direction_column padding_top_30 padding_bottom_40"
				>
					<div class="form_row" :class="firstNameClass">
						<div class="col_4 text_right">
							<label for="firstName" class="input_label">{{ $t('firstName') }}</label>
						</div>
						<div class="col_8">
							<input
								required
								ref="first"
								type="text"
								id="firstName"
								class="regular min_width_220"
								v-model="firstName"
								@keyup.enter="modalEnterHandler('firstName', 'lastName')"
								@focus="() => focusHandler('firstName')"
								autocomplete="off"
							/>
						</div>
					</div>

					<div class="form_row" :class="lastNameClass">
						<div class="col_4 text_right">
							<label for="lastName" class="input_label">{{ $t('lastName') }}</label>
						</div>
						<div class="col_8">
							<input
								required
								type="text"
								id="lastName"
								class="regular min_width_220"
								v-model="lastName"
								@keyup.enter="modalEnterHandler('lastName', '')"
								@focus="() => focusHandler('lastName')"
								autocomplete="off"
							/>
						</div>
					</div>
					<div class="form_row">
						<div class="col_4 text_right">
							<label class="input_label">{{ $t('country') }}</label>
						</div>

						<div class="col_8 z_3">
							<Dropdown
								:items="countryList"
								:callback="selectCountry"
								:dontAutoselect="true"
								zIndex="z_1"
								:label="$t('selectCountry')"
							/>
						</div>
					</div>

					<div v-show="country && domestic" class="form_row" :class="jmbgClass">
						<div class="col_4 text_right">
							<label for="jmbg" class="input_label">{{ $t('jmbg') }}</label>
						</div>
						<div class="col_8">
							<input
								:required="!passport && country && domestic"
								type="text"
								id="jmbg"
								title="JMBG should be 13 characters long and contain only numbers"
								class="regular min_width_220 "
								v-model="jmbg"
								@blur="jmbgBlur"
								@keyup.enter="modalEnterHandler('jmbg', 'documentId')"
								@focus="() => focusHandler('jmbg')"
								autocomplete="off"
								@input="validateJMBG"
							/>
						</div>
					</div>

					<div class="flex_direction_row padding_bottom_10" :style="{ opacity: jmbgError ? 1 : 0 }">
						<div class="col_4 text_right"></div>
						<div class="col_8">
							<span class="validation_message red">{{ jmbgError || 'NA' }}</span>
						</div>
					</div>

					<div v-show="country && domestic" class="form_row" :class="documentIdClass">
						<div class="col_4 text_right">
							<label for="documentId" class="input_label">{{ $t('documentId') }}</label>
						</div>
						<div class="col_8">
							<input
								:required="!passport && country && domestic"
								type="text"
								id="documentId"
								class="regular min_width_220"
								v-model="documentId"
								@keyup.enter="modalEnterHandler('documentId', 'city')"
								@focus="() => focusHandler('documentId')"
								autocomplete="off"
							/>
						</div>
					</div>

					<div class="form_row" :class="passportClass" v-show="country && !domestic">
						<div class="col_4 text_right">
							<label for="passport" class="input_label">{{ $t('passport') }}</label>
						</div>
						<div class="col_8">
							<input
								:required="!jmbg && !documentId && country && !domestic"
								type="text"
								id="passport"
								class="regular min_width_220"
								v-model="passport"
								@keyup.enter="modalEnterHandler('passport', 'city')"
								@focus="() => focusHandler('passport')"
								autocomplete="off"
							/>
						</div>
					</div>

					<div class="form_row" :class="cityClass">
						<div class="col_4 text_right">
							<label for="city" class="input_label">{{ $t('city') }}</label>
						</div>
						<div class="col_8">
							<input
								required
								type="text"
								id="city"
								class="regular min_width_220"
								v-model="city"
								@keyup.enter="modalEnterHandler('city', 'address')"
								@focus="() => focusHandler('city')"
								autocomplete="off"
							/>
						</div>
					</div>

					<div class="form_row" :class="addressClass">
						<div class="col_4 text_right">
							<label for="address" class="input_label">{{ $t('address') }}</label>
						</div>
						<div class="col_8">
							<input
								required
								type="text"
								id="address"
								class="regular min_width_220"
								v-model="address"
								@keyup.enter="modalEnterHandler('address', 'addressNum')"
								@focus="() => focusHandler('address')"
								autocomplete="off"
							/>
						</div>
					</div>

					<div class="form_row" :class="addressNumClass">
						<div class="col_4 text_right">
							<label for="address" class="input_label">{{ $t('addressNum') }}</label>
						</div>
						<div class="col_8">
							<input
								required
								type="text"
								id="addressNum"
								class="regular min_width_220"
								v-model="addressNum"
								@keyup.enter="modalEnterHandler('addressNum', 'zip')"
								@focus="() => focusHandler('addressNum')"
								autocomplete="off"
							/>
						</div>
					</div>
					<div class="form_row" :class="zipClass">
						<div class="col_4 text_right">
							<label for="zip" class="input_label">{{ $t('zip') }}</label>
						</div>
						<div class="col_2">
							<input
								type="number"
								id="zip"
								class="regular max_width_80"
								v-model="zip"
								@keyup.enter="modalEnterHandler('zip', 'telephone')"
								@focus="() => focusHandler('zip')"
								autocomplete="off"
							/>
						</div>
					</div>

					<div class="form_row">
						<div class="col_4 text_right">
							<label for="date" class="input_label">{{ $t('dateOfBirth') }}</label>
						</div>
						<div class="col_8">
							<DateSelector :value="birthDate" :onChange="setBirthDate" />
						</div>
					</div>

					<div class="form_row" :class="telephoneClass">
						<div class="col_4 text_right">
							<label for="telephone" class="input_label">{{ $t('telephone') }}</label>
						</div>
						<div class="col_8 flex_direction_row">
							<input type="text" class="regular area_code disabled" :value="areaCode" autocomplete="off" />
							<input
								required
								type="text"
								id="telephone"
								class="regular phone_num"
								v-model="telephone"
								@keyup.enter="updateTelephone"
								@focus="() => focusHandler('telephone')"
								autocomplete="off"
							/>
						</div>
					</div>

					<div class="form_row" :class="emailClass">
						<div class="col_4 text_right">
							<label for="email" class="input_label">{{ $t('email') }}</label>
						</div>
						<div class="col_8">
							<input
								required
								type="email"
								id="email"
								class="regular min_width_220"
								v-model="email"
								@keyup.enter="modalEnterHandler('email', '')"
								@focus="() => focusHandler('email')"
								autocomplete="off"
							/>
						</div>
					</div>

					<div class="form_row">
						<div class="col_4 text_right">
							<label for="pronouns" class="input_label">{{ $t('gender') }}</label>
						</div>
						<div class="col_8 direction_col">
							<div class="radio_custom label_right fit_content padding_right_20">
								<input type="radio" name="Male" id="Male" value="Male" v-model="gender" autocomplete="off" />
								<i class="mdi mdi-radiobox-blank unchecked"></i>
								<i class="mdi mdi-radiobox-marked checked"></i>

								<label for="Male">{{ $t('male') }}</label>
							</div>

							<div class="radio_custom label_right fit_content padding_right_20">
								<input
									type="radio"
									name="Female"
									id="Female"
									value="Female"
									v-model="gender"
									autocomplete="off"
								/>
								<i class="mdi mdi-radiobox-blank unchecked"></i>
								<i class="mdi mdi-radiobox-marked checked"></i>

								<label for="Female">{{ $t('female') }}</label>
							</div>

							<div class="radio_custom label_right fit_content padding_right_20">
								<input type="radio" name="Other" id="Other" value="Other" v-model="gender" autocomplete="off" />
								<i class="mdi mdi-radiobox-blank unchecked"></i>
								<i class="mdi mdi-radiobox-marked checked"></i>

								<label for="Other">{{ $t('other') }}</label>
							</div>
						</div>
					</div>
				</div>

				<div class="modal_footer col_border_top">
					<button :disabled="submitting" type="submit" class="btn md round blue ">
						{{ $t('registerUser') }}
					</button>
					<button :disabled="submitting" class="btn md round outline" @click="toggleRegistrationModal">
						{{ $t('cancel') }}
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { mapMutations } from 'vuex';
import Dropdown from '../../General/Dropdown';
import DateSelector from '../../General/DateSelector';
import { formatDate, calculateNationalId, getZipCode } from '../../../utils';
import { api } from '../../../api';
import { countryList } from '../../../assets/countries';
import { inputHandler, registrationValidation } from '../../../mixins';
import { AleaControlShell } from '../../../utils/AleaControlShell';

export default {
	mixins: [inputHandler, registrationValidation],
	components: { Dropdown, DateSelector },
	data() {
		return {
			firstName: null,
			lastName: null,
			city: null,
			zip: null,
			address: null,
			addressNum: null,
			country: null,
			jmbg: null,
			jmbgData: null,
			jmbgError: null,
			birthDate: [0, 0, 0],
			documentId: null,
			passport: null,
			areaCode: '',
			telephone: null,
			email: null,
			gender: 'Male',
			countryList,
			submitting: false,
			maxDate: null,
		};
	},
	watch: {
		city() {
			this.zipCodeFromCity();
		},
	},
	computed: {
		domestic() {
			const selectedCountry = this.country;
			const domesticCountries = ['rs', 'ba', 'me', 'mk'];
			return domesticCountries.includes(selectedCountry);
		},
	},
	methods: {
		...mapMutations(['toggleRegistrationModal']),

		focusHandler(el) {
			if (this[el] === null) {
				this[el] = '';
			}
		},

		selectCountry(countryId) {
			const { validateJMBG } = this;

			this.country = countryId;
			const domestic = ['rs', 'ba', 'me', 'mk'].includes(countryId);
			const country = countryList.find(({ id }) => id === countryId) || {};
			this.areaCode = country.areaCode ? `+${country.areaCode}` : '';

			if (this.jmbg && domestic) validateJMBG();
			else this.jmbg = '';
		},

		updateTelephone() {
			this.modalEnterHandler('telephone', 'email');
			if (this.telephone.startsWith('0')) {
				this.telephone = this.telephone.substring(1);
			}
		},

		jmbgBlur() {
			this.validateJMBG();
			this.birthdateFromJMBG();
			this.cityFromJMBG();
			this.genderFromJMBG();
		},
		setBirthDate(day, month, year) {
			const { domestic, validateDate } = this;

			this.birthDate = [day, month, year];
			if (domestic) validateDate();
		},
		birthdateFromJMBG() {
			try {
				const { jmbgData: { date = 0, month = 0, year = 0 } = {} } = this;
				this.birthDate = [date, month, year];
			} catch {}
		},

		cityFromJMBG() {
			try {
				const { jmbgData: { city = '' } = {} } = this;
				if (!this.city && city) {
					this.city = city;
				}
			} catch {}
		},

		genderFromJMBG() {
			try {
				const { jmbgData: { gender = '' } = {} } = this;
				if (gender) {
					this.gender = gender;
				}
			} catch {}
		},

		zipCodeFromCity() {
			try {
				const { city, country } = this;
				const zip = getZipCode(country, city);
				if (zip) this.zip = zip;
			} catch {}
		},

		validateDate() {
			const { domestic, $notifications } = this;
			if (!domestic) return true;

			try {
				const { date: jmbgDay = 0, month: jmbgMonth = 0, year: jmbgYear = 0 } = this.jmbgData || {};
				const [day, month, year] = this.birthDate;

				const invalid = jmbgDay !== day || jmbgMonth !== month || jmbgYear !== year;

				// Validate the date of birth based on the JMBG
				if (invalid) throw this.$t('jmbgWrongDate');

				// Return true for external validation purposes
				return true;
			} catch (text) {
				$notifications.info({
					title: this.$t('error'),
					type: 'error',
					data: { id: 'firstName' },
					text,
				});
			}
		},
		validateJMBG() {
			const { domestic } = this;
			if (!domestic || !this.jmbg) {
				if (this.jmbgError) this.jmbgError = '';
				return true;
			}
			try {
				this.jmbgData = calculateNationalId(this.jmbg);

				// Validate the country based on the JMBG
				if (!this.jmbgData.country || this.jmbgData.country !== this.country) throw this.$t('jmbgWrongCountry');

				if (this.jmbgError) this.jmbgError = '';

				// Return true for external validation purposes
				return true;
			} catch (text) {
				this.jmbgError = text;
			}
		},
		// Validator for form fields which don't have inputs or require additional validation
		validateForm() {
			const { country, birthDate, validateJMBG, validateDate, $notifications } = this;

			try {
				if (!country) throw this.$t('countryRequired');

				// validateJMBG function will do its own error logging
				if (!validateJMBG()) return;

				if (birthDate.some((val) => !val)) throw this.$t('birthDateRequired');

				// validateDate function will do its own error logging
				if (!validateDate()) return;

				// Form is valid
				return true;
			} catch (text) {
				$notifications.info({
					title: this.$t('error'),
					type: 'error',
					data: { id: 'firstName' },
					text,
				});
			}
		},

		async submitForm() {
			try {
				const { toggleRegistrationModal, validateForm } = this;
				const valid = validateForm();
				if (!valid) return;

				// Disable submit btn
				this.submitting = true;
				const {
					jmbg,
					country,
					lastName,
					firstName,
					documentId,
					birthDate,
					city,
					address,
					addressNum,
					passport,
					areaCode,
					telephone,
					email,
					gender,
					zip,
				} = this;
				const date = [...birthDate].reverse().join('-');

				// Format user object for api
				const user = {
					lastName,
					firstName,
					country,
					birthDate: date,
					nationalId: jmbg ? jmbg : null,
					idCard: documentId ? documentId : null,
					idGeolocation: window.idLocation,
					city,
					address,
					addressNum,
					passport: passport ? passport : null,
					telephone: `${areaCode}${telephone}`,
					email,
					gender,
					zip: zip || null,
				};

				// Make api call
				const { code } = await api.createUser(user);

				const registrationDate = new Date().toISOString().slice(0, -1);

				const slip = {
					id: code,
					name: `${firstName} ${lastName}`,
					email,
					date: formatDate(registrationDate, 'date.month.year. hours:minutes')[0],
				};

				const registrationSlip = JSON.stringify(slip);
				AleaControlShell.setPrintText(registrationSlip, 'RegistrationSlip');

				// Notify operater that user is created and display response info
				this.$notifications.info({
					title: this.$t('userCreated'),
					text: [`${this.$t('user')}: ${firstName} ${lastName}`, `${this.$t('userId')}: ${code}`],
					data: { multiline: true },
					type: 'success',
				});

				toggleRegistrationModal();
			} catch (error) {
				let err = 'Something went wrong, try again';
				if (error.response && error.response.data) err = error.response.data.detail;

				// Notify operater that error occured
				this.$notifications.info({
					title: this.$t('error'),
					text: `${err}`,
					type: 'error',
					data: { id: 'firstName' },
				});
			} finally {
				this.submitting = false;
			}
		},
	},
	mounted() {
		// Focus first field
		this.$refs.first.focus();
		const elements = document.getElementById('registration_modal_body').getElementsByTagName('input');

		const getText = (id) => this.$t('fieldRequired', { field: this.$t(id) });
		const emailText = this.$t('enterValidEmail');

		for (let i = 0; i < elements.length; i++) {
			elements[i].oninvalid = function(e) {
				e.target.setCustomValidity('');
				if (e.target.value === '') {
					e.target.setCustomValidity(getText(e.target.id));
				}
				if (e.target.type === 'email') {
					e.target.setCustomValidity(emailText);
				}
			};
			elements[i].oninput = function(e) {
				e.target.setCustomValidity('');
			};
		}
	},

	destroyed() {
		history.back();
	},

	created() {
		const today = new Date();
		// Getting required values
		const year = today.getFullYear();
		const month = today.getMonth();
		const day = today.getDate();

		this.maxDate = new Date(year - 18, month, day);

		// Push to alias route to allow shell identify registration form opened
		// and prevent triggering modals form other apps in shell
		// when we fill JMBG in form
		const href = window.location.href;
		history.pushState({}, null, `${href}${href.endsWith('/') ? 'registration' : '/registration'}`);
	},
};
</script>

<style scoped>
.radio_custom {
	z-index: 1 !important;
}

.area_code {
	width: 65px;
	margin-right: 5px;
}

.phone_num {
	width: 150px;
	min-width: 150px;
}
</style>
