<template>
	<tr class="table_row">
		<td class="p_0 row_number" colspan="6">
			<span class="remove_row">
				<i class="mdi mdi-close-circle tooltip_right" @click="removeNewOdd()">
					<span class="tooltip_content">{{ $t('deleteOddtype') }}</span>
				</i>
			</span>
		</td>

		<td class="p_0 game_input">
			<span class="tooltip_right">
				<input
					ref="odd"
					type="text"
					class="td_input"
					:value="odd.name"
					:id="`${row.rowId}`"
					:name="`${row.rowId}`"
					@focus="
						focusHandler(`${row.rowId}`);
						resetfocusInputTimer();
					"
					@keyup.enter="oddEnter"
					@keydown.enter="newOddHandler"
					@keyup="handleKeyUp"
					autocomplete="new-odd-no-autocomplete"
				/>
				<span class="tooltip_content">{{ odd && odd.description }}</span>
			</span>
		</td>
		<td class="pr_10 text_right odd">{{ odd && odd.stake && oddsFormat(+odd.stake) }}</td>
		<td class="pl_0 odd_margin strong">
			<span class="border_left"></span>
		</td>
		<td class="pl_0 add_game"></td>
		<td class="pr_0 league" colspan="3">{{ odd && odd.description }}</td>
	</tr>
</template>

<script>
import { keys } from 'keycodes-map';
import {
	eventNavigation,
	computedEvent,
	createEditEvent,
	inputHandler,
	formatting,
	focusInputConfig,
} from '../../mixins';
import { select } from '../../utils';
import { MAX_EVENT_ODDS } from '../../consts';

export default {
	mixins: [eventNavigation, computedEvent, createEditEvent, inputHandler, formatting, focusInputConfig],
	props: ['row', 'system'],

	methods: {
		newOddHandler() {
			this.focusInputTimer++;
			if (this.focusInputTimer === this.defaultFocusInputTimer) {
				this.oddEnter();
				this.resetfocusInputTimer();
			}
		},

		handleKeyUp(e) {
			if (this.scanning) return;
			if (e.keyCode === keys.insert) this.enableScan();

			this.oddStar(e);
			this.oddsModal(e);
		},
		oddEnter() {
			if (this.scanning) return;
			const { systemIndex, eventIndex, eventId, eventListCode } = this;
			const liveEvent = this.$store.getters.liveEventData(eventListCode);
			let oddCode = this.getOddCode();
			if (liveEvent?.live && !oddCode) return this.openLiveOddsModal(eventId);
			const { odd, isLastRow } = this;

			const oddId = odd.id;

			if (!oddCode) {
				this.removeEventOdd({ systemIndex, eventIndex, oddId });
				if (isLastRow) this.addNewEmptyEvent(systemIndex);
				else this.oddRight();
				return;
			}

			if (!this.panelBarcodeScanCheck(oddCode)) return;

			const bonus = this.isBonusOddCode(oddCode);
			if (bonus) {
				oddCode = oddCode.slice(1);
			}


			const { listCode, odds: eventOdds } = this.ticketEvent(systemIndex, eventIndex) || {};
			const { sport = {}, odds = [] } = (bonus ? this.$store.getters.bonusOddsEventData(listCode) : this.$store.getters.prematchEventData(listCode)) || {};
			const oddType = this.oddTypeData({ oddCode, sportId: sport.id, odds, bonus });
			if (!oddType) {
				if (liveEvent?.live) return this.useLiveOfferNotify();
				return this.noOddTypeNotify();
			}

			if (odd.keyboardCode !== oddType.keyboardCode) {
				// Check if we played that oddType
				const exists = eventOdds.find(({ keyboardCode }) => keyboardCode === oddType.keyboardCode);
				if (exists) {
					if (Boolean(exists.bonusType) === bonus) {
						return this.oddAlreadyExists();
					} else return this.cannotCombineSame();
				}
			}
			this.replaceEventOdd({
				newOdd: oddType,
				systemIndex,
				eventIndex,
				oddId,
			});

			if (isLastRow) this.addNewEmptyEvent(systemIndex);
			else this.oddRight();
		},
		oddStar(e) {
			if (e.keyCode !== keys.numpadMultiply) return;
			const { odd, oddIndex, $refs, systemIndex, eventIndex, maxEventOddsNotify } = this;
			const oddValue = this.getOddCode();
			const oddId = odd.id;

			if (!oddValue) return;

			// Remove the star symbol from the oddCode
			// and update the input field
			let oddCode = oddValue.replace('*', '');
			$refs.odd.value = oddCode;

			const bonus = this.isBonusOddCode(oddCode);
			if (bonus) {
				oddCode = oddCode.slice(1);
			}

			const { listCode, odds: eventOdds, live } = this.ticketEvent(systemIndex, eventIndex) || {};
			const { sport = {}, odds = [] } =
				(bonus
					? this.$store.getters.bonusOddsEventData(listCode)
					: live
					? this.$store.getters?.liveEventData(listCode)
					: this.$store.getters?.prematchEventData(listCode)) || {};
			const oddType = this.oddTypeData({ oddCode, sportId: sport.id, odds, bonus });
			if (!oddType) return this.noOddTypeNotify();

			if (odd.keyboardCode !== oddType.keyboardCode) {
				// Check if we played that oddType
				const exists = eventOdds.find(({ keyboardCode }) => keyboardCode === oddType.keyboardCode);
				if (exists) {
					if (Boolean(exists.bonusType) === bonus) {
						return this.oddAlreadyExists();
					} else return this.cannotCombineSame();
				}
			}
			this.replaceEventOdd({
				newOdd: oddType,
				systemIndex,
				eventIndex,
				oddId,
			});

			if (eventOdds.length === MAX_EVENT_ODDS) {
				maxEventOddsNotify(`row:${systemIndex}:${oddIndex}:odd`);
				return;
			}
			this.addNewEmptyOdd({ systemIndex, eventIndex });
		},

		oddsModal(e) {
			if (e.keyCode !== keys.ctrl) return;
			const { eventId } = this;
			const liveEvent = this.$store.getters.liveEventDataById(eventId);
			if (liveEvent && liveEvent.live) this.openLiveOddsModal(eventId);
			else this.openOddsModal();
		},
		removeNewOdd() {
			const { systemIndex, eventIndex, odd, isLastRow } = this;
			const oddId = odd.id;
			this.removeEventOdd({ systemIndex, eventIndex, oddId });
			if (isLastRow) this.addNewEmptyEvent(systemIndex);
			else this.oddRight();
			return;
		},
	},
	mounted() {
		select(`row:new:odd:${this.systemIndex}:${this.eventId}`);
	},
};
</script>
