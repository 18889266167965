<template>
	<div class="odds_info" v-if="showMinMax">
		<div class="single_info">
			<span class="label">{{ $t('minOdd') }}:</span>
			<strong class="value">{{ minMaxOdd.minOdd && oddsFormat(+minMaxOdd.minOdd) }}</strong>
		</div>
		<div class="single_info">
			<span class="label">{{ $t('maxOdd') }}:</span>
			<strong class="value">{{ minMaxOdd.maxOdd && oddsFormat(+minMaxOdd.maxOdd) }}</strong>
		</div>
		<div class="single_info">
			<span class="label">{{ $t('combinations') }}:</span>
			<strong class="value">{{ minMaxOdd.combinations }}</strong>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import formatting from '../../mixins/formatting';

export default {
	mixins: [formatting],
	props: ['showMinMax'],
	computed: {
		...mapGetters(['minMaxOdd']),
	},
};
</script>
