export default {
	state: {
		firstName: null,
		lastName: null,
		jmbg: null,
		triggerType: '',
		cashoutMessage: '',
	},
	getters: {
		firstName: (state) => state.firstName,
		lastName: (state) => state.lastName,
		jmbg: (state) => state.jmbg,
		country: (state) => state.country,
		passportNumber: (state) => state.passportNumber,
		userInfo: ({ firstName, lastName, jmbg, country, passportNumber }) =>
			jmbg || passportNumber ? { firstName, lastName, jmbg, country, passportNumber } : null,
		cashoutMessage: (state) => state.cashoutMessage,
		triggerType: (state) => state.triggerType,
	},
	mutations: {
		setUserInfo(state, userInfo) {
			if (userInfo) {
				state.firstName = userInfo.firstName;
				state.lastName = userInfo.lastName;
				state.jmbg = userInfo.jmbg;
				state.country = userInfo.country;
				state.passportNumber = userInfo.passportNumber;
			} else {
				state.firstName = null;
				state.lastName = null;
				state.jmbg = null;
				state.country = null;
				state.passportNumber = null;
			}
		},
		deleteUserInfo(state) {
			state.firstName = null;
			state.lastName = null;
			state.jmbg = null;
			state.country = null;
			state.passportNumber = null;
		},
		setTriggerType(state, triggerType) {
			state.triggerType = triggerType;
		},
		setCashoutMessage(state, cashoutMessage) {
			state.cashoutMessage = cashoutMessage;
		},
		resetTriggerState(state) {
			state.firstName = null;
			state.lastName = null;
			state.jmbg = null;
			state.country = null;
			state.passportNumber = null;
			state.cashoutMessage = '';
			state.triggerType = '';
		},
	},
	actions: {
		setUserInfoAction({ commit }, { userInfo }) {
			commit('setUserInfo', userInfo);
		},
		deleteUserInfoAction({ commit }) {
			commit('deleteUserInfo');
		},
		setTriggerTypeAction({ commit }, triggerType) {
			commit('setTriggerType', triggerType);
		},
		setCashoutMessageAction({ commit }, cashoutMessage) {
			commit('setCashoutMessage', cashoutMessage);
		},
		resetTriggerStateAction({ commit }) {
			commit('resetTriggerState');
		},
	},
};
