export const ticketPayInMinAmount = (systems, combinations, geoParams) => {
	let minPayIn = 0;
	if (!geoParams) return minPayIn;

	let numberOfEvents = 0;

	for (const system of systems) {
		let size = Object.keys(system.events).length;
		numberOfEvents += size;
	}

	const live = systems.some(({ events }) => events.some(({ live }) => live));
	const isSystemTicket = numberOfEvents > 1 && combinations > 1;
	const isMulti = systems.length > 1;

	if (live) {
		if (isSystemTicket) {
			const minPayInPerCombination =
				geoParams.BETTING.LIVE.MIN_LIVE_DEPOSIT_PER_COMBO_FOR_SYSTEM_TICKET * combinations;
			minPayIn =
				geoParams.BETTING.LIVE.MIN_LIVE_DEPOSIT_FOR_SYSTEM_TICKET > minPayInPerCombination
					? geoParams.BETTING.LIVE.MIN_LIVE_DEPOSIT_FOR_SYSTEM_TICKET
					: minPayInPerCombination;
		} else if (isMulti) {
			const minPayInPerEvents = geoParams.BETTING.LIVE.MIN_LIVE_DEPOSIT_PER_EVENT_FOR_MULTI_TICKET * numberOfEvents;
			minPayIn =
				geoParams.BETTING.LIVE.MIN_LIVE_DEPOSIT_FOR_MULTI_TICKET > minPayInPerEvents
					? geoParams.BETTING.LIVE.MIN_LIVE_DEPOSIT_FOR_MULTI_TICKET
					: minPayInPerEvents;
		} else {
			minPayIn = geoParams.BETTING.LIVE.MIN_LIVE_DEPOSIT_FOR_SINGLE_TICKET;
		}
	} else {
		if (isSystemTicket) {
			const minPayInPerCombination =
				geoParams.BETTING.PREMATCH.MIN_DEPOSIT_PER_COMBO_FOR_SYSTEM_TICKET * combinations;

			minPayIn =
				geoParams.BETTING.PREMATCH.MIN_DEPOSIT_FOR_SYSTEM_TICKET > minPayInPerCombination
					? geoParams.BETTING.PREMATCH.MIN_DEPOSIT_FOR_SYSTEM_TICKET
					: minPayInPerCombination;
		} else if (isMulti) {
			const minPayInPerEvents = geoParams.BETTING.PREMATCH.MIN_DEPOSIT_PER_EVENT_FOR_MULTI_TICKET * numberOfEvents;

			minPayIn =
				geoParams.BETTING.PREMATCH.MIN_DEPOSIT_FOR_MULTI_TICKET > minPayInPerEvents
					? geoParams.BETTING.PREMATCH.MIN_DEPOSIT_FOR_MULTI_TICKET
					: minPayInPerEvents;
		} else {
			minPayIn = geoParams.BETTING.PREMATCH.MIN_DEPOSIT_FOR_SINGLE_TICKET;
		}
	}

	return minPayIn;
};
