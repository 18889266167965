<template>
	<span>
		<slot v-bind="{ changed, prev, current: value }" />
	</span>
</template>

<script>
import { isEqual, cloneDeep } from 'lodash';

export default {
	props: ['value', 'duration'],
	data() {
		return {
			last: undefined,
			timeout: undefined,
			changed: false,
			prev: undefined,
		};
	},
	watch: {
		value: function(value) {
			this.clearTimeout();

			this.changed = !isEqual(value, this.last);
			this.prev = this.last;
			this.last = cloneDeep(value);

			this.setTimeout();
		},
	},
	methods: {
		clearPrev() {
			this.prev = undefined;
			this.changed = false;
		},
		setTimeout() {
			if (typeof this.duration === 'number') {
				this.timeout = setTimeout(this.clearPrev, this.duration);
			}
		},
		clearTimeout() {
			this.timeout && clearTimeout(this.timeout);
		},
	},
	created() {
		this.last = cloneDeep(this.value);
	},
};
</script>
