export { transformTicketForApi } from './transformTicketForApi';
export { transformTicketForPrint } from './transformTicketForPrint';
export { generateBarcode } from './generateBarcode';
export { normalizeSystem } from './normalizeSystem';
export { minMaxPlugin } from './minMaxPlugin';
export { formatTicket } from './ticketToCamelCase';
export { scrollTo } from './scrollTo';
export { debounce } from './debounce';
export { extractItems, EVENT_ITEM_TYPE, ODD_ITEM_TYPE } from './extractItems';
export { unset, ascending, descending, sortIconHelper, sortTypeHelper, timeSort, numberSort } from './SortHelpers';
export { calculateNationalId } from './jmbg';
export { getZipCode } from './autoZipCode';
export { compose } from './compose';
export * from './validateTicket';
export * from './language';
export { parseDynamicMessage } from './parseDynamicMessage';

// Temp method, to be replaced
export function handleKey(event, key, callback) {
	function handler(event) {
		if (key && event.keyCode !== key) return;
		event.preventDefault();
		callback(event);
	}

	window.addEventListener(event, handler);
	return () => window.removeEventListener(event, handler);
}

export function combineHandlers(...handlers) {
	return () => handlers.filter((h) => typeof h === 'function').forEach((f) => f());
}

/**
 * Check does the element with the provided id exists
 * @param { string } id
 */
export const exists = (id) => !!document.getElementById(id);

/**
 * Focus element with the provided id and select its text
 * @param { string } id
 */
export const select = (id) => {
	const el = document.getElementById(id);
	if (!el) return;

	el.focus();
	el.select && setTimeout(() => el.select(), 25);
	return true;
};

/**
 * Delay select function
 */
export const selectDelayed = (id, delay = 0) => {
	return new Promise(() => {
		setTimeout(() => {
			const selected = select(id);
			if (selected) {
				Promise.resolve();
			} else {
				//When a component is destroyed, and we attempt to select input within that component
				let promise = Promise.reject("Reject: Input for focus removed, it's OK");
				promise.catch((error) => {
					console.log(error);
				});
			}
		}, delay);
	});
};

export const focus = (id) => {
	const el = document.getElementById(id);
	if (!el) return;
	el.focus != null && el.focus();
};

/**
 * Removes white spaces around comas in csv string
 */
export const toCsv = (str) => str.replace(/\s?,\s?/g, ',');

/**
 * Returns formatted date object
 */
export function formatDate(providedDate, ...queries) {
	// Return empty array if date is not provided
	if (!Date.parse(providedDate)) return [];

	// Zero prefix
	const zp = (num) => (num > 9 ? num : `0${num}`);
	const d = new Date(providedDate + 'Z');
	let weekdays = '';
	if (localStorage.getItem('language') === 'en') {
		weekdays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
	} else {
		weekdays = ['NED', 'PON', 'UTO', 'SRE', 'CET', 'PET', 'SUB'];
	}

	const dateProps = {
		hours: zp(d.getHours()),
		minutes: zp(d.getMinutes()),
		seconds: zp(d.getSeconds()),
		date: zp(d.getDate()),
		month: zp(d.getMonth() + 1),
		year: d.getFullYear(),
		day: weekdays[d.getDay()],
	};

	return queries.map((query) =>
		query.replace(/(\w+)/g, (_, prop) => {
			return dateProps[prop];
		})
	);
}

/**
 * Transform camel-case to snake-case myName -> my_name
 */
export const toSnake = (obj) => {
	if (!obj || typeof obj !== 'object') return obj;
	if (Array.isArray(obj)) return obj.map(toSnake);

	return Object.entries(obj).reduce((newObj, [key, value]) => {
		const prop = key.replace(/[a-z]([A-Z])/g, ([lower, upper]) => `${lower}_${upper.toLowerCase()}`);
		newObj[prop] = toSnake(value);
		return newObj;
	}, {});
};

/**
 * Remap object values based on provided instructions
 */
export function remap(instructions, inclusive = false) {
	return (props) => {
		const remapped = {};

		for (let key in props) {
			const data = props[key];
			let prop = instructions[key];

			if (!prop && inclusive) continue;
			else if (!prop) prop = key;
			remapped[prop] = data;
		}

		return remapped;
	};
}

/**
 * Helper for parsing json
 */
export const parse = (json) => {
	if (typeof json !== 'string') return json;
	try {
		return JSON.parse(json);
	} catch {
		return json;
	}
};

/**
 * Generates an array of numbers using the range
 */
export const optionsFromRange = (from = 0, to = from + 100, reverse) => {
	const options = [];
	for (let i = from; i <= to; i++) options.push({ id: i, name: i });
	return reverse ? options.reverse() : options;
};

/**
 * Check is string sequence of number characters
 */
export const isNumeric = (str) => {
	if (typeof str !== 'string') return false;
	return !isNaN(str) && !isNaN(parseFloat(str));
};

export const getDefaultAmount = () => {
	return window.rules && window.rules.MIN_DEPOSIT_FOR_SINGLE_TICKET ? window.rules.MIN_DEPOSIT_FOR_SINGLE_TICKET : 20;
};

export const getGeoParamsData = (fieldPath) => {
	if (!Array.isArray(fieldPath) || !window.geoParams || !window.geoMappings) return null;
	let field = window.geoParams;
	for (const element of fieldPath) {
		if (typeof element !== 'string') return null;
		const next = field[element];
		if (!next) return null;
		field = next;
	}
	const mapping = geoMappings[fieldPath[fieldPath.length - 1]];
	if (!mapping || mapping.field_type !== 'CSV') return field;
	const {
		field_type_specifics: { delimiter, pydantic_fields },
	} = mapping;
	const parts = field.split(delimiter);
	const objectSize = pydantic_fields.length;
	let groups = [];
	for (let index = 0; index < parts.length; index += objectSize) {
		groups.push(parts.slice(index, index + objectSize));
	}
	let tupples = [];
	groups.forEach((group) => {
		let temp = {};
		group.forEach((value, index) => {
			temp[pydantic_fields[index]] = +value;
		});
		tupples.push(temp);
	});
	return tupples;
};

export const executeDelayed = (callback, timeout = 1000, existingExecution = null, threshold = 950) => {
	if (typeof callback !== 'function') return null;
	if (existingExecution != null && existingExecution.timeoutID != null && existingExecution != timestamp) {
		const now = Date.now();
		if (now - existingExecution.timestamp < threshold) {
			clearTimeout(existingExecution.timeoutID);
		}
	}
	const timeoutID = setTimeout(callback, timeout);
	const timestamp = Date.now();
	return { timeoutID, timestamp };
};

export const remapEventForUpdate = (event) => {
	if (event == null) return;
	const {
		active,
		competitors,
		id,
		listCode,
		liveStatus,
		manuallyBlocked,
		markets,
		scheduled,
		sport,
		status,
		tournament,
		idParent = null,
		live,
		period,
	} = event;

	const mappedMarkets =
		markets && markets.map
			? markets.map(({ id, status, odds }) => ({
					id_market: id,
					status: status,
					oddtypes:
						odds && odds.map
							? odds.map(
									({
										value,
										specialValue,
										oddtypeStatus,
										id: id_oddtype,
										manualStatus,
										marketStatus,
										...rest
									}) => ({
										id_oddtype,
										odd: value,
										status: oddtypeStatus,
										special_value: specialValue,
										manual_status: manualStatus,
										market_status: marketStatus,
										market_key: id,
										special_value_order: rest.specialValueOrder ?? rest.special_value_order,
										keyboard_code_oddtype: rest.keyboardCodeOddtype ?? rest.keyboard_code_oddtype,
										...rest,
									})
							  )
							: [],
			  }))
			: [];
	return {
		active,
		competitors,
		id_event: id,
		id_parent: idParent,
		list_code: listCode,
		live: live,
		live_status: liveStatus,
		manually_blocked: manuallyBlocked,
		markets: mappedMarkets,
		utc_scheduled: scheduled,
		sport,
		status,
		tournament,
		period,
	};
};
