/**
 * If ticket has length of 12, calculate the last digit
 */
export const generateBarcode = (barcode) => {
	barcode = `${barcode}`.trim();
	if (barcode.length === 13) return barcode;

	let even = 0;
	let odd = 0;

	for (let i = 11; i > -1; i--) {
		if (i % 2 != 0) even += parseInt(barcode[i]) * 3;
		else odd += parseInt(barcode[i]);
	}

	return barcode + ((10 - ((even + odd) % 10)) % 10);
};
