import i18n from '../localisation/i18n';

export const MIN_DEPOSIT_FOR_MULTI_TICKET = ({ amount, currency }, expected, { isMulti, isLive }) =>
	isMulti && !isLive && amount < expected && i18n.t('minPayIn', { minPayIn: expected.toFixed(2), currency });

export const MAX_DEPOSIT_FOR_MULTI_TICKET = ({ amount, currency }, expected, { isMulti, isLive }) =>
	isMulti && !isLive && amount > expected && i18n.t('maxPayIn', { maxPayIn: expected.toFixed(2), currency });

export const MAX_NUM_OF_EVENTS_FOR_MULTI_TICKET = ({ systems }, expected, { isMulti, isLive }) => {
	const isInvalid = isMulti && !isLive && systems[0].events.length > expected;
	return isInvalid && i18n.t('maxEventsNum', { eventsNum: expected });
};

export const MIN_DEPOSIT_PER_EVENT_FOR_MULTI_TICKET = (
	{ currency },
	expected,
	{ isMulti, depositPerEvent, isLive, multiNumEvents }
) =>
	isMulti &&
	!isLive &&
	depositPerEvent < expected &&
	i18n.t('minEventPayIn', {
		minPayIn: expected.toFixed(2),
		currency,
		minPayInExpected: (expected * multiNumEvents).toFixed(2),
	});

export const MAX_DEPOSIT_PER_EVENT_FOR_MULTI_TICKET = ({ currency }, expected, { isMulti, depositPerEvent, isLive }) =>
	isMulti &&
	!isLive &&
	depositPerEvent > expected &&
	i18n.t('maxEventPayIn', { maxPayIn: expected.toFixed(2), currency });

/**
 * LIVE
 *
 */
export const MIN_LIVE_DEPOSIT_FOR_MULTI_TICKET = ({ amount, currency }, expected, { isMulti, isLive }) =>
	isMulti && isLive && amount < expected && i18n.t('minPayInLive', { minPayIn: expected.toFixed(2), currency });

export const MAX_LIVE_DEPOSIT_FOR_MULTI_TICKET = ({ amount, currency }, expected, { isMulti, isLive }) =>
	isMulti && isLive && amount > expected && i18n.t('maxPayInLive', { maxPayIn: expected.toFixed(2), currency });

export const MAX_LIVE_NUM_OF_EVENTS_FOR_MULTI_TICKET = ({ systems }, expected, { isMulti, isLive }) => {
	const isInvalid = isMulti && isLive && systems[0].events.length > expected;
	return isInvalid && i18n.t('maxEventsNum', { eventsNum: expected });
};

export const MIN_LIVE_DEPOSIT_PER_EVENT_FOR_MULTI_TICKET = (
	{ currency },
	expected,
	{ isMulti, isLive, depositPerEvent, multiNumEvents }
) =>
	isMulti &&
	isLive &&
	depositPerEvent < expected &&
	i18n.t('minEventPayInLive', {
		minPayIn: expected.toFixed(2),
		currency,
		minPayInExpected: (expected * multiNumEvents).toFixed(2),
	});

export const MAX_LIVE_DEPOSIT_PER_EVENT_FOR_MULTI_TICKET = (
	{ currency },
	expected,
	{ isMulti, isLive, depositPerEvent }
) =>
	isMulti &&
	isLive &&
	depositPerEvent > expected &&
	i18n.t('maxEventPayInLive', {
		maxPayIn: expected.toFixed(2),
		currency,
	});
