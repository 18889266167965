var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"events"},[_c('tbody',_vm._l((_vm.filteredMarkets),function(ref,index){
var key = ref.key;
var name = ref.name;
var odds = ref.odds;
var status = ref.status;
return _c('tr',{key:key,class:[{ selected: _vm.currentMarket === index }, { disabled: status !== 'ACTIVE' }, ' table_row'],attrs:{"id":("live-market:" + index),"row":index}},[_c('OddMargin',{attrs:{"name":name}}),_c('td',{staticClass:"live_typing_code"},[_c('div',{staticClass:"market_odd_list"},_vm._l((odds),function(ref,i){
var id = ref.id;
var name = ref.name;
var value = ref.value;
return _c('OddRow',{key:id,attrs:{"rowSelected":_vm.currentOdd === (index + ":" + i),"oddRow":(index + ":" + i),"value":value,"name":name,"addNewOdd":_vm.addNewOdd}})}),1)])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }