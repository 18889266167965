<template>
	<div class="head_right">
		<div class="header_tools">
			<div class="margin_left_auto tooltip_left" :class="socketStatus && socketStatus.class">
				<i class="mdi mdi-checkbox-blank-circle"> </i>
				<span class="tooltip_content">{{ socketStatus && socketStatus.text }}</span>
			</div>
			<div @click="toggleOPInfoModal" class="settings_toggle">
				<i class="mdi mdi-tune"></i>
			</div>
		</div>
	</div>
</template>

<script>
import { updateStatusBus } from '../../api';
import { mapMutations } from 'vuex';

export default {
	data() {
		return {
			connected: true,
		};
	},

	computed: {
		socketStatus() {
			return this.connected
				? { class: 'green_status', text: this.$t('serviceLive') }
				: { class: 'red_status', text: this.$t('serviceDisconnected') };
		},
	},

	methods: {
		...mapMutations(['toggleOperatorPanelInfoModal']),

		toggleOPInfoModal() {
			this.toggleOperatorPanelInfoModal();
		},
	},

	created() {
		updateStatusBus.$on('connected', () => {
			this.connected = true;
		});
		updateStatusBus.$on('disconnected', () => {
			this.connected = false;
		});
	},
};
</script>

<style scoped>
.green_status {
	color: #7fbd22;
}

.red_status {
	color: #f30000;
}
</style>
