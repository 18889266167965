import i18n from '../localisation/i18n';

export const MIN_DEPOSIT_PER_COMBO_FOR_SYSTEM_TICKET = (
	{ amount, currency },
	expected,
	{ isSystem, isLive, combinations, skip }
) => {
	const validateMinPayIn = parseFloat(((amount * 100) / combinations).toFixed(2)) < expected * 100;

	return (
		isSystem &&
		!isLive &&
		!skip &&
		validateMinPayIn &&
		i18n.t('minPayInPerCombo', {
			minPayIn: expected.toFixed(2),
			currency,
			minPayInExpected: (combinations * expected).toFixed(2),
		})
	);
};

export const MAX_DEPOSIT_PER_COMBO_FOR_SYSTEM_TICKET = (
	{ amount, currency },
	expected,
	{ isSystem, isLive, combinations }
) => {
	return (
		isSystem &&
		!isLive &&
		amount / combinations > expected &&
		i18n.t('maxPayInPerCombo', {
			maxPayIn: expected.toFixed(2),
			currency,
			maxPayInExpected: (combinations * expected).toFixed(2),
		})
	);
};

export const MIN_LIVE_DEPOSIT_PER_COMBO_FOR_SYSTEM_TICKET = (
	{ amount, currency },
	expected,
	{ isSystem, isLive, combinations, skipLive }
) => {
	const validateMinPayInLive = parseFloat(((amount * 100) / combinations).toFixed(2)) < expected * 100;
	return (
		isSystem &&
		isLive &&
		!skipLive &&
		validateMinPayInLive &&
		i18n.t('minPayInPerCombo', {
			minPayIn: expected.toFixed(2),
			currency,
			minPayInExpected: (combinations * expected).toFixed(2),
		})
	);
};

export const MAX_LIVE_DEPOSIT_PER_COMBO_FOR_SYSTEM_TICKET = (
	{ amount, currency },
	expected,
	{ isSystem, isLive, combinations }
) => {
	return (
		isSystem &&
		isLive &&
		amount / combinations > expected &&
		i18n.t('maxPayInPerCombo', {
			maxPayIn: expected.toFixed(2),
			currency,
			maxPayInExpected: (combinations * expected).toFixed(2),
		})
	);
};

export const MIN_DEPOSIT_FOR_SYSTEM_TICKET = ({ amount, currency }, expected, { isSystem, isLive }) =>
	isSystem && !isLive && amount < expected && i18n.t('minPayIn', { minPayIn: expected.toFixed(2), currency });

export const MAX_DEPOSIT_FOR_SYSTEM_TICKET = ({ amount, currency }, expected, { isSystem, isLive }) =>
	isSystem && !isLive && amount > expected && i18n.t('maxPayIn', { maxPayIn: expected.toFixed(2), currency });

export const MIN_NUM_OF_EVENTS_FOR_SYSTEM_TICKET = (_, expected, { isSystem, systemNumEvents, isLive }) =>
	isSystem && !isLive && systemNumEvents < expected && i18n.t('minEventsNum', { eventsNum: expected });

export const MAX_NUM_OF_EVENTS_FOR_SYSTEM_TICKET = (_, expected, { isSystem, systemNumEvents, isLive }) =>
	isSystem && !isLive && systemNumEvents > expected && i18n.t('maxEventsNum', { eventsNum: expected });

export const MAX_NUM_OF_COMBOS_FOR_SYSTEM_TICKET = (_, expected, { isSystem, isLive, combinations }) => {
	return isSystem && !isLive && combinations > expected && i18n.t('maxComboNum', { eventsNum: expected });
};

/**
 * LIVE
 *
 */

export const MIN_LIVE_DEPOSIT_FOR_SYSTEM_TICKET = ({ amount, currency }, expected, { isSystem, isLive }) =>
	isSystem &&
	isLive &&
	amount < expected &&
	i18n.t('minPayInLive', {
		minPayIn: expected.toFixed(2),
		currency,
	});

export const MAX_LIVE_DEPOSIT_FOR_SYSTEM_TICKET = ({ amount, currency }, expected, { isSystem, isLive }) =>
	isSystem &&
	isLive &&
	amount > expected &&
	i18n.t('maxPayInLive', {
		maxPayIn: expected.toFixed(2),
		currency,
	});

export const MIN_LIVE_NUM_OF_EVENTS_FOR_SYSTEM_TICKET = (_, expected, { isSystem, systemNumEvents, isLive }) =>
	isSystem && isLive && systemNumEvents < expected && i18n.t('minNumLiveEvents', { eventsNum: expected });

export const MAX_LIVE_NUM_OF_EVENTS_FOR_SYSTEM_TICKET = (_, expected, { isSystem, systemNumEvents, isLive }) =>
	isSystem && isLive && systemNumEvents > expected && i18n.t('maxNumLiveEvents', { eventsNum: expected });

export const MAX_LIVE_NUM_OF_COMBOS_FOR_SYSTEM_TICKET = (_, expected, { isSystem, isLive, combinations }) => {
	return isSystem && isLive && combinations > expected && i18n.t('maxComboLiveNum', { comboLiveNum: expected });
};
