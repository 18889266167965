export const log = (name, table) => {
	if (process.env.NODE_ENV === 'production') return;
	// console.log('\n\n', name);
	// console.table(table);
};

export const ticketReceived = ({ data: { status, systems, ...data } = {} } = {}) => {
	log('Received from ticket handler', {
		type: status === 1 ? 'SUCCESS' : status === 5 ? 'INVALID' : 'REFUNDED',
		status,
		...data,
		systems: JSON.stringify(systems, null, 3),
	});
};

export const joinedRoom = (room = '', rooms = []) => {
	const joined = {};
	rooms.forEach((room, i) => (joined['Room ' + i + 1] = room));

	log('Joined room', {
		'Room name': room,
		...joined,
	});
};
