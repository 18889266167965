<template>
	<div class="tab_nav lg padding_left_20">
		<div
			v-for="tab in tabs"
			:key="tab.id"
			:class="[{ active: activeTab === tab.id }, 'tab_nav_item']"
			@click="setActiveTab(tab.id)"
		>
			{{ tab.name }}
		</div>
	</div>
</template>

<script>
export default {
	props: ['activeTab', 'setActiveTab'],
	data() {
		return {
			tabs: [
				{ id: 'all', name: this.$t('all') },
				{ id: null, name: this.$t('regular') },
				{ id: 'EVENT', name: this.$t('special') },
				{ id: 'MIX', name: this.$t('mix') },
				{ id: 'PLAYER', name: this.$t('players') },
			],
		};
	},
};
</script>
