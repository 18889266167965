<template>
	<div class="modal_cover xxl toggle" @keyup.esc="closeModal">
		<div class="modal_content fadeInDown animated faster">
			<!-- Close modal -->
			<button class="btn transparent close" @click="closeModal()">
				<i class="mdi mdi-close"></i>
			</button>
			<!-- Header -->
			<div class="modal_head padding_left_20 col_border_bottom">
				<input
					:placeholder="$t('search')"
					v-model="inputValue"
					@input="handleChangeDebounced"
					id="modal-search"
					class="search"
					ref="search"
					type="text"
				/>
				<slot name="modal-header" />
			</div>

			<!-- Events list -->
			<div class="modal_body content_start direction_column" id='event-list-container'>
				<slot :searchValue="searchValue" />
			</div>
			<div class="modal_footer">
				<slot name="modal-footer" />
			</div>
		</div>
	</div>
</template>

<script>
import { keys } from 'keycodes-map';
import {DEBOUNCE_DELAY_MS} from '../../consts' 
export default {
	name: 'Modal',
	props: ['closeModal', 'searchQuery'],
	data() {
		return {
			searchValue: this.searchQuery,
			inputValue: this.searchQuery,
		};
	},
	methods: {
		handleChangeDebounced: function() {
			if (this.timeout) 
				clearTimeout(this.timeout); 

			this.timeout = setTimeout(() => {
				this.searchValue = this.inputValue
			}, DEBOUNCE_DELAY_MS);
			},
		handleEnterDown: function(e) {
			/**
			 *  EventList component triggers search based on 'searchValue' on enter-keyup event, so we need to update
			 * 	'searchValue' before that on keydown
			 */
			if (e.keyCode !== keys.enter) return;
			clearTimeout(this.timeout); 
			this.searchValue = this.inputValue

		}
    },
	mounted() {
		this.$refs.search.focus();
		document.addEventListener('keydown', this.handleEnterDown);

	},
	destroyed() {
		document.removeEventListener('keydown', this.handleEnterDown);
	},
};
</script>
