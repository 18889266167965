export default {
	state: {
		focusCode: false,
		focusOdd: false,
		oddString: '',
	},
	getters: {
		focusCode: (state) => state.focusCode,
		focusOdd: (state) => state.focusOdd,
		oddString: (state) => state.oddString,
	},
	mutations: {
		focusCodeHandler(state) {
			state.focusCode = true;
			setTimeout(() => {
				state.focusCode = false;
			}, 700);
		},

		focusOddHandler(state, payload) {
			state.focusOdd = payload.value;
		},
		selectEventOddHandler(state, payload) {
			state.oddString = payload.value;
		},
	},
	actions: {
		focusCodeAction({ commit }) {
			commit('focusCodeHandler');
		},
		focusOddAction({ commit }, payload) {
			commit('focusOddHandler', payload);
		},

		selectEventOddAction({ commit }, payload) {
			commit('selectEventOddHandler', payload);
		},
	},
};
