<template>
	<div class="modal_cover m toggle" @keyup.esc="closeModal">
		<div class="modal_content fadeInDown animated faster">
			<div class="modal_body modal_content padding_30">
				<h2>{{ $t('will_reload_in') + ' ' + this.seconds + ' ' + $t('seconds') }}</h2>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['secondsToCount', 'action'],
	data() {
		return {
			seconds: 0,
			timer: '',
		};
	},

	mounted() {
		const { secondsToCount, action } = this;
		this.seconds = secondsToCount;
		this.timer = setInterval(() => {
			this.seconds--;
			if (!this.seconds || this.seconds < 0) {
				this.timer && clearInterval(this.timer);
				action && action();
			}
		}, 1000);
	},
};
</script>
