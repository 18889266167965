<template>
	<tr :class="`table_row ${removed ? 'red_row disabled' : ''}`">
		<td class="strong text_left padding_left_20 padding_right_0">{{ event.listCode || event.list_code }}</td>
		<td class="text_right align_center">
			<span class="svg_icon tooltip_left">
				<!-- <svg class="sport_ico">
					<use :xlink:href="`/svg/sprite_sport_icons.svg#${sport.id || ''}`" />
				</svg> -->
				<span class="">{{ sport.name.substring(0, 3) }}</span>
				<span class="tooltip_content">{{ sport.name }}</span>
			</span>
			<i v-if="event.live" class="mdi mdi-play-circle tooltip_left live_event padding_right_10"
				><span class="tooltip_content">{{ $t('liveEvent') }}</span></i
			>
			<span :class="!event.live ? 'padding_left_40' : 'padding_left_16'">{{ start }}</span>
		</td>
		<td class="strong text_right padding_right_5">{{ home }}</td>
		<td class="padding_left_0 padding_right_0 small vs text_center">-</td>
		<td class="strong text_left padding_left_5">{{ away }}</td>
		<td class="padding_right_10 text_right">{{ marketName }}</td>

		<td class="padding_left_0 strong">
			<span v-if="odd.special_value || (prevOdd && prevOdd.specialValue)" class="border_left">
				<div v-if="prevOdd && prevOdd.specialValue !== odd.special_value" class="margin_change tooltip_right">
					<div class="margin_new">{{ odd.special_value }}</div>
					<div class="margin_old">{{ prevOdd.specialValue }}</div>
					<span class="tooltip_content yellow">{{ $t('marginChanged') }}</span>
				</div>
				<div v-else-if="odd.special_value" :class="` ${added ? ' margin_change margin_new' : ''}`">
					{{ odd.special_value }}
				</div>
			</span>
		</td>

		<td class="strong tooltip_left">
			{{ `${odd.bonusType || odd.bonus_type ? `B ${oddData.name}` : oddData.name || ''} `
			}}<span class="tooltip_content">{{ oddData.description }}</span>
		</td>

		<td>
			<span v-if="odd.bonusType || odd.bonus_type" class="svg_icon tooltip_right">
				<svg><use xlink:href="/svg/sprite_mb.svg#bonus_tip_18"></use></svg>
				<span class="tooltip_content">{{ $t('bonusOdd') }}</span>
			</span>
		</td>

		<!-- Stake type -->
		<td :class="`padding_right_10 text_right`">
			<div v-if="prevOdd && prevOdd.stake !== odd.stake" class="odd_change margin_left_auto tooltip_left">
				<div class="odd_old">{{ oddsFormat(+prevOdd.stake) }}</div>
				<div class="odd_new odd_up" :class="`odd_new ${prevOdd.stake < odd.stake ? 'odd_up' : 'odd_down'}`">
					{{ oddsFormat(+odd.stake) }}
				</div>
				<span class="tooltip_content green" :class="prevOdd.stake < odd.stake ? 'green' : 'red'">
					{{ $t('oddChanged') }}
				</span>
			</div>

			<div v-else>
				{{ oddsFormat(+odd.stake) }}
			</div>
		</td>
		<td class="condition"></td>
	</tr>
</template>

<script>
import { memoize } from '../../Memoization';
import { mapGetters } from 'vuex';
import { formatDate } from '../../../utils';
import { formatting } from '../../../mixins';

export default {
	mixins: [memoize('event'), formatting],

	props: ['index', 'removed', 'event', 'added', 'oldEvent'],
	computed: {
		...mapGetters(['eventOddsData', 'marketsMap']),
		start() {
			const { utc_scheduled } = this.event;
			if (!utc_scheduled) return '';

			const date = new Date(utc_scheduled);
			return date && formatDate(date, 'date.month.year. hours:minutes')[0];
		},
		sport() {
			const { sport } = this.event || {};
			return sport || {};
		},
		competitors() {
			const { competitors = [] } = this.event || {};
			return competitors;
		},
		home() {
			const [home] = this.competitors;
			return home && home.name;
		},
		away() {
			const [, away] = this.competitors;
			return away && away.name;
		},
		odds() {
			const { odds } = this.event || {};
			return odds;
		},
		odd() {
			const { odds } = this.event;
			if (!odds) return {};
			const [{ value: stake, ...rest }] = Object.values(odds);
			const id = Object.keys(odds)[0];
			return { stake, id, ...rest };
		},
		prevOdd() {
			const { odds } = this.prev_event || {};
			if (!odds) {
				if (!this.oldEvent) return null;

				if (this.oldEvent.type === 'event') {
					const [{ value: stake, ...rest }] = Object.values(this.oldEvent.odds);
					return { stake, ...rest };
				} else {
					const { value: stake, ...rest } = this.oldEvent;
					return { stake, ...rest };
				}
			}

			const [{ value: stake, ...rest }] = Object.values(odds);
			return { stake, ...rest };
		},
		oddData() {
			const { odds, eventOddsData } = this;
			if (!odds) return '';
			const [odd] = eventOddsData(odds);
			return odd ? { name: odd.name, description: odd.description } : { name: '', description: '' };
		},

		marketName() {
			const {
				marketsMap,
				sport: { id: sportId },
				odd: { id: oddId },
			} = this;
			const parts = oddId.split(':');
			const marketId = parts.length ? parts[0] + ':' + parts[1] : null;
			const { name = '' } = marketId && marketsMap ? marketsMap[sportId].markets.markets[marketId] : {};
			return name;
		},
	},
};
</script>

<style>
.padding_left_16 {
	padding-left: 16px;
}
</style>
