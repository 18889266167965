// @ts-nocheck
/* eslint-disable */
/**
 * AleaShell
 * @version 1.0.10
 * @Date 10.04.2018.
 * @author  AleaControl
 */

const AleaControlShellClass = function() {
	barcodeScannerAcceptorEvent: null;
	return {
		setCoinAcceptorEvent: function(t) {
			this.coinAcceptorEvent = t;
		},
		addCoin: function(t) {
			try {
				return 'function' == typeof this.coinAcceptorEvent && (this.coinAcceptorEvent(t), !0);
			} catch (t) {
				return !1;
			}
		},
		setBarcodeScannerAcceptorEvent: function(t) {
			this.barcodeScannerAcceptorEvent = t;
		},
		barcodeScannerRead: function(t, n, e) {
			try {
				return (
					'function' == typeof this.barcodeScannerAcceptorEvent && (this.barcodeScannerAcceptorEvent(t, n, e), !0)
				);
			} catch (t) {
				return !1;
			}
		},
		setLoyaltyCardScannerAcceptorEvent: function(t) {
			this.loyaltyCardScannerAcceptorEvent = t;
		},
		loyaltyCardRead: function(t) {
			try {
				return (
					'function' == typeof this.loyaltyCardScannerAcceptorEvent &&
					(this.loyaltyCardScannerAcceptorEvent(t), !0)
				);
			} catch (t) {
				return !1;
			}
		},
		setChangeLanguageEvent: function(t) {
			this.changeLanguageEvent = t;
		},
		changeLanguage: function(t) {
			try {
				return 'function' == typeof this.changeLanguageEvent && (this.changeLanguageEvent(t), !0);
			} catch (t) {
				return !1;
			}
		},
		setChangeTabEvent: function(t) {
			this.changeTabEvent = t;
		},
		changeTab: function(t) {
			try {
				return 'function' == typeof this.changeTabEvent && (this.changeTabEvent(t), !0);
			} catch (t) {
				return !1;
			}
		},
		setPrintCopyTicketEvent: function(t) {
			this.printCopyTicketEvent = t;
		},
		printCopyTicket: function(t) {
			try {
				return 'function' == typeof this.printCopyTicketEvent && (this.printCopyTicketEvent(t), !0);
			} catch (t) {
				return !1;
			}
		},
		setPrintText: function(t, n) {
			try {
				return AleaControlShellInterface.print(t, n), !0;
			} catch (t) {
				return !1;
			}
		},
		refreshBalance: function() {
			try {
				return AleaControlShellInterface.refreshBalance(), !0;
			} catch (t) {
				return !1;
			}
		},
		reconnectGame: function(t, n) {
			try {
				return AleaControlShellInterface.reconnectGame(t, n), !0;
			} catch (t) {
				return !1;
			}
		},
		getBalance: function() {
			try {
				return AleaControlShellInterface.getBalance();
			} catch (t) {
				return 0;
			}
		},
		getCurrentOperator: function() {
			try {
				return AleaControlShellInterface.getCurrentOperator();
			} catch (t) {
				return '';
			}
		},
		getInsertTicketInformations: function() {
			try {
				return AleaControlShellInterface.getInsertTicketInformations();
			} catch (t) {
				return '';
			}
		},
		openCopyTicketWindow: function() {
			try {
				return AleaControlShellInterface.openCopyTicketWindow(), !0;
			} catch (t) {
				return !1;
			}
		},

		/**
		 * Open Shell modal form for player details.
		 * @param {string} t Ticket barcode and pin code concatenated with '+' sign
		 * @returns {boolean} 'true' if call was successful
		 */
		openPlayerDetailsForm: function(t) {
			try {
				return AleaControlShellInterface.openPlayerDetailsForm(t), !0;
			} catch (t) {
				return !1;
			}
		},
	};
};

export const AleaControlShell = new AleaControlShellClass();
/* eslint-enable */
