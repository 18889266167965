<template>
	<div class="modal_cover sm green_stroke modal_07 toggle" @keyup.esc="toggleDepositModal">
		<div class="modal_content fadeInDown animated faster">
			<button class="btn transparent close" @click="toggleDepositModal">
				<i class="mdi mdi-close"></i>
			</button>

			<div class="modal_head padding_left_20">
				<h2 class="modal_title ">{{ $t('online.deposit') }}</h2>
			</div>

			<div class="modal_body content_start direction_column small_form" v-if="!user">
				<div class="form_row">
					<Dropdown
						:items="depositOptions"
						:callback="selectOption"
						zIndex="z_1"
						:label="$t('online.wayOfDeposit')"
					/>
				</div>

				<div class="form_row direction_column" v-if="selectedOption === 'userName'">
					<label for="userName" class="input_label">{{ $t('online.userEmail') }}</label>
					<input
						required
						type="email"
						id="userName"
						class="regular min_width_200"
						v-model="email"
						ref="email"
						@keyup.enter="modalEnterHandler('email', 'paymentAmount')"
					/>
				</div>

				<div class="form_row direction_column" v-if="selectedOption === 'cardCode'">
					<label for="cardCode" class="input_label">{{ $t('online.userCode') }}</label>
					<input
						required
						type="number"
						id="cardCode"
						class="regular min_width_200"
						v-model="cardId"
						@keyup.enter="modalEnterHandler('cardId', 'paymentAmount')"
					/>
				</div>

				<div class="form_row direction_column">
					<label for="paymentAmount" class="input_label">{{ $t('online.depositAmount') }}</label>
					<input
						type="number"
						id="paymentAmount"
						class="regular min_width_200"
						v-model="deposit"
						@keyup.enter="modalEnterHandler('deposit', 'checkButton')"
					/>
				</div>
			</div>
			<div class="modal_body content_start direction_column small_form" v-if="user">
				<div class="form_row direction_column" v-if="user">
					<label class="input_label">{{ `${$t('online.confirmText')} ${user.firstName} ${user.lastName}` }}</label>
					<label class="input_label"></label>
					<label class="input_label">{{ `${$t('online.depositAmount')} ${toMoneyFormat(+deposit)}` }}</label>
				</div>
			</div>

			<div class="modal_footer">
				<button
					id="checkButton"
					@click="getUser"
					:disabled="!deposit || (!email && !cardId) || submitting"
					class="btn md round dark_green"
					v-if="!user"
				>
					{{ $t('online.check') }}
				</button>
				<button
					class="btn md round"
					v-if="user"
					id="cancelButton"
					@keyup.right="focusOn('payinButton')"
					@click="removeUser"
				>
					{{ $t('cancel') }}
				</button>
				<button
					class="btn md round dark_green"
					@click="payin"
					:disabled="submitting"
					v-if="user"
					@keyup.left="focusOn('cancelButton')"
					id="payinButton"
				>
					{{ $t('online.payin') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations } from 'vuex';
import Dropdown from '../../General/Dropdown';
import { api } from '../../../api';
import { printSlips } from '../../../utils/printSlips';
import { formatting, inputHandler } from '../../../mixins';
import { selectDelayed, isNumeric, select } from '../../../utils';

export default {
	mixins: [formatting, inputHandler],
	components: {
		Dropdown,
	},

	data() {
		return {
			depositOptions: [
				{ id: 'userName', name: 'Korisnicko ime' },
				{ id: 'cardCode', name: 'Sifra kartice' },
			],
			selectedOption: 'userName',
			user: null,
			email: null,
			cardId: null,
			deposit: null,
			submitting: false,
		};
	},

	methods: {
		...mapMutations(['toggleDepositModal']),

		selectOption(option) {
			this.selectedOption = option;
			selectDelayed(option, 150);
		},

		async getUser() {
			try {
				this.submitting = true;
				const { email, cardId, selectedOption } = this;
				if (selectedOption === 'userName') {
					const data = await api.getUserByEmail(email);
					this.user = data;
				} else {
					const data = await api.getUserByCode(cardId);
					this.user = data;
				}
				selectDelayed('payinButton', 150);
			} catch (error) {
				this.showErrorMessage(error);
			} finally {
				this.submitting = false;
			}
		},

		async payin() {
			try {
				this.submitting = true;
				const { email, cardId, selectedOption, deposit, user, toMoneyFormat, toggleDepositModal } = this;
				let data;
				if (selectedOption === 'userName') {
					data = await api.depositByEmail(email, deposit);
				} else {
					data = await api.depositByCode(cardId, deposit);
				}
				// Format date to local time
				const dtCreated = new Date(data.dtCreated + 'Z').toLocaleString();
				// Call shell  function for deposit slips print
				printSlips({ ...data, dtCreated }, 'deposit');

				this.$notifications.info({
					title: `${user.firstName} ${user.lastName}`,
					text: this.$t('depositPayedIn', { deposit: toMoneyFormat(+data.deposit), currency: data.currency }),
					type: 'success',
				});
				toggleDepositModal();
			} catch (error) {
				this.showErrorMessage(error);
			} finally {
				this.submitting = false;
			}
		},

		removeUser() {
			const { selectedOption } = this;
			this.user = null;
			this.focusOn(selectedOption);
		},

		showErrorMessage(error) {
			const { selectedOption } = this;
			let err = 'Something went wrong, try again';
			if (error.response && error.response.data)
				err = error.response.data.detail[0].msg || error.response.data.detail;

			// Notify operater that error occured
			this.$notifications.info({
				title: this.$t('error'),
				text: `${err}`,
				type: 'error',
				data: { id: selectedOption },
			});
		},
	},
	mounted() {
		this.$refs.email.focus();
	},
};
</script>
