export default {
	install: function(Vue) {
		Object.assign(Vue.prototype, {
			$notifications: {
				info(props) {
					Vue.notify({
						...props,
						group: 'payin',
						closeOnClick: true,
						duration: -1,
					});
				},
				error(props) {
					Vue.notify({
						...props,
						type: 'error',
						group: 'payin',
						closeOnClick: true,
						duration: -1,
					});
				},
			},
		});
	},
};
