<template>
	<div :class="`drop_menu down ${zIndex} dumb_drop_menu_toggle_trigger_01`">
		<span class="drop_label uppercase font_600 font_11px" @click="toggleDropdown">
			{{ text }}
			<i class=" mdi mdi-menu-down "></i>
		</span>
		<div class="drop_list left_0 z_2" :class="{ toggle: opened }">
			<div
				v-for="item in items"
				:key="item.id"
				class="drop_list_item font_400"
				:class="{ selected: selectedItem && selectedItem.id === item.id }"
				@click="handleSelect(item)"
			>
				{{ item.name }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['zIndex', 'callback', 'items', 'label', 'value', 'dontAutoselect'],
	data() {
		return {
			opened: false,
			selectedItem: null,
		};
	},
	computed: {
		text() {
			const { label, selectedItem } = this;
			return selectedItem ? selectedItem.name : label;
		},
	},
	methods: {
		handleSelect(item) {
			this.selectedItem = item;
			this.opened = false;
			this.callback(item.id);
		},

		openDropdown() {
			this.opened = true;
		},

		closeDropdown() {
			this.opened = false;
		},

		toggleDropdown() {
			this.opened = !this.opened;
		},
	},
	watch: {
		value() {
			const { items, value } = this;
			this.selectedItem = items.find(({ id }) => id === value);
		},
	},
	created() {
		const { items, value, dontAutoselect } = this;
		if ('value' in this) this.selectedItem = items.find(({ id }) => id === value);
		else if (!dontAutoselect) this.selectedItem = items[0] || null;
	},
};
</script>

<style></style>
