import { EVENT_CHANGE, EVENT_CHANGE_LIVE, EVENT_CHANGE_PREMATCH, BET_ODDS } from '../../../src/consts/messages'
import settings from '../../settings.json'
import { applyEventChangeLiveOnPrematchEvents, applyEventChangeMessageOnEvents, applyEventChangePrematchOnPrematchEvents, applyOddsChangeMessageOnEvents } from './messageHandlers';
import {parse} from '../../utils'
import { remapMinified } from '../remappers';


const minificationEnabled = settings.socketMessagesMinification;


export const applyMessagesOnPrematchEvents = (events, messages) => {
    messages.forEach(message => {
        const parsed = parse(message);
        if (!parsed || typeof parsed !== 'object' || !parsed.data) return;
    
        let data = parsed.data;
        if (minificationEnabled) {
            data = remapMinified(message.type, data);
        }


        switch (message.type) {
            case BET_ODDS: {
                applyOddsChangeMessageOnEvents(events, data)
                break
            }
            case EVENT_CHANGE: {
                applyEventChangeMessageOnEvents(events, data)
                break
            }
            case EVENT_CHANGE_LIVE: {
                applyEventChangeLiveOnPrematchEvents(events, data)
                break
            }
            case EVENT_CHANGE_PREMATCH: {
                applyEventChangePrematchOnPrematchEvents(events, data)
                break
            }
        }
        
    })
}

