<template>
	<div :class="[{ selected: rowSelected, disabled: value === 1 }, 'game_wrapp']" @click="addNewOdd(oddRow)">
		<div class="game_label">{{ name }}</div>
		<div class="game_odd" :class="colorClass">{{ value === 1 ? '-' : oddsFormat(+value) }}</div>
	</div>
</template>

<script>
import { memoize } from '../../Memoization';
import { formatting } from '../../../mixins';
export default {
	mixins: [memoize('value', 3000), formatting],
	props: ['name', 'value', 'rowSelected', 'oddRow', 'addNewOdd'],
	computed: {
		colorClass() {
			const { value, prev_value } = this;
			if (value === 1) return 'warn';
			if (!prev_value || prev_value === value) return '';
			return prev_value > value ? 'down' : 'up';
		},
	},
};
</script>

<style scoped>
.selected {
	border: 2px solid #feb200;
	background-color: #feb200;
	color: white;
}

.up {
	background-color: green;
	color: white;
	border: 1px solid white;
}
.down {
	background-color: red;
	color: white;
	border: 1px solid white;
}
.warn {
	background-color: orange;
	color: white;
	border: 1px solid white;
}
</style>
