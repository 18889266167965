import { REQUESTED_NUM, EXPIRED_NUM, MODIFIED_AUTH_NUM } from '../../../consts/index';
import i18n from '../../../localisation/i18n';

export const ticketStatuses = {
	[REQUESTED_NUM]: {
		text: i18n.t('REQUESTED'),
		bgColor: 'gray_darken_bg',
	},
	[EXPIRED_NUM]: {
		text: i18n.t('EXPIRED'),
		bgColor: 'redish_bg',
	},
	[MODIFIED_AUTH_NUM]: {
		text: i18n.t('MODIFIED'),
		bgColor: 'orange_bg',
	},
};
