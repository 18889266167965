<template>
	<!-- Modals -->
	<div class="modal_cover modal_01" :class="{ toggle: closePromotionModal }">
		<!-- .toggle .notification -->
		<div class="modal_content fadeInDown animated faster">
			<button class="btn transparent close toggle_modal_01" @click="togglePromotionModal()">
				<i class="mdi mdi-close"></i>
			</button>

			<div class="modal_head col_border_bottom">
				<h2 class="modal_title font_500 flex_0">
					{{ $t('previewPromotions') }} <span class="font_700 padding_left_5">{{ appliedDate }}</span>
				</h2>
			</div>

			<div class="modal_body content_start direction_column">
				<table class="events">
					<thead>
						<tr class="table_row">
							<th class="text_left padding_left_40">{{ $t('name') }}</th>
							<th class="text_right padding_right_40">{{ $t('number') }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="promo in promotionsData" :key="promo.name" class="table_row ">
							<td class="text_left padding_left_40">{{ promo.name }}</td>
							<td class="text_right padding_right_40">{{ promo.count }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<!-- .modal_body -->
		</div>
		<!-- .modal_content -->
	</div>
</template>
<script>
export default {
	name: 'PromotionListModal',
	props: {
		closePromotionModal: Boolean,
		togglePromotionModal: Function,
		promotionsData: Array,
		appliedDate: String,
	},
};
</script>
