<template>
	<iframe
		class="home_screen"
		:src="
			`https://downloadsection.net/downloads/download_page.php?idcompany=4229811e-ef80-4004-850f-c180be1a1b18&idregion=ec7353b1-1c08-49a1-a9bf-15e9a81c2831&idgroup=8cf525bb-c93d-46e8-9337-7292787b0ef4&idlocation=${idLocation}&lang=${lang}`
		"
	/>
</template>

<script>
export default {
	data() {
		return { idLocation: window.idLocation, lang: this.$i18n.locale };
	},
};
</script>

<style scoped>
.home_screen {
	width: 100vw;
	height: calc(100vh - 60px);
}
</style>
