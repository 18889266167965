import { ACTIVE, REMOVED } from '../consts';
import { parse } from '../utils';
import { remapMinified } from '../utils/remappers';
import settings from '../settings.json';

const minificationEnabled = settings.socketMessagesMinification;

export const formatOdds = (markets = []) => {
	let newOdds = {};
	markets.forEach((market) => {
		market.oddtypes.forEach((odd) => {
			if (
				odd.status === 1 &&
				(odd.market_status === ACTIVE || market.status === ACTIVE) &&
				odd.manual_status !== REMOVED
			) {
				newOdds[odd.id_oddtype] = {
					id: odd.id_oddtype,
					value: odd.odd,
					status: odd.status,
					result: odd.result,
					special_value: odd.special_value,
					manual_status: odd.manual_status,
					oddtype_status: odd.status,
					oddtype_key: odd.id_oddtype,
					market_status: odd.market_status || market.status,
					market_key: market.id_market,
					keyboard_code_oddtype: odd.keyboardCodeOddtype ?? odd.keyboard_code_oddtype ,
					special_value_order: odd.specialValueOrder ?? odd.special_value_order,
				};
			}
		});
	});

	return newOdds;
};

export const formatBonusOdds = (markets) => {
	let newOdds = {};

	markets.forEach(({ oddtypes, status: marketStatus, id_market }) => {
		oddtypes.forEach((odd) => {
			const { id_oddtype: id, special_value, odd: value, status, manual_status, result } = odd;
			if (status === 1 && marketStatus === ACTIVE && manual_status !== REMOVED) {
				newOdds[id] = {
					id,
					value,
					status,
					result,
					special_value,
					manual_status,
					oddtype_status: status,
					market_status: marketStatus,
					market_key: id_market
				};
			}
		});
	});
	return newOdds;
};

export const getMessageData = (type, message) => {
	const parsed = parse(message);
	if (!parsed || typeof parsed !== 'object' || !parsed.data) return;

	let data = parsed.data;
	if (minificationEnabled) {
		data = remapMinified(type, data);
	}
	return data;
};
