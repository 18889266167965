<template>
	<tr class="table_row new_row">
		<td class="p_0 row_number" />

		<!-- Code -->
		<td class="event_code p_0">
			<input
				ref="code"
				type="text"
				class="td_input"
				:value="listCode"
				:id="`new:${systemIndex}:code`"
				:name="`new:${systemIndex}:code`"
				@focus="newCodeFocus()"
				@keyup.right="codeRight"
				@keyup.enter="codeEnter"
				@keyup.up="removeThisRow"
				@keydown.enter="newEventCodeHandler"
				@keyup.down="removeThisRow"
				@keyup="handleEventKeyUp"
				autocomplete="new-event-code-no-autocomplete"
			/>
		</td>

		<!-- Time -->
		<td class="p_0 small">
			<i v-show="event.live" class="mdi mdi-play-circle tooltip_left live_event padding_right_10 padding_top_10">
				<span class="tooltip_content">{{ $t('liveMatch') }}</span>
			</i>
			<span :class="event.live ? '' : 'padding_left_30'">{{ time }}</span>
		</td>

		<!-- Competitors -->
		<template v-if="event">
			<td class="home pr_5 strong text_right">{{ home }}</td>
			<td class="p_0 small vs text_center">{{ away && '-' }}</td>
			<td class="away pl_5 strong text_left">{{ away }}</td>
		</template>
		<template v-else>
			<td class="pr_5" />
			<td class="p_0" />
			<td class="pl_5" />
		</template>

		<!-- Odd type -->
		<td class="p_0 game_input">
			<span class="tooltip_right">
				<input
					ref="odd"
					type="text"
					class="td_input"
					:id="`new:${systemIndex}:odd`"
					:name="`new:${systemIndex}:odd`"
					@focus="
						oddtypeFocus(`new:${systemIndex}:odd`);
						resetfocusInputTimer();
					"
					@keyup.left="oddLeft"
					@keyup.enter="oddEnter"
					@keydown.enter="newEventOddHandler"
					@keyup="handleKeyUp"
					@keyup.up="removeThisRow"
					@keyup.down="removeThisRow"
					autocomplete="no-event-odd-no-autocomplete"
				/>
			</span>
		</td>

		<td class="pr_10 text_right odd"></td>

		<!-- Add more odds -->
		<td class="pl_0 add_game">
			<i class="mdi mdi-plus-circle add_btn tooltip_right" @click="oddStar({ keyCode: 106 })">
				<span class="tooltip_content">{{ $t('addOddtype') }}</span>
			</i>
		</td>

		<td></td>

		<!-- Sport icon -->
		<td class="pr_0 sport">
			<span v-if="event" class="svg_icon tooltip_right">
				<!-- <svg class="sport_ico">
					<use :xlink:href="icon" />
				</svg> -->
				<span class="">{{ sportName.substring(0, 3) }}</span>
				<span class="tooltip_content">{{ sportName }}</span>
			</span>
		</td>

		<!-- Tournament -->
		<td class="pr_0 league">
			<span class="border_right">{{ tournament }}</span>
		</td>

		<!-- Condition -->
		<td class="condition">{{ condition }}</td>
	</tr>
</template>

<script>
import { keys, isLetter } from 'keycodes-map';
import {
	computedEvent,
	createEditEvent,
	eventNavigation,
	inputHandler,
	eventHelpers,
	specialEvents,
	focusInputConfig,
} from '../../mixins';
import { select, selectDelayed, remapEventForUpdate, focus } from '../../utils';
import { mapActions } from 'vuex';
import { NOT_RUNNING, RUNNING, STOPPED, LIVE, ANNOUNCED, POSTPONED, INTERRUPTED } from '../../consts';

export default {
	mixins: [
		computedEvent,
		createEditEvent,
		eventNavigation,
		inputHandler,
		eventHelpers,
		specialEvents,
		focusInputConfig,
	],
	props: ['row', 'system'],
	data() {
		return {
			event: this.row.event,
			modalIsOpen: false,
		};
	},

	methods: {
		...mapActions(['addNewLiveEvent']),
		...mapActions(['addNewPrematchEvent']),

		handleEventKeyUp(e) {
			if (this.scanning) return;
			if (e.keyCode === keys.insert) this.enableScan();

			this.eventsModal(e);
			this.codePlus(e);
		},

		handleKeyUp(e) {
			if (this.scanning) return;
			if (e.keyCode === keys.insert) this.enableScan();

			this.oddStar(e);
			this.oddsModal(e);
		},

		async codeEnter() {
			if (this.scanning) return;

			const {
				systemIndex,
				eventId,
				listCode: oldListCode,
				startedOrFinished,
				$api,
				noEventNotify,
				addNewPrematchEvent,
				eventIsLive,
				eventIsNotReady,
			} = this;
			const listCode = this.getListCode();

			if (!listCode) {
				setTimeout(() => {
					this.resetfocusInputTimer();
				}, 200);
				this.removeTicketEvent({ systemIndex, eventId });
				return select(`sys:${systemIndex}:0`);
			}

			if (!this.panelBarcodeScanCheck(listCode)) return;

			let event = this.$store.getters?.prematchEventData(listCode);

			const now = new Date();
			now.setSeconds(0, 0);
			const currentDate = now
				.toISOString()
				.replace(/T/, ' ')
				.replace(/:00.000Z/, '');
			const scheduledDate = event ? event?.utc_scheduled : null;
			// Match stay in preMatch list - move from pm in live list
			if (event && currentDate > scheduledDate) {
				try {
					const newEvent = await $api.getEventByListCode(listCode);

					if (!newEvent) throw 'No event found';

					const { competitors, status, liveStatus } = newEvent;
					const [{ name: home }, { name: away } = { name: '' }] = competitors || [{}, {}];
					if (liveStatus === ANNOUNCED) {
						return eventIsNotReady({ listCode, home, away });
					}
					if ((status === STOPPED || status === INTERRUPTED || status === RUNNING) && liveStatus === LIVE) {
						await this.addNewLiveEvent(remapEventForUpdate(newEvent));
						return eventIsLive({ listCode, home, away });
					}
					return eventInactive({ listCode, home, away });
				} catch (error) {
					console.error(err);
					if (this.modalIsOpen === false) {
						noEventNotify(listCode);
						this.modalIsOpen === true;
					}
				}
			}
			if (!event) {
				try {
					event = await $api.getEventByListCode(listCode);
					if (!event) throw 'No event found';

					const { active, status, manuallyBlocked } = event;
					if (
						!active ||
						(status !== NOT_RUNNING && status !== POSTPONED) ||
						manuallyBlocked ||
						event.live === true
					) {
						return startedOrFinished(event);
					}
					await addNewPrematchEvent(remapEventForUpdate(event));
					event = this.$store.getters?.prematchEventData(listCode);
				} catch (err) {
					console.log(err);
					if (this.modalIsOpen === false) {
						noEventNotify(listCode);
						this.modalIsOpen === true;
					}
				} finally {
					if (!event) return;
				}
			}

			if (event.live) {
				const { competitors, utc_scheduled } = event;
				const [{ name: home }, { name: away } = { name: '' }] = competitors || [{}, {}];
				return this.eventStartedNotify({ listCode, home, away, scheduled: utc_scheduled });
			}
			if (oldListCode === listCode) return this.codeRight();
			if (this.isEventAdded(listCode)) return this.eventAddedNotify();

			// Specials check
			const specialWarningMessage = this.checkSpecialConditions(event);
			if (specialWarningMessage) return this.specialWarning(specialWarningMessage);

			this.replaceTicketEvent({
				systemIndex,
				eventId,
				newEvent: {
					...event,
					odds: [],
					isNew: true,
				},
			});
		},
		codeRight() {
			const { event, getListCode, systemIndex } = this;
			const listCode = getListCode();

			if (event && event.listCode === listCode) selectDelayed(`new:${systemIndex}:odd`);
		},

		oddEnter() {
			if (this.scanning) return;

			const { systemIndex, event, getOddCode, $refs, eventId } = this;
			const listCode = this.getListCode();

			if (!listCode) {
				this.removeTicketEvent({ systemIndex, eventId });
				return select(`sys:${systemIndex}:0`);
			}

			if (!event) return;
			const oddCode = getOddCode();
			if (!oddCode) {
				if (!event.live) {
					$refs.odd.value = 1;
					return this.oddEnter();
				}
			}
			const bonus = this.isBonusOddCode(oddCode);
			const oddType = this.getOddType(bonus, event.live);

			if (!oddType) {
				if (event.live) return this.useLiveOfferNotify();
				return this.noOddTypeNotify();
			}
			if (!oddType.stake || oddType.stake <= 1) {
				return this.oddCurrentlyUnavailable(oddType.name);
			}
			this.setLastOdd(oddType);

			this.replaceTicketEvent({
				eventId: event.id,
				systemIndex,
				newEvent: {
					...event,
					odds: [oddType],
					isNew: false,
				},
			});

			this.addNewEmptyEvent(systemIndex);
		},
		oddStar(e) {
			if (e.keyCode !== keys.numpadMultiply) return;

			const { systemIndex, $refs, event, getOddCode, eventIndex, noMultipleOddsLive } = this;
			if (!event) return;

			//removed is event live check based on client request
			// if (event.live) {
			// 	$refs.odd.value = $refs.odd.value.replace('*', '');
			// 	return noMultipleOddsLive();
			// }

			const oddCode = getOddCode();

			const bonus = this.isBonusOddCode(oddCode);
			let oddType = this.getOddType(bonus, event.live);
			if (!oddType) return this.noOddTypeNotify();
			this.replaceTicketEvent({
				eventId: event.id,
				systemIndex,
				newEvent: {
					...event,
					odds: [oddType],
					isNew: false,
				},
			});
			this.addNewEmptyOdd({ systemIndex, eventIndex });
		},
		oddLeft() {
			select(`new:${this.systemIndex}:code`);
		},
		eventsModal(e) {
			if (e.keyCode !== keys.ctrl && !isLetter(e.keyCode)) return;
			const { systemIndex, eventId, $refs, listCode } = this;
			const searchQuery = $refs.code.value;

			this.openEventsModal({
				systemIndex,
				eventId,
				searchQuery,
				listCode,
				isNew: true,
				isEdit: false,
			});
		},
		async codePlus(e) {
			if (e.keyCode !== keys.numpadAdd) return;

			const {
				systemIndex,
				eventId,
				$refs,
				listCode: oldListCode,
				$api,
				noEventNotify,
				eventInactiveWithStatus,
				eventIsNotReady,
			} = this;

			const input = $refs.code.value;
			const listCode = input.replace('+', '');
			$refs.code.value = listCode;

			if (input === '+') {
				this.openEventsModal({
					systemIndex,
					eventId,
					listCode: oldListCode,
					searchQuery: '',
					live: true,
				});
			} else {
				let event = this.$store.getters?.liveEventData(listCode);

				if (!event) {
					try {
						const newEvent = await $api.getEventByListCode(listCode);
						if (!newEvent) throw 'No event found';
						const { competitors, status, liveStatus, live, manuallyBlocked } = newEvent;
						const [{ name: home }, { name: away } = { name: '' }] = competitors || [{}, {}];
						if (liveStatus === ANNOUNCED) {
							return eventIsNotReady({ listCode, home, away });
						} else if (!live) {
							return this.eventIsPrematch(listCode);
						} else if (manuallyBlocked) {
							return eventInactive({ listCode, home, away });
						}
						if (
							(status === STOPPED || status === RUNNING || status === INTERRUPTED || status === POSTPONED) &&
							liveStatus === LIVE
						) {
							await this.addNewLiveEvent(remapEventForUpdate(newEvent));
							event = this.$store.getters?.liveEventData(listCode);
						} else {
							return eventInactiveWithStatus({ listCode, home, away, status });
						}
					} catch (err) {
						console.error(err);
						return noEventNotify(listCode);
					}
				}

				if (this.isEventAdded(+listCode)) return this.eventAddedNotify();
				//if (!event) return this.noEventNotify(listCode);
				if (!event.live) return this.eventIsPrematch(listCode);
				if (!event.active) return this.eventDisabledNotify(listCode);
				if (event.stopped || event.disabled) return this.eventDisabledNotify(listCode);

				this.replaceTicketEvent({
					systemIndex,
					eventId,
					newEvent: {
						...event,
						odds: [],
						isNew: true,
					},
				});

				select(`new:${this.systemIndex}:odd`);

				setTimeout(() => {
					this.openLiveOddsModal(event.id);
				}, 100);
			}
		},
		oddsModal(e) {
			if (e.keyCode !== keys.ctrl) return;
			if (this.event.live) this.openLiveOddsModal(this.eventId);
			else this.openOddsModal();
		},
		removeThisRow() {
			const { removeTicketEvent, systemIndex, eventId, codeUp, cleanSubSystems } = this;
			removeTicketEvent({ systemIndex, eventId });
			cleanSubSystems();
			codeUp();
		},

		newCodeFocus() {
			this.resetfocusInputTimer();
			const { clearSelectedRow, setLastSelectedId, systemIndex } = this;
			clearSelectedRow();
			setLastSelectedId(`new:${systemIndex}:code`);
		},

		oddtypeFocus(elementId) {
			const { options, lastOdd, focusHandler } = this;
			if (options.saveOdd && lastOdd) {
				const element = this.$refs.odd;
				if (!element.value && lastOdd.keyboardCode) {
					element.value = lastOdd.keyboardCode;
				}
			}
			focusHandler(elementId);
		},

		async newEventCodeHandler() {
			this.focusInputTimer++;
			if (this.focusInputTimer === this.defaultFocusInputTimer) {
				await this.codeEnter();
				this.resetfocusInputTimer();
			}
		},

		newEventOddHandler() {
			this.focusInputTimer++;
			if (this.focusInputTimer === this.defaultFocusInputTimer) {
				this.oddEnter();
				this.resetfocusInputTimer();
			}
		},
	},
	mounted() {
		if (this.listCode) select(`new:${this.systemIndex}:odd`);
		else focus(`new:${this.systemIndex}:code`);
	},
};
</script>
