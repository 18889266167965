<template>
	<tr class="table_row">
		<td class="" />
		<td class="text_right" />
		<td class="small" />
		<td class="strong text_right padding_right_5" />
		<td class="padding_left_0 padding_right_0 small vs text_center" />
		<td class="strong text_left padding_left_5" />
		<td class="condition"></td>
		<td class="small" style="width: 24px"></td>

		<td class="padding_right_10 text_right">
			{{ marketData.name }}
		</td>
		<td class="padding_left_0 strong">
			<span v-if="odd.specialValue" class="border_left">{{ odd.specialValue }}</span>
		</td>
		<td class="strong tooltip_left">
			{{ `${bonusType ? `B ${odd.name}` : odd.name || ''} ` }}
			<span class="tooltip_content">{{ odd.description || '' }}</span>
		</td>
		<td class="condition"></td>
		<td class="padding_left_0 strong">
			<i v-if="result === 1" class="mdi mdi-check-bold green_text "></i>
			<i v-if="result === 0" class="mdi mdi-close red_text "></i>
		</td>

		<td class="padding_right_10 text_right strong" :class="odd && odd.voided ? 'orange_text' : ''">
			{{ (odd && (odd.voided ? '1.00' : odd.stake && oddsFormat(+odd.stake))) || '' }}
		</td>

		<td class="condition" />
		<td class="condition" />
	</tr>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatting } from '../../mixins';

export default {
	mixins: [formatting],
	props: ['sport', 'id', 'value', 'specialValue', 'result', 'bonusType', 'voided'],
	computed: {
		...mapGetters(['eventOddsData', 'marketsMap']),
		odd() {
			const { id, value, specialValue, eventOddsData, voided } = this;
			const oddData = { [id]: { value, specialValue, special_value: specialValue } };
			const [odd] = eventOddsData(oddData);
			return { ...odd, voided };
		},
		marketData() {
			const {
				marketsMap,
				sport: { id: sportId },
				id,
			} = this;
			const parts = id.split(':');
			const marketId = parts.length ? parts[0] + ':' + parts[1] : null;
			const { name = '', description = ' ' } =
				marketId && marketsMap ? marketsMap[sportId].markets.markets[marketId] : {};
			return { name, description };
		},
	},
};
</script>
