<template>
	<div class="component">
		<div class="count_row">
			<div class="label">{{ $t('minWin') }}</div>
			<div class="value">{{ toMoneyFormat(winning.minWinning) }}</div>
		</div>

		<div class="count_row">
			<div class="label">{{ $t('maxWin') }}</div>
			<!-- <div class="value">{{ winning.maxWinning.toFixed(2) }}</div> -->
			<div class="value">{{ toMoneyFormat(winning.maxWinning) }}</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import formatting from '../../mixins/formatting';
export default {
	mixins: [formatting],
	computed: {
		...mapGetters(['winning']),
	},
};
</script>
