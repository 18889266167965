<template>
	<portal to="auth-confirmation">
		<div class="modal_cover md toggle z_4">
			<div class="modal_content fadeInDown animated faster">
				<button class="btn transparent close" @click="closeAuthModal">
					<i class="mdi mdi-close"></i>
				</button>

				<div class="modal_head padding_left_20">
					<h2 class="modal_title ">{{ $t('ticketAuthRequired') }}</h2>
				</div>

				<div class="modal_body content_center direction_column small_form" style="padding: 0px 40px;">
					<div class="form_row direction_row">
						<textarea id="auth-message" rows="10" v-model="authMessage" @keyup.enter="sendToRisk(authMessage)" />
					</div>
				</div>

				<div class="modal_footer col_border_top">
					<button
						class="btn lg round dark_green"
						@keyup.enter="sendToRisk(authMessage)"
						@click="sendToRisk(authMessage)"
					>
						OK
					</button>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
export default {
	props: ['sendToRisk', 'closeAuthModal'],
	data() {
		return {
			authMessage: '',
		};
	},
};
</script>
