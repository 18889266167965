import { isEmpty } from 'lodash';
import { api } from '../api';
import { RUNNING, ACTIVE_EVENT_STATUSES } from '../consts';

import i18n from '../localisation/i18n';
import { toCsv } from '../utils';
import { formatOdds } from '../utils/messages';
import { resetBufferState } from './bufferState';
import settings from '../settings.json';
import { EVENT_CHANGE, EVENT_CHANGE_LIVE, EVENT_CHANGE_PREMATCH, BONUS_TYPE_ODDS, EVENT_RESULT, BET_ODDS } from '../consts/messages';
import { applyMessagesOnPrematchEvents } from '../utils/cached-events/applyMessagesOnPrematchEvents';
import { applyMessagesOnLiveEvents } from '../utils/cached-events/applyMessagesOnLiveEvents';
import {applyMessagesOnBonusTipEvents} from '../utils/cached-events/applyMessagesOnBonusTipEvents';

const EVENT_PAUSE_PERIODS = settings.stopInactiveLiveEvents.pausePeriods || [];
const MAX_INACTIVE_TIME = settings.stopInactiveLiveEvents.timeThreshold || 120000;

const eventFields = 'listCode,sport,category,tournament,competitors,odds,utc_scheduled';

export default {
	state: {
		sports: [],
		prematchEvents: {},
		prematchMapEventIdToListCode: {},
		liveEvents: {},
		liveMapEventIdToListCode: {},
		bonusOddsEvents: {},
		loadingData: {
			active: false,
			error: '',
		},
		scanning: false,
		marketsMap: null,
		prematchOrderMap: {},
		oddTypes: {},
		oddIds: {},
		offerPlans: {},
		lastOdd: null,
		//data for countdown modal used for offer plan update, language change etc.
		timer: {
			countdownOpened: false,
			secondsToCount: 10,
		},
	},
	getters: {
		sportsData: ({ sports }) => sports,
		offerPlans: ({ offerPlans }) => offerPlans,
		bonusOddsEvents: ({ bonusOddsEvents }) => bonusOddsEvents,
		loadingData: ({ loadingData }) => loadingData,
		prematchEventData: ({ prematchEvents }) => (listCode) => {
			const event = prematchEvents[listCode];
			if (event && !event.disabled) return prematchEvents[listCode];
		},
		liveEventData: ({ liveEvents }) => (listCode) => {
			const event = liveEvents[listCode];
			if (event && !event.disabled) return liveEvents[listCode];
		},
		bonusOddsEventData: ({ bonusOddsEvents, prematchEvents }) => (listCode) => {
			const event = prematchEvents[listCode];
			const bonusEvent = bonusOddsEvents[listCode];
			if (event && bonusEvent && !event.disabled) return bonusOddsEvents[listCode];
		},
		prematchEventDataById: ({ prematchEvents, prematchMapEventIdToListCode }) => (eventId) => {
			return prematchEvents[prematchMapEventIdToListCode[eventId]];
		},
		liveEventDataById: ({ liveEvents, liveMapEventIdToListCode }) => (eventId) => {
			return liveEvents[liveMapEventIdToListCode[eventId]];
		},
		bonusEventDataById: ({ bonusOddsEvents, prematchMapEventIdToListCode }) => (eventId) => {
			return bonusOddsEvents[prematchMapEventIdToListCode[eventId]];
		},
		filterLiveEvents: ({ liveEvents, liveMapEventIdToListCode }) => (
			searchValue,
			showStarted,
			sortedByListCode,
			sortedByTime
		) => {
			const search = `${searchValue}`.toLowerCase();
			const filtered = Object.values(liveMapEventIdToListCode).filter((listCode) => {
				const event = liveEvents[listCode];
				if (event?.disabled) return false;
				if (!showStarted && !event.stopped) return false;
				const target = `${event.competitors[0]?.name ?? ''} ${event.competitors[1]?.name ?? ''} ${
					event.listCode
				}`.toLowerCase();
				return target.includes(search);
			});

			if (sortedByListCode === 'descending')
				filtered.sort(function(item1, item2) {
					return item2 - item1;
				});
			if (sortedByListCode === 'ascending')
				filtered.sort(function(item1, item2) {
					return item1 - item2;
				});

			if (sortedByTime === 'descending') {
				filtered.sort(function(item1, item2) {
					const a = liveEvents[item1].utc_scheduled;
					const b = liveEvents[item2].utc_scheduled;
					return Date.parse(b) - Date.parse(a);
				});
			}
			if (sortedByTime === 'ascending') {
				filtered.sort(function(item1, item2) {
					const a = liveEvents[item1].utc_scheduled;
					const b = liveEvents[item2].utc_scheduled;
					return Date.parse(a) - Date.parse(b);
				});
			}

			return filtered;
		},
		filterPrematchEvents: ({ prematchEvents, prematchMapEventIdToListCode }) => (
			searchValue,
			activeTab,
			sortedByListCode,
			sortedByTime
		) => {
			const search = `${searchValue}`.toLowerCase();
			const filtered = Object.values(prematchMapEventIdToListCode).filter((listCode) => {
				const event = prematchEvents[listCode];
				if (!event || event.disabled) {
					return false;
				}
				if (activeTab !== 'all' && activeTab !== event.special_type) return false;
				const target = `${event.competitors?.[0]?.name ?? ''} ${event.competitors?.[1]?.name ?? ''} ${
					event.listCode
				}`.toLowerCase();
				return target.includes(search);
			});

			if (sortedByListCode === 'descending')
				filtered.sort(function(item1, item2) {
					return item2 - item1;
				});
			if (sortedByListCode === 'ascending')
				filtered.sort(function(item1, item2) {
					return item1 - item2;
				});

			if (sortedByTime === 'descending') {
				filtered.sort(function(item1, item2) {
					const a = prematchEvents[item1].utc_scheduled;
					const b = prematchEvents[item2].utc_scheduled;
					return Date.parse(b) - Date.parse(a);
				});
			}
			if (sortedByTime === 'ascending') {
				filtered.sort(function(item1, item2) {
					const a = prematchEvents[item1].utc_scheduled;
					const b = prematchEvents[item2].utc_scheduled;
					return Date.parse(a) - Date.parse(b);
				});
			}

			return filtered;
		},
		oddTypeData: ({ oddIds, oddTypes }) => ({ oddCode, sportId, odds, oddId, bonus = false }) => {
			if (!oddId) oddId = oddIds[`${sportId}:${oddCode?.toUpperCase()}`];

			let oddObject = odds[oddId ?? ''];

			if ((!oddId || !oddObject) && oddCode) {
				oddObject = Object.values(odds).find((odd) => {
					const oddTypeId =
						odd?.keyboard_code_oddtype ?? odd?.oddtype_key?.split('|')[0] ?? odd?.id?.split('|')[0] ?? '';
					return (
						(oddTypes[oddTypeId]?.keyboardCode === oddCode?.toUpperCase() ||
							oddTypes[oddTypeId]?.alternative_keyboard_codes?.includes(oddCode?.toUpperCase())) &&
						odd?.oddtype_status
					);
				});
				if (oddObject) {
					oddId = oddObject?.keyboard_code_oddtype ?? oddObject.oddtype_key ?? oddObject.id;
				}
			}

			if (!oddId) return;

			const [id] = oddId.split('|');
			let oddType = oddTypes[id];
			if (isEmpty(oddType)) {
				oddType = oddTypes[oddId];
			}

			if (!oddType) {
				return null;
			}

			const { name, description, keyboardCode, marketName, alternative_keyboard_codes } = oddType;

			//probably redundant condition and calculation
			// if (!oddObject) {
			// 	oddObject = Object.values(odds).find((odd) => {
			// 		const oddTypeId =
			// 			odd?.keyboard_code_oddtype ?? odd?.oddtype_key?.split('|')[0] ?? odd?.id?.split('|')[0] ?? '';
			// 		return oddType.id === oddTypeId && odd?.oddtype_status;
			// 	});
			// }

			if (!oddObject || oddObject.oddtype_status !== 1) return;

			return {
				...oddObject,
				id: oddObject.oddtype_key ?? oddObject.id,
				stake: oddObject.value || oddObject.stake,
				name: bonus ? `B ${name}` : name,
				description,
				keyboardCode,
				specialValue: oddObject.special_value,
				bonusType: bonus,
				marketName,
				alternative_keyboard_codes,
			};
		},
		eventOddsData: ({ oddTypes }) => (odds) => {
			let eventOdds = [];

			for (const odd in odds) {
				let oddType = oddTypes[odd];

				const { value: stake, special_value, bonusType, oddtype_status, keyboard_code_oddtype } = odds[odd];

				if (isEmpty(oddType)) {
					// Split to remove boundary part from odd id if exists
					const [oddId] = odd.split('|');
					oddType = keyboard_code_oddtype ? oddTypes[keyboard_code_oddtype] : oddTypes[oddId];
				}

				if (oddType)
					eventOdds.push({
						...oddType,
						...odds[odd],
						id: odd,
						stake,
						specialValue: special_value,
						bonusType,
						oddtype_status,
					});
			}

			return eventOdds;
		},
		getUpdatedEventOdds: ({ oddTypes, liveEvents, prematchEvents, bonusOddsEvents }) => (odds, listCode, live) => {
			let eventOdds = {};
			const event = live ? liveEvents[listCode] : prematchEvents[listCode];
			const bonusEvent = bonusOddsEvents[listCode];

			if (!event?.odds) return eventOdds;

			for (const odd in odds) {
				const bonus = odds[odd]?.bonusType || odds[odd]?.bonus_type || false;

				if (bonus && !bonusEvent?.odds) continue;

				let eventOdd = bonus ? bonusEvent.odds[odd] : event.odds[odd];

				if (!eventOdd) eventOdd = { ...odds[odd], special_value: odds[odd]?.specialValue, id: odd };

				const { value: stake, special_value, oddtype_status, keyboard_code_oddtype } = eventOdd;

				let oddType = oddTypes[odd];

				if (!oddType) {
					// Split to remove boundary part from odd id if exists
					const [oddId] = odd.split('|');
					oddType = keyboard_code_oddtype ? oddTypes[keyboard_code_oddtype] : oddTypes[oddId];
				}

				if (oddType && eventOdd)
					eventOdds[eventOdd.oddtype_key ?? eventOdd.id] = {
						...oddType,
						...eventOdd,
						id: eventOdd.oddtype_key ?? eventOdd.id,
						stake,
						specialValue: special_value,
						bonusType: bonus,
						oddtype_status,
					};
			}

			return Object.values(eventOdds);
		},
		lastOdd: ({ lastOdd }) => lastOdd,
		marketsMap: ({ marketsMap }) => marketsMap,
		prematchOrderMap: ({ prematchOrderMap }) => prematchOrderMap,
		scanning: ({ scanning }) => scanning,
		countdownOpened: ({ timer: { countdownOpened } }) => countdownOpened,
		secondsToCount: ({ timer: { secondsToCount } }) => secondsToCount,
	},
	mutations: {
		setLoadingData(state, payload) {
			state.loadingData = payload;
		},

		setOfferPlans(state, plans) {
			state.offerPlans = plans;
		},
		setEvents(state, payload) {
			const eventsMap = {};
			const eventIdToListCodeMap = {}; // key: eventId, value: eventListCode
			for (let event of payload.events) {
				if (event.odds) {
					const { odds, event_status, ...rest } = event;
					Object.keys(odds).forEach((oddKey) => {
						odds[oddKey].id = odds[oddKey].oddtype_key;
					});
					const freezed = Object.freeze(odds);
					eventsMap[event.listCode] = {
						odds: freezed,
						status: event_status,
						stopped: event.live && !ACTIVE_EVENT_STATUSES.includes(event.event_status),
						...rest,
					};
				}
				eventIdToListCodeMap[event.id] = event.listCode;
			}

			if (payload.liveOffer) {
				state.liveEvents = eventsMap;
				state.liveMapEventIdToListCode = eventIdToListCodeMap;
			} else {
				state.prematchEvents = eventsMap;
				state.prematchMapEventIdToListCode = eventIdToListCodeMap;
			}
		},
		updateEvents(state, payload) {
			const { liveEvents, prematchEvents } = payload;

			let updatePrematchMap = false;

			if (prematchEvents.size) {
				prematchEvents.forEach((update, eventId) => {
					const listCode = state.prematchMapEventIdToListCode[eventId];

					const oldEvent = listCode ? state.prematchEvents[listCode] : null;

					if (oldEvent) {
						if (update.deleted) {
							state.prematchEvents[listCode] = null;
							delete state.prematchMapEventIdToListCode[eventId];
						} else {
							state.prematchEvents[listCode] = {
								...oldEvent,
								...update,
							};
						}
					} else if (update.listCode) {
						updatePrematchMap = true;

						state.prematchEvents[update.listCode] = { ...update };
						state.prematchMapEventIdToListCode = {
							...state.prematchMapEventIdToListCode,
							[eventId]: update.listCode,
						};
					}
				});
			}

			if (updatePrematchMap) {
				state.prematchEvents = {
					...state.prematchEvents,
				};
			}

			let updateLiveMap = false;

			if (liveEvents.size) {
				liveEvents.forEach((update, eventId) => {
					const listCode = state.liveMapEventIdToListCode[eventId];

					const oldEvent = listCode ? state.liveEvents[listCode] : null;

					if (oldEvent) {
						if (update.deleted) {
							state.liveEvents[listCode] = null;
							delete state.liveMapEventIdToListCode[eventId];
						} else {
							state.liveEvents[listCode] = {
								...oldEvent,
								...update,
							};
						}
					} else if (update.competitors && update.listCode) {
						updateLiveMap = true;

						state.liveEvents[update.listCode] = { ...update };
						state.liveMapEventIdToListCode = { ...state.liveMapEventIdToListCode, [eventId]: update.listCode };
					}
				});
			}

			if (updateLiveMap) {
				state.liveEvents = {
					...state.liveEvents,
				};
			}

			resetBufferState();
		},
		checkInactiveLiveEvents(state) {
			const timeThreshold = new Date(new Date() - MAX_INACTIVE_TIME).toISOString();

			for (let listCode in state.liveEvents) {
				const event = state.liveEvents[listCode];
				if (
					event &&
					event.odds_data_updated &&
					timeThreshold.localeCompare(event.odds_data_updated) >= 0 &&
					!event.stopped &&
					event.status === RUNNING &&
					!EVENT_PAUSE_PERIODS.includes(event.period)
				) {
					state.liveEvents[listCode] = { ...state.liveEvents[listCode], stopped: true };
				}
			}
		},
		setBonusOddsEvents(state, bonusOddsEvents) {
			for (let event of bonusOddsEvents) {
				if (event.odds) {
					const { odds, ...rest } = event;
					const freezed = Object.freeze(odds);
					state.bonusOddsEvents[event.listCode] = { odds: freezed, ...rest };
					if (!state.prematchMapEventIdToListCode[event.id]) {
						state.prematchMapEventIdToListCode[event.id] = event.listCode;
					}
				}
			}
		},
		setSports(state, sports) {
			state.sports = sports;
		},
		setOddTypes(state, marketsMap) {
			state.marketsMap = Object.freeze(marketsMap);

			for (let sportId in marketsMap) {
				let oddsTypesData = [];
				let prematchOrderBySport = {};
				const marketsData = Object.values(marketsMap[sportId].markets.markets);

				for (let market of marketsData) {
					Object.values(market.oddtypes).forEach((oddtype) => {
						oddsTypesData.push({ ...oddtype, keyboardCode: oddtype.keyboard_code, marketName: market.name });
						if (!market.live) prematchOrderBySport[oddtype.id] = market.order * 50 + oddtype.order;
					});
				}
				for (const oddType of oddsTypesData) {
					state.oddIds[`${sportId}:${oddType.keyboardCode}`] = oddType.id;
					const { alternative_keyboard_codes } = oddType;
					if (alternative_keyboard_codes && alternative_keyboard_codes.length) {
						alternative_keyboard_codes.forEach((altCode) => {
							state.oddIds[`${sportId}:${altCode?.toUpperCase()}`] = oddType.id;
						});
					}
					state.oddTypes[oddType.id] = Object.freeze(oddType);
				}
				state.prematchOrderMap[sportId] = Object.freeze(prematchOrderBySport);
			}
		},
		setLastOdd(state, odd) {
			state.lastOdd = odd;
		},
		updateEvent(state, [listCode, live, event]) {
			if (!event) {
				if (live) {
					const eventId = state.liveEvents[listCode]?.id;

					if (!eventId) return;

					delete state.liveMapEventIdToListCode[eventId];
					state.liveEvents[listCode] = null;
				} else if (state.prematchEvents[listCode]) {
					const eventId = state.prematchEvents[listCode]?.id;

					delete state.prematchMapEventIdToListCode[eventId];
					state.prematchEvents[listCode] = null;
				}
			} else {
				if (event.live) {
					state.liveMapEventIdToListCode = { ...state.liveMapEventIdToListCode, [event.id]: event.listCode };
					state.liveEvents[listCode] = { ...event };
				} else {
					state.prematchMapEventIdToListCode = {
						...state.prematchMapEventIdToListCode,
						[event.id]: listCode,
					};
					state.prematchEvents[listCode] = { ...event };
				}
			}
		},
		updateEventOdds(state, [id, live, odds]) {
			if (live) {
				const listCode = state.liveMapEventIdToListCode[id];
				const oldEvent = state.liveEvents[listCode];
				state.liveEvents[listCode] = { ...oldEvent, odds };
			} else {
				const listCode = state.prematchMapEventIdToListCode[id];
				const oldEvent = state.prematchEvents[listCode];
				state.prematchEvents[listCode] = { ...oldEvent, odds };
			}
		},
		updateTicketOdds(state) {
			// artificial mutation that serves to recalculate ticket min/max quotes
		},
		updateBonusEventOdds(state, [listCode, odds]) {
			state.bonusOddsEvents[listCode] = { ...state.bonusOddsEvents[listCode], odds };
			state.prematchEvents[listCode] = { ...state.prematchEvents[listCode] };
		},

		createBonusOddsEvent(state, event) {
			if (!event || !event.listCode) return;
			state.bonusOddsEvents[event.listCode] = { ...event };
			state.bonusOddsEvents = { ...state.bonusOddsEvents };
		},

		enableScan(state) {
			state.scanning = true;
			setTimeout(() => {
				state.scanning = false;
			}, 2500);
		},
		disableScan(state) {
			state.scanning = false;
		},
		startCountdown(state) {
			state.timer.countdownOpened = true;
		},
		setTimerSeconds(state, seconds) {
			state.timer.secondsToCount = seconds;
		},
	},
	actions: {
		async initCachedData({ commit }) {
			try {
				commit('setLoadingData', { active: true, error: '' });

				const sports = await api.getSports();
				const marketsPrematch = sports.map((sport) => `o${sport.id}`).join(',');
				const marketsLive = sports.map((sport) => `l${sport.id}`).join(',');

				// ======= Prematch ======== //
				const prematchEvents = [];
				let fromDate
				let offset = 0;
				const limit = 100; 
				let countFetchedEvents = 0;
				do {
					const {cached_time, events: eventsPart} = await api.getCachedEvents({ markets: marketsPrematch, offset, limit });
					if(offset === 0) fromDate = cached_time // Save the oldest date_from
					countFetchedEvents = (eventsPart ?? []).length ?? 0;
					offset += countFetchedEvents;
					prematchEvents.push(...eventsPart);
				} while (countFetchedEvents === limit);

				const prematchMessages = await api.getMessagesForCachedEvents({from_date: fromDate, live: false, message_types: `${EVENT_CHANGE},${EVENT_CHANGE_LIVE},${EVENT_CHANGE_PREMATCH},${BET_ODDS}`})

				applyMessagesOnPrematchEvents(prematchEvents, prematchMessages)

				// ========== Live ========== //
				const liveEvents = [];
				const liveLimit = 100;
				let liveOffset = 0;
				let countFetchedLiveEvents = 0;
				do {
					const {
						events: liveEventsPart,
						cached_time
					} = await api.getCachedLiveEvents({
						markets: marketsLive,
						offset: liveOffset,
						limit: liveLimit
					});
					if(liveOffset === 0) fromDate = cached_time // Save the oldest date_from
					countFetchedLiveEvents = liveEventsPart?.length ?? 0;
					liveOffset += countFetchedLiveEvents;
					liveEvents.push(...liveEventsPart);
				} while (countFetchedLiveEvents === liveLimit);

				const liveMessages = await api.getMessagesForCachedEvents({from_date: fromDate, live: true, message_types: `${EVENT_CHANGE},${EVENT_RESULT},${EVENT_CHANGE_LIVE},${EVENT_CHANGE_PREMATCH},${BET_ODDS}`})
				applyMessagesOnLiveEvents(liveEvents, liveMessages)

				// ========== BonusTip ========= //
				const bonusOddsEvents = [];
				let fromBonusDate
				let bonusOffset = 0
				let bonusLimit = 500

				while (true) {
					let response = await api.getCachedBonusEvents(i18n.locale, toCsv(eventFields), bonusOffset, bonusLimit, marketsPrematch);
					if(bonusOffset === 0) fromBonusDate = response.cached_time
					bonusOddsEvents.push(...response.events);
					if (response.events?.length === bonusLimit) bonusOffset += bonusLimit;
					else break;
				}

				const bonusMessages = await api.getMessagesForCachedEvents({from_date: fromBonusDate, live: false, message_types: `${BONUS_TYPE_ODDS}`})
				applyMessagesOnBonusTipEvents(prematchEvents, bonusOddsEvents, bonusMessages)

				const { offerPlans: plans } = await api.getOfferPlans();
				commit('setOfferPlans', plans);

				const data = await api.getMarkets();
				commit('setOddTypes', data);

				Array.isArray(sports) && commit('setSports', sports);

				prematchEvents.length && commit('setEvents', { events: prematchEvents, liveOffer: false });
				liveEvents.length && commit('setEvents', { events: liveEvents, liveOffer: true });

				Array.isArray(bonusOddsEvents) && commit('setBonusOddsEvents', bonusOddsEvents);

				commit('setLoadingData', { active: false, error: '' });
			} catch (err) {
				console.error(err);
				commit('setLoadingData', {
					active: true,
					error: 'Something went wrong. Please try again',
				});
			}
		},

		async initData({ commit }) {
			try {
				commit('setLoadingData', { active: true, error: '' });

				const sports = await api.getSports();
				const marketsPrematch = sports.map((sport) => `o${sport.id}`).join(',');
				const marketsLive = sports.map((sport) => `l${sport.id}`).join(',');
				const prematchEvents = [];
				let offset = 0;
				const limit = 100;
				let countFetchedEvents = 0;
				do {
					const {
						events: eventsPart,
						// summary: { fetched_events, total_events },
					} = await api.getEvents({ markets: marketsPrematch, offset, limit });
					countFetchedEvents = (eventsPart ?? []).length ?? 0;
					offset += countFetchedEvents;
					prematchEvents.push(...eventsPart);
				} while (countFetchedEvents === limit);

				const getBonusEventsInChunks = async (
					lang = i18n.locale,
					fields = eventFields,
					offset = 0,
					limit = 100,
					markets = marketsPrematch
				) => {
					fields = toCsv(fields);
					const events = [];
					let response;

					while (true) {
						response = await api.getBonusEvents(lang, fields, offset, limit, markets);
						events.push(...response.events);
						if (response.events?.length === limit) offset += limit;
						else break;
					}

					return {
						events: events,
					};
				};

				const { events: bonusOddsEvents } = await getBonusEventsInChunks(
					i18n.locale,
					eventFields,
					0,
					50,
					marketsPrematch
				).then((res) => {
					return res;
				});

				const liveEvents = [];
				const liveLimit = 100;
				let liveOffset = 0;
				let countFetchedLiveEvents = 0;
				do {
					const {
						events: liveEventsPart,
						// summary: { fetched_events: fetchedLive, total_events: totalLive },
					} = await api.getLiveEvents({
						markets: marketsLive,
						offset: liveOffset,
						limit: liveLimit
					});
					countFetchedLiveEvents = liveEventsPart?.length ?? 0;
					liveOffset += countFetchedLiveEvents;
					liveEvents.push(...liveEventsPart);
				} while (countFetchedLiveEvents === liveLimit);

				const { offerPlans: plans } = await api.getOfferPlans();
				commit('setOfferPlans', plans);

				const data = await api.getMarkets();
				commit('setOddTypes', data);

				Array.isArray(sports) && commit('setSports', sports);

				prematchEvents.length && commit('setEvents', { events: prematchEvents, liveOffer: false });
				liveEvents.length && commit('setEvents', { events: liveEvents, liveOffer: true });

				Array.isArray(bonusOddsEvents) && commit('setBonusOddsEvents', bonusOddsEvents);

				commit('setLoadingData', { active: false, error: '' });
			} catch (err) {
				console.error(err);
				commit('setLoadingData', {
					active: true,
					error: 'Something went wrong. Please try again',
				});
			}
		},

		async addNewPrematchEvent({ commit, state }, message) {
			const {
				id_event: id,
				list_code: listCode,
				markets,
				utc_scheduled,
				start_time,
				active,
				live,
				status,
				...rest
			} = message || {};
			const event = state.prematchEvents[listCode];
			if (event && !active) {
				return commit('updateEvent', [listCode, live, null]);
			} else if (active) {
				if (!Array.isArray(markets)) return;

				const odds = formatOdds(markets);
				const newEvent = {
					id,
					listCode,
					utc_scheduled: utc_scheduled || start_time,
					stopped: false,
					status,
					active,
					live,
					odds: { ...odds },
					...rest,
					markets,
				};
				commit('updateEvent', [listCode, live, newEvent]);
			}
		},

		async addNewLiveEvent({ commit, state }, message) {
			const {
				id_event: id,
				list_code: listCode,
				markets,
				utc_scheduled,
				start_time,
				current_time,
				status,
				manually_blocked,
				live,
				period,
				odds_data_updated,
				...rest
			} = message || {};

			if (!live) return;

			const newMarkets = Array.isArray(markets) ? markets : [];

			const odds = Object.freeze(formatOdds(newMarkets));

			const newLiveEvent = {
				...rest,
				id,
				period,
				odds,
				listCode,
				live,
				current_time,
				status,
				stopped: live && !ACTIVE_EVENT_STATUSES.includes(status),
				manually_blocked,
				disabled: manually_blocked,
				utc_scheduled: utc_scheduled || start_time,
				odds_data_updated,
			};
			commit('updateEvent', [listCode, true, newLiveEvent]);
			commit('updateEvent', [listCode, false, null]);
		},

		async updateEvents({ commit, state }, payload) {
			commit('updateEvents', payload);
		},

		async checkInactiveLiveEvents({ commit, state }) {
			commit('checkInactiveLiveEvents');
		},

		async updateBonusOdds({ commit, state }, message) {
			const { markets, id_event } = message || {};
			if (!Array.isArray(markets)) return;

			const listCode = state.prematchMapEventIdToListCode[id_event];
			if (!listCode) return;

			const bonusOddsEvent = state.bonusOddsEvents[listCode];
			const odds = formatOdds(markets);

			const newOdds = Object.freeze(odds);

			if (!bonusOddsEvent) {
				const event = state.prematchEvents[listCode];
				if (!event) return;

				const { active_market_count, active_oddtype_count, odds, ...rest } = event;

				commit('createBonusOddsEvent', { ...rest, odds: newOdds });
			} else {
				commit('updateBonusEventOdds', [listCode, newOdds]);
			}
		},

		startCountdown({ commit }) {
			commit('startCountdown');
		},
		setTimerSeconds({ commit }, seconds) {
			commit('setTimerSeconds', seconds);
		},
	},
};
