import methods from './methods';
import io from 'socket.io-client';
import { log } from '../utils/loggers';
import { wsHost, useMsgPack } from './host';
import Vue from 'vue';
const msgParser = require('socket.io-msgpack-parser');

export const updateStatusBus = new Vue();

export const api = methods;
let socket = null;
let subsciptions = [];
let rooms = [];
let connectLock = false;

const initSocket = () => {
	if (connectLock) return;
	connectLock = true;
	socket = null;
	const additionalSettings = useMsgPack ? { parser: msgParser } : {};
	try {
		socket = io(wsHost, {
			reconnection: false,
			transports: ['websocket'],
			...additionalSettings,
		});
	} catch (err) {
		console.error(`Socket client initialization failed. Error: ${err}`);
	}
	rooms.forEach((room) => socket.emit('join', room));
	subsciptions.forEach((listeners) => {
		listeners.forEach(([event, fn]) => socket.on(event, fn));
		return () => listeners.forEach(([event, fn]) => socket.off(event, fn));
	});

	socket.on('disconnect', (reason) => {
		console.log(`Socket disconnected. Reason: ${reason} - ${new Date()}`);
		updateStatusBus.$emit('disconnected');
		setTimeout(() => initSocket(), 500);
	});

	socket.on('connect', () => {
		console.log(`Socket connected! - ${new Date()}`);
		updateStatusBus.$emit('connected');
	});

	socket.on('connect_error', (error = '') => {
		console.log(`Socket connect error: ${error} \n Socket will reconnect! - ${new Date()}`);
		updateStatusBus.$emit('disconnected');
		setTimeout(() => initSocket(), 500);
	});

	socket.on('error', (error = '') => {
		console.log(`Socket error! ${error} - ${new Date()}`);
	});

	socket.on('reconnect', () => {
		console.log(`Socket reconnecting! - ${new Date()}`);
	});
	connectLock = false;
};

initSocket();

export default {
	install: function(Vue) {
		Object.assign(Vue.prototype, {
			$api: methods,
			$join: (room, callback = () => {}) => {
				log('Joining room', { room: room.trim() });
				socket.emit('join', room.trim(), callback);
				rooms.push(room.trim());
			},
			$leave: (room, callback = () => {}) => {
				log('Leaving room', { room: room.trim() });
				socket.emit('leave', room.trim(), callback);
				rooms = rooms.filter((element) => element !== room);
			},
			$subscribeTo(events, callback) {
				let listeners = [];
				if (typeof events === 'string') events = [events];
				if (!events || typeof events !== 'object') throw new Error('Invalid event provided');

				// Convert events to proper format
				if (Array.isArray(events)) listeners = events.map((e) => [e, callback]);
				else listeners = Object.entries(events);

				// Validate types of events and callbacks
				listeners.forEach(([event, fn]) => {
					if (typeof event !== 'string') throw new Error('Invalid event provided');
					if (typeof fn !== 'function') throw new Error('Invalid callback provided');
				});

				subsciptions.push(listeners);

				// Add listeners and return the function for removing the listeners
				listeners.forEach(([event, fn]) => socket.on(event, fn));
				return () => listeners.forEach(([event, fn]) => socket.off(event, fn));
			},
		});
	},
};
