<template>
	<table class="events">
		<thead class="t_head">
			<tr class="table_row">
				<td class="padding_left_20" @click="sortByCode">
					<div class="labe_icon">
						<span>Šifra</span>
						<i class="mdi" :class="sortIconHelper[sortedByListCode]"></i>
					</div>
				</td>
				<td class="text_center" colspan="2" @click="sortByTime">
					<div class="labe_icon">
						<span>{{ $t('time') }}</span>
						<i class="mdi" :class="sortIconHelper[sortedByTime]"></i>
					</div>
				</td>
				<td class="pr_5 strong text_right">&nbsp;</td>
				<td class="p_0 small vs text_center z_1">
					<span class="competetors_head_label">
						{{ $t('homeAway') }}
					</span>
				</td>
				<td class="pl_5 strong text_left">&nbsp;</td>
				<td class="pr_0 sport text_center">&nbsp;</td>
				<td class="league">{{ $t('league') }}</td>
				<td v-if="live" class="league">{{ $t('started') }}</td>
			</tr>
		</thead>
		<tbody id="event-list">
			<EventRow
				v-for="(listCode, index) in visibleEvents"
				:key="listCode"
				:eventListCode="listCode"
				:row="index"
				:rowSelected="currentEvent === index"
				:eventClick="eventClick"
				:selectEvent="onMouseOver"
				:live="live"
			/>
		</tbody>
	</table>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { createEditEvent, specialEvents } from '../../../mixins';
import { ascending, unset, descending, scrollTo, selectDelayed, remapEventForUpdate } from '../../../utils';
import { sortIconHelper, sortTypeHelper, numberSort, timeSort } from '../../../utils';
import { keys } from 'keycodes-map';
import EventRow from './EventRow';
import { mapActions } from 'vuex';
import { NOT_RUNNING, RUNNING, STOPPED, LIVE, ANNOUNCED } from '../../../consts';

export default {
	mixins: [createEditEvent, specialEvents],
	props: ['searchValue', 'live', 'showStarted', 'activeTab'],
	components: { EventRow },
	data() {
		return {
			currentEvent: 0,
			sortedByListCode: 'ascending',
			sortedByTime: 'unset',
			observer: null,
			observerRoot: null,
			lastVisibleEventDOM: null,
			chunkSize: 30,
			chunkNumber: 1,
		};
	},
	watch: {
		searchValue() {
			this.observerRoot.scrollTop = 0;
			this.chunkNumber = 1;
		},
		activeTab() {
			this.observerRoot.scrollTop = 0;
			this.chunkNumber = 1;
		},
	},

	computed: {
		...mapGetters(['filterPrematchEvents', 'filterLiveEvents', 'eventsModalData', 'options']),
		listCodes() {
			const { searchValue, sortedByListCode, sortedByTime, showStarted, live } = this;
			if (live) {
				return this.filterLiveEvents(searchValue, showStarted, sortedByListCode, sortedByTime);
			}
			return this.filterPrematchEvents(searchValue, this.activeTab, sortedByListCode, sortedByTime);
		},
		visibleListCodes() {
			const { listCodes } = this;

			if (this.currentEvent > listCodes.length - 1) {
				this.currentEvent = 0;
			}

			return listCodes;
		},
		visibleEvents() {
			return this.visibleListCodes.slice(0, this.chunkSize * this.chunkNumber);
		},
	},
	methods: {
		...mapActions(['addNewLiveEvent', 'addNewPrematchEvent']),
		...mapMutations(['closeEventsModal', 'replaceTicketEvent', 'addNewEmptyEvent']),

		sortByCode() {
			this.sortedByListCode = this.sortTypeHelper[this.sortedByListCode];
			this.sortedByTime = unset;
		},
		sortByTime() {
			this.sortedByTime = this.sortTypeHelper[this.sortedByTime];
			this.sortedByListCode = unset;
		},

		async addEvent(event) {
			const { systemIndex, eventId, listCode: oldListCode, isNew, isEdit } = this.eventsModalData;

			if (this.isEventAdded(event.listCode) && event.listCode !== oldListCode)
				return this.$notifications.info({
					title: this.$t('warn'),
					text: `${this.$t('eventAdded')}`,
					type: 'warn',
					data: { id: 'modal-search' },
				});

			if (event.live) {
				this.replaceTicketEvent({
					systemIndex,
					eventId,
					newEvent: {
						...event,
						odds: [],
						isNew: true,
						isEdit: !!oldListCode,
					},
				});

				selectDelayed(`new:${systemIndex}:odd`, 100);

				setTimeout(() => {
					this.openLiveOddsModal(event.id);
				}, 200);

				this.closeEventsModal();
				return;
			}

			const now = new Date();
			now.setSeconds(0, 0);
			const currentDate = now
				.toISOString()
				.replace(/T/, ' ')
				.replace(/:00.000Z/, '');
			const scheduledDate = event ? event?.utc_scheduled : null;
			if (event !== undefined && currentDate > scheduledDate) {
				try {
					const newEvent = await $api.getEventByListCode(listCode);

					if (!newEvent) throw 'No event found';

					const { competitors, status, liveStatus } = newEvent;
					const [{ name: home }, { name: away } = { name: '' }] = competitors || [{}, {}];
					if (liveStatus === ANNOUNCED) {
						return eventIsNotReady({ listCode, home, away });
					}
					if ((status === STOPPED || status === RUNNING) && liveStatus === LIVE) {
						await this.addNewLiveEvent(remapEventForUpdate(newEvent));
						return eventIsLive({ listCode, home, away });
					}
					return eventInactive({ listCode, home, away });
				} catch (error) {
					console.error(err);
				}
			}

			const specialWarningMessage = this.checkSpecialConditions(event, oldListCode);
			if (specialWarningMessage) return this.specialWarning(specialWarningMessage, 'modal-search');

			this.replaceTicketEvent({
				systemIndex,
				eventId,
				newEvent: {
					...event,
					odds: [],
					isNew,
					isEdit,
				},
			});

			selectDelayed(`new:${systemIndex}:odd`, 100);
			this.closeEventsModal();
		},

		eventClick(listCode, live) {
			const event = this.live
				? this.$store.getters?.liveEventData(listCode)
				: this.$store.getters?.prematchEventData(listCode);
			this.addEvent(event);
		},

		async eventEnter(e) {
			if (e.keyCode !== keys.enter) return;
			const { visibleEvents, currentEvent, searchValue } = this;
			const liveModal = this.live || false;

			let eventListCode = visibleEvents[currentEvent];

			let event = this.live
				? this.$store.getters?.liveEventData(eventListCode)
				: this.$store.getters?.prematchEventData(eventListCode);

			const now = new Date();
			now.setSeconds(0, 0);
			const currentDate = now
				.toISOString()
				.replace(/T/, ' ')
				.replace(/:00.000Z/, '');
			const scheduledDate = event ? event?.utc_scheduled : null;

			const { noEventNotify, eventIsLive, eventInactiveWithStatus, eventIsNotReady } = this;

			// Match stay in preMatch list - move from pm in live list
			//this.live if value is true then show live modal - undefined show preMatchModal
			if (event !== undefined && currentDate > scheduledDate && this.live === undefined) {
				try {
					if (!this.isNumber(searchValue) || searchValue.length > 6) return;
					const { $api } = this;
					const newEvent = await $api.getEventByListCode(searchValue);
					if (!newEvent) throw 'No event found';

					const { competitors, status, liveStatus } = newEvent;
					const [{ name: home }, { name: away } = { name: '' }] = competitors || [{}, {}];

					// If liveStatus === ANNOUNCED - leave match in pm list - liveStatus === LIVE then move match in live list
					if (liveStatus === ANNOUNCED) {
						return eventIsNotReady({ searchValue, home, away });
					}
					if ((status === STOPPED || status === RUNNING) && liveStatus === LIVE) {
						await this.addNewLiveEvent(remapEventForUpdate(newEvent));
						return eventIsLive({ searchValue, home, away });
					}
					return eventInactiveWithStatus({ searchValue, home, away, status });
				} catch (err) {
					console.error(err);
					return noEventNotify(searchValue);
				} finally {
					this.closeEventsModal();
				}
			}
			if (event === undefined) {
				try {
					if (!this.isNumber(searchValue) || searchValue.length > 6) return;
					const { $api } = this;
					const newEvent = await $api.getEventByListCode(searchValue);

					if (!newEvent || newEvent.live !== liveModal) throw 'No event found';

					const { active, status, liveStatus, manuallyBlocked, competitors, live } = newEvent;
					if (liveStatus === ANNOUNCED) {
						return eventIsNotReady({ searchValue, home, away });
					}
					//this.live if value is true then show live modal - undefined show preMatchModal
					if ((status === STOPPED || status === RUNNING) && liveStatus === LIVE && liveModal && live) {
						await this.addNewLiveEvent(remapEventForUpdate(newEvent));
						event = this.$store.getters?.liveEventData(newEvent.listCode);
					} else if (active && status === NOT_RUNNING && manuallyBlocked === false && !liveModal && !live) {
						await this.addNewPrematchEvent(remapEventForUpdate(newEvent));
						event = this.$store.getters?.prematchEventData(newEvent.listCode);
					} else {
						this.closeEventsModal();
						const [{ name: home }, { name: away } = { name: '' }] = competitors || [{}, {}];
						return eventInactiveWithStatus({ searchValue, home, away, status });
					}
				} catch (err) {
					console.error(err);
					this.closeEventsModal();
					return noEventNotify(searchValue);
				}
			}
			if (event === undefined || event.stopped) return;
			this.addEvent(event);
		},
		isNumber(value) {
			return /^-?\d+$/.test(value);
		},
		onMouseOver(index) {
			this.currentEvent = index;
		},

		eventUp(e) {
			if (e.keyCode === keys.arrowUp && this.currentEvent > 0) scrollTo(`events-modal:${--this.currentEvent}`);
		},

		eventDown(e) {
			if (e.keyCode === keys.arrowDown && this.currentEvent < this.visibleEvents.length - 1)
				scrollTo(`events-modal:${++this.currentEvent}`);
		},
		handleScrollToBottom(e) {
			if (e[0].isIntersecting) {
				this.observer.unobserve(this.lastVisibleEventDOM);
				this.chunkNumber += 1;
			}
		},
	},

	mounted() {
		document.addEventListener('keydown', this.eventUp);
		document.addEventListener('keydown', this.eventDown);
		document.addEventListener('keyup', this.eventEnter);
		this.observerRoot = document.getElementById('event-list-container');

		this.observer = new IntersectionObserver(this.handleScrollToBottom, {
			root: this.observerRoot,
			rootMargin: '0px',
			// threshold: 0.5,
		});
		if (this.chunkNumber * this.chunkSize >= this.visibleListCodes.length) return;
		this.lastVisibleEventDOM = document.getElementById(`events-modal:${this.visibleEvents.length - 1}`);
		if (!this.lastVisibleEventDOM) return;
		this.observer.observe(this.lastVisibleEventDOM);
	},
	created() {
		this.sortIconHelper = sortIconHelper;
		this.sortTypeHelper = sortTypeHelper;
	},
	updated() {
		if (this.chunkNumber * this.chunkSize >= this.visibleListCodes.length) {
			return;
		}
		const newLast = document.getElementById(`events-modal:${this.chunkNumber * this.chunkSize - 1}`);
		if (this.lastVisibleEventDOM === newLast && newLast) return;

		if (!this.lastVisibleEventDOM) this.lastVisibleEventDOM = newLast;
		this.observer.unobserve(this.lastVisibleEventDOM);
		this.lastVisibleEventDOM = newLast;
		this.observer.observe(this.lastVisibleEventDOM);
	},
	beforeDestroy() {
		this.observer.disconnect();
	},
	destroyed() {
		document.removeEventListener('keydown', this.eventUp);
		document.removeEventListener('keydown', this.eventDown);
		document.removeEventListener('keyup', this.eventEnter);
	},
};
</script>
