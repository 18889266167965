<template>
	<div>
		<td class="pr_10 text_right odd without_border">
			<div class="margin_left_auto padding_right_5 col_10 odd_change_live" :class="colorClass">
				{{ typeof value === 'number' ? oddsFormat(value) : value }}
			</div>
		</td>

		<td v-if="bonusType" class="pl_0 odd_margin strong without_border">
			<span class="" :class="{ border_left: odd.specialValue }">{{ +odd.specialValue || '' }}</span>
		</td>

		<td v-if="bonusType" class="without_border">
			<span class="svg_icon tooltip_right">
				<svg><use xlink:href="/svg/sprite_mb.svg#bonus_tip_18"></use></svg>
				<span class="tooltip_content">{{ $t('bonusOdd') }}</span>
			</span>
		</td>

		<td v-else class="pl_0 odd_margin strong without_border">
			<span class="" :class="{ specialValueColorClass, border_left: specialValue }">{{ specialValue || '' }}</span>
		</td>
	</div>
</template>

<script>
import { memoize } from '../Memoization';
import { formatting } from '../../mixins';
import { mapGetters, mapMutations } from 'vuex';

export default {
	mixins: [memoize('value', 3000), formatting],
	props: ['eventId', 'oddId', 'bonus', 'live'],
	computed: {
		...mapGetters(['oddTypeData']),
		odd() {
			const { bonus, eventId, oddId, live } = this;
			let updated;
			if (!bonus) {
				if (live) {
					const listCode = this.$store._modules.root.state.dataState.liveMapEventIdToListCode[eventId];
					updated = this.$store._modules.root.state.dataState.liveEvents[listCode];
				} else {
					const listCode = this.$store._modules.root.state.dataState.prematchMapEventIdToListCode[eventId];
					updated = this.$store._modules.root.state.dataState.prematchEvents[listCode];
				}
			} else {
				const listCode = this.$store._modules.root.state.dataState.prematchMapEventIdToListCode[eventId];
				updated = this.$store.getters?.bonusOddsEventData(listCode);
			}

			if (!updated || updated.disabled || updated.stopped) return null;
			const { sport = {}, odds = [] } = updated;
			const odd = this.oddTypeData({ oddId, sportId: sport.id, odds: odds, bonus });

			return odd;
		},
		value() {
			const { odd } = this;

			if (!odd || odd.stake === 1 || odd.stake === 0 || odd.manual_status === 'TEMPORARY_REMOVED') {
				return '-';
			}
			return odd.stake;
		},
		specialValue() {
			const { odd } = this;
			return odd?.specialValue ?? odd?.special_value;
		},
		bonusType() {
			const { odd } = this;
			return odd?.bonusType ?? odd?.bonus_type;
		},
		colorClass() {
			const { value, prev_value } = this;
			if (value === '-') return ' odd_down';
			if (!prev_value || prev_value === value) return '';
			return prev_value > value ? 'odd_down' : 'odd_up';
		},
		specialValueColorClass() {
			const { specialValue, prev_specialValue } = this;

			if (!prev_specialValue || prev_specialValue === specialValue) return '';
			return prev_specialValue > specialValue ? 'down' : 'up';
		},
	},
	watch: {
		value: function(value) {
			//call mutation for recalculation min and max quotes on ticket
			this.updateTicketOdds();
		},
	},
	methods: {
		...mapMutations(['updateTicketOdds']),
	},
};
</script>
<style scoped>
.up {
	background-color: green;
	color: white;
}
.down {
	background-color: red;
	color: white;
}
.warn {
	background-color: orange;
	color: white;
}
</style>
