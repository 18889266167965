<template>
	<div v-if="true" class="app_sidebar">
		<div class="sidebar_top">
			<SaveActions />
			<div class="separator_thin"></div>
		</div>

		<div class="sidebar_bottom">
			<div class="separator_thin"></div>
			<Bonus />
			<div class="separator_thin"></div>
			<MinMaxWin />

			<div class="separator_thin"></div>
			<SumCalculator />

			<div class="separator_thin"></div>
			<PaymentSection />
		</div>
	</div>
</template>

<script>
import SaveActions from './SaveActions';
import Bonus from './Bonus';
import MinMaxWin from './MinMaxWin';
import SumCalculator from './SumCalculator';
import PaymentSection from './PaymentSection';

export default {
	components: {
		SaveActions,
		Bonus,
		MinMaxWin,
		SumCalculator,
		PaymentSection,
	},
};
</script>
