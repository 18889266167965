<template>
	<label class="switch margin_left_20 margin_right_20" for="neki_check_id_14">
		<input @click="toggleCheckboxAction" class="switch_mark" id="neki_check_id_14" type="checkbox" />

		<span class="checkbox_label">
			<span class="on_label font_700">{{ $t('location') }}</span>
			<span class="off_label font_700">{{ $t('location') }}</span>
		</span>

		<span class="slider"></span>

		<span class="checkbox_label">
			<span class="on_label font_700">{{ $t('device') }}</span>
			<span class="off_label font_700">{{ $t('device') }}</span>
		</span>
	</label>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
	computed: mapGetters(['isCheckedData']),

	methods: {
		...mapActions(['toggleCheckboxAction']),

		toggleCheckbox() {
			this.toggleCheckboxAction;
		},
	},
};
</script>

<style scoped>
.switch .slider {
	background-color: #27ae60;
}
.switch input.switch_mark:checked ~ .slider,
.switch input.switch_mark:focus ~ .slider {
	background-color: #27ae60;
}
</style>
