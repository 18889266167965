<template>
	<div class="modal_cover sm toggle" @keyup.esc="closeConfirmationModal">
		<div class="modal_content fadeInDown animated faster">
			<button data-testid="close-btn" class="btn transparent close" @click="closeConfirmationModal">
				<i class="mdi mdi-close"></i>
			</button>
			<div class="modal_body content_start direction_column">
				<div class="context_icon margin_top_40">
					<svg class="svg_graphic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 158 130">
						<path
							fill="#E35454"
							d="M105.8,69.2c-1.3,0-2.5-0.5-3.5-1.5c-1.9-1.9-1.9-5.1,0-7l8.7-8.7c5.2-5.2,5.2-13.6,0-18.8 c-5.2-5.2-13.6-5.2-18.8,0l-8.7,8.7c-1.9,1.9-5.1,1.9-7,0c-1.9-1.9-1.9-5.1,0-7l8.7-8.7c9.1-9.1,23.8-9.1,32.9,0 C127,35.2,127,50,118,59l-8.7,8.7C108.3,68.7,107,69.2,105.8,69.2z"
						></path>
						<path
							fill="#E35454"
							d="M56.7,110.6c-6,0-11.9-2.3-16.4-6.8c-4.4-4.4-6.8-10.2-6.8-16.4s2.4-12,6.8-16.4l8.7-8.7 c1.9-1.9,5.1-1.9,7,0c1.9,1.9,1.9,5.1,0,7L47.3,78c-2.5,2.5-3.9,5.9-3.9,9.4s1.4,6.9,3.9,9.4c5.2,5.2,13.6,5.2,18.8,0l8.7-8.7 c1.9-1.9,5.1-1.9,7,0s1.9,5.1,0,7l-8.7,8.7C68.6,108.3,62.7,110.6,56.7,110.6z"
						></path>
						<path
							fill="#E35454"
							d="M97.4,121c-2.7,0-5-2.2-5-5v-13.2c0-2.7,2.2-5,5-5s5,2.2,5,5V116C102.3,118.7,100.1,121,97.4,121z"
						></path>
						<path
							fill="#E35454"
							d="M130.2,88.2H117c-2.7,0-5-2.2-5-5s2.2-5,5-5h13.2c2.7,0,5,2.2,5,5S132.9,88.2,130.2,88.2z"
						></path>
						<path
							fill="#E35454"
							d="M60.9,32.1c-2.7,0-5-2.2-5-5V14c0-2.7,2.2-5,5-5s5,2.2,5,5v13.2C65.8,29.9,63.6,32.1,60.9,32.1z"
						></path>
						<path
							fill="#E35454"
							d="M41.3,51.7H28.1c-2.7,0-5-2.2-5-5s2.2-5,5-5h13.2c2.7,0,5,2.2,5,5S44,51.7,41.3,51.7z"
						></path>
					</svg>
				</div>
				<h2 class="modal_message capitalize text_center">{{ data.title }}</h2>
				<p class="text_center margin_top_10">{{ data.text }}</p>
			</div>
			<div data-testid="modal-footer" class="modal_footer">
				<button
					id="cancel"
					class="btn lg round outline "
					@keyup.right="selectConfirm"
					@click="closeConfirmationModal"
				>
					{{ $t('cancel') }}
				</button>
				<button id="confirm" ref="confirm" @keyup.left="selectCancel" class="btn lg round green" @click="confirm">
					{{ $t('confirm') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { selectDelayed } from '../../utils';
export default {
	data() {
		return {
			confirming: false,
		};
	},
	computed: {
		...mapGetters({
			data: 'confirmationModalData',
		}),
	},
	mounted() {
		this.selectConfirm();
	},
	methods: {
		...mapMutations(['closeConfirmationModal']),

		confirm() {
			if (!this.confirming) {
				this.confirming = true;
				this.data.confirmAction();
				this.confirming = false;
				this.closeConfirmationModal();
			}
		},

		selectConfirm() {
			setTimeout(() => {
				this.$refs.confirm.focus();
			}, 100);
		},

		selectCancel() {
			selectDelayed('cancel', 50);
		},
	},
};
</script>
