<template>
	<div
		class="ticket_list ticket_list_target ticket_list_wide"
		:class="{ toggle: sidebar, ticket_list_wide_hidden: sidebar }"
	>
		<Loading v-if="loading || error" :error="error" :reload_btn_visible="!!error"/>

		<table v-else class="events">
			<thead>
				<tr class="table_row gray_header">
					<th class="event_code padding_right_5 small th_sticky">{{ $t('numOfTickets') }}</th>
					<th class="event_code padding_0 small th_sticky">{{ $t('operator') }}</th>
					<th class="padding_0 strong small th_sticky">{{ $t('time') }}</th>
					<th class="padding_right_5 strong text_right small th_sticky">
						{{ $t('amount') }}
					</th>
					<th class="padding_0 small th_sticky"></th>
					<th class="padding_0 text_right small th_sticky">{{ $t('depositType') }}</th>
					<th class="padding_left_15 text_right small th_sticky">{{ $t('number') }}</th>
					<th class="padding_right_15 text_right small th_sticky">{{ $t('sum') }}</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(ticket, index) in tickets"
					:id="ticket.id"
					:key="ticket.id"
					class="table_row"
					@keyup="handleEnter"
					style="cursor: pointer;"
					@click="selectTicket(ticket.id)"
					:class="ticket.id === selectedTicketId ? 'selected' : ''"
				>
					<td class="event_code padding_right_5">{{ ticket.barcode }}</td>
					<td class="event_code padding_0">{{ ticket.operator }}</td>
					<td class="padding_0 strong">{{ getTime(ticket.placedDatetime || ticket.requestedDatetime) }}</td>
					<td class="padding_right_5 strong text_right">
						{{ toMoneyFormat(ticket.originalAmount || ticket.amount) }}
					</td>
					<td class="padding_0 small">{{ ticket.currency }}</td>
					<td class="padding_0 text_right">{{ $t('cash') }}</td>
					<!-- <td class="padding_right_15 strong text_right">{{ ticket.maxGain }}</td> -->
					<td class="padding_left_15 text_right">{{ index + 1 }}</td>
					<td class="padding_right_15 text_right">{{ toMoneyFormat(+sum[index]) }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import Loading from '../Loading';
import { mapGetters } from 'vuex';
import { formatDate, select } from '../../utils';
import formatting from '../../mixins/formatting';
import { handleKey, combineHandlers, scrollTo } from '../../utils';
import { keys } from 'keycodes-map';

export default {
	mixins: [formatting],
	props: ['sidebar', 'loading', 'error', 'tickets', 'selected', 'setTicket', 'setSidebar'],
	data() {
		return {
			removeListeners: null,
			ticketsToShow: [],
		};
	},
	computed: {
		...mapGetters(['activeModal']),
		sum() {
			const { tickets = [] } = this;
			const originalAmounts = tickets.map(({ originalAmount }) => originalAmount);

			return originalAmounts.reduce((sum, current) => {
				const last = sum[sum.length - 1] || 0;
				return [...sum, last + current];
			}, []);
		},
		selectedTicketId() {
			const { id = '' } = this.selected || {};
			return id;
		},
	},
	components: {
		Loading,
	},
	created() {
		this.removeListeners = combineHandlers(
			handleKey('keydown', keys.arrowUp, this.prevent),
			handleKey('keyup', keys.arrowUp, this.moveUp),
			handleKey('keyup', keys.enter, this.handleEnter),
			handleKey('keydown', keys.arrowDown, this.prevent),
			handleKey('keyup', keys.arrowDown, this.moveDown)
		);
	},
	destroyed() {
		this.removeListeners && this.removeListeners();
	},
	methods: {
		getTime(date) {
			return date && formatDate(new Date(date), 'hours:minutes:seconds')[0];
		},
		prevent(e) {
			e.preventDefault();
		},
		moveUp() {
			if (this.activeModal || !this.sidebar) return;
			const { selectedTicketId, tickets, setTicket } = this;
			const selectedTicketIndex = tickets.findIndex((ticket) => ticket.id === selectedTicketId);
			if (!selectedTicketIndex || selectedTicketIndex === -1) return;
			const previousTicket = tickets[selectedTicketIndex - 1];
			setTicket(previousTicket.id);
			scrollTo(previousTicket.id);
		},
		moveDown() {
			if (this.activeModal || !this.sidebar) return;
			const { selectedTicketId, tickets, setTicket } = this;
			const selectedTicketIndex = tickets.findIndex((ticket) => ticket.id === selectedTicketId);
			if (selectedTicketIndex === -1 || selectedTicketIndex === tickets.length - 1) return;
			const nextTicket = tickets[selectedTicketIndex + 1];
			setTicket(nextTicket.id);
			scrollTo(nextTicket.id);
		},
		selectTicket(id) {
			if (this.activeModal) return;
			this.setTicket(id);
			this.setSidebar(false);
			select('barcode');
		},
		handleEnter() {
			if (!this.sidebar || this.activeModal) return;
			this.setSidebar(false);
			select('barcode');
		},
	},
};
</script>

<style scoped>
.th_sticky {
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	z-index: 20;
	border-width: 0 !important;
}
.gray_header {
	background-color: rgba(143, 164, 184, 0.1) !important;
}
.ticket_list_wide {
	width: 750px !important;
	right: -750px !important;
}
.ticket_list_wide_hidden {
	right: 0 !important;
}
</style>
