import settings from '../settings.json';

/**
 * Maps different language variants to our supported language formats
 *
 * @example
 * resolveLanguage('sr_sr') -> 'sr'
 * resolveLanguage('en_EN') -> 'en'
 *
 */
export function resolveLanguage(language, defaultLanguage = 'en') {
	return settings.languagesMap[language] || defaultLanguage;
}
