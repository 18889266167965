import io from 'socket.io-client';
import { wsTicketHost, useMsgPackTicket } from './host';
const msgParser = require('socket.io-msgpack-parser');

let socket = null;
let subsciptions = [];
let rooms = [];
let connectLock = false;

const initSocket = () => {
	if (connectLock) return;
	connectLock = true;
	socket = null;
	try {
		const additionalSettings = useMsgPackTicket ? { parser: msgParser } : {};
		socket = io(wsTicketHost, {
			reconnection: false,
			transports: ['websocket'],
			...additionalSettings,
		});
	} catch (err) {
		console.error(`Ticket socket client initialization failed. Error: ${err}`);
	}
	rooms.forEach((room) => socket.emit('join', room));
	subsciptions.forEach((listeners) => {
		listeners.forEach(([event, fn]) => socket.on(event, fn));
		return () => listeners.forEach(([event, fn]) => socket.off(event, fn));
	});

	socket.on('disconnect', (reason) => {
		console.log(`Ticket socket disconnected. Reason: ${reason} - ${new Date()}`);
		setTimeout(() => initSocket(), 500);
	});

	socket.on('connect', () => {
		console.log(`Ticket socket connected! - ${new Date()}`);
	});

	socket.on('connect_error', (error = '') => {
		console.log(`Ticket socket connect error: ${error} \n Socket will reconnect! - ${new Date()}`);
		setTimeout(() => initSocket(), 500);
	});

	socket.on('error', (error = '') => {
		console.log(`Ticket socket error! ${error} - ${new Date()}`);
	});

	socket.on('reconnect', () => {
		console.log(`Ticket socket reconnecting! - ${new Date()}`);
	});
	connectLock = false;
};

initSocket();

function join(room, callback = () => {}) {
	socket.emit('join', room.trim(), callback);
	rooms.push(room.trim());
}
function leave(room, callback = () => {}) {
	socket.emit('leave', room.trim(), callback);
	rooms = rooms.filter((element) => element !== room);
}

function subscribeTo(events, callback) {
	let listeners = [];
	if (typeof events === 'string') events = [events];
	if (!events || typeof events !== 'object') throw new Error('Invalid event provided');

	// Convert events to proper format
	if (Array.isArray(events)) listeners = events.map((e) => [e, callback]);
	else listeners = Object.entries(events);

	// Validate types of events and callbacks
	listeners.forEach(([event, fn]) => {
		if (typeof event !== 'string') throw new Error('Invalid event provided');
		if (typeof fn !== 'function') throw new Error('Invalid callback provided');
	});

	subsciptions.push(listeners);

	// Add listeners and return the function for removing the listeners
	listeners.forEach(([event, fn]) => socket.on(event, fn));
	return () => listeners.forEach(([event, fn]) => socket.off(event, fn));
}

export default {
	join,
	leave,
	subscribeTo,
};
