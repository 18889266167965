<template>
	<div class="app_header">
		<Navigation msg="Uplata" />

		<div class="round">
			<p>
				{{ $t('location') }}
				<strong>{{ locationShortCode }}</strong>
			</p>
		</div>

		<RightSide />
	</div>
</template>

<script>
import Navigation from './Navigation';
import RightSide from './RightSide';

export default {
	components: {
		Navigation,
		RightSide,
	},
	computed: {
		locationShortCode() {
			return window.shortCode || '999-6666';
		},
	},
};
</script>
