import {BONUS_TYPE_ODDS} from '../../consts/messages'
import settings from '../../settings.json'
import {parse} from '..'
import { remapMinified } from '../remappers';
import { applyBonusOddsChangeMessageOnBonusEvents } from './messageHandlers'


const minificationEnabled = settings.socketMessagesMinification;


export const applyMessagesOnBonusTipEvents = (events, bonusEvents, messages) => {
    messages.forEach(message => {
        const parsed = parse(message);
        if (!parsed || typeof parsed !== 'object' || !parsed.data) return;
    
        let data = parsed.data;
        if (minificationEnabled) {
            data = remapMinified(message.type, data);
        }


        switch (message.type) {
            case BONUS_TYPE_ODDS: {
                applyBonusOddsChangeMessageOnBonusEvents(events,bonusEvents, data)
                break
            }
        }
        
    })
}

