import { isEqual, cloneDeep } from 'lodash';

export const memoize = (prop, timeout) => ({
	data() {
		return {
			_last: undefined,
			_timeout: undefined,
			[`${prop}_changed`]: false,
			[`prev_${prop}`]: undefined,
		};
	},
	watch: {
		[prop]: function(value) {
			this._clearTimeout();

			this[`${prop}_changed`] = !isEqual(value, this._last);
			this[`prev_${prop}`] = this._last;
			this._last = cloneDeep(value);

			this._setTimeout();
		},
	},
	methods: {
		_clearPrev() {
			this[`prev_${prop}`] = undefined;
			this[`${prop}_changed`] = false;
		},
		_setTimeout() {
			if (typeof timeout === 'number') {
				this._timeout = setTimeout(this._clearPrev, timeout);
			}
		},
		_clearTimeout() {
			this._timeout && clearTimeout(this._timeout);
		},
	},
	created() {
		this._last = cloneDeep(this[prop]);
	},
});
