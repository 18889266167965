<template>
	<div style="display: flex; justify-content: center; align-items: center; height: 100%; width: 100%;">
		<div style="display: flex; flex-direction: column; align-items: center; gap: 10px;" v-if="error">
			{{ error }}
			<button v-if="reload_btn_visible" class="btn" @click="reload()">{{ $t('reload') }}</button>
		</div>
		
		<img v-else src="/loader.gif" alt="Loading..." />
	</div>
</template>

<script>
export default {
	props: ['error', 'reload_btn_visible'],
	methods: {
		reload(){
			window.location.reload();
		},
	}
};
</script>
