import { AleaControlShell } from '../utils/AleaControlShell';

/**
 * Print slip function
 * @param data Data that shell expects
 * @param {string} type Should be "deposit" or "withdraw"
 */
export function printSlips(data, type) {
	const slipType = type === 'deposit' ? 'DepositPayin' : 'DepositPayout';
	const slip = JSON.stringify(data, null, 2);
	AleaControlShell.setPrintText(slip, slipType);
}
