<template>
	<!-- Modals -->
	<div class="modal_cover xxl modal_01" :class="{ toggle: closeTicketsModal }">
		<!-- .toggle .notification -->
		<div class="modal_content fadeInDown animated faster">
			<button
				class="btn transparent close toggle_modal_01"
				@click="
					toggleTicketModal();
					resetPaginationPage(1);
				"
			>
				<i class="mdi mdi-close"></i>
			</button>

			<!-- .col_border_bottom/top/right/left -->
			<!-- .content_start/end/center/separate -->
			<!-- .direction_row/column -->

			<div class="modal_head col_border_bottom">
				<h2 class="modal_title font_500 flex_0">
					{{ $t('previewPayInPayOut') }} <span class="font_700 padding_left_5">{{ appliedDate }}</span>
				</h2>

				<Paggination
					v-if="totalTickets != 0"
					:totalPages="totalPages"
					:perPage="perPage"
					:currentPage="currentPage"
					@pagechanged="onPageChange"
				/>
			</div>

			<div class="modal_body content_start direction_column">
				<table v-if="!loading" class="events">
					<thead>
						<tr class="table_row">
							<th class="padding_left_20 text_left">{{ $t('timePlaced') }}</th>
							<th class="text_center">{{ $t('payoutTime') }}</th>
							<!-- <th class="text_center">{{ $t('idDevice') }}</th> -->

							<th class="text_left">{{ $t('ticketCode') }}</th>
							<th class="text_left">{{ $t('ticketStatus') }}</th>
							<th class="text_left"></th>

							<th class="text_right">{{ $t('payin') }}</th>
							<th class="text_center">{{ $t('currency') }}</th>
							<th class="text_right">{{ $t('payout') }}</th>
							<th class="text_left">{{ $t('payoutType') }}</th>

							<th class="">{{ $t('payinOperator') }}</th>
							<th class="">{{ $t('payoutOperator') }}</th>

							<th class="padding_right_20 text_right">{{ $t('paymentType') }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="ticket in ticketsData.tickets" :key="ticket.id" class="table_row ">
							<td
								v-if="ticket.placedDatetime || ticket.requestedDatetime"
								class="padding_left_20 gray_darken_text"
							>
								{{ getTime(ticket.placedDatetime || ticket.requestedDatetime) }}
							</td>
							<td v-else class="text_center padding_left_20"></td>

							<td v-if="ticket.paidOutDatetime" class="text_center">
								{{ getTime(ticket.paidOutDatetime) }}
							</td>
							<td v-else-if="ticket.cashout" class="text_center">
								{{ getTime(ticket.cashout.dtPaidout) }}
							</td>
							<td v-else class="text_center"></td>

							<td>{{ ticket.barcode }}</td>

							<td v-if="ticket.winType === 'CASHOUT'">
								{{ $t('PAID_OUT') }}
							</td>

							<td v-else-if="ticket.status === 'PLACED' && !ticket.paidOutDatetime && !ticket.paidOutAmount">
								{{ $t('PLACED') }}
							</td>
							<td v-else-if="ticket.status === 'PLACED' && ticket.paidOutDatetime && ticket.paidOutAmount">
								{{ $t('PAID_OUT') }}
							</td>
							<td v-else-if="ticket.status === 'REFUNDED'">{{ $t('REFUNDED') }}</td>
							<td v-else-if="ticket.status === 'WINNING'">{{ $t('WINNING') }}</td>
							<td v-else-if="ticket.status === 'LOSING'">{{ $t('LOSING') }}</td>
							<td v-else-if="ticket.status === 'PAID_OUT'">{{ $t('PAID_OUT') }}</td>
							<td v-else></td>

							<td v-if="ticket.resultStatus === 'PLACED'" class="text_left">{{ $t('PLACED') }}</td>
							<td v-else-if="ticket.resultStatus === 'REFUNDED'" class="text_left">{{ $t('REFUNDED') }}</td>
							<td v-else-if="ticket.resultStatus === 'WINNING'" class="text_left">{{ $t('WINNING') }}</td>
							<td v-else-if="ticket.resultStatus === 'LOSING'" class="text_left">{{ $t('LOSING') }}</td>
							<td v-else-if="ticket.resultStatus === 'PAID_OUT'" class="text_left">{{ $t('PAID_OUT') }}</td>
							<td v-else></td>

							<td v-if="ticket.amount" class="strong text_right">
								{{ toMoneyFormat(ticket.originalAmount) }}
							</td>
							<td v-else class="strong text_right"></td>

							<td class="text_center">{{ ticket.currency }}</td>

							<td v-if="ticket.paidOutAmount" class="strong text_right">
								{{ toMoneyFormat(ticket.paidOutAmount) }}
							</td>
							<td v-else-if="ticket.cashout" class="strong text_right">
								{{ toMoneyFormat(ticket.cashout.amount) }}
							</td>
							<td v-else class="text_right"></td>

							<td class="text_center">{{ ticket.winType }}</td>

							<td v-if="ticket.operator" class="text_center">{{ ticket.operator }}</td>
							<td v-else class="text_center"></td>

							<td v-if="ticket.payoutOperator" class="text_center">{{ ticket.payoutOperator }}</td>
							<td v-else class="text_center"></td>

							<td v-if="ticket.device === 'POS'" class=" text_right padding_right_20">
								{{ $t('cashRegister') }}
							</td>
							<td v-else-if="ticket.device === 'PRESENTATION'" class=" text_right padding_right_20">
								{{ $t('presentation') }}
							</td>
							<td v-else-if="ticket.device === 'TERMINAL'" class=" text_right padding_right_20">
								{{ $t('terminal') }}
							</td>
							<td v-else-if="ticket.device === 'E_TERMINAL'" class=" text_right padding_right_20">
								{{ $t('eTerminal') }}
							</td>
						</tr>
					</tbody>
				</table>
				<loading v-else />
			</div>
			<!-- .modal_body -->
		</div>
		<!-- .modal_content -->
	</div>
</template>

<script>
import Paggination from '../../General/Paggination.vue';
import Loading from '../../Loading.vue';
import { mapGetters } from 'vuex';
import { formatDate } from '../../../utils';
import { formatting } from '../../../mixins';
export default {
	mixins: [formatting],
	name: 'TicketListModal',

	props: {
		loading: Boolean,
		closeTicketsModal: Boolean,
		toggleTicketModal: Function,
		ticketsData: Object,
		appliedDate: String,
		loadTicketsData: Function,
		totalPages: Number,
		perPage: Number,
		totalTickets: Number,
	},

	components: {
		Loading,
		Paggination,
	},
	data() {
		return {
			currentPage: 1,
		};
	},
	computed: {
		...mapGetters(['loadingData']),
	},

	methods: {
		getTime(date) {
			return date && formatDate(new Date(date), 'hours:minutes:seconds')[0];
		},
		onPageChange(page) {
			this.loadTicketsData((page - 1) * this.perPage, this.perPage);
			this.currentPage = page;
		},

		resetPaginationPage(page) {
			this.currentPage = page;
		},
	},
};
</script>
