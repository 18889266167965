export default {
	state: {
		isChecked: false,
	},
	getters: {
		isCheckedData: (state) => state.isChecked,
	},
	mutations: {
		toggleCheckbox(state) {
			state.isChecked = !state.isChecked;
		},
	},
	actions: {
		toggleCheckboxAction({ commit }) {
			commit('toggleCheckbox');
		},
	},
};
