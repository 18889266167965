<template>
	<span style="width: 100%">
		<slot :final="final" />
	</span>
</template>

<script>
export default {
	props: {
		states: Array,
		duration: {
			type: Number,
			default: 10,
		},
	},
	data() {
		return {
			_states: undefined,
			interval: undefined,
			final: undefined,
		};
	},
	methods: {
		iterate() {
			const [current, ...rest] = this._states;
			this.final = current;

			if (rest.length) this._states = rest;
			else clearInterval(this.interval);
		},
	},
	watch: {
		states: function(newVal, _) {
			if (newVal) {
				this._states = newVal;
				this.interval = setInterval(this.iterate, this.duration || 10);
			}
		},
	},
	created() {
		this._states = this.states;
		this.interval = setInterval(this.iterate, this.duration || 10);
	},
};
</script>
