<template>
	<table class="events">
		<tbody>
			<tr
				v-for="({ key, name, odds, status }, index) in filteredMarkets"
				:class="[{ selected: currentMarket === index }, { disabled: status !== 'ACTIVE' }, ' table_row']"
				:id="`live-market:${index}`"
				:row="index"
				:key="key"
			>
				<OddMargin :name="name" />
				<td class="live_typing_code">
					<div class="market_odd_list">
						<OddRow
							v-for="({ id, name, value }, i) in odds"
							:rowSelected="currentOdd === `${index}:${i}`"
							:oddRow="`${index}:${i}`"
							:value="value"
							:name="name"
							:key="id"
							:addNewOdd="addNewOdd"
						>
						</OddRow>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
import OddRow from './OddRow';
import OddMargin from './OddMargin';
import { mapGetters } from 'vuex';
import { keys } from 'keycodes-map';
import { scrollTo } from '../../../utils';
import { computedEvent, createEditEvent } from '../../../mixins';
import { ACTIVE } from '../../../consts';

export default {
	mixins: [computedEvent, createEditEvent],
	props: ['markets', 'searchValue'],
	components: { OddRow, OddMargin },
	data() {
		return {
			currentMarket: 0,
			currentOdd: '0:0',
		};
	},
	computed: {
		...mapGetters({ row: 'selectedRow' }),

		filteredMarkets() {
			const { markets, searchValue } = this;
			const search = searchValue.toLowerCase();
			const filtered = markets?.filter(({ name }) => name?.toLowerCase().includes(search)) ?? [];
			if (this.currentMarket > filtered.length - 1) {
				this.currentMarket = 0;
				this.currentOdd = '0:0';
			}
			return filtered.map(({ odds, ...other }) => {
				const sortedOdds = odds.sort((a, b) => a.order - b.order);
				return {
					odds: sortedOdds,
					...other,
				};
			});
		},
	},

	methods: {
		oddEnter(e) {
			if (e.keyCode !== keys.enter) return;
			this.addNewOdd(this.currentOdd);
		},
		addNewOdd(currentOdd) {
			const { systemIndex, eventIndex, odd: oldOdd, row } = this;

			// Extract selected odd
			const [marketIndex, oddIndex] = currentOdd.split(':');
			const market = this.filteredMarkets[+marketIndex];
			const odd = market?.odds[+oddIndex];
			if (!odd || market.status !== ACTIVE || odd.value === 1) return;

			// Check is odd already played
			const event = this.ticketEvent(systemIndex, eventIndex) || {};
			if (!event.isNew && oldOdd.id !== odd.id) {
				if (event.odds.some(({ id }) => id === odd.id))
					return this.$notifications.info({
						title: this.$t('warn'),
						text: `${this.$t('oddExists')}`,
						type: 'warn',
						data: { id: 'modal-search' },
					});
			}
			// Add odd to the ticket or replace current one
			if (event.isNew) {
				this.replaceTicketEvent({
					eventId: event.id,
					systemIndex,
					newEvent: {
						...event,
						isNew: false,
						isEdit: !oldOdd,
						odds: [odd],
					},
				});
			} else {
				this.replaceEventOdd({
					newOdd: odd,
					oddId: oldOdd.id,
					systemIndex,
					eventIndex,
				});
			}

			this.addNewEmptyEvent(systemIndex);
			this.closeLiveOddsModal();
		},
		setCurrentOdd(id) {
			this.currentOdd = id;
		},

		marketUp(e) {
			if (e.keyCode === keys.arrowUp && this.currentMarket > 0) {
				scrollTo(`live-market:${--this.currentMarket}`);
				this.currentOdd = `${this.currentMarket}:0`;
			}
		},
		marketDown(e) {
			if (e.keyCode === keys.arrowDown && this.currentMarket < this.filteredMarkets.length - 1) {
				scrollTo(`live-market:${++this.currentMarket}`);
				this.currentOdd = `${this.currentMarket}:0`;
			}
		},

		oddLeft(e) {
			if (e.keyCode === keys.arrowLeft) {
				const [marketIndex, oddIndex] = this.currentOdd.split(':');
				if (oddIndex === '0') return;
				this.currentOdd = `${marketIndex}:${+oddIndex - 1}`;
			}
		},

		oddRight(e) {
			if (e.keyCode === keys.arowRight) {
				const [marketIndex, oddIndex] = this.currentOdd.split(':');
				const oddsLenght = this.filteredMarkets[marketIndex].odds.length - 1;
				if (oddIndex === `${oddsLenght}`) return;
				this.currentOdd = `${marketIndex}:${+oddIndex + 1}`;
			}
		},
	},

	mounted() {
		document.addEventListener('keydown', this.marketDown);
		document.addEventListener('keydown', this.oddRight);
		document.addEventListener('keydown', this.marketUp);
		document.addEventListener('keydown', this.oddLeft);
		document.addEventListener('keyup', this.oddEnter);
	},
	destroyed() {
		document.removeEventListener('keydown', this.marketDown);
		document.removeEventListener('keydown', this.oddRight);
		document.removeEventListener('keydown', this.marketUp);
		document.removeEventListener('keydown', this.oddLeft);
		document.removeEventListener('keyup', this.oddEnter);
	},
};
</script>
