export * from './tickets';
export * from './pauseEventPeriods';

export const keys = {
	f11: 122,
	f12: 123,
	minus: 109,
};

export const defaultLocation = 'cb9897f0-5179-4e27-84da-ac2d098fbc80';
export const BARCODE_LENGTH = 21;
export const BARCODE_REGEX = /(\d{3}-\d{4}-\w{7})(\+\d{4})/;
export const PIN_LENGTH = 4;
export const MAX_EVENT_ODDS = 6;

// Event live statuses
export const LIVE = 'LIVE';
export const ANNOUNCED = 'ANNOUNCED';
export const DISABLED = 'DISABLED';
export const ENABLED = 'ENABLED';
export const EventLiveStatus = {
	LIVE,
	ANNOUNCED,
	DISABLED,
	ENABLED,
};

// Event provider statuses
export const NOT_RUNNING = 'NOT_RUNNING';
export const RUNNING = 'RUNNING';
export const STOPPED = 'STOPPED';
export const NOT_FINISHED = 'NOT_FINISHED';
export const FINISHED = 'FINISHED';
export const POSTPONED = 'POSTPONED';
export const INTERRUPTED = 'INTERRUPTED';
export const CANCELED = 'CANCELED';

export const ACTIVE_EVENT_STATUSES = [POSTPONED, RUNNING, INTERRUPTED];

export const ACTIVE = 'ACTIVE';
export const REMOVED = 'REMOVED';
export const TEMPORARY_REMOVED = 'TEMPORARY_REMOVED';
export const EventProviderStatus = {
	NOT_RUNNING,
	RUNNING,
	STOPPED,
	NOT_FINISHED,
	FINISHED,
	POSTPONED,
	INTERRUPTED,
	ACTIVE,
	REMOVED,
	TEMPORARY_REMOVED,
};

// Ticket statuses
export const REQUESTED = 'REQUESTED';
export const PLACED = 'PLACED';
export const REFUNDED = 'REFUNDED';
export const WINNING = 'WINNING';
export const LOSING = 'LOSING';
export const INVALID = 'INVALID';
export const DELETED = 'DELETED';
export const PAID_OUT = 'PAID_OUT';

// Ticket statuses nums
export const REQUESTED_NUM = 0;
export const PLACED_NUM = 1;
export const REFUNDED_NUM = 2;
export const INVALID_NUM = 5;
export const DELETED_NUM = 6;
export const WITHDRAWN_NUM = 8;
export const EXPIRED_NUM = 9;

export const REQUESTED_AUTH_NUM = 0;
export const REJECTED_AUTH_NUM = 1;
export const ACCEPTED_AUTH_NUM = 2;
export const MODIFIED_AUTH_NUM = 3;
export const SYSTEM_CLOSED_AUTH_NUM = 6;

//Cashout statuses
export const SENT = 'SENT';
export const ACCEPTED = 'ACCEPTED';
export const DENIED = 'DENIED';
export const EXPIRED = 'EXPIRED';

//Cashback
export const CASHBACK = 'CASHBACK';

//email regex
export const EMAIL_REGEX = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';

// Event search
export const DEBOUNCE_DELAY_MS = 300;
