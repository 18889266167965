import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './views/Home.vue';
import Panel from './views/Panel.vue';
import Tickets from './views/Tickets.vue';
import Balance from './views/Balance.vue';
Vue.use(VueRouter);

const router = new VueRouter({
	routes: [
		{
			path: '/',
			name: 'panel',
			component: Panel,
		},
		{
			path: '/home',
			name: 'home',
			component: Home,
		},
		{
			path: '/tickets',
			name: 'tickets',
			component: Tickets,
		},
		{
			path: '/balance',
			name: 'balance',
			component: Balance,
		},
	],
});

export default router;
