export default {
	data() {
		return {
			defaultFocusInputTimer: 3,
			focusInputTimer: 0,
		};
	},
	methods: {
		resetfocusInputTimer() {
			this.focusInputTimer = 0;
		},
	},
};
