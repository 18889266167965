<template>
	<portal to="bonus-voucher">
		<div class="modal_cover md toggle z_4">
			<div class="modal_content fadeInDown animated faster">
				<button class="btn transparent close" @click="voucherReset">
					<i class="mdi mdi-close"></i>
				</button>

				<div class="modal_head padding_left_20">
					<h2 class="modal_title ">{{ $i18n.t('bonusVoucherPayIn') }}</h2>
				</div>

				<div class="modal_body content_start direction_column small_form" style="padding: 0px 30px;">
					<div class="form_row">
						<p class="validation_message text_center">
							{{ $t('scanOrAddVouchers') }}
						</p>
					</div>

					<div class="form_row align_center justify_center margin_bottom_10">
						<input
							ref="barcode"
							id="barcode"
							type="text"
							class="regular h_md mw_100 margin_right_10 uppercase"
							:placeholder="$i18n.t('barcode')"
							v-model="barcode"
							@keydown.enter="focusOnEnter"
							@keyup.right="focusPin"
						/>
						<input
							ref="pin"
							type="text"
							class="regular h_md mw_100"
							:placeholder="$i18n.t('pin')"
							v-model="pin"
							@keyup.left="focusBarcode"
							@keydown.enter="validateVoucher(barcode, pin)"
						/>
					</div>
					<div v-if="payInErrMsg === 'min'" class="form_row red">
						<p class="validation_message text_center font_600 font_16px red">
							{{ $t('minPayIn', { minPayIn: toMoneyFormat(minPayIn), currency }) }}
						</p>
					</div>
					<div v-if="payInErrMsg === 'max'" class="form_row red">
						<p class="validation_message text_center font_600 font_16px red">
							{{ $t('maxPayIn', { maxPayIn: toMoneyFormat(maxPayIn), currency }) }}
						</p>
					</div>
					<div v-if="errMsg" class="form_row red">
						<p class="validation_message text_center font_600 font_16px red">
							{{ $i18n.t(errMsg) }}
						</p>
					</div>
					<div v-if="errMsgApi" class="form_row red">
						<p class="validation_message text_center font_600 font_16px red">
							{{ errMsgApi }}
						</p>
					</div>

					<div class="margin_bottom_50" style="overflow-y: auto">
						<div
							v-for="(voucher, index) in vouchersArr"
							:key="index"
							class="align_center flex_justify_space_between margin_bottom_10 font_14px font_600 "
						>
							<div class="text_left padding_5 w_100">{{ voucher.barcode }}</div>
							<div class="text_left padding_5 w_100">{{ voucher.pin }}</div>

							<div class="text_right w_100 padding_5">
								{{ toMoneyFormat(voucher.value) }}
							</div>
							<div class="text_right w_80 padding_5">
								{{ currency }}
							</div>
							<div class="text_right w_100 padding_5" @click="remove(index)"><i class="mdi mdi-delete"></i></div>
						</div>
					</div>
					<h2
						v-if="vouchersArr.length !== 0"
						class="modal_message"
						style="position: absolute; bottom:20px; left:35px"
					>
						<span>{{ $i18n.t('payInSum') }}</span> <span>{{ toMoneyFormat(calculatedSum) }}</span>
						<span> {{ currency }}</span>
					</h2>
				</div>

				<div class="modal_footer col_border_top">
					<button id="cancel" class="btn lg round outline" @click="voucherReset">
						{{ $t('cancel') }}
					</button>
					<button
						ref="confirm"
						id="confirm"
						class="btn lg round green"
						:class="{ disabled: payInErrMsg }"
						@click="confirm"
					>
						{{ $t('confirm') }}
					</button>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { formatting, inputHandler } from '../..//mixins';
import { selectDelayed } from '../../utils';
import { ticketPayInMinAmount } from '../../utils/ticket/ticketPayInMinAmount';
import { ticketPayInMaxAmount } from '../../utils/ticket/ticketPayInMaxAmount';

export default {
	name: 'BonusvoucherModal',
	mixins: [formatting, inputHandler],
	props: ['systems', 'combinations'],
	data() {
		return {
			minPayIn: 0,
			maxPayIn: 0,
			barcode: '',
			pin: '',
			voucherStrings: [],
			validatedVouchers: [],
			errMsg: '',
			errMsgApi: '',
			payInErrMsg: '',
		};
	},
	computed: {
		...mapGetters(['promotionCodes', 'bonusvoucherModalIsOpen', 'vouchersArr']),
		currency() {
			return window.currency;
		},
		calculatedSum() {
			return this.vouchersArr.reduce((accumulator, object) => {
				return accumulator + object.value;
			}, 0);
		},
	},
	methods: {
		...mapActions([
			'promotionCodesAction',
			'toggleBonusVoucherModalAction',
			'vouchersSumAction',
			'toggleVoucherPayInAction',
			'vouchersArrRemoveAction',
			'vouchersArrPushAction',
			'vouchersArrReset',
		]),
		async validateVoucher(barcode, pin) {
			if (this.barcode === '' || this.pin === '') {
				this.errMsg = 'emptyFields';
				this.focusBarcode();
				return;
			}

			if (this.vouchersArr.some((voucherEl) => voucherEl.barcode === barcode)) {
				this.errMsg = 'enteredVoucher';
				this.focusBarcode();
				return;
			}

			const voucherString = `${barcode.toUpperCase()}+${pin}`;

			try {
				const getVoucherData = await this.$api.getPromoCodeValue(voucherString);
				this.vouchersArrPushAction({
					barcode: barcode.toUpperCase(),
					pin: pin,
					value: getVoucherData,
				});

				this.voucherStrings.unshift(voucherString);

				this.barcode = '';
				this.pin = '';
				this.errMsg = '';
				this.errMsgApi = '';
			} catch (err) {
				const {
					response: {
						data: { detail },
					},
				} = err;
				this.errMsgApi = detail;
			} finally {
				this.focusBarcode();
			}
		},

		focusOnEnter() {
			if (
				this.barcode.length === 0 &&
				this.vouchersArr.length !== 0 &&
				this.payInErrMsg === '' &&
				this.errMsg === '' &&
				this.errMsgApi === ''
			) {
				this.$refs.confirm.focus();
			} else {
				this.focusPin();
			}
		},
		focusPin() {
			this.$refs.pin?.focus();
		},

		focusBarcode() {
			this.$refs.barcode.focus();
		},

		remove(index) {
			this.vouchersArrRemoveAction(index);
			this.voucherStrings.splice(index, 1);
			this.focusBarcode();
		},
		confirm() {
			if (this.vouchersArr.length > 0) {
				this.promotionCodesAction({ value: this.voucherStrings });
				this.vouchersSumAction({ value: +this.calculatedSum });
				this.toggleVoucherPayInAction(true);
				this.toggleBonusVoucherModalAction();
			} else {
				selectDelayed('barcode');
			}
		},

		voucherReset() {
			this.vouchersSumAction({ value: 0 });
			this.vouchersArrReset();
			this.toggleBonusVoucherModalAction();
		},
	},
	watch: {
		barcode(value) {
			if (value.length === 13) {
				this.focusPin();

				const [barcodeScan, pinScan] = value.split('+');

				this.barcode = barcodeScan;
				this.pin = pinScan;
			}
			this.errMsg = '';
			this.errMsgApi = '';
		},

		calculatedSum(sumValue) {
			this.minPayIn = ticketPayInMinAmount(this.systems, this.combinations, window.geoParams);
			this.maxPayIn = ticketPayInMaxAmount(this.systems, this.combinations, window.geoParams);

			if (sumValue === 0) {
				this.payInErrMsg = '';
				this.errMsg = '';
				this.errMsgApi = '';
			} else if (sumValue.toFixed(2) < this.minPayIn) {
				this.payInErrMsg = 'min';
			} else if (sumValue.toFixed(2) > this.maxPayIn) {
				this.payInErrMsg = 'max';
			} else {
				this.payInErrMsg = '';
				this.errMsg = '';
				this.errMsgApi = '';
			}
		},
	},

	mounted() {
		setTimeout(() => {
			this.$refs.barcode?.focus();
		}, 300);
	},
};
</script>
<style scoped>
.mw_100 {
	max-width: 100px !important;
}
.w_80 {
	width: 80px !important;
}
.w_100 {
	width: 100px !important;
}
.w_150 {
	width: 150px !important;
}
.w_200 {
	width: 200px !important;
}
.w_300 {
	width: 300px !important;
}

.red {
	color: #e35454;
}

.uppercase {
	text-transform: uppercase;
}
</style>
