import { mapMutations, mapGetters, mapState } from 'vuex';
import { formatDate } from '../utils';
import i18n from '../localisation/i18n';

export default {
	computed: {
		...mapGetters([]),
		...mapState(['ticketState']),
	},
	methods: {
		...mapMutations([]),

		checkSpecialConditions(event, currentListCode) {
			const { id_parent, special_type } = event;

			// Extract events from ticket systems and filter out event that/if we editing
			const events = this.ticketState.systems
				.map(({ events }) => events)
				.flat()
				.filter(({ listCode }) => listCode !== currentListCode);

			// Find event that is related to one we run through validation
			const related = events.find(
				({ id, id_parent }) =>
					id === event.id_parent ||
					(id_parent === event.id_parent && event.id_parent != null) ||
					id_parent === event.id
			);

			if (!related) return null;
			if (!id_parent) return validateEvent('eventWarn', event, related);
			if (special_type === 'MIX') return validateMix('mixWarn', event, related);
			if (special_type === 'EVENT') return validateSpecial('specialEventWarn', event);
			if (special_type === 'PLAYER') return validatePlayerProps('playerPropsWarn', event, related);
		},

		specialWarning(message, id = '') {
			this.$notifications.info({
				title: this.$t('warn'),
				text: message,
				type: 'warn',
				data: { id },
			});
		},
	},
};

// Validators
function validateEvent(msgKey, event, related) {
	const valid = related.special_type === 'MIX';
	return createMessage(msgKey, event, valid);
}

function validateSpecial(msgKey, event) {
	return createMessage(msgKey, event, false);
}

function validateMix(msgKey, event, related) {
	const valid = related.special_type === 'MIX' || !related.special_type;
	return createMessage(msgKey, event, valid);
}

function validatePlayerProps(msgKey, event, related) {
	const valid = related.special_type === 'PLAYER';
	return createMessage(msgKey, event, valid);
}

// Helpers
function createMessage(msgKey, event, valid) {
	const { listCode, competitors, utc_scheduled } = event;
	const [{ name: home }, { name: away } = { name: '' }] = competitors || [{}, {}];
	return valid ? null : i18n.t(msgKey, { listCode, home, away, time: formatDate(utc_scheduled, 'hours:minutes')[0] });
}
