const MappingTypes = {
	CSV: 'CSV',
	INPUT: 'INPUT',
};

const generateArrayFromCSV = (itemsString, delimiter = '#', fields) => {
	const splitFieldsAt = fields.length;

	let parsedArray = [];

	return itemsString?.split(delimiter)?.reduce((result, value, index, array) => {
		if (index % splitFieldsAt === 0) {
			const parts = array.slice(index, index + splitFieldsAt);

			let parameters = {};

			fields.forEach((field, index) => (parameters = { ...parameters, [field]: parts[index] }));

			parsedArray = [...parsedArray, parameters];
		}
		return parsedArray;
	}, []);
};

export const geoParser = (globalData, geoParameter, mappingType) => {
	const parameterMapping = globalData?.parameterMappings[mappingType];

	if (!parameterMapping) return;

	let parsedArray = [];

	if (parameterMapping.field_type === MappingTypes.CSV) {
		parsedArray = generateArrayFromCSV(
			geoParameter,
			parameterMapping.field_type_specifics.delimiter,
			parameterMapping.field_type_specifics.pydantic_fields
		);
	}

	return parsedArray;
};
