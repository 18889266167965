export const ODDS_CHANGE = 'odds_change';
export const BET_ODDS = 'bet_odds'
export const EVENT_CHANGE = 'event_change';
export const EVENT_CHANGE_LIVE = 'event_change_live';
export const EVENT_CHANGE_PREMATCH = 'event_change_prematch';
export const OFFER_PLAN_UPDATE = 'offer_plan_update';
export const BONUS_ODDS_CHANGE = 'bonus_odds_change';
export const BONUS_TYPE_ODDS = 'bonus_type_odds';
export const EVENT_RESULT = 'event_result';

export const messageHandlers = {
	[ODDS_CHANGE]: 'updateOdds',
	[EVENT_CHANGE]: 'updateEvent',
	[EVENT_CHANGE_LIVE]: 'addNewLiveEvent',
	[EVENT_CHANGE_PREMATCH]: 'addNewPrematchEvent',
	[OFFER_PLAN_UPDATE]: 'startCountdown',
	[BONUS_ODDS_CHANGE]: 'updateBonusOdds',
};
