import { select, selectDelayed } from '../utils';

export default {
	methods: {
		/**
		 * Loop through all previous rows until the one
		 * with the code property is found and selected
		 * Or the first system input of the previous system
		 */
		codeUp() {
			const { systemIndex: system, rowIndex: row } = this.row;
			let selected;
			let i = row;

			while (i--) {
				selected = select(`row:${system}:${i}:code`);
				if (selected) return;
			}

			selectDelayed(`sys:${system - 1}:0`, 10);
		},

		/**
		 * Loop through all next rows until the one
		 * with the code property is found and selected
		 * Or the first system input of the next system
		 */
		codeDown() {
			const { systemIndex: system, rowIndex: row } = this.row;
			const len = this.system ? this.system.rows.length - 1 : 0;

			let selected;
			let i = row;

			while (i++ < len) {
				selected = select(`row:${system}:${i}:code`);
				if (selected) return;
			}

			selectDelayed(`sys:${system}:0`, 10);
		},

		/**
		 * Select the odd type input of the previous event
		 * Or the first system input of the previous system
		 */
		codeLeft() {
			const { systemIndex: system, rowIndex: row } = this.row;
			let selected = select(`row:${system}:${row - 1}:odd`);
			if (!selected) select(`sys:${system - 1}:0`);
		},

		/**
		 * Select the odd type input of the current event
		 */
		codeRight() {
			const { systemIndex: system, rowIndex: row } = this.row;
			select(`row:${system}:${row}:odd`);
		},

		/**
		 * Select the odd type input of the previous event
		 * Or the first system input of the previous system
		 */
		oddUp() {
			const { systemIndex: system, rowIndex: row } = this.row;
			const selected = select(`row:${system}:${row - 1}:odd`);
			if (!selected) select(`sys:${system - 1}:0`);
		},

		/**
		 * Select the odd type input of the next event
		 * Or the first system input of the next system
		 */
		oddDown() {
			const { systemIndex: system, rowIndex: row } = this.row;
			const selected = select(`row:${system}:${row + 1}:odd`);
			if (!selected) select(`sys:${system}:0`);
		},

		/**
		 * Select the code input of the current event
		 * Or the odd type input of the previous event
		 */
		oddLeft() {
			const { systemIndex: system, rowIndex: row } = this.row;
			let selected = select(`row:${system}:${row}:code`);
			if (!selected) select(`row:${system}:${row - 1}:odd`);
		},

		/**
		 * Select the code input of the next event
		 * Or the first system input of the next system
		 */
		oddRight() {
			const { systemIndex: system, rowIndex: row } = this.row;
			let selected = select(`row:${system}:${row + 1}:code`);
			if (!selected) selected = select(`row:${system}:${row + 1}:odd`);
			if (!selected) select(`sys:${system}:0`);
		},
	},
};
