<template>
	<tr class="table_row">
		<td class="strong text_left padding_left_20">{{ listCode || list_code }}</td>
		<td class="text_right">
			<div class="align_center">
				<span class="svg_icon tooltip_right">
					<!-- <svg class="sport_ico">
						<use :xlink:href="`/svg/sprite_sport_icons.svg#${sport.id || ''}`" />
					</svg> -->
					<span class="">{{ sport.name.substring(0, 3) }}</span>
					<span class="tooltip_content">{{ sport.name }}</span>
				</span>

				<i v-if="live" class="mdi mdi-play-circle tooltip_right live_event"
					><span class="tooltip_content">{{ $t('liveEvent') }}</span></i
				>
			</div>
		</td>
		<td class="small">{{ start }}</td>
		<td class="strong text_right padding_right_5">{{ home }}</td>
		<td class="padding_left_0 padding_right_0 small vs text_center">-</td>
		<td class="strong text_left padding_left_5">{{ away }}</td>
		<td v-if="showResultTooltipOne" class="small" style="width: 24px">
			<i class="mdi mdi-information-outline tooltip_left"
				><td class="condition tooltip_content">
					<span v-if="playTimeResult" class="font_11px margin_right_5 white">{{
						$t('playtime').toLowerCase()
					}}</span>
					<span v-for="result in playTimeResult" :key="result.period" class="result white"
						>{{ `${result.home}:${result.away}`
						}}<span class="period_label">{{ `${periodSymbol}${result.period}` }}</span></span
					>
					<span v-if="playTime" class="col_border_left padding_left_10 white">{{ playTime }}</span>
				</td></i
			>
		</td>

		<td v-else class="small" style="width: 24px"></td>

		<td id="resultOne" class="condition ellipses">
			<span v-if="playTimeResult" class="font_11px margin_right_5">{{ $t('playtime').toLowerCase() }}</span>
			<span v-for="result in playTimeResult" :key="result.period" class="result"
				>{{ `${result.home}:${result.away}`
				}}<span class="period_label">{{ `${periodSymbol}${result.period}` }}</span></span
			>
			<span v-if="playTime" class="col_border_left padding_left_10">{{ playTime }}</span>
		</td>
		<td class="padding_right_10 text_right">
			{{ marketName }}
		</td>
		<td class="padding_left_0 strong">
			<span v-if="odd.specialValue" class="border_left">{{ odd.specialValue }}</span>
		</td>
		<td class="strong tooltip_left">
			{{ `${odd.bonusType || odd.bonus_type ? `B ${odd.name}` : odd.name || ''} ` }}
			<span class="tooltip_content">{{ odd.description }}</span>
		</td>

		<td>
			<span v-if="odd.bonusType || odd.bonus_type" class="svg_icon tooltip_right">
				<svg><use xlink:href="/svg/sprite_mb.svg#bonus_tip_18"></use></svg>
				<span class="tooltip_content">{{ $t('bonusOdd') }}</span>
			</span>
		</td>

		<td class="padding_left_0 strong">
			<i v-if="odd.result === 1" class="mdi mdi-check-bold green_text "></i>
			<i v-if="odd.result === 0" class="mdi mdi-close red_text "></i>
			<i v-if="odd.result === -1" class="mdi mdi-alpha-s-circle orange_text"></i>
		</td>
		<td class="padding_right_10 text_right strong" :class="odd && odd.voided ? 'orange_text' : ''">
			{{ (odd && (odd.voided ? '1.00' : odd.stake && oddsFormat(odd.stake))) || '' }}
		</td>

		<td v-if="showResultTooltipTwo" class="small" style="width: 24px;">
			<i class="mdi mdi-information-outline tooltip_left"
				><td class="condition tooltip_content white">
					<span v-if="finalScore" class="result font_600 col_border_right white">
						{{ `${finalScore.home}:${finalScore.away}` }}
					</span>
					<span v-for="result in currentResult" :key="result.period" class="result white"
						>{{ `${result.home}:${result.away}`
						}}<span class="period_label white">{{ `${periodSymbol}${result.period}` }}</span></span
					>
					<span v-if="currentTime" class="col_border_left padding_left_10 white">{{ currentTime }}</span>
				</td></i
			>
		</td>
		<td v-else class="small" style="width: 24px"></td>

		<td id="resultTwo" class="condition text_center ellipses">
			<span v-if="finalScore" class="result font_600 col_border_right">
				{{ `${finalScore.home}:${finalScore.away}` }}
			</span>
			<span v-for="result in currentResult" :key="result.period" class="result"
				>{{ `${result.home}:${result.away}`
				}}<span class="period_label">{{ `${periodSymbol}${result.period}` }}</span></span
			>
			<span v-if="currentTime" class="col_border_left padding_left_10">{{ currentTime }}</span>
		</td>

		<td class="condition"></td>
	</tr>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDate } from '../../utils';
import { formatting } from '../../mixins';
import { sports } from '../../utils/results/sports';
import { calculateFinalScore } from '../../utils/results/finalScore';

export default {
	mixins: [formatting],
	props: ['index', 'id', 'result', 'sport', 'competitors', 'utcScheduled', 'odds', 'live', 'betScore', 'listCode'],

	data() {
		return {
			showResultTooltipOne: false,
			showResultTooltipTwo: false,
		};
	},
	computed: {
		...mapGetters(['eventOddsData', 'marketsMap']),
		home() {
			const [home] = this.competitors;
			return home && home.name;
		},
		away() {
			const [, away] = this.competitors;
			return away && away.name;
		},
		start() {
			const start = this.utcScheduled;
			const date = start && new Date(start);
			return date && formatDate(date, 'date.month.year. hours:minutes')[0];
		},
		odd() {
			const { odds, eventOddsData } = this;
			const [odd] = eventOddsData(odds);
			const [{ value: stake, ...rest }] = Object.values(odds);
			return {
				stake,
				name: odd ? odd.name : '',
				id: odd ? odd.id : '',
				description: odd ? odd.description : '',
				...rest,
			};
		},
		currentResult() {
			const el_one = document.getElementById('resultOne');
			if (!el_one) return;
			if (!this.finalScore || !this.result) return;
			if (el_one.offsetWidth < 300) {
				this.showResultTooltipOne = false;
			} else {
				this.showResultTooltipOne = true;
			}

			const { result } = this;
			if (!result || !result.length) return null;
			return result
				.sort(({ period: periodA }, { period: periodB }) => periodA - periodB)
				.map(({ score, period }) => {
					const home = score.homeScore || '0';
					const away = score.awayScore || '0';
					return { period, home, away };
				});
		},
		playTimeResult() {
			const el_two = document.getElementById('resultTwo');
			if (!el_two) return;
			if (!this.finalScore || !this.result) return;
			if (el_two.offsetWidth < 300) {
				this.showResultTooltipTwo = false;
			} else {
				this.showResultTooltipTwo = true;
			}

			const { betScore } = this;
			if (!betScore) return null;
			return betScore.result.periodScores
				.sort(({ period: periodA }, { period: periodB }) => periodA - periodB)
				.map(({ score, period }) => {
					const home = score.homeScore || '0';
					const away = score.awayScore || '0';
					return { period, home, away };
				});
		},
		playTime() {
			const { betScore } = this;
			if (!betScore || !betScore.currentTime) return '';
			const [minutes] = betScore.currentTime.split(':');
			return `${minutes} '`;
		},
		currentTime() {
			const { listCode, live } = this;
			const event = live
				? this.$store.getters.liveEventData(listCode)
				: this.$store.getters.prematchEventData(listCode);
			if (event == null) return null;
			const { current_time = null } = event;
			if (current_time == null) return null;
			const [minutes] = current_time.split(':');
			return `${minutes} '`;
		},
		finalScore() {
			const { sport: { id: sportId = '' } = {}, result = [] } = this;
			return calculateFinalScore(result, sportId);
		},
		marketName() {
			const {
				marketsMap,
				sport: { id: sportId },
				odd: { id: oddId },
			} = this;
			const parts = oddId.split(':');
			const marketId = parts.length ? parts[0] + ':' + parts[1] : null;
			const { name = '' } = marketId && marketsMap ? marketsMap[sportId].markets.markets[marketId] : {};
			return name;
		},
		periodSymbol() {
			const { sport: { id: sportId = '' } = {} } = this;
			const data = sports[sportId];
			return data && data.period ? data.period : '';
		},
	},
};
</script>

<style scoped>
.tooltip_left td.tooltip_content {
	white-space: nowrap;
	position: absolute;
	min-width: 80px;
	font-size: 10px;
	text-transform: uppercase;
	background: #4e6375;
	height: 44px;
	line-height: 24px;
	text-align: center;
	visibility: hidden;
	border-radius: 4px;
	padding: 0 10px 0 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tooltip_left td.tooltip_content:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 100%;
	margin-top: -5px;
	width: 0;
	height: 0;
}

*:hover.tooltip_left td.tooltip_content {
	white-space: nowrap;
	visibility: visible;
	opacity: 0.95;
	right: 100%;
	top: 50%;
	margin-top: -12px;
	margin-right: 10px;
	z-index: 99999;
}

.white {
	color: #ffffff !important;
}

.ellipses {
	max-width: 200px !important;
	overflow: hidden !important;
	white-space: nowrap !important;
	text-overflow: ellipsis !important;
}
</style>
