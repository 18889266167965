<template>
	<div class="tab_nav lg padding_left_20 width_15">
		<div
			v-for="tab in tabs"
			:key="tab.id"
			:class="[{ active: activeTab === tab.id }, 'tab_nav_item']"
			@click="setActiveTab(tab.id)"
		>
			{{ tab.name }}
		</div>
	</div>
</template>

<script>
export default {
	props: ['activeTab', 'setActiveTab'],
	data() {
		return {
			tabs: [
				{ id: 'regular', name: this.$t('regular') },
				{ id: 'bonus', name: this.$t('bonus') },
			],
		};
	},
};
</script>

<style scoped>
.width_15 {
	width: 15%;
}
</style>
