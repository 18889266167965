import numeral from 'numeral';

export default {
	methods: {
		toMoneyFormat(number) {
			if (typeof number !== 'number') return numeral(0).format();
			return numeral(number).format();
		},

		oddsFormat(odds) {
			if (odds < 1000000000) return numeral(odds).format();
			return `> ${numeral(1000000000).format()}`;
		},
	},
};
