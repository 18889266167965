import { isEmpty } from 'lodash';
import Vue from 'vue';
import { api } from '../api';
import router from '../router';
import { normalizeSystem, compose, getGeoParamsData } from '../utils';
import i18n from '../localisation/i18n';

export default {
	state: {
		amount: 0,
		minOdd: 0.0,
		maxOdd: 0.0,
		bonus: 0,
		bonusPercentage: 0,
		minPayInValue: 0,
		winning: {
			minWinning: 0.0,
			maxWinning: 0.0,
		},
		combinations: 1,
		selectedRow: null,
		addedEvents: [],
		systems: [
			{
				events: [
					{
						isNew: true,
						id: 'new:event:0',
					},
				],
				required: [],
			},
		],
		riskCategories: {},
	},
	getters: {
		amount: ({ amount }) => amount,
		winning: ({ winning }) => winning,
		bonus: ({ bonus }) => bonus,
		bonusPercentage: ({ bonusPercentage }) => bonusPercentage,
		minPayInValue: ({ minPayInValue }) => minPayInValue,
		minMaxOdd: ({ minOdd, maxOdd, combinations }) => ({ minOdd, maxOdd, combinations }),
		// Get the systems from the state and turn them into the structure with rows
		systems: ({ systems }) => systems.map(normalizeSystem),
		// Return the event from the system
		ticketEvent: ({ systems }) => (systemIndex, eventIndex) => {
			const system = systems[systemIndex];
			return system && system.events[eventIndex];
		},

		selectedRow: ({ selectedRow }) => selectedRow,
		isEventAdded: ({ systems }) => (listCode) => {
			const events = systems.map(({ events }) => events).flat();
			const added = events.find((event) => event.listCode === listCode);
			return !!added;
		},

		lastSystemId: ({ systems }) => `sys:${systems.length - 1}:0`,
		combinations: ({ combinations }) => combinations,
	},
	mutations: {
		removeEmptyRows,
		setTicketCopy(state, [systems, amount]) {
			state.systems = systems;
			state.amount = amount;
			if (router.currentRoute.path !== '/') router.push('/');
		},
		updateTicketAmount(state, newAmount) {
			state.amount = newAmount;
		},
		/**
		 * Adds new empty system to the ticket
		 */
		addNewEmptySystem({ systems }) {
			if (systems.some(({ required }) => !required.length)) return;
			systems.push({
				events: [
					{
						isNew: true,
						id: 'new:event:' + systems.length,
					},
				],
				required: [],
			});
		},
		joinSystems: compose(removeEmptyRows, (state) => {
			let events = [];
			for (const system of state.systems) {
				const systemEvents = system.events.filter(({ isNew }) => !isNew);
				events = [...events, ...systemEvents];
			}
			state.systems = [{ events, required: [events.length] }];
		}),
		splitSystem: compose(removeEmptyRows, (state, { systemIndex, eventIndex }) => {
			const system = state.systems[systemIndex];
			if (!system) return;

			const events = system.events;
			const splitIndex = eventIndex + 1;

			const sys1events = events.slice(0, splitIndex);
			const sys2events = events.slice(splitIndex, events.length);
			const system1 = { events: sys1events, required: [sys1events.length] };
			const system2 = { events: sys2events, required: [sys2events.length] };

			const newSystems = [...state.systems];
			newSystems.splice(systemIndex, 1, system1, system2);
			Vue.set(state, 'systems', newSystems);
		}),
		clearSystems(state) {
			state.systems = [];
		},
		/**
		 * Removes the system from the ticket
		 */
		removeSystem(state, systemIndex) {
			// If system is not the only system on the ticket
			if (state.systems[1]) {
				// Remove system event listCodes from addedEvents
				state.systems[systemIndex].events.forEach(({ listCode }) => {
					state.addedEvents = state.addedEvents.filter((id) => id !== listCode);
				});

				state.systems.splice(systemIndex, 1);
			} else {
				state.addedEvents = [];
				state.systems = [{ events: [{ isNew: true, id: `new:event:0` }], required: [] }];
			}
		},
		/**
		 * Adds the new empty event to the system which will result
		 * in creating a new event row by the normalizeSystem function
		 */
		addNewEmptyEvent: compose(removeEmptyRows, ({ systems }, systemIndex) => {
			const system = systems[systemIndex];
			if (!system && !system.events) return;
			system.events.push({ isNew: true, id: `new:event:${systemIndex}` });
		}),
		/**
		 * Replaces the event in the ticket which has the provided eventId
		 * with the provided event
		 */
		replaceTicketEvent({ systems }, { systemIndex, eventId, newEvent }) {
			const system = systems[systemIndex];
			if (!system || !system.events || !eventId || !newEvent) return;
			let modifySystem = true;

			// Create newEvent without isNew property and with oddType in the odds array
			system.events = system.events.map((event) => {
				if (event.id !== eventId) return event;
				if (newEvent.isNew || newEvent.isEdit) {
					modifySystem = false;
				}
				return newEvent;
			});

			// Update the system form section
			if (modifySystem) {
				system.required = system.required.map((req) => req + 1);
				if (!system.required.length) system.required.push(1);
			}
		},
		/**
		 * Adds event id to addedEvents arrray
		 */
		addNewToAddedEvents(state, listCode) {
			state.addedEvents.push(listCode);
		},
		/**
		 * Adds event id to addedEvents arrray
		 */
		removeFromAddedEvents(state, listCode) {
			state.addedEvents = state.addedEvents.filter((id) => id !== listCode);
		},

		clearAddedEvents(state) {
			state.addedEvents = [];
		},
		/**
		 * Removes the event from the system
		 */
		removeTicketEvent(state, { systemIndex, eventId, listCode }) {
			const systems = state.systems;
			const system = systems[systemIndex];
			if (!system || !system.events || !eventId) return;

			state.addedEvents = state.addedEvents.filter((id) => id !== listCode);

			system.events = system.events.filter((event) => {
				if (event.id !== eventId) return true;

				return false;
			});

			if (!system.events.length) {
				if (systemIndex || systems[1]) state.systems.splice(systemIndex, 1);
				else state.systems = [{ events: [{ isNew: true, id: `new:event:0` }], required: [] }];
				return;
			}
		},
		/**
		 * Adds the new empty odd to the event with the provided id
		 */
		addNewEmptyOdd: compose(removeEmptyRows, ({ systems }, { systemIndex, eventIndex }) => {
			const system = systems[systemIndex];
			const event = system && system.events[eventIndex];
			// Prevent adding new empty odd if we have one already
			if (event && event.odds.length && event.odds[event.odds.length - 1].isNew) return;

			event && event.odds.push({ isNew: true, id: 'new:odd' });
		}),
		/**
		 * Replaces the odd in the event with the newOdd
		 */
		replaceEventOdd({ systems }, { systemIndex, eventIndex, oddId, newOdd }) {
			const system = systems[systemIndex];
			const event = system && system.events[eventIndex];
			if (!event || !event.odds) return;
			event.odds = event.odds.map((odd) => (odd.id === oddId ? newOdd : odd));
		},
		/**
		 * Removes the odd from the event
		 */
		removeEventOdd({ systems }, { systemIndex, eventIndex, oddId }) {
			const system = systems[systemIndex];
			const event = system && system.events[eventIndex];
			if (!event || !event.odds || !oddId) return;

			event.odds = event.odds.filter(({ id }) => id !== oddId);
		},
		/**
		 * Adds the new sub-system to the system.required array
		 */
		addNewSubSystem({ systems }, systemIndex) {
			const system = systems[systemIndex];
			const all = [...Array(system.events.length + 1).keys()];
			let difference = all.filter((x) => !system.required.includes(x)).sort((a, b) => b - a);
			const next = difference.length ? difference[0] : null;
			system && next && system.required.push(next);
		},
		/**
		 * Removes the sub-system from the system.required array
		 */
		removeSubSystem({ systems }, [systemIndex, subSystemIndex]) {
			const system = systems[systemIndex];
			if (!system) return;
			system.required = system.required.filter((_, i) => i != subSystemIndex);
		},
		/**
		 * Updates the sub-system
		 */
		updateSubSystem({ systems }, { systemIndex, subSystemIndex, value }) {
			let { events, required } = systems[systemIndex] || {};
			if (!events || !required) return;

			value = +value;
			if (events.length < value) value = events.length;
			if (events.length) required[subSystemIndex] = value > 0 ? value : required.length === 1 ? events.length : 0;
		},
		/**
		 * Remove not numbers and duplicate values from sub-systems
		 */

		cleanSubSystems: compose(removeEmptyRows, ({ systems }) => {
			systems.forEach((system, systemIndex) => {
				if (systemIndex === 0 && system.events.length === 1) {
					if (system.events[0].id === 'new:event:0') return;
				}

				const onlyNumbers = system.required.filter(
					(val) => val && typeof +val === 'number' && val > 0 && val <= system.events.length
				);

				const unique = onlyNumbers.length ? Array.from(new Set(onlyNumbers)) : [system.events.length];
				system.required = unique;
			});
		}),

		updateMinMax(state, { minOdd, maxOdd, combinations, maxPayout }) {
			state.minOdd = minOdd;
			state.maxOdd = maxOdd;

			if (state.amount) {
				let perCombination = state.amount / combinations;
				if (perCombination === Infinity) perCombination = 0;

				let minWin = perCombination * minOdd + state.bonus;
				let maxWin = perCombination * maxOdd + state.bonus;
				if (maxPayout && typeof maxPayout === 'number' && maxWin > maxPayout) maxWin = maxPayout;
				if (minWin > maxWin) minWin = maxWin;
				state.winning = {
					minWinning: minWin,
					maxWinning: maxWin,
				};
			} else {
				state.winning = {
					minWinning: 0.0,
					maxWinning: 0.0,
				};
			}
			state.combinations = combinations;
		},

		updateBonus(state, { maxOdd, combinations, systems }) {
			const isAllLive = systems.every(({ events }) => events.every(({ live = true }) => live));
			const isAllPrematch = systems.every(({ events }) => events.every(({ live = false }) => !live));

			let bonusMinQuota;
			let bonusMinPayIn;
			let bonusRules;
			let bonusAvailableInBonusType;

			let isDefaultBonusParams = false;

			const bonusAccumulatorMinQuota = getGeoParamsData(['BONUSES', 'ACCUMULATOR', 'ACCUMULATOR_MIN_QUOTA']) ?? 1;
			const bonusAccumulatorMinPayIn =
				getGeoParamsData(['BONUSES', 'ACCUMULATOR', 'ACCUMULATOR_MIN_PAYMENT']) ?? 0.001;
			const bonusAccumulatorRules = getGeoParamsData(['BONUSES', 'ACCUMULATOR', 'ACCUMULATOR_RULES']) ?? '';
			const bonusAccumulatorAvailableInBonusType =
				getGeoParamsData(['BONUSES', 'ACCUMULATOR', 'ACCUMULATOR_IN_BONUS_TYPE']) ?? false;

			switch (true) {
				case isAllLive && geoParams.BONUSES.BONUS_BOOST?.LIVE_BONUS_BOOST_ACTIVE:
					bonusMinQuota = getGeoParamsData(['BONUSES', 'BONUS_BOOST', 'LIVE_BONUS_BOOST_MIN_QUOTA']) ?? 1;
					bonusMinPayIn = getGeoParamsData(['BONUSES', 'BONUS_BOOST', 'LIVE_BONUS_BOOST_MIN_PAYMENT']) ?? 0.001;
					bonusRules = getGeoParamsData(['BONUSES', 'BONUS_BOOST', 'LIVE_BONUS_BOOST_RULES']) ?? '';
					bonusAvailableInBonusType = false;
					break;

				case isAllPrematch && geoParams.BONUSES.BONUS_BOOST?.PREMATCH_BONUS_BOOST_ACTIVE:
					bonusMinQuota = getGeoParamsData(['BONUSES', 'BONUS_BOOST', 'PREMATCH_BONUS_BOOST_MIN_QUOTA']) ?? 1;
					bonusMinPayIn =
						getGeoParamsData(['BONUSES', 'BONUS_BOOST', 'PREMATCH_BONUS_BOOST_MIN_PAYMENT']) ?? 0.001;
					bonusRules = getGeoParamsData(['BONUSES', 'BONUS_BOOST', 'PREMATCH_BONUS_BOOST_RULES']) ?? '';
					bonusAvailableInBonusType =
						getGeoParamsData(['BONUSES', 'BONUS_BOOST', 'PREMATCH_BONUS_BOOST_IN_BONUS_TYPE']) ?? false;
					break;

				default:
					isDefaultBonusParams = true;
					bonusMinQuota = getGeoParamsData(['BONUSES', 'ACCUMULATOR', 'ACCUMULATOR_MIN_QUOTA']) ?? 1;
					bonusMinPayIn = getGeoParamsData(['BONUSES', 'ACCUMULATOR', 'ACCUMULATOR_MIN_PAYMENT']) ?? 0.001;
					bonusRules = getGeoParamsData(['BONUSES', 'ACCUMULATOR', 'ACCUMULATOR_RULES']) ?? '';
					bonusAvailableInBonusType =
						getGeoParamsData(['BONUSES', 'ACCUMULATOR', 'ACCUMULATOR_IN_BONUS_TYPE']) ?? false;
			}

			state.minPayInValue = bonusMinPayIn;

			const { amount } = state;
			if (!bonusRules || !bonusMinQuota || !bonusMinPayIn || combinations > 1) {
				state.bonus = 0;
				state.bonusPercentage = 0;
				return;
			}
			const events = systems[0].events;
			let validOdds = events
				.filter(({ isNew, odds }) => !isNew && odds[0] && (odds[0].value || odds[0].stake) >= bonusMinQuota)
				.map(({ odds }) => odds[0]);
			if (!bonusAvailableInBonusType) {
				validOdds = validOdds.filter(({ bonusType }) => !bonusType);
			}
			let bonusPercentage = (
				bonusRules
					.sort((a, b) => b.number_of_events - a.number_of_events)
					.find(({ number_of_events }) => validOdds.length >= number_of_events) || { percentage: 0 }
			).percentage;

			state.bonusPercentage = bonusPercentage;

			if (bonusPercentage > 0 && amount && amount >= bonusMinPayIn) {
				state.bonus = state.bonusPercentage * (maxOdd * amount) || 0;
			} else if (!isDefaultBonusParams) {
				validOdds = events
					.filter(
						({ isNew, odds }) => !isNew && odds[0] && (odds[0].value || odds[0].stake) >= bonusAccumulatorMinQuota
					)
					.map(({ odds }) => odds[0]);
				if (!bonusAccumulatorAvailableInBonusType) {
					validOdds = validOdds.filter(({ bonusType }) => !bonusType);
				}
				const bonusAccumulatorPercentage = (
					bonusAccumulatorRules
						.sort((a, b) => b.number_of_events - a.number_of_events)
						.find(({ number_of_events }) => validOdds.length >= number_of_events) || { percentage: 0 }
				).percentage;

				if (bonusAccumulatorPercentage > 0 && amount && amount >= bonusAccumulatorMinPayIn) {
					state.minPayInValue = bonusAccumulatorMinPayIn;
					state.bonusPercentage = bonusAccumulatorPercentage;
					state.bonus = state.bonusPercentage * (maxOdd * amount) || 0;
				} else if (bonusPercentage === 0 && bonusAccumulatorPercentage > 0) {
					state.minPayInValue = bonusAccumulatorMinPayIn;
					state.bonusPercentage = bonusAccumulatorPercentage;
					state.bonus = 0;
				} else {
					state.bonus = 0;
				}
			} else {
				state.bonus = 0;
			}
		},
		selectRow(state, { row, isLastRow }) {
			state.selectedRow = {
				...row,
				isLastRow,
			};
		},
		clearSelectedRow(state) {
			state.selectedRow = null;
		},

		removeSystems(state) {
			state.systems = [
				{
					events: [
						{
							isNew: true,
							id: 'new:event:0',
						},
					],
					required: [],
				},
			];
		},
	},
	actions: {
		populateTicketWithCopy({ rootGetters, commit }, copy = {}) {
			if (!copy || !copy.systems) return;

			commit('clearAddedEvents');

			const getLiveEvent = this.getters.liveEventDataById;
			const getPrematchEvent = this.getters.prematchEventDataById;
			const getOdds = rootGetters.getUpdatedEventOdds;

			let systems = [];
			copy.systems.forEach(({ events = [], required = [] }) => {
				let updatedEvents = [];

				events.forEach(({ id, odds, live }) => {
					const event = live ? getLiveEvent(id) : getPrematchEvent(id);
					if (event) {
						updatedEvents.push({
							...event,
							odds: getOdds(odds, event.listCode ?? event.list_code, event.live).map((odd) => ({
								...odd,
								name: `${odd.bonusType ? 'B ' : ''}${odd.name}`,
							})),
						});
						commit('addNewToAddedEvents', event.listCode);
					}
				});

				const len = updatedEvents.length;
				const updatedRequired = required.map((req) => (req > len ? len : req));
				len && systems.push({ events: updatedEvents, required: updatedRequired });
			});

			if (systems.length) commit('setTicketCopy', [systems, copy.originalAmount || copy.amount]);
			else commit('setTicketCopy', [[{ events: [{ isNew: true, id: `new:event:0` }], required: [] }], 0.0]);
		},

		async populateExpressTicket(context, ticket = {}) {
			if (isEmpty(ticket) || isEmpty(ticket.systems)) return;
			const { rootGetters, commit, dispatch } = context;
			commit('clearAddedEvents');

			const getLiveEvent = this.getters.liveEventDataById;
			const getPrematchEvent = this.getters.prematchEventDataById;
			const getOdds = rootGetters.eventOddsData;

			let eventsData = [];

			try {
				const events = ticket.systems.map(({ events }) => events.map(({ id }) => id)).join(',');
				const markets = ticket.systems.map((system) =>
					system.events.map(({ odds }) => {
						const oddsKeys = Object.keys(odds);
						return oddsKeys.map((key) => {
							const parts = key.split(':');
							return `${parts[0]}`;
						});
					})
				);
				const uniqueMarkets = markets.filter((value, index, array) => array.indexOf(value) === index).join(',');
				const { events: loadedEvents } = await api.getEventsData({ events, markets: uniqueMarkets });
				eventsData = loadedEvents;
			} catch (err) {
				console.error(err);
			}

			const systems = await Promise.all(
				ticket.systems.map(async ({ events = [], required = [] }) => {
					const updatedEvents = await Promise.all(
						events.map(async ({ id, odds }) => {
							let event = getLiveEvent(id) ?? getPrematchEvent(id);
							if (isEmpty(event)) {
								const loadedEvent = eventsData.find(({ id: loadedId }) => loadedId === id);
								if (!isEmpty(loadedEvent)) {
									commit('updateEvent', [loadedEvent.listCode, loadedEvent.live, loadedEvent], { root: true });
									event = loadedEvent;
								}
							}
							if (isEmpty(event)) {
								event = await api.getEvent(id);
								event = { ...event, utc_scheduled: event.scheduled };
							}
							return {
								...event,
								odds: getOdds(odds).map((odd) => ({ ...odd, name: `${odd.bonusType ? 'B ' : ''}${odd.name}` })),
							};
						})
					);

					updatedEvents.forEach(({ listCode }) => commit('addNewToAddedEvents', listCode));

					return {
						events: updatedEvents,
						required,
					};
				})
			);

			if (systems.length) commit('setTicketCopy', [systems, ticket.amount]);
			else commit('setTicketCopy', [[{ events: [{ isNew: true, id: `new:event:0` }], required: [] }], 0.0]);
		},

		async calculateMinMax({ commit, state }, { minOdd, maxOdd, combinations, systems }) {
			const maxPayoutPerTicket = getGeoParamsData(['BETTING', 'PREMATCH', 'MAX_PAYOUT_PER_TICKET']) || null;
			const maxPayoutPerLiveTicket = getGeoParamsData(['BETTING', 'LIVE', 'MAX_PAYOUT_PER_LIVE_TICKET']) || null;
			const tournamentCategorisation = getGeoParamsData(['RISK', 'CATEGORISATION', 'TOURNAMENT_CATEGORISATION']);

			let categories = [];
			let undefinedRisks = [];
			state.systems.forEach(({ events }) =>
				events.forEach(({ isNew, tournament: { id = '', id_risk_category } = {} }) => {
					if (!isNew) {
						if (id_risk_category === undefined) {
							const existsInTemp = state.riskCategories[id];
							if (existsInTemp !== undefined) {
								if (existsInTemp && !categories.includes(existsInTemp)) {
									categories.push(existsInTemp);
								}
							} else undefinedRisks.push(id);
						}
						if (id_risk_category && !categories.includes(id_risk_category)) {
							categories.push(id_risk_category);
						}
					}
				})
			);

			if (undefinedRisks.length) {
				const params = {
					idTournaments: undefinedRisks,
					lang: i18n.locale || 'en',
				};
				const data = await api.getTournamentRiskCategories(params);
				const missingCategories = Object.values(data);
				if (missingCategories.length) {
					missingCategories.forEach(({ id, idRiskCategory }) => {
						state.riskCategories[id] = idRiskCategory;
						if (idRiskCategory && !categories.includes(idRiskCategory)) {
							categories.push(idRiskCategory);
						}
					});
				}
			}

			let tournamentPayoutLimit = null;

			categories.forEach((riskCategory) => {
				const payoutLimit = (
					(Array.isArray(tournamentCategorisation) &&
						tournamentCategorisation.find(({ name }) => name === riskCategory)) || {
						max_payout: null,
					}
				).max_payout;
				if (payoutLimit && (tournamentPayoutLimit === null || payoutLimit < tournamentPayoutLimit)) {
					tournamentPayoutLimit = payoutLimit;
				}
			});

			const isLive = state.systems.some(({ events }) => events.some(({ live }) => live));

			let maxPayout = null;

			if (isLive) {
				if (tournamentPayoutLimit && maxPayoutPerLiveTicket) {
					maxPayout =
						tournamentPayoutLimit < maxPayoutPerLiveTicket ? tournamentPayoutLimit : maxPayoutPerLiveTicket;
				} else {
					if (tournamentPayoutLimit) maxPayout = tournamentPayoutLimit;
					if (maxPayoutPerLiveTicket) maxPayout = maxPayoutPerLiveTicket;
				}
			} else {
				if (tournamentPayoutLimit && maxPayoutPerTicket) {
					maxPayout = tournamentPayoutLimit < maxPayoutPerTicket ? tournamentPayoutLimit : maxPayoutPerTicket;
				} else {
					if (tournamentPayoutLimit) maxPayout = tournamentPayoutLimit;
					if (maxPayoutPerTicket) maxPayout = maxPayoutPerTicket;
				}
			}

			commit('updateBonus', { maxOdd, combinations, systems });

			commit('updateMinMax', {
				minOdd,
				maxOdd,
				combinations,
				maxPayout,
			});
		},

		removeSystems({ commit }) {
			commit('removeSystems');
		},
	},
};

/**
 * Reuseable mutations
 */
function removeEmptyRows(state) {
	const notNew = ({ isNew }) => !isNew;
	const hasEvents = ({ events }) => events.length;
	const cleanOdds = ({ odds, ...event }) => ({ ...event, odds: odds.filter(notNew) });
	const emptySystem = [{ events: [{ isNew: true, id: `new:event:0` }], required: [] }];
	const systems = state.systems
		.map(({ events, required, ...system }) => {
			const newEvents = events.filter((event) => !event.isNew || (event.odds && event.odds.length)).map(cleanOdds);
			let lowerReqBy = 0;
			events.forEach((event) => {
				lowerReqBy = event.isNew && event.odds ? lowerReqBy + 1 : lowerReqBy;
			});
			return {
				...system,
				required: required.map((req) => req - lowerReqBy),
				events: newEvents,
			};
		})
		.filter(hasEvents);
	state.systems = systems.length ? systems : emptySystem;
}
