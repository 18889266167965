export const formatTicketPromotions = (ticket, language, offerPlan, date, companyName) => {
	const ticketPromotions = Object.values(ticket.promotion_data).map((promotion) => {
		return {
			amount: promotion.amount ?? null,
			PromotionPin: promotion.redeem_code,
			promotionTag: promotion.tag,
			PromotionPictureLink: promotion.tag_image,
			PromotionPictureLink120: promotion.tag_image_120,
			PromotionName: promotion.name[language] ?? '',
			Round: offerPlan,
			Barcode: ticket.barcode,
			PayInDate: date,
			CompanyName: companyName ?? '',
			PromotionNote: promotion.description[language] ?? '',
		};
	});

	return ticketPromotions;
};
