<template>
	<div class="modal_head">
		<span class="svg_icon tooltip_right">
			<!-- <svg class="sport_ico">
				<use :xlink:href="icon" />
			</svg> -->
			<span class="">{{ sportName.substring(0, 3) }}</span>
			<span class="tooltip_content">{{ sportName }}</span>
		</span>

		<div class="event_time">
			<span class="date">{{ time }}</span>
		</div>

		<div class="competetors">
			<span class="home">{{ home }}</span>
			<span class="vs">{{ away && '-' }}</span>
			<span class="away">{{ away }}</span>
		</div>

		<div class="tournament">{{ tournament }}</div>
	</div>
</template>

<script>
import { computedEvent, createEditEvent } from '../../../mixins';

export default {
	mixins: [computedEvent, createEditEvent],
	props: ['event', 'row'],
};
</script>
