import { EVENT_RESULT, EVENT_CHANGE, EVENT_CHANGE_LIVE, EVENT_CHANGE_PREMATCH, BET_ODDS } from '../../consts/messages'
import settings from '../../settings.json'
import {parse} from '..'
import { remapMinified } from '../remappers';
import { applyOddsChangeMessageOnEvents, applyEventChangeMessageOnEvents, applyResultChangeMessageOnLiveEvents, applyEventChangeLiveOnLiveEvents, applyEventChangePrematchOnLiveEvents } from './messageHandlers'


const minificationEnabled = settings.socketMessagesMinification;


export const applyMessagesOnLiveEvents = (events, messages) => {
    messages.forEach(message => {
        const parsed = parse(message);
        if (!parsed || typeof parsed !== 'object' || !parsed.data) return;
    
        let data = parsed.data;
        if (minificationEnabled) {
            data = remapMinified(message.type, data);
        }

        switch (message.type) {
            case BET_ODDS: {
                applyOddsChangeMessageOnEvents(events, data)
                break
            }
            case EVENT_CHANGE: {
                applyEventChangeMessageOnEvents(events, data)
                break
            }
            case EVENT_RESULT: {
                applyResultChangeMessageOnLiveEvents(events, data)
                break
            }
            case EVENT_CHANGE_LIVE: {
                applyEventChangeLiveOnLiveEvents(events, data)
                break
            }
            case EVENT_CHANGE_PREMATCH: {
                applyEventChangePrematchOnLiveEvents(events, data)
                break
            }
        }
        
    })
}

