<template>
	<div class="modal_cover full_w modal_05 toggle" @keyup.esc="closeAuthModal">
		<div class="modal_content fadeInDown animated faster">
			<button class="btn transparent close toggle_modal_05" @click="closeAuthModal">
				<i class="mdi mdi-close"></i>
			</button>

			<TicketInfo v-bind="ticket" :cashoutTicketOriginaAmount="cashoutTicketOriginaAmount" />

			<div class="modal_body content_start direction_row">
				<div class="ticket_event_list" style="flex-wrap: wrap;">
					<PassThrough :states="[prevItems, items]">
						<table slot-scope="{ final }" v-if="final" class="events">
							<component
								v-for="({ key, removed, added, ...item }, i) in final"
								:key="key"
								:is="item.type"
								:index="i"
								:removed="removed"
								:added="added"
								:event="item"
								:oldEvent="item.oldEvent"
							/>
						</table>
					</PassThrough>
				</div>
				<Sidebar :ticket="ticket" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { keyBy } from 'lodash';
import TicketInfo from './TicketInfo';
import Sidebar from './Sidebar';
import Event from './Event';
import Odd from './Odd';
import { extractItems } from '../../../utils';
import { PassThrough } from '../../Memoization';

export default {
	components: {
		TicketInfo,
		Event,
		Odd,
		Sidebar,
		PassThrough,
	},
	computed: {
		...mapGetters(['authModalData', 'authTickets', 'cashoutTickets']),
		ticket() {
			const { authTickets, cashoutTickets, authModalData } = this;

			return (
				authTickets.find((ticket) => ticket.id === authModalData.ticketId) ||
				cashoutTickets.find((ticket) => ticket.id === authModalData.ticketId)
			);
		},

		cashoutTicketOriginaAmount() {
			const { cashoutTickets, authModalData } = this;

			const cashoutTicket = cashoutTicket?.originalAmount
				? cashoutTickets.find((ticket) => ticket.id === authModalData.ticketId)
				: 0;

			return cashoutTicket.originalAmount;
		},

		prevItems() {
			const { prev } = this.ticket || {};
			if (!prev) return null;

			const events = prev.systems.map(({ events }) => events).flat();
			return events.reduce(extractItems, []);
		},
		items() {
			const { ticket, prevItems } = this;
			if (!ticket) return;

			const currentItems = ticket.systems
				.map(({ events }) => events)
				.flat()
				.reduce(extractItems, []);
			const itemMap = keyBy(currentItems, 'key');
			const items = !prevItems || !prevItems.length ? currentItems : prevItems;

			const finalItems = [];

			items.forEach(({ key, ...prevItem }) => {
				if (itemMap[key]) {
					//handle situation when first oddtype from event was unchecked, so second oddtype from original ticket
					//becomes first oddtype resulting different array from extractItems function response.
					if (itemMap[key].type === 'odd' && prevItem.type === 'event') {
						finalItems.push({ key, ...prevItem, odds: { [itemMap[key]?.id]: { ...itemMap[key] } } });
					} else if (itemMap[key].type === 'event' && prevItem.type === 'odd') {
						finalItems.push({
							...prevItem,
							...Object.values(itemMap[key]?.odds)[0],
							type: prevItem.type,
							listCode: itemMap[key]?.listCode ?? itemMap[key]?.list_code,
							oldEvent: prevItem,
						});
					} else {
						finalItems.push({ ...itemMap[key], listCode: prevItem.listCode });
					}
				} else {
					const oddsForCurrentEvent = Object.values(itemMap)
						.filter((item) => {
							return item?.key?.split('|')[0] === key?.split('|')[0];
						})
						.find(
							(item) =>
								!finalItems.find((final) => final.key === item.key) &&
								!items.find((oldItem) => item.key === oldItem.key)
						);

					if (!oddsForCurrentEvent) {
						finalItems.push({ key, ...prevItem, removed: true });
						return;
					}

					if (prevItem.type === 'event' && oddsForCurrentEvent.type === 'odd') {
						finalItems.push({
							...prevItem,
							key: oddsForCurrentEvent?.key,
							odds: { [oddsForCurrentEvent.key]: oddsForCurrentEvent },
							oldEvent: { key, ...prevItem },
						});
					} else {
						finalItems.push({
							...oddsForCurrentEvent,
							listCode: prevItem.listCode,
							oldEvent: { key, ...prevItem },
						});
					}
				}
			});

			return finalItems;
		},
	},
	methods: mapMutations(['closeAuthModal']),
};
</script>

<style></style>
