<template>
	<td class="odd_type_description padding_left_25 text_right">
		{{ description }} <span v-if="specialValue">| {{ specialValue }}</span>
	</td>
</template>

<script>
import { memoize } from '../../Memoization';
export default {
	mixins: [memoize('specialValue', 3000)],
	props: ['name'],
	computed: {
		specialValue() {
			const [_, value] = this.name.split('|');
			return value;
		},

		description() {
			const [desc] = this.name.split('|');
			return desc;
		},
	},
};
</script>

<style scoped>
.selected {
	border: 2px solid #feb200;
	background-color: #feb200;
	color: white;
}

.up {
	background-color: green;
	color: white;
	border: 1px solid white;
}
.down {
	background-color: red;
	color: white;
	border: 1px solid white;
}
.warn {
	background-color: orange;
	color: white;
	border: 1px solid white;
}
</style>
