function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal_cover full_w modal_05 toggle",on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAuthModal($event)}}},[_c('div',{staticClass:"modal_content fadeInDown animated faster"},[_c('button',{staticClass:"btn transparent close toggle_modal_05",on:{"click":_vm.closeAuthModal}},[_c('i',{staticClass:"mdi mdi-close"})]),_c('TicketInfo',_vm._b({attrs:{"cashoutTicketOriginaAmount":_vm.cashoutTicketOriginaAmount}},'TicketInfo',_vm.ticket,false)),_c('div',{staticClass:"modal_body content_start direction_row"},[_c('div',{staticClass:"ticket_event_list",staticStyle:{"flex-wrap":"wrap"}},[_c('PassThrough',{attrs:{"states":[_vm.prevItems, _vm.items]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var final = ref.final;
return (final)?_c('table',{staticClass:"events"},_vm._l((final),function(ref,i){
var key = ref.key;
var removed = ref.removed;
var added = ref.added;
var rest = objectWithoutProperties( ref, ["key", "removed", "added"] );
var item = rest;
return _c(item.type,{key:key,tag:"component",attrs:{"index":i,"removed":removed,"added":added,"event":item,"oldEvent":item.oldEvent}})}),1):_vm._e()}}],null,true)})],1),_c('Sidebar',{attrs:{"ticket":_vm.ticket}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }