export const ODD_ITEM_TYPE = 'odd';
export const EVENT_ITEM_TYPE = 'event';

export function extractItems(items, event) {
	const oddsArray = Object.entries(event.odds).map(([id, odd]) => ({ id, ...odd }));
	const [{ id: firstOddId, ...firstOdd }, ...restOdds] = oddsArray;

	const odds = restOdds.map((odd) => ({
		key: `${event.id}${odd.id}`,
		sport: event.sport,
		type: ODD_ITEM_TYPE,
		eventId: event.id,
		...odd,
	}));

	const item = {
		...event,
		key: `${event.id}${firstOddId}`,
		type: EVENT_ITEM_TYPE,
		odds: {
			[firstOddId]: firstOdd,
		},
	};

	// If there is only one odd add event to the items array
	// Otherwise add both event and the odds to the items array
	if (!odds.length) return [...items, item];
	return [...items, item, ...odds];
}
