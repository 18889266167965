import { formatDate } from '../utils';

export default {
	computed: {
		systemIndex() {
			const { systemIndex } = this.row;
			return systemIndex;
		},
		isLastRow() {
			const { row = {}, system = { rows: [] } } = this;
			if ('isLastRow' in row) return row.isLastRow;
			return row.rowIndex === system.rows.length - 1;
		},
		oddIndex() {
			const { oddIndex } = this.row;
			return oddIndex;
		},
		eventId() {
			const { row = {}, event = {} } = this;
			return row.eventId || event.id;
		},
		bonusOdd() {
			return (this.row && this.row.odd && this.row.odd.bonusType) || false;
		},
		eventIndex() {
			const { eventIndex } = this.row;
			return eventIndex;
		},
		listCode() {
			const { event = {}, row = {} } = this;
			return event.listCode || row.eventListCode;
		},
		time() {
			const { utc_scheduled } = this.event || {};
			if (!Date.parse(utc_scheduled)) return;
			const [formatted] = formatDate(utc_scheduled, 'day hours:minutes');
			return formatted;
		},
		home() {
			const { competitors = [{}] } = this.event || {};
			return (competitors[0] && competitors[0].name) || '';
		},
		away() {
			const { competitors = [{}, {}] } = this.event || {};
			return (competitors[1] && competitors[1].name) || '';
		},
		odd() {
			const { odd = {}, event = {} } = this.row || {};

			// Case for EventOdd component
			if (odd) return odd;

			// Case for Event component
			const { odds } = event;
			const isArray = Array.isArray(odds);
			return isArray ? odds[0] : {};
		},
		specialValue() {
			const { specialValue, special_value } = this.odd;
			if (+specialValue !== 0) return specialValue;
			if (+special_value !== 0) return special_value;
			return '';
		},
		sportName() {
			const { sport = {} } = this.event || {};
			return sport.name || '';
		},
		sportId() {
			const { sport = {} } = this.event || {};
			return sport.id || '';
		},
		icon() {
			const { sport = {} } = this.event || {};
			return `/svg/sprite_sport_icons.svg#${sport.id || ''}`;
		},
		tournament() {
			const { tournament = {}, special_type } = this.event || {};

			return `${
				this.odd && special_type && this.odd.marketName
					? `  ${`${this.odd.marketName} ${special_type === 'MIX' ? '- MIX ' : ''}`}`
					: `${tournament.name || ''}`
			}`;
		},
		condition() {},
	},
};
