<template>
	<div>
		<!-- Time -->
		<td class="p_0 small without_border">
			<i v-show="event.live" class="mdi mdi-play-circle tooltip_left live_event padding_right_10 ">
				<span class="tooltip_content">{{ $t('liveMatch') }}</span>
			</i>
			<span :memo="[scheduledTime]" :class="event.live ? '' : 'padding_left_30'">{{ scheduledTime }}</span>
		</td>
	</div>
</template>

<script>
import { formatDate } from '../../utils';

export default {
	props: ['event'],
	computed: {
		scheduledTime() {
			const event = this.event;
			const { listCode, live } = event || {};
			let utc_scheduled;
			if (live) {
				utc_scheduled = this.$store._modules.root.state.dataState.liveEvents[listCode]?.utc_scheduled;
			} else {
				utc_scheduled = this.$store._modules.root.state.dataState.prematchEvents[listCode]?.utc_scheduled;
			}
			if (!utc_scheduled) utc_scheduled = event.utc_scheduled;

			if (!Date.parse(utc_scheduled)) return;
			const [formatted] = formatDate(utc_scheduled, 'day hours:minutes');

			return formatted;
		},
	},
};
</script>
