import Vue from 'vue';
import Vuex from 'vuex';
import modals from './modals';
import dataState from './data';
import ticketState from './ticket';
import authorizationState from './authorization';
import messagesState from './messages';
import focusDataState from './focusData';
import switcherState from './switcher';
import triggerPayoutState from './triggerPayout';
import notificationIsOpenState from './notificationIsOpen';
import bonusVoucher from './bonusVoucher';

import { minMaxPlugin } from '../utils';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		options: {
			savePayin: false,
			saveOdd: false,
			calculatorEnabled: false,
			manualAuthorization: false,
		},
		calculator: {
			numOfTickets: 0,
			sum: 0,
		},

		// TEST
		newEventRow: null,
	},
	mutations: {
		toggleSavePayin(state) {
			if (state.options.savePayin) {
				state.ticketState.amount = null;
			}
			state.options.savePayin = !state.options.savePayin;
		},
		toggleSaveOdd({ options }) {
			options.saveOdd = !options.saveOdd;
		},
		toggleCalculator({ options, calculator }) {
			if (options.calculatorEnabled) {
				options.calculatorEnabled = false;
				calculator.numOfTickets = 0;
				calculator.sum = 0;
			} else {
				options.calculatorEnabled = true;
			}
		},
		toggleManualAuthorization({ options }) {
			options.manualAuthorization = !options.manualAuthorization;
		},
		closeNewEventRow(state) {
			state.newEventRow = null;
			state.newOddRow = null;
		},
		updateCalculator(state, newValues) {
			state.calculator = newValues;
		},
	},
	getters: {
		options: ({ options }) => ({ ...options }),
		calculator: ({ calculator }) => ({ ...calculator }),
		// TEST
		newEventRow: ({ newEventRow }) => (systemId) => newEventRow === systemId,
	},
	actions: {},
	plugins: [minMaxPlugin],
	modules: {
		dataState,
		ticketState,
		modals,
		authorizationState,
		messagesState,
		focusDataState,
		switcherState,
		triggerPayoutState,
		notificationIsOpenState,
		bonusVoucher,
	},
});
