<template>
	<div class="app_main">
		<div class="content_wrapper">
			<!-- Content -->
			<div class="app_content">
				<table class="events" v-if="!loadingData.active">
					<Systems />
				</table>
				<loading v-else :error="loadingData.error" :reload_btn_visible="true"/>
			</div>

			<!-- Footer -->
			<div class="app_footer">
				<Favorites v-if="selectedRow" />

				<!-- Authorization -->
				<AuthorizationLine />
			</div>
		</div>

		<!-- Sidebar -->
		<Sidebar />
		<portal-target name="auth-confirmation" />
		<portal-target name="bonus-voucher" />
		<EventsModal v-if="eventsModalData.isOpen" />
		<OddsModal v-if="oddsModalData.isOpen" />
		<LiveOddsModal v-if="liveOddsModalData.isOpen" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Systems from '../components/Systems';
import Favorites from '../components/Favorites';
import EventsModal from '../components/Modals/EventsModal';
import OddsModal from '../components/Modals/OddsModal';
import LiveOddsModal from '../components/Modals/LiveOddsModal';
import AuthorizationLine from '../components/AuthorizationLine';
import Loading from '../components/Loading.vue';

export default {
	components: {
		Header,
		Sidebar,
		Systems,
		Favorites,
		EventsModal,
		OddsModal,
		AuthorizationLine,
		LiveOddsModal,
		Loading,
	},
	computed: mapGetters(['eventsModalData', 'oddsModalData', 'liveOddsModalData', 'selectedRow', 'loadingData']),
};
</script>
