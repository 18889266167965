import { select, selectDelayed } from '../utils';

export default {
	state: {
		eventsModal: {
			isOpen: false,
			searchQuery: '',
			systemIndex: null,
			rowId: null,
			eventId: null,
			listCode: null,
			live: false,
			isNew: null,
			isEdit: null,
		},
		oddsModal: {
			isOpen: false,
		},
		notificationModal: {
			isOpen: false,
		},
		liveOddsModal: {
			isOpen: false,
			eventId: null,
		},
		authModal: {
			ticketId: null,
		},
		confirmationModal: {
			isOpen: false,
			title: '',
			text: '',
			confirmAction: () => {},
		},
		registrationModalOpened: false,
		depositModalOpened: false,
		withdrawalModalOpened: false,
		expressTicketOpened: false,
		operatorPanelInfoModalOpened: false,
		lastSelectedId: 0,
	},
	getters: {
		eventsModalData: ({ eventsModal }) => ({ ...eventsModal }),
		oddsModalData: ({ oddsModal }) => ({ ...oddsModal }),
		liveOddsModalData: ({ liveOddsModal }) => ({ ...liveOddsModal }),
		authModalData: ({ authModal }) => ({ ...authModal }),
		registrationModalOpened: ({ registrationModalOpened }) => registrationModalOpened,
		depositModalOpened: ({ depositModalOpened }) => depositModalOpened,
		withdrawalModalOpened: ({ withdrawalModalOpened }) => withdrawalModalOpened,
		confirmationModalOpened: ({ confirmationModal: { isOpen } }) => isOpen,
		expressTicketOpened: ({ expressTicketOpened }) => expressTicketOpened,
		operatorPanelInfoModalOpened: ({ operatorPanelInfoModalOpened }) => operatorPanelInfoModalOpened,
		confirmationModalData: ({ confirmationModal: { title, text, confirmAction } }) => ({
			title,
			text,
			confirmAction,
		}),
		lastSelectedId: ({ lastSelectedId }) => lastSelectedId,
		activeModal: (state) =>
			state.eventsModal.isOpen ||
			state.oddsModal.isOpen ||
			state.authModal.ticketId ||
			state.registrationModalOpened ||
			state.depositModalOpened ||
			state.withdrawalModalOpened ||
			state.expressTicketOpened ||
			state.operatorPanelInfoModalOpened ||
			state.liveOddsModal.isOpen ||
			state.notificationModal.isOpen ||
			state.confirmationModal.isOpen,
	},
	mutations: {
		openEventsModal(state, { listCode = null, ...rest }) {
			state.eventsModal = {
				isOpen: true,
				listCode,
				...rest,
			};
		},
		closeEventsModal({ eventsModal }) {
			eventsModal.isOpen = false;
			eventsModal.isNew = false;
			eventsModal.isEdit = false;

			// Focus back code input from where modal is opened
			const { systemIndex, rowId } = eventsModal;
			if (rowId) select(`${rowId}:code`);
			else select(`new:${systemIndex}:code`);
		},
		openOddsModal(state) {
			state.oddsModal.isOpen = true;
		},
		closeOddsModal({ oddsModal, lastSelectedId }) {
			oddsModal.isOpen = false;
			// Focus back odd input from where modal is opened
			select(lastSelectedId);
		},
		openNotificationModal(state) {
			state.notificationModal.isOpen = true;
		},
		closeNotificationModal(state) {
			state.notificationModal.isOpen = false;
		},
		openLiveOddsModal(state, eventId) {
			state.liveOddsModal.isOpen = true;
			state.liveOddsModal.eventId = eventId;
		},
		closeLiveOddsModal({ liveOddsModal, lastSelectedId }) {
			liveOddsModal.isOpen = false;
			// Focus back odd input from where modal is opened
			select(lastSelectedId);
		},
		openAuthModal(state, { ticketId }) {
			state.authModal.ticketId = ticketId;
		},
		closeAuthModal(state) {
			state.authModal.ticketId = null;
			selectDelayed(state.lastSelectedId, 25);
		},
		openConfirmationModal(state, { title, text, confirmAction }) {
			state.confirmationModal = {
				isOpen: true,
				title,
				text,
				confirmAction,
			};
		},

		closeConfirmationModal(state) {
			state.confirmationModal = {
				isOpen: false,
				title: '',
				text: '',
				confirmAction: () => {},
			};
		},

		toggleRegistrationModal(state) {
			state.registrationModalOpened = !state.registrationModalOpened;
			if (!state.registrationModalOpened) selectDelayed(state.lastSelectedId, 25);
		},
		toggleDepositModal(state) {
			state.depositModalOpened = !state.depositModalOpened;
			if (!state.depositModalOpened) selectDelayed(state.lastSelectedId, 25);
		},
		toggleWithdrawalModal(state) {
			state.withdrawalModalOpened = !state.withdrawalModalOpened;
			if (!state.withdrawalModalOpened) selectDelayed(state.lastSelectedId, 25);
		},
		toggleExpressTicketModal(state) {
			state.expressTicketOpened = !state.expressTicketOpened;
		},

		toggleOperatorPanelInfoModal(state) {
			state.operatorPanelInfoModalOpened = !state.operatorPanelInfoModalOpened;
		},

		setLastSelectedId(state, newId) {
			state.lastSelectedId = newId;
		},
	},
	actions: {},
};
