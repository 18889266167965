import { mapMutations, mapGetters } from 'vuex';
import { formatDate } from '../utils';
export default {
	computed: {
		...mapGetters([
			'eventOddsData',
			'bonusOddsEvents',
			'oddTypeData',
			'ticketEvent',
			'lastOdd',
			'options',
			'isEventAdded',
			'scanning',
			'isNotificationVisible',
		]),
	},
	methods: {
		...mapMutations([
			'replaceTicketEvent',
			'removeTicketEvent',
			'addNewEmptySystem',
			'addNewEmptyEvent',
			'addNewSubSystem',
			'removeSubSystem',
			'replaceEventOdd',
			'updateSubSystem',
			'removeEventOdd',
			'setLastOdd',
			'addNewEmptyOdd',
			'selectRow',
			'clearSelectedRow',
			'openEventsModal',
			'openOddsModal',
			'openLiveOddsModal',
			'splitSystem',
			'joinSystems',
			'setLastSelectedId',
			'closeLiveOddsModal',
			'enableScan',
			'cleanSubSystems',
		]),
		getListCode() {
			const {
				$refs: { code },
			} = this;
			return code ? +code.value.trim() : null;
		},
		getOddCode() {
			const {
				$refs: { odd },
			} = this;
			return odd ? odd.value : null;
		},

		isBonusOddCode(oddCode) {
			return oddCode.startsWith('B') || oddCode.startsWith('b');
		},

		oddChanged() {
			const { $refs, odd } = this;
			const oddValue = $refs.odd && $refs.odd.value;
			const oddCode = oddValue && oddValue.replace('*', '');
			return oddCode && oddCode !== odd.name;
		},
		getOddType(bonus = false, live = false) {
			const { $refs, listCode, odd = {}, row } = this;
			const oddValue = $refs.odd && $refs.odd.value;
			// Remove * character if exists
			let oddCode = oddValue && oddValue.replace('*', '').trim();
			if (bonus) oddCode = `${oddCode.slice(1)}`;
			if (!oddCode) oddCode = odd.name;
			if (!oddCode) return;
			if (oddValue !== oddCode) $refs.odd.value = oddCode;

			const { sport = {}, odds } =
				(bonus
					? this.$store.getters.bonusOddsEventData(listCode)
					: live
					? this.$store.getters.liveEventData(listCode)
					: this.$store.getters.prematchEventData(listCode)) || {};
			const oddType = this.oddTypeData({ oddCode, sportId: sport.id, odds, bonus });
			// Check if we played that oddType
			if (oddType) {
				const exists = row.event.odds.find(({ keyboardCode }) => keyboardCode === oddType.keyboardCode);
				if (exists) {
					if (Boolean(exists.bonusType) === bonus) return 'exists';
					else {
						if (odd.keyboardCode !== oddType.keyboardCode) return 'cannotCombine';
					}
				}
			}
			return oddType;
		},

		noEventNotify(code) {
			if (this.isNotificationVisible) return;
			this.$notifications.info({
				title: this.$t('error'),
				text: `${this.$t('eventNotExists')} ${code}`,
				type: 'error',
			});
		},

		noEventExist() {
			if (this.isNotificationVisible) return;
			this.$notifications.info({
				title: this.$t('error'),
				text: `${this.$t('oddTypeNotExists')}`,
				type: 'error',
			});
		},

		eventDisabledNotify(code) {
			if (this.isNotificationVisible) return;
			this.$notifications.info({
				title: this.$t('error'),
				text: this.$t('eventCurrentlyUnavailable', { listCode: code }),
				type: 'error',
			});
		},
		eventIsPrematch(code) {
			if (this.isNotificationVisible) return;
			this.$notifications.info({
				title: this.$t('error'),
				text: this.$t('eventInPrematch', { listCode: code }),
				type: 'error',
			});
		},
		noOddTypeNotify() {
			if (this.isNotificationVisible) return;
			this.$notifications.info({
				title: this.$t('error'),
				text: `${this.$t('oddTypeNotExists')}`,
				type: 'error',
			});
		},
		oddCurrentlyUnavailable(odd) {
			if (this.isNotificationVisible) return;
			this.$notifications.info({
				title: this.$t('error'),
				text: `${this.$t('oddUnavailable', { odd })}`,
				type: 'error',
			});
		},
		eventAddedNotify() {
			if (this.isNotificationVisible) return;
			this.$notifications.info({
				title: this.$t('warn'),
				text: `${this.$t('eventAdded')}`,
				type: 'warn',
			});
		},
		oddAlreadyExists(id = '') {
			if (this.isNotificationVisible) return;
			this.$notifications.info({
				title: this.$t('warn'),
				text: `${this.$t('oddExists')}`,
				type: 'warn',
				data: { id },
			});
		},

		maxEventOddsNotify(elementId) {
			if (this.isNotificationVisible) return;
			this.$notifications.info({
				title: this.$t('warn'),
				text: `${this.$t('maxEventOdds')}`,
				type: 'warn',
				data: { id: elementId },
			});
		},

		useLiveOfferNotify() {
			if (this.isNotificationVisible) return;
			this.$notifications.info({
				title: this.$t('warn'),
				text: `${this.$t('useLive')}`,
				type: `warn`,
			});
		},
		cannotCombineSame(id = '') {
			if (this.isNotificationVisible) return;
			this.$notifications.info({
				title: this.$t('warn'),
				text: `${this.$t('cannotCombine')}`,
				type: 'warn',
				data: { id },
			});
		},

		noMultipleOddsLive() {
			if (this.isNotificationVisible) return;
			this.$notifications.info({
				title: this.$t('warn'),
				text: `${this.$t('multipleOddsLive')}`,
				type: 'warn',
			});
		},

		eventFinishedNotify({ listCode, home, away, home_score, away_score, periods }) {
			if (this.isNotificationVisible) return;
			const textMessages = [this.$t('eventFinished', { listCode, home, away })];
			typeof home_score === 'number'
				? textMessages.push(this.$t('result', { home_score, away_score }))
				: textMessages.push(this.$t('resultUnavailable'));
			if (periods !== '' && periods !== undefined) textMessages.push(this.$t('byPeriods', { periods }));
			this.$notifications.info({
				title: this.$t('warn'),
				text: textMessages,
				type: 'warn',
				data: {
					multiline: true,
				},
			});
		},
		eventStartedNotify({ listCode, home, away, scheduled }) {
			if (this.isNotificationVisible) return;
			const time = formatDate(scheduled, 'hours:minutes')[0];
			this.$notifications.info({
				title: this.$t('warn'),
				text: this.$t('eventStarted', { listCode, home, away, time }),
				type: 'warn',
			});
		},
		eventInactive({ listCode, home, away }) {
			if (this.isNotificationVisible) return;
			this.$notifications.info({
				title: this.$t('warn'),
				text: this.$t('eventInactive', { listCode, home, away }),
				type: 'warn',
			});
		},
		eventInactiveForLive({ listCode, home, away }) {
			if (this.isNotificationVisible) return;
			this.$notifications.info({
				title: this.$t('warn'),
				text: this.$t('eventInactiveInLIve', { listCode, home, away }),
				type: 'warn',
			});
		},
		eventIsLive({ listCode, home, away }) {
			if (this.isNotificationVisible) return;
			this.$notifications.info({
				title: this.$t('warn'),
				text: this.$t('liveEventMessage', { listCode, home, away }),
				type: 'warn',
			});
		},
		eventIsNotReady({ listCode, home, away }) {
			if (this.isNotificationVisible) return;
			this.$notifications.info({
				title: this.$t('warn'),
				text: this.$t('eventIsNotReady', { listCode, home, away }),
				type: 'warn',
			});
		},
		eventInactiveWithStatus({ listCode, home, away, status }) {
			if (this.isNotificationVisible) return;
			this.$notifications.info({
				title: this.$t('warn'),
				text: this.$t('eventInactiveWithStatus', { listCode, home, away, status }),
				type: 'warn',
			});
		},
		focusHandler(elementId) {
			const { selectRow, row, isLastRow, setLastSelectedId } = this;
			selectRow({ row, isLastRow });
			setLastSelectedId(elementId);
		},
	},
};
