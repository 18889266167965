<template>
	<div class="modal_cover sm toggle" @keyup.esc="toggleExpressTicketModal">
		<div class="modal_content fadeInDown animated faster">
			<button class="btn transparent close" @click="toggleExpressTicketModal">
				<i class="mdi mdi-close"></i>
			</button>
			<div class="modal_head padding_left_20">
				<h2 class="modal_title ">{{ $i18n.t('expressTicket') }}</h2>
			</div>

			<div class="modal_body content_center direction_column small_form">
				<div class="form_row direction_row padding_bottom_20">
					<input
						type="number"
						id="expressCode"
						class="regular h_xl min_width_150 cut_right"
						:placeholder="$i18n.t('expressTicketCode')"
						v-model="code"
						@keyup.enter="getExpressTicket"
						ref="expressCode"
					/>
					<button class="btn md h_xl cut_left" @click="getExpressTicket">OK</button>
				</div>
				<div class="form_row">
					<p class="validation_message text_center">
						{{ $i18n.t('enterExpressCode') }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';

export default {
	data() {
		return {
			code: '',
		};
	},
	methods: {
		...mapActions(['populateExpressTicket', 'focusOddAction', 'selectEventOddAction', 'removeSystems']),
		...mapMutations(['toggleExpressTicketModal']),

		async getExpressTicket() {
			try {
				const data = await this.$api.getExpressTicket(this.code);
				this.removeSystems();
				let oddsLength = -1;
				let lastSystem = data.systems[data.systems.length - 1];

				for (const event of lastSystem.events) {
					for (const odd in event.odds) {
						oddsLength++;
					}
				}

				const systemsLength = data.systems.length - 1;
				const focusOddString = `row:${systemsLength}:${oddsLength}:odd`;

				this.populateExpressTicket(data);
				this.toggleExpressTicketModal();
				this.focusOddAction({ value: true });
				this.selectEventOddAction({ value: focusOddString });
			} catch (error) {
				console.error(error);
				let err = 'Express ticket not found';
				if (error.response && error.response.data && error.response.data.detail)
					err = error.response.data.detail[0].msg || error.response.data.detail;

				this.$notifications.info({
					title: this.$t('error'),
					text: `${err}`,
					type: 'error',
					data: { id: 'expressCode' },
				});
			}
		},
	},

	created() {
		const href = window.location.href;
		history.pushState({}, null, `${href}${href.endsWith('/') ? 'express' : '/express'}`);
	},

	destroyed() {
		history.back();
	},

	mounted() {
		this.$refs.expressCode.focus();
	},
};
</script>
