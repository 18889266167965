<template>
	<div class="ticket_action ">
		<div class="autorisation horizontal_scrollbar">
			<div class="label">{{ `${$t('authorization')}:` }}</div>

			<button
				v-for="ticket in authTickets"
				:key="ticket.id"
				class="btn"
				:class="{
					red:
						ticket.status === INVALID_NUM ||
						ticket.status === REFUNDED_NUM ||
						ticket.status === EXPIRED_NUM ||
						ticket.authStatus === REJECTED_AUTH_NUM,
					green: ticket.status === PLACED_NUM,
					orange: ticket.authStatus === MODIFIED_AUTH_NUM && ticket.status !== EXPIRED_NUM,
				}"
				@click="openAuthModal({ ticketId: ticket.id })"
			>
				{{ partOfId(ticket.id) }}
			</button>
		</div>
		<div class="cashout horizontal_scrollbar">
			<div class="label">{{ `${$t('cashout')}:` }}</div>
			<button
				v-for="ticket in cashoutTickets"
				:key="ticket.id"
				class="btn"
				:class="{
					red: ticket.authStatus === DENIED || ticket.authStatus === EXPIRED,
					green: ticket.status === ACCEPTED && ticket.authStatus === ACCEPTED,
					orange: ticket.authStatus === ACCEPTED,
				}"
				@click="openAuthModal({ ticketId: ticket.id })"
			>
				{{ partOfId(ticket.id) }}
			</button>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import {
	SENT,
	DENIED,
	ACCEPTED,
	EXPIRED,
	INVALID_NUM,
	REFUNDED_NUM,
	PLACED_NUM,
	MODIFIED_AUTH_NUM,
	EXPIRED_NUM,
	REJECTED_AUTH_NUM,
} from '../consts';
export default {
	data() {
		return {
			SENT,
			DENIED,
			ACCEPTED,
			EXPIRED,
			INVALID_NUM,
			REFUNDED_NUM,
			PLACED_NUM,
			EXPIRED_NUM,
			MODIFIED_AUTH_NUM,
			REJECTED_AUTH_NUM,
		};
	},
	computed: mapGetters(['authTickets', 'cashoutTickets']),
	methods: {
		...mapMutations(['openAuthModal']),
		partOfId(id = '') {
			return id.split('-')[0];
		},
	},
};
</script>

<style scoped>
.horizontal_scrollbar {
	overflow-x: auto;
}
</style>
