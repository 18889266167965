<template>
	<tr class="table_row">
		<td class="p_0 row_number">
			<span class="row_no tooltip_right">
				<span class="no">{{ eventIndex + 1 }}</span>
				<i class="mdi mdi-close-circle" @click="removeEvent({ keyCode: 109 })" />
				<span class="tooltip_content">{{ $t('deleteEvent') }}</span>
			</span>
		</td>
		<td class="event_code p_0">
			<input
				ref="code"
				type="text"
				:value="event.listCode"
				class="td_input"
				:id="`${row.rowId}:code`"
				:name="`${row.rowId}:code`"
				@focus="
					focusHandler(`${row.rowId}:code`);
					resetfocusInputTimer();
				"
				@keyup.up="codeUp"
				@keyup.down="codeDown"
				@keyup.left="codeLeft"
				@keyup.right="codeRight"
				@keyup.enter="codeEnter"
				@keydown.enter="EventCodeHandler"
				@keyup="handleEventKeyUp"
				autocomplete="event-code-no-autocomplete"
			/>
		</td>

		<ScheduledTime :event="event" />

		<!-- Competitors -->
		<td class="home pr_5 strong text_right">{{ home }}</td>
		<td class="p_0 small vs text_center">{{ away && '-' }}</td>
		<td class="away pl_5 strong text_left">{{ away }}</td>

		<!-- Odd type -->
		<td class="p_0 game_input">
			<span class="tooltip_right">
				<input
					ref="odd"
					type="text"
					:value="odd.name"
					class="td_input"
					:id="`${row.rowId}:odd`"
					:name="`${row.rowId}:odd`"
					@focus="
						focusHandler(`${row.rowId}:odd`);
						resetfocusInputTimer();
					"
					@keyup.up="oddUp"
					@keyup.down="oddDown"
					@keyup.left="oddLeft"
					@keyup.right="oddRight"
					@keyup.enter="oddEnter"
					@keydown.enter="EventOddHandler"
					@keyup="handleOddKeyUp"
					autocomplete="event-odd-no-autocomplete"
				/>
				<span class="tooltip_content">{{
					event.live
						? `${getMarketDescription(odd)}${`${specialValue != null ? ` ${specialValue} ` : ''}`} | ${
								odd.description
						  }`
						: odd.description
				}}</span>
			</span>
		</td>

		<Stake :eventId="eventId" :oddId="odd.id" :bonus="odd.bonusType || odd.bonus_type" :live="event.live" />

		<!-- Add more odds -->
		<td class="pl_0 add_game">
			<i class="mdi mdi-plus-circle add_btn tooltip_right" @click="oddStar({ keyCode: 106 })">
				<span class="tooltip_content">{{ $t('addOddtype') }}</span>
			</i>
		</td>

		<Period :eventId="eventId" :live="event.live" />

		<!-- Sport icon -->
		<td class="pr_0 sport">
			<span class="svg_icon tooltip_right">
				<!-- <svg class="sport_ico">
					<use :xlink:href="icon" />
				</svg> -->
				<span class="">{{ sportName.substring(0, 3) }}</span>

				<span class="tooltip_content">{{ sportName }}</span>
			</span>
		</td>

		<!-- Tournament -->
		<td class="pr_0 league">
			<span class="border_right">{{ tournament }}</span>
		</td>

		<!-- Condition -->
		<td class="condition">{{ condition }}</td>
	</tr>
</template>

<script>
import { keys, isLetter } from 'keycodes-map';
import {
	eventNavigation,
	computedEvent,
	createEditEvent,
	eventHelpers,
	specialEvents,
	focusInputConfig,
} from '../../mixins';
import { select, selectDelayed, remapEventForUpdate } from '../../utils';
import Stake from './Stake';
import Period from './EventPeriod';
import ScheduledTime from './EventScheduled.vue';
import SpecialValue from './SpecialValue';
import { MAX_EVENT_ODDS } from '../../consts';
import { mapGetters, mapActions } from 'vuex';
import { NOT_RUNNING, RUNNING, STOPPED, LIVE, ANNOUNCED, POSTPONED, INTERRUPTED } from '../../consts';

export default {
	mixins: [eventNavigation, computedEvent, createEditEvent, eventHelpers, specialEvents, focusInputConfig],
	props: ['row', 'system', 'addEventRow'],
	components: { Stake, SpecialValue, Period, ScheduledTime },
	data() {
		return {
			edited: false,
			oldEventId: '',
			event: this.row.event,
			isOpend: false,
		};
	},

	computed: {
		...mapGetters(['focusCode', 'focusOdd', 'oddString']),
	},

	methods: {
		async EventCodeHandler() {
			this.focusInputTimer++;
			if (this.focusInputTimer === this.defaultFocusInputTimer) {
				await this.codeEnter();
				this.resetfocusInputTimer();
			}
		},

		EventOddHandler() {
			this.focusInputTimer++;
			if (this.focusInputTimer === this.defaultFocusInputTimer) {
				this.oddEnter();
				this.resetfocusInputTimer();
			}
		},

		...mapActions(['addNewPrematchEvent', 'focusOddAction']),
		handleEventKeyUp(e) {
			if (this.scanning) return;
			if (e.keyCode === keys.insert) this.enableScan();

			this.eventsModal(e);
			this.codePlus(e);
			this.removeEvent(e);
			this.handleCtrlS(e);
			this.handleCtrlR(e);
		},
		handleOddKeyUp(e) {
			if (this.scanning) return;
			if (e.keyCode === keys.insert) this.enableScan();

			this.oddStar(e);
			this.oddsModal(e);
			this.handleCtrlS(e);
			this.handleCtrlR(e);
		},
		handleCtrlS(e) {
			e.preventDefault();
			if (e.keyCode !== keys.s || !e.ctrlKey) return;
			const { systemIndex, eventIndex, splitSystem } = this;
			splitSystem({ systemIndex, eventIndex });
			selectDelayed(`sys:${systemIndex}:0`, 1);
		},
		handleCtrlR(e) {
			e.preventDefault();
			if (e.keyCode !== keys.r || !e.ctrlKey) return;
			const { systemIndex } = this;
			this.joinSystems();
			selectDelayed(`sys:${systemIndex}:0`, 1);
		},
		async codeEnter() {
			if (this.scanning) return;
			this.isOpend = false;
			const newListCode = this.getListCode();
			const { systemIndex, listCode } = this;
			const { event: oldEvent, startedOrFinished, $api, noEventNotify, noEventExist, addNewPrematchEvent } = this;
			if (+newListCode === listCode) return this.codeRight();

			let event = this.$store.getters?.prematchEventData(newListCode);

			if (!event) {
				if (newListCode === 0) {
					if (this.isOpend === false) {
						noEventExist();
						this.isOpend = true;
					}
					return;
				}
				try {
					event = await $api.getEventByListCode(newListCode);
					if (!event) throw 'No event found';
					const { active, status, manuallyBlocked } = event;
					if (!active || status !== NOT_RUNNING || manuallyBlocked) {
						return startedOrFinished(event);
					}
					await addNewPrematchEvent(remapEventForUpdate(event));
					event = this.$store.getters?.prematchEventData(newListCode);
				} catch (err) {
					console.error(err);
					if (this.isOpend === false) {
						noEventNotify(newListCode);
						this.isOpend = true;
					}
				}
			}

			if (this.isEventAdded(newListCode)) return this.eventAddedNotify();
			if (event && event.live) {
				const { competitors, utc_scheduled } = event;
				const [{ name: home }, { name: away } = { name: '' }] = competitors || [{}, {}];
				return this.eventStartedNotify({ listCode: newListCode, home, away, scheduled: utc_scheduled });
			}
			// Specials check
			const specialWarningMessage = this.checkSpecialConditions(event, listCode);
			if (specialWarningMessage) return this.specialWarning(specialWarningMessage);

			this.replaceTicketEvent({
				eventId: oldEvent.id,
				systemIndex,
				newEvent: {
					...event,
					odds: [],
					isNew: true,
					isEdit: true,
				},
			});
		},
		oddEnter() {
			if (this.scanning) return;
			const { systemIndex, isLastRow, event, eventIndex } = this;
			const { oddChanged, addNewEmptyEvent, oddRight, odd } = this;
			if (!event) return;

			const oddCode = this.getOddCode();

			if (event.live && !oddCode) return this.openLiveOddsModal(event.id);
			if (!oddChanged()) return isLastRow ? addNewEmptyEvent(systemIndex) : oddRight();

			const bonus = this.isBonusOddCode(oddCode);

			const oddType = this.getOddType(bonus, event.live);
			if (!oddType) {
				if (event.live) return this.useLiveOfferNotify();
				return this.noOddTypeNotify();
			}
			if (oddType === 'exists') return this.oddAlreadyExists();
			if (oddType === 'cannotCombine') return this.cannotCombineSame();

			if (!oddType.stake || oddType.stake <= 1) {
				return this.oddCurrentlyUnavailable(oddType.name);
			}

			this.setLastOdd(oddType);

			if (event.isNew) {
				this.replaceTicketEvent({
					eventId: event.id,
					systemIndex,
					newEvent: {
						...event,
						isNew: false,
						odds: [oddType],
						bonus,
					},
				});
			} else {
				this.replaceEventOdd({
					newOdd: oddType,
					oddId: odd.id,
					systemIndex,
					eventIndex,
				});
			}

			if (isLastRow) addNewEmptyEvent(systemIndex);
			else this.oddRight();
		},
		oddStar(e) {
			if (e.keyCode !== keys.numpadMultiply) return;
			const { odd, $refs, oddChanged, addNewEmptyOdd, maxEventOddsNotify, noMultipleOddsLive } = this;
			const { event, edited, systemIndex, eventIndex } = this;

			//removed is event live check based on client request
			// if (event.live) {
			// 	$refs.odd.value = $refs.odd.value.replace('*', '');
			// 	return noMultipleOddsLive();
			// }

			const { odds: eventOdds } = this.ticketEvent(systemIndex, eventIndex) || {};
			if (eventOdds.length === MAX_EVENT_ODDS) {
				maxEventOddsNotify(`${this.row.rowId}:odd`);
				$refs.odd.value = $refs.odd.value.replace('*', '');
				return;
			}
			if (!oddChanged()) return addNewEmptyOdd({ systemIndex, eventIndex });

			const oddCode = this.getOddCode();

			const bonus = this.isBonusOddCode(oddCode);

			// Stop the execution of the function and reset the odd input values
			const oddType = this.getOddType(bonus, event.live);
			if (!oddType) {
				$refs.odd.value = odd.name;
				this.noOddTypeNotify();
				return;
			}

			if (oddType === 'exists') return this.oddAlreadyExists();
			if (oddType === 'cannotCombine') return this.cannotCombineSame();

			if (edited) {
				this.replaceTicketEvent({
					eventId: event.id,
					systemIndex,
					newEvent: {
						...event,
						isNew: false,
						odds: [oddType],
					},
				});
			} else {
				this.replaceEventOdd({
					newOdd: oddType,
					oddId: odd.id,
					systemIndex,
					eventIndex,
				});
			}

			const alreadyExists = select(`row:new:odd:${systemIndex}:${event.id}`);
			if (alreadyExists) return;

			addNewEmptyOdd({ systemIndex, eventIndex });
		},

		async codePlus(e) {
			if (e.keyCode !== keys.numpadAdd) return;
			const { systemIndex, eventId, $refs, row, listCode: oldListCode, $api } = this;
			const input = $refs.code.value;
			const listCode = input.replace('+', '');
			$refs.code.value = listCode;

			if (input === '+') {
				this.openEventsModal({
					systemIndex,
					rowId: row.rowId,
					eventId,
					listCode: oldListCode,
					searchQuery: '',
					live: true,
				});
			} else {
				let event = this.$store.getters?.liveEventData(listCode);

				if (!event) {
					try {
						const newEvent = await $api.getEventByListCode(listCode);
						if (!newEvent) throw 'No event found';
						const { competitors, status, liveStatus, live } = newEvent;
						const [{ name: home }, { name: away } = { name: '' }] = competitors || [{}, {}];
						if (liveStatus === ANNOUNCED) {
							return this.eventIsNotReady({ listCode, home, away });
						} else if (!live) {
							return this.eventIsPrematch(listCode);
						} else if (manuallyBlocked) {
							return eventInactive({ listCode, home, away });
						} else if (
							(status === STOPPED || status === RUNNING || status === INTERRUPTED || status === POSTPONED) &&
							liveStatus === LIVE
						) {
							await this.addNewLiveEvent(remapEventForUpdate(newEvent));
							event = this.$store.getters?.liveEventData(listCode);
						} else {
							return this.eventInactiveWithStatus({ listCode, home, away, status });
						}
					} catch (err) {
						console.error(err);
						return this.noEventNotify(listCode);
					}
				}

				if (this.isEventAdded(listCode)) return this.eventAddedNotify();
				if (!event) return this.noEventNotify(listCode);
				if (!event.live) return this.eventIsPrematch(listCode);
				if (!event.active) return this.eventDisabledNotify(listCode);
				if (event.stopped || event.disabled) return this.eventDisabledNotify(listCode);

				this.replaceTicketEvent({
					systemIndex,
					eventId,
					newEvent: {
						...event,
						odds: [],
						isNew: true,
					},
				});

				select(`new:${this.systemIndex}:odd`);

				setTimeout(() => {
					this.openLiveOddsModal(event.id);
				}, 100);
			}
		},
		eventsModal(e) {
			if (e.ctrlKey) return;
			if (e.keyCode !== keys.ctrl && !isLetter(e.keyCode)) return;
			const { systemIndex, eventId, $refs, listCode, row } = this;
			const searchQuery = $refs.code.value;

			this.openEventsModal({
				isOpen: true,
				systemIndex,
				rowId: row.rowId,
				eventId,
				listCode,
				searchQuery,
				isNew: true,
				isEdit: true,
			});
		},
		oddsModal(e) {
			if (e.keyCode !== keys.ctrl) return;
			const { live, id } = this.event;
			if (live) this.openLiveOddsModal(id);
			else this.openOddsModal();
		},
		removeEvent(e) {
			if (e.keyCode !== keys.numpadSubtract) return;
			const { systemIndex, eventId, listCode, cleanSubSystems } = this;
			this.removeTicketEvent({ systemIndex, eventId, listCode });
			cleanSubSystems();
			this.codeDown();
		},
	},
	mounted() {
		this.focusCode === true &&
			setTimeout(() => {
				this.$refs.code.focus();
			}, 600);

		if (this.focusOdd === true) {
			selectDelayed(this.oddString, 100);
			setTimeout(() => {
				this.focusOddAction({ value: false });
			}, 400);
		}
	},
};
</script>
