<template>
	<div class="modal_cover sm red_stroke modal_07 toggle" @keyup.esc="toggleWithdrawalModal">
		<div class="modal_content fadeInDown animated faster">
			<button class="btn transparent close" @click="toggleWithdrawalModal">
				<i class="mdi mdi-close"></i>
			</button>

			<div class="modal_head padding_left_20">
				<h2 class="modal_title ">{{ $t('online.withdrawal') }}</h2>
			</div>

			<div class="modal_body content_start direction_column small_form" v-if="!user">
				<div class="form_row">
					<Dropdown
						:items="depositOptions"
						:callback="selectOption"
						zIndex="z_1"
						:label="$t('online.wayOfWithdrawal')"
					/>
				</div>

				<div class="form_row direction_column" v-if="selectedOption === 'userName'">
					<label for="userName" class="input_label">{{ $t('online.userEmail') }}</label>
					<input
						required
						ref="email"
						type="email"
						id="userName"
						class="regular min_width_200"
						v-model="email"
						@keyup.enter="modalEnterHandler('email', 'documentCode')"
					/>
				</div>

				<div class="form_row direction_column" v-if="selectedOption === 'cardCode'">
					<label for="cardCode" class="input_label">{{ $t('online.userCode') }}</label>
					<input
						required
						type="number"
						id="cardCode"
						class="regular min_width_200"
						v-model="cardId"
						@keyup.enter="modalEnterHandler('cardId', 'documentCode')"
					/>
				</div>

				<div class="form_row direction_column">
					<label for="documentCode" class="input_label">{{ $t('online.documentId') }}</label>
					<input
						type="text"
						id="documentCode"
						class="regular min_width_200"
						v-model="documentId"
						@keyup.enter="modalEnterHandler('documentId', 'withdrawalCode')"
					/>
				</div>

				<div class="form_row direction_column">
					<label for="withdrawalCode" class="input_label">{{ $t('online.withdrawalCode') }}</label>
					<input
						type="text"
						id="withdrawalCode"
						class="regular min_width_200"
						v-model="withdrawalCode"
						@keyup.enter="modalEnterHandler('withdrawalCode', 'checkButton')"
					/>
				</div>
			</div>
			<div class="modal_body content_start direction_column small_form" v-if="user">
				<div class="form_row direction_column" v-if="user">
					<label class="input_label">{{ `${$t('Korisnik')}:` }}</label>
					<label class="input_label font_600 margin_bottom_5">{{ `${user.firstName} ${user.lastName}` }}</label>
					<label class="input_label">{{ `${$t('online.documentId')}:` }}</label>
					<label class="input_label font_600 margin_bottom_5">{{ `${user.idCard || user.passport}` }}</label>
					<label class="input_label">{{ `${$t('Rezervisana Sredstva')}:` }}</label>
					<label class="input_label font_600 margin_bottom_5">{{
						`${toMoneyFormat(+reservedAmount)} ${currency}`
					}}</label>
					<label class="input_label">{{ `${$t('Dostupna Sredstva')}:` }}</label>
					<label class="input_label font_600 margin_bottom_5">{{
						`${toMoneyFormat(+availableAmount)} ${currency}`
					}}</label>
				</div>
			</div>

			<div class="modal_footer">
				<button
					id="checkButton"
					@click="getUser"
					:disabled="!withdrawalCode || (!email && !cardId) || submitting"
					class="btn md round dark_green"
					v-if="!user"
				>
					{{ $t('online.check') }}
				</button>
				<button
					class="btn md round"
					id="cancelButton"
					v-if="user"
					@click="removeUser"
					@keyup.right="focusOn('withdrawButton')"
				>
					{{ $t('cancel') }}
				</button>
				<button
					class="btn md round dark_green"
					id="withdrawButton"
					v-if="user"
					:disabled="submitting"
					@click="withdraw"
					@keyup.left="focusOn('cancelButton')"
				>
					{{ $t('online.withdrawalShort') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations } from 'vuex';
import Dropdown from '../../General/Dropdown';
import { api } from '../../../api';
import { printSlips } from '../../../utils/printSlips';
import { formatting, inputHandler } from '../../../mixins';
import { formatDate, selectDelayed } from '../../../utils/index';
export default {
	mixins: [formatting, inputHandler],
	components: {
		Dropdown,
	},

	data() {
		return {
			depositOptions: [
				{ id: 'userName', name: 'Korisnicko ime' },
				{ id: 'cardCode', name: 'Sifra kartice' },
			],
			selectedOption: 'userName',
			user: null,
			email: null,
			cardId: null,
			withdrawalCode: null,
			reservedAmount: null,
			availableAmount: null,
			currency: null,
			documentId: null,
			submitting: false,
		};
	},

	methods: {
		...mapMutations(['toggleWithdrawalModal']),

		selectOption(option) {
			this.selectedOption = option;
			selectDelayed(option, 150);
		},

		async getUser() {
			try {
				this.submitting = true;
				const { email, cardId, selectedOption, withdrawalCode, documentId } = this;
				let data;

				if (selectedOption === 'userName') {
					data = await api.getReservationByEmail(email, withdrawalCode).catch((error) => {
						return this.showErrorMessageValidation(error);
					});
				} else {
					data = await api.getReservationByCode(cardId, withdrawalCode).catch((error) => {
						return this.showErrorMessageValidation(error);
					});
				}

				if (data.user.idCard) {
					if (documentId !== data.user.idCard) {
						this.$notifications.info({
							title: this.$t('error'),
							text: this.$t('documentValidation'),
							type: 'error',
							data: { id: selectedOption },
						});
						return;
					}
				} else {
					if (documentId !== data.user.passport) {
						this.$notifications.info({
							title: this.$t('error'),
							text: this.$t('documentValidation'),
							type: 'error',
							data: { id: selectedOption },
						});
						return;
					}
				}
				this.user = data.user;
				this.reservedAmount = data.reservedAmount;
				this.availableAmount = data.availableAmount;
				this.currency = data.currency;
				selectDelayed('withdrawButton', 150);
			} catch (exception) {
				//this.showErrorMessage(exception);
			} finally {
				this.submitting = false;
			}
		},

		async withdraw() {
			try {
				this.submitting = true;
				const { user, withdrawalCode, availableAmount, currency, toMoneyFormat, toggleWithdrawalModal } = this;
				const { deposit: amount, transactionUuid, dtCreated } = await api.withdrawMoney(
					user.id,
					withdrawalCode,
					availableAmount
				);

				// Format date to local time

				const date = new Date(dtCreated + 'Z').toLocaleString();

				const { firstName, lastName, code, email } = user;
				printSlips({ email, firstName, lastName, code, amount, currency, date, transactionUuid }, 'withdraw');

				this.$notifications.info({
					title: `${firstName} ${lastName}`,
					text: this.$t('payOutInfo', { money: toMoneyFormat(+amount), currency: currency }),
					type: 'success',
				});
				toggleWithdrawalModal();
			} catch (error) {
				this.showErrorMessage(error);
			} finally {
				this.submitting = false;
			}
		},

		removeUser() {
			const { selectedOption } = this;
			this.user = null;
			this.focusOn(selectedOption);
		},

		showErrorMessageValidation(error) {
			const { selectedOption } = this;
			let err = 'Something went wrong, try again';
			if (typeof error.response.data['detail'] === 'string') {
				switch (error.response.data['detail']) {
					case 'Pin is not valid.':
						err = this.$t('pinValidation');
						break;
					case 'User data not found for specified pay in request.':
						err = this.$t('userDataNotFoundValidation');
						break;
					case 'User location is not same as this location.':
						err = this.$t('userLocationValidation');
						break;
					case 'User data not found for specified input data.':
						err = this.$t('userValidation');
						break;
					default:
						err = error.response.data['detail'];
						break;
				}
			} else if (error.response && error.response.data && error.response.data.detail[0]?.msg) {
				err = error.response.data.detail[0].msg || error.response.data.detail;
				switch (err) {
					case 'value is not a valid email address':
						err = this.$t('emailValidation');
						break;
				}
			}

			this.$notifications.info({
				title: this.$t('error'),
				text: `${err}`,
				type: 'error',
				data: { id: selectedOption },
			});
		},

		showErrorMessage(error) {
			try {
				const { selectedOption } = this;
				let errorText = 'Something went wrong, try again';

				if (error.response && error.response.data) {
					const { detail: { message, dt_available, dt_expires, dt_created } = {} } = error.response.data || {
						detail: {},
					};

					if (message) {
						errorText = [message];
					}
					if (dt_available && dt_expires && dt_created) {
						const timeFormat = 'date.month.year. hours:minutes';
						[
							{ translation: 'online.reservationCreated', value: dt_created },
							{ translation: 'online.reservationAvailable', value: dt_available },
							{ translation: 'online.reservationExpires', value: dt_expires },
						].forEach(({ translation, value }) =>
							errorText.push(`${this.$t(translation)}: ${formatDate(new Date(value), timeFormat)}`)
						);
					}
				}

				// Notify operater that error occured
				this.$notifications.info({
					title: this.$t('error'),
					text: errorText,
					type: 'error',
					data: { id: selectedOption, multiline: true },
				});
			} catch (err) {
				console.error(err);
			}
		},
	},
	mounted() {
		// Focus email field
		this.$refs.email.focus();
	},
};
</script>
