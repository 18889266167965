import { mapGetters } from 'vuex';
import { FINISHED, RUNNING, STOPPED, CANCELED } from '../consts';

export default {
	computed: mapGetters(['marketsMap']),
	methods: {
		async startedOrFinished(event) {
			const {
				eventInactive,
				eventFinishedNotify,
				eventStartedNotify,
				eventInactiveWithStatus,
				noEventNotify,
			} = this;
			try {
				if (event) {
					const { competitors, active, status, currentScore, periodScores, scheduled, listCode } = event;
					const [{ name: home }, { name: away } = { name: '' }] = competitors || [{}, {}];
					if (!active && status !== FINISHED) {
						return eventInactive({ listCode, home, away });
					}
					if (status === FINISHED) {
						if (!currentScore) {
							return eventFinishedNotify({ listCode, home, away });
						}
						const { home_score = '', away_score = '' } = currentScore;

						let periods = '';
						if (periodScores) {
							periodScores.forEach((item) => {
								const {
									score: { home_score: period_home_score, away_score: period_away_score },
								} = item;
								periods += ` ${period_home_score}:${period_away_score}  |`;
							});
							periods = periods.slice(0, -1);
						}

						return eventFinishedNotify({ listCode, home, away, home_score, away_score, periods });
					} else if (status === CANCELED) {
						return eventInactiveWithStatus({ listCode, home, away, status });
					} else if (status === RUNNING || status === STOPPED) {
						return eventStartedNotify({ listCode, home, away, scheduled });
					}
				}
			} catch (err) {
				console.error(err);
			}
			return noEventNotify(listCode);
		},
		getMarketDescription(odd) {
			try {
				const { marketsMap } = this;
				const { id: oddId, oddtype_key } = odd;
				const parts = oddId?.split(':') ?? oddtype_key?.split(':') ?? [];
				if (parts.length < 2) return '';
				const marketId = `${parts[0]}:${parts[1]}`;
				const sportId = parts[0].slice(1);
				const market = marketsMap[sportId].markets.markets[marketId];
				if (market.name) return market.name;
				return '';
			} catch (err) {
				console.error(err);
				return '';
			}
		},
	},
};
