import CODE_REGIONS from './regions.json';
import i18n from '../../localisation/i18n';

export const Serbia = 'rs';
export const Bosnia = 'ba';
export const Montenegro = 'me';
export const Slovenia = 'si';
export const Croatia = 'hr';

const MALE = 'Male';
const FEMALE = 'Female';

const getCountry = (region) => {
	if (region === 1 || (region > 9 && region < 20)) return Bosnia;
	if (region === 2 || (region > 19 && region < 30)) return Montenegro;
	if (region === 3 || (region > 29 && region < 40)) return Croatia;
	if (region === 4 || (region > 40 && region < 50)) return Croatia;
	if (region === 5 || (region > 40 && region < 50)) return Slovenia;
	if ([7, 8, 9].includes(region) || (region > 69 && region < 100)) return Serbia;
	return null;
};

export const calculateNationalId = (umcn) => {
	if (umcn.length !== 13) throw i18n.t('jmbgLengthMessage');
	const invalid = typeof umcn !== 'string';
	if (invalid) throw i18n.t('jmbgWrongFormat');
	const date = umcn.substring(0, 2);
	const month = umcn.substring(2, 4);
	const year = `${umcn[4] === '9' ? '1' : '2'}${umcn.substring(4, 7)}`;

	const region = umcn.substring(7, 9);
	const unique = umcn.substring(9, 12);
	const control = umcn.substring(12);

	const isNationalIdValid = () => {
		let m =
			11 -
			((7 * (+date[0] + +year[3]) +
				6 * (+date[1] + +region[0]) +
				5 * (+month[0] + +region[1]) +
				4 * (+month[1] + +unique[0]) +
				3 * (+year[1] + +unique[1]) +
				2 * (+year[2] + +unique[2])) %
				11);
		if (m === 10 || m === 11) {
			m = 0;
		}
		return m === +control;
	};

	if (isNationalIdValid())
		return {
			date: +date,
			month: +month,
			year: +year,
			city: CODE_REGIONS[region],
			gender: +unique < 500 ? MALE : FEMALE,
			country: getCountry(region),
		};
	else {
		throw i18n.t('jmbgWrongFormat');
	}
};
