import * as single from './single';
import * as multi from './multi';
import * as system from './system';

const min = 'MIN_DEPOSIT_FOR_SYSTEM_TICKET';
const minLive = 'MIN_LIVE_DEPOSIT_FOR_SYSTEM_TICKET';
const minPerCombo = 'MIN_DEPOSIT_PER_COMBO_FOR_SYSTEM_TICKET';
const minLivePerCombo = 'MIN_LIVE_DEPOSIT_PER_COMBO_FOR_SYSTEM_TICKET';

export const Schema = {
	computed: {
		isEmpty: ({ systems }) => !systems.length || (systems.length === 1 && !systems[0].events.length),
		isSystem: ({ combinations = 1 }) => combinations > 1,
		combinations: ({ combinations }) => combinations,
		isMulti: ({ systems }, { isSystem }) => !isSystem && systems.some(({ events }) => events.length > 1),
		depositPerEvent: ({ amount, systems }, { isMulti }) => isMulti && amount / systems[0].events.length,
		multiNumEvents: ({ systems }, { isMulti }) => isMulti && systems[0].events.length,
		systemNumEvents: ({ systems }, { isSystem }) => isSystem && systems.flatMap(({ events }) => events).length,
		isSingle: (_, { isEmpty, isMulti, isSystem }) => !isEmpty && !isMulti && !isSystem,
		isLive: ({ systems }) => systems.some(({ events }) => events.some(({ live }) => live)),
		// For the usecases where we want to show general min deposit over per combo
		skip: ({ combinations }, { isSystem }, rules) => isSystem && rules[minPerCombo] * combinations < rules[min],
		skipLive: ({ combinations }, { isSystem }, rules) =>
			isSystem && rules[minLivePerCombo] * combinations < rules[minLive],
	},
	validators: { ...single, ...multi, ...system },
};
