<template>
	<Modal :searchQuery="eventsModalData.searchQuery" :closeModal="closeEventsModal">
		<template v-slot:modal-header>
			<ToggleStarted v-if="live" :started="started" :toggleStarted="toggleStarted" />
			<EventTabs v-else :activeTab="activeTab" :setActiveTab="setActiveTab" />
		</template>
		<EventsList
			slot-scope="{ searchValue }"
			:searchValue="searchValue"
			:live="live"
			:showStarted="started"
			:activeTab="activeTab"
		/>
	</Modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Modal from '../index';
import EventsList from './EventsList';
import ToggleStarted from './ToggleStarted';
import EventTabs from './EventTabs';

export default {
	components: { Modal, EventsList, ToggleStarted, EventTabs },
	data() {
		return {
			started: true,
			activeTab: 'all',
		};
	},
	computed: {
		...mapGetters(['eventsModalData']),

		live() {
			return this.eventsModalData.live;
		},
	},
	methods: {
		...mapMutations(['closeEventsModal']),

		setActiveTab(id) {
			this.activeTab = id;
		},

		toggleStarted() {
			this.started = !this.started;
		},
	},
};
</script>
