import { removeEmptyEvents } from './transformTicketForApi';
import { calculateAll } from '../../workers/calculation.worker';
/**
 * Vuex plugin for re-calculating and updating min, max and number of combinations after watched mutations occurs
 */

let combinationsMemo = {};
let cartesianMemo = {};

export const minMaxPlugin = (store) => {
	store.subscribe((mutation, state) => {
		const watchedMutations = [
			'replaceTicketEvent',
			'updateTicketAmount',
			'replaceEventOdd',
			'updateTicketOdds',
			'addNewSubSystem',
			'removeSubSystem',
			'cleanSubSystems',
			'removeEventOdd',
			'setTicketCopy',
			'splitSystem',
			'joinSystems',
			'removeEmptyRows',
		];

		if (watchedMutations.includes(mutation.type)) {

			const {
				liveEvents: upToDateLiveEvents = {},
				prematchEvents: upToDatePrematchEvents = {},
				bonusOddsEvents: upToDateBonusOddsEvents = {},
			} = state.dataState;

			if (state.ticketState.systems.length === 1) {
				const validEvents = removeEmptyEvents(state.ticketState.systems[0].events);
				if (validEvents.length === 0) {
					combinationsMemo = {};
					cartesianMemo = {};
				}
			}
			const updatedSystems = state.ticketState.systems.map(({ events, required }) => {
				const filteredEvents = removeEmptyEvents(events);
				let updatedEvents = [];
				filteredEvents.forEach((event) => {
					const upToDateEvent = event.live
						? upToDateLiveEvents[event.listCode]
						: upToDatePrematchEvents[event.listCode];
					const upToDateBonusEvent = upToDateBonusOddsEvents[event.listCode];

					let updatedOdds = [];
					if (!upToDateEvent) {
						event.odds.forEach((odd) => {
							updatedOdds.push({ ...odd, stake: 1, value: 1, specialValue: null });
						});
					} else {
						event.odds.forEach((odd) => {
							let upToDateOdd = null;
							if (odd.bonusType && upToDateBonusEvent) {
								upToDateOdd = upToDateBonusEvent.odds[odd.id];
							} else upToDateOdd = upToDateEvent.odds[odd.id];
							if (!upToDateOdd) updatedOdds.push({ ...odd, stake: 1, value: 1, specialValue: null });
							else
								updatedOdds.push({
									...odd,
									stake: upToDateOdd.value,
									value: upToDateOdd.value,
									specialValue: upToDateOdd.specialValue,
								});
						});
					}
					updatedEvents.push({ ...event, odds: updatedOdds });
				});

				return { events: updatedEvents, required };
			});

			calculateAll(updatedSystems).then(([minOdd, maxOdd, combinations, systems]) => {
				store.dispatch('calculateMinMax', { minOdd, maxOdd, combinations, systems });
			});
		}
	});
};
