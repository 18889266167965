/**
 * Transforms odds array from the state into odds map expected by the API
 *
 * [ { id: 'ofb:fs:1', stake: 3 }, { id: 'ofb:dc:1X', stake: 1.7 } ]
 * { 'ofb:fs:1': 3, 'ofb:dc:1X': 1.7 }
 */
const oddsArrayToMap = (oddsArr) => {
	return oddsArr.reduce((oddsMap, { id, stake, special_value, bonusType }) => {
		oddsMap[id] = { value: stake, specialValue: special_value, special_value, bonusType };
		return oddsMap;
	}, {});
};

// Filters out empty systems
export const removeEmptySystems = (systems) => systems.filter(({ events }) => events.length);

// Filters out empty events
export const removeEmptyEvents = (events) =>
	events
		.filter(({ isNew, odds }) => !isNew && odds.length)
		.map(({ odds, ...rest }) => ({ ...rest, odds: odds.filter(({ isNew }) => !isNew) }));

// Removes unnecessary values from the event and transforms event odds
export const transformEvents = (events) =>
	events.map(({ id, odds, listCode }) => ({ id, listCode, odds: oddsArrayToMap(odds) }));

/**
 * Transforms systems from the state into data expected by the API
 */
export function transformTicketForApi(systems, amount, forceRisk, authMessage, ticketId, promotionCodes) {
	const transformedSystems = systems.map(({ events, required }) => {
		const transformed = transformEvents(events);
		return { events: transformed, required };
	});

	const { currency = 'RSD' } = JSON.parse(localStorage.getItem('POS_device_info') || '{}') || {};

	return {
		amount,
		systems: removeEmptySystems(transformedSystems),
		idLocation: window.idLocation,
		manualAuthorization: forceRisk,
		operater_message: authMessage,
		acceptChanges: false,
		currency,
		id: ticketId,
		promotionCodes: promotionCodes,
	};
}
