<template>
	<div class="component payment" @keyup="toggleManualAuth">
		<label class="input_label" for="payment_amount">{{ $t('cash') }}</label>

		<div class="count_row">
			<div class="payment_amount_form">
				<input
					class="lg"
					id="amount"
					type="number"
					ref="amount"
					v-model="amount"
					placeholder="0.00"
					@focus="handleAmountFocus()"
					@keyup="handleKeyUp"
					@keydown="handleKeyDown"
					@keyup.enter="selectPayinButton"
					@keydown.enter="payInHandler(selectPayinButton)"
					@keyup.up="selectLastSystem"
					@keyup.down="selectPayinButton"
					@keydown.prevent.down
					@keydown.prevent.up
				/>
				<span class="input_label">{{ currency }}</span>
			</div>
		</div>

		<div class="count_row">
			<button
				id="payin"
				ref="payin"
				type="button"
				@focus="resetfocusInputTimer()"
				@keyup.up="selectAmount"
				:disabled="disabledPayIn"
				class="btn lg a full_width"
				@click="handleSubmit()"
				@keyup.enter="handleSubmit()"
				@keydown.enter="payInHandler(handleSubmit)"
				@keydown.enter.prevent.stop
				:class="btnPayClass"
				v-text="$t(btnPayText)"
			/>
		</div>

		<AuthConfirmation v-if="showMessageModal" :sendToRisk="sendToRisk" :closeAuthModal="closeAuthModal" />
		<BonusVoucherModal v-if="bonusVoucherModalIsOpen" :systems="systems" :combinations="combinations" />
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { joinedRoom } from '../../utils/loggers';
import { selectDelayed } from '../../utils';
import AuthConfirmation from './AuthConfirmation';
import { keys, isNumber } from 'keycodes-map';
import ticketSocket from '../../api/ticketSocket';
import { focusInputConfig } from '../../mixins';
import BonusVoucherModal from '../Modals/BonusVoucherModal.vue';

export default {
	mixins: [focusInputConfig],
	components: {
		AuthConfirmation,
		BonusVoucherModal,
	},
	data() {
		return {
			showMessageModal: false,
			userOrVoucher: '',
			blacklistKeys: [69, keys.numpadAdd, keys.numpadSubtract, keys.equal, keys.minus], // keyCode for 'e' is 69
			APressed: false,
			ticketIdRequested: false,
		};
	},
	computed: {
		...mapGetters({
			options: 'options',
			stateAmount: 'amount',
			lastSystemId: 'lastSystemId',
			submitting: 'submitting',
			systems: 'systems',
			combinations: 'combinations',
			scanning: 'scanning',
			loadingTimeoutValue: 'loadingTimeoutValue',
			ticketsResponse: 'ticketsResponse',
			lastRiskTicket: 'lastRiskTicket',
			riskTickets: 'riskTickets',
			promotionCodes: 'promotionCodes',
			bonusVoucherModalIsOpen: 'bonusVoucherModalIsOpen',
			vouchersSum: 'vouchersSum',
			bonusVoucherPayIn: 'bonusVoucherPayIn',
			bonusVoucherModal: 'bonusVoucherModal',
		}),

		forceRisk() {
			const { manualAuthorization } = this.options || {};
			return manualAuthorization;
		},
		amount: {
			get() {
				return this.stateAmount;
			},
			set(newAmount) {
				if (this.scanning || this.APressed) {
					return;
				}
				const [_, decimals] = newAmount.toString().split('.');
				if (decimals && decimals.length > 2) return;
				this.updateTicketAmount(+newAmount);
			},
		},

		currency() {
			return (window.rules && window.rules.CURRENCY) || '';
		},
		disabledPayIn() {
			const { stateAmount, submitting, systems } = this;
			const oddsExists =
				systems[0] &&
				systems[0].events &&
				systems[0].events[0].odds &&
				Object.values(systems[0].events[0].odds).length;

			return !stateAmount || submitting || !oddsExists;
		},
		btnPayClass() {
			switch (true) {
				case this.forceRisk && !this.bonusVoucherPayIn:
					return 'red';
				case !this.forceRisk && this.bonusVoucherPayIn:
					return 'blue';
				case this.forceRisk && this.bonusVoucherPayIn:
					return 'orange';
				default:
					return 'dark_green';
			}
		},

		btnPayText() {
			switch (true) {
				case this.forceRisk && !this.bonusVoucherPayIn:
					return 'authorization';
				case !this.forceRisk && this.bonusVoucherPayIn:
					return 'bonusVoucherPayIn';
				case this.forceRisk && this.bonusVoucherPayIn:
					return 'authorizationBonusVoucher';
				default:
					return 'pay';
			}
		},
	},

	watch: {
		lastRiskTicket(value) {
			const timeout = value.timeout ? value.timeout + this.loadingTimeoutValue : this.loadingTimeoutValue + 10000;

			setTimeout(() => {
				if (this.hasDuplicates(this.riskTickets) && this.riskTickets.find((ticket) => ticket === value.ticketId)) {
					this.getCashedTicketHandler(value.ticketId);
				}
			}, timeout);
		},
	},

	methods: {
		hasDuplicates(array) {
			return new Set(array).size !== array.length;
		},

		payInHandler(eventFnc) {
			this.focusInputTimer++;
			if (this.focusInputTimer === this.defaultFocusInputTimer) {
				eventFnc();
				this.resetfocusInputTimer();
			}
		},

		...mapMutations([
			'toggleManualAuthorization',
			'updateTicketAmount',
			'removeEmptyRows',
			'setLastSelectedId',
			'enableScan',
			'clearSelectedRow',
			'cleanSubSystems',
		]),
		...mapActions([
			'submitTicket',
			'updateTicketData',
			'ticketToRisk',
			'setSubmittingAction',
			'toggleVoucherPayInAction',
			'toggleBonusVoucherModalAction',
			'promotionCodesAction',
			'vouchersArrReset',
			'bonusVoucherTicketIdMapAddAction',
		]),

		handleKeyUp(e) {
			this.APressed = e.keyCode === keys.a;
			if (this.scanning || e.keyCode == keys.a) return;

			if (e.keyCode === keys.insert) this.enableScan();
			this.prevent(e);
		},

		handleKeyDown(e) {
			this.APressed = e.keyCode === keys.a;
			this.prevent(e);
		},

		prevent(e) {
			const { blacklistKeys } = this;
			const keyCode = e.keyCode;
			const [integerByRef, decimalsByRef] = this.$refs.amount.value.split('.');
			const [integerByValue, decimalsByValue] = this.amount.toString().split('.');
			if (
				blacklistKeys.includes(keyCode) ||
				(isNumber(keyCode) &&
					((decimalsByRef && decimalsByRef.length >= 3) ||
						(decimalsByValue && decimalsByRef.length >= 3) ||
						integerByRef.length >= 17 ||
						integerByValue.length >= 17))
			) {
				if (e.keyCode !== 109) this.updateTicketAmount(0);

				e.preventDefault();
			}
		},

		selectLastSystem() {
			selectDelayed(this.lastSystemId);
		},

		selectAmount(e) {
			selectDelayed('amount');
		},
		selectProcent() {
			selectDelayed('procent');
		},
		selectUser(e) {
			selectDelayed('user_code');
		},
		selectPayinButton() {
			this.resetfocusInputTimer();
			if (this.scanning) return;
			selectDelayed('payin');
		},
		toggleManualAuth(e) {
			if (this.scanning) {
				return;
			} else if (e.keyCode == keys.a) {
				this.toggleManualAuthorization();
			} else if (e.keyCode == keys.numpadSubtract && !this.disabledPayIn) {
				this.toggleBonusVoucherModalAction();
			}
		},
		closeAuthModal() {
			this.showMessageModal = false;
			selectDelayed('payin');
		},
		sendToRisk(authMessage) {
			this.showMessageModal = false;
			this.sendTicket(true, authMessage);
		},

		async handleSubmit() {
			this.resetfocusInputTimer();
			if (this.scanning) return;
			const { forceRisk, sendTicket } = this;

			if (!forceRisk) return sendTicket();

			this.showMessageModal = true;
			selectDelayed('auth-message', 300);
		},

		async sendTicket(forceRisk, authMessage) {
			const errorNotification = this.$notifications.info;
			if (this.ticketIdRequested) return;
			try {
				this.ticketIdRequested = true;
				const { id: ticketId } = await this.$api.reserveTicketId();

				let promotionCodes = this.promotionCodes;
				const room = `alea.${ticketId}`;

				if (promotionCodes) this.bonusVoucherTicketIdMapAddAction(ticketId);

				ticketSocket.join(room, (rooms) => joinedRoom(room, rooms));

				await this.submitTicket({
					forceRisk,
					authMessage,
					errorNotification,
					ticketId,
					promotionCodes,
				});

				this.promotionCodesAction({ value: null });
				this.vouchersArrReset();
				this.toggleVoucherPayInAction(false);

				setTimeout(() => {
					this.ticketsResponse.includes(ticketId) && this.getCashedTicketHandler(ticketId);
				}, this.loadingTimeoutValue - 1000);
			} catch (err) {
				console.error(err);
			} finally {
				this.ticketIdRequested = false;
			}
		},

		async getCashedTicketHandler(ticketId) {
			const room = `alea.${ticketId}`;
			ticketSocket.leave(`alea.${ticketId}`);

			try {
				const ticketData = await this.$api.getCashedTicket(ticketId);

				if (ticketData.data.status === 0 && ticketData.data.auth_status !== 3) {
					this.ticketToRisk(ticketData);

					ticketSocket.join(room, (rooms) => joinedRoom(room, rooms));
				} else if (ticketData.data.auth_status === 3) {
					ticketSocket.join(room, (rooms) => joinedRoom(room, rooms));
					this.ticketToRisk(ticketData);
					this.updater(ticketData);
				} else {
					this.updater(ticketData);
				}
			} catch (error) {
				console.log(error);

				error = error.response.data.detail[0].msg || error.response.data.detail;
				const { text, id } = typeof error === 'string' ? { text: error, id: null } : error;

				this.$notifications.info({
					title: this.$t('error'),
					type: 'error',
					data: error,
					text,
				});
			} finally {
				this.setSubmittingAction(false);
			}
		},

		updater(data) {
			this.updateTicketData([data, this.$notifications.info, ticketSocket.leave]);
		},

		handleAmountFocus() {
			this.resetfocusInputTimer();
			const { setLastSelectedId, clearSelectedRow } = this;
			clearSelectedRow();
			setLastSelectedId('amount');
		},
	},
	watch: {
		bonusVoucherModal(value) {
			if (this.vouchersSum !== 0 && !value) {
				this.updateTicketAmount(+this.vouchersSum);
			} else {
				this.promotionCodesAction({ value: null });
				this.toggleVoucherPayInAction(false);
			}

			this.selectAmount();
		},

		amount(value) {
			if (value !== this.vouchersSum) {
				this.promotionCodesAction({ value: null });
				this.vouchersArrReset();
				this.toggleVoucherPayInAction(false);
			}
		},
	},
	mounted() {
		this.promotionCodesAction({ value: null });
		this.vouchersArrReset();
		this.toggleVoucherPayInAction(false);
	},
};
</script>
