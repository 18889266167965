export const ADDITION = 'ADDITION';
export const INCREMENT = 'INCREMENT';

const HALFTIME = 'HT';
const THIRD = 'T';
const QUARTER = 'Q';
const SET = 'S';
const INNING = 'I';

export const sports = {
	fb: {
		halfTimeAt: 1,
		withOvertimes: true,
		withPenalties: true,
		regularTimePeriodsUntil: 2,
		calcFinalScore: ADDITION,
		period: HALFTIME,
	},
	bb: {
		halfTimeAt: 2,
		withOvertimes: true,
		withPenalties: false,
		regularTimePeriodsUntil: 4,
		calcFinalScore: ADDITION,
		period: QUARTER,
	},
	tn: {
		halfTimeAt: 1,
		withOvertimes: false,
		withPenalties: false,
		regularTimePeriodsUntil: 5,
		calcFinalScore: INCREMENT,
		period: SET,
	},
	tt: {
		halfTimeAt: 1,
		withOvertimes: false,
		withPenalties: false,
		regularTimePeriodsUntil: 5,
		calcFinalScore: INCREMENT,
		period: SET,
	},
	vb: {
		halfTimeAt: 1,
		withOvertimes: false,
		withPenalties: false,
		regularTimePeriodsUntil: 5,
		calcFinalScore: INCREMENT,
		period: SET,
	},
	hb: {
		halfTimeAt: 1,
		withOvertimes: true,
		withPenalties: true,
		regularTimePeriodsUntil: 2,
		calcFinalScore: ADDITION,
		period: HALFTIME,
	},
	ih: {
		halfTimeAt: 1,
		withOvertimes: true,
		withPenalties: true,
		regularTimePeriodsUntil: 3,
		calcFinalScore: ADDITION,
		period: THIRD,
	},
	rb: {
		halfTimeAt: 1,
		withOvertimes: false,
		withPenalties: false,
		regularTimePeriodsUntil: 2,
		calcFinalScore: ADDITION,
		period: HALFTIME,
	},
	bs: {
		halfTimeAt: 1,
		withOvertimes: false,
		withPenalties: false,
		regularTimePeriodsUntil: 9,
		calcFinalScore: ADDITION,
		period: INNING,
	},
	af: {
		halfTimeAt: 2,
		withOvertimes: true,
		withPenalties: false,
		regularTimePeriodsUntil: 4,
		calcFinalScore: ADDITION,
		period: HALFTIME,
	},
	ft: {
		halfTimeAt: 1,
		withOvertimes: true,
		withPenalties: true,
		regularTimePeriodsUntil: 2,
		calcFinalScore: ADDITION,
		period: HALFTIME,
	},
	bv: {
		halfTimeAt: 1,
		withOvertimes: false,
		withPenalties: false,
		regularTimePeriodsUntil: 3,
		calcFinalScore: INCREMENT,
		period: SET,
	},
	wp: {
		halfTimeAt: 2,
		withOvertimes: false,
		withPenalties: true,
		regularTimePeriodsUntil: 4,
		calcFinalScore: ADDITION,
		period: HALFTIME,
	},
};
