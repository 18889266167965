<template>
	<tr class="table_row sys_form" v-if="enabled">
		<td class="p_0" colspan="13">
			<div class="sys_wrap">
				<div class="system">
					<div class="sys_top_row">
						<div class="control">
							<span class="sys_del tooltip_right" @click="removeSystemItem(system.index)">
								<i class="mdi mdi-close-circle"></i>
								<span class="tooltip_content">{{ $t('deleteSystem') }}</span>
							</span>

							<div class="label">{{ $t('addSystem') }}</div>
						</div>

						<div class="systems">
							<!-- Single system -->
							<div
								v-for="(subSystem, i) in system.required"
								:key="`${system.index}:${i}:${subSystem}`"
								class="system_input"
							>
								<span class="tooltip_right">
									<input
										type="text"
										:value="subSystem"
										class="td_input ticket_sys"
										:id="`sys:${system.index}:${i}`"
										@keyup="(e) => handleKeyUp(e, i, subSystem)"
										@keydown="(e) => handleKeyDown(e)"
										@blur="cleanUp"
										@keyup.up="systemUp"
										@focus="systemFocus(i)"
										@keyup.down="systemDown"
										@keyup.left="systemLeft(i)"
										@keyup.right="systemRight(i)"
										@keydown.enter="(e) => systemFormHandler(e, subSystem)"
									/>
									<span class="tooltip_content">{{ $t('defineSystem') }}</span>
									<!-- TODO Smisliti nesto pametnije -->
								</span>

								<label class="small form_label">{{ $t('from') }} {{ events.length }}</label>
								<i
									v-if="system.required.length - 1"
									class="mdi mdi-close-circle close_btn tooltip_right"
									@click="removeSubSystem([system.index, i])"
								>
									<span class="tooltip_content">{{ $t('deleteSystem') }}</span>
								</i>
							</div>
						</div>
					</div>

					<div class="sys_bottom_row">
						<button class="btn green_light" @click="addNewEmptySystem">{{ $t('addSystem') }}</button>
					</div>
				</div>
				<MinMaxPreview :showMinMax="showMinMax" />
			</div>
		</td>
	</tr>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import { select, selectDelayed } from '../../utils';
import MinMaxPreview from './MinMaxPreview';
import { keys } from 'keycodes-map';
import { focusInputConfig } from '../../mixins';

export default {
	mixins: [focusInputConfig],
	props: ['system', 'showMinMax'],
	components: { MinMaxPreview },
	data() {
		return {
			preventCleanup: false,
			enterPressed: false,
		};
	},
	computed: {
		...mapGetters(['scanning', 'systems']),
		events() {
			const { events } = this.system;
			return events.filter(({ isNew, isEdit }) => !isNew || isEdit);
		},
		enabled() {
			const { required } = this.system;
			const { events } = this;
			return required.length && events.length;
		},
	},

	methods: {
		...mapMutations([
			'removeSystem',
			'removeSubSystem',
			'updateSubSystem',
			'addNewSubSystem',
			'cleanSubSystems',
			'removeEmptyRows',
			'addNewEmptySystem',
			'setLastSelectedId',
			'enableScan',
			'clearSelectedRow',
		]),

		...mapActions(['promotionCodesAction', 'vouchersArrReset', 'toggleVoucherPayInAction']),

		handleKeyUp(event, subSystemIndex) {
			const lastSystem = this.systems.findLast((system) => system);

			try {
				if (this.scanning) return;

				if (event.keyCode === keys.enter) {
					if (
						(lastSystem?.index === this.system?.index && this.focusInputTimer > 1) ||
						this.focusInputTimer === 1
					) {
						if (this.enterPressed) return;
						else this.enterPressed = true;
					} else if (this.focusInputTimer > 1) {
						this.resetfocusInputTimer();
						return;
					}
				}
				if (event.keyCode === keys.insert) this.enableScan();
				let { target, keyCode } = event;
				const { index: systemIndex, required } = this.system;
				const { addNew, updateSubSystem, addNewSystem, events, $notifications } = this;
				const {
					arrowLeft,
					arowRight,
					arrowDown,
					enter,
					numpadMultiply,
					numpadAdd,
					numpadSubtract,
					numpadDivide,
				} = keys;
				const backspace = 8;
				const blacklistKeys = [arrowLeft, arrowLeft, arowRight, arrowDown, backspace, numpadSubtract, numpadDivide];
				if (blacklistKeys.includes(keyCode)) return;

				if (keyCode === numpadMultiply && required.length === events.length) {
					this.preventCleanup = true;
					$notifications.info({
						title: 'Greška',
						text: `Nije moguće dodati više sistema!`,
						type: 'error',
					});
					return;
				}

				if (keyCode === numpadMultiply || keyCode === numpadAdd || keyCode === enter) {
					return keyCode === numpadAdd
						? addNewSystem()
						: keyCode === numpadMultiply
						? addNew()
						: selectDelayed('amount', 100);
				}

				// Remove the + and * symbols from the value
				let value = +target.value.replace(/[+|*]/g, '').split('.')[0];
				if (value > events.length) {
					this.preventCleanup = true;
					$notifications.info({
						title: 'Greška',
						text: `Unesite ispravnu vrijednost sistema!`,
						type: 'error',
					});
					return;
				}

				updateSubSystem({ systemIndex, subSystemIndex, value });
			} catch (err) {
				console.error(err);
			} finally {
				this.resetfocusInputTimer();
				if (event.keyCode === keys.enter) {
					this.enterPressed = false;
				}
			}
		},

		handleKeyDown(event) {
			if (event.key === '.') event.preventDefault();
		},

		addNew() {
			const { system, addNewSubSystem, selectLastSubSystem } = this;
			this.preventCleanup = true;
			addNewSubSystem(system.index);
			selectLastSubSystem();
		},
		addNewSystem() {
			this.preventCleanup = true;
			this.addNewEmptySystem();
		},
		cleanUp() {
			const { system, preventCleanup, cleanSubSystems } = this;
			if (!preventCleanup) cleanSubSystems(system.index);
			this.preventCleanup = false;
		},
		/**
		 * Select the code input of the last event in the previous system
		 * Or the the odd type input of the last event in the previous system
		 */
		systemUp() {
			const { rows, index } = this.system;
			const lastRow = rows.length - 1;

			const selected = select(`row:${index}:${lastRow}:code`);
			if (!selected) select(`row:${index}:${lastRow}:odd`);
		},
		/**
		 * Select the code input of the first event in the next system
		 */
		systemDown() {
			const nextSystem = this.system.index + 1;
			select(`row:${nextSystem}:0:code`);
		},
		/**
		 * If this is the first sub-system in the system, select the
		 * od type input of the last event in the previous system
		 *
		 * Otherwise select the previous sub-system in the current system
		 */
		systemLeft(index) {
			const { index: systemIndex, rows } = this.system;
			const lastRow = rows.length - 1;

			if (index === 0) select(`row:${systemIndex}:${lastRow}:odd`);
			else select(`sys:${systemIndex}:${index - 1}`);
		},
		/**
		 * If this is the last sub-system in the system, select the
		 * od code input of the first event in the next system
		 *
		 * Otherwise select the next sub-system in the current system
		 */
		systemRight(index) {
			const { index: systemIndex, required } = this.system;
			const nextSystem = systemIndex + 1;
			const subSystemsLen = required.length - 1;

			if (index < subSystemsLen) select(`sys:${systemIndex}:${index + 1}`);
			else select(`row:${nextSystem}:0:code`);
		},
		/**
		 * Select the last sub-system
		 */
		selectLastSubSystem() {
			const { index: systemIndex, required } = this.system;
			selectDelayed(`sys:${systemIndex}:${required.length - 1}`, 100);
		},

		systemFocus(i) {
			const { removeEmptyRows, setLastSelectedId, system, clearSelectedRow } = this;
			removeEmptyRows();
			clearSelectedRow();
			setLastSelectedId(`sys:${system.index}:${i}`);
		},

		systemFormHandler(e, i, subSystem) {
			this.focusInputTimer++;

			if (this.focusInputTimer === this.defaultFocusInputTimer) {
				const lastSystem = this.systems?.findLast((system) => system);

				if (lastSystem?.index === this.system?.index) {
					this.handleKeyUp(e, subSystem);
				} else {
					this.systemDown();
				}
				this.resetfocusInputTimer();
			}
		},

		removeSystemItem(systemIndex) {
			const lastSystem = this.systems?.findLast((system) => system).index;

			if (lastSystem === systemIndex) {
				this.promotionCodesAction({ value: null });
				this.vouchersArrReset();
				this.toggleVoucherPayInAction(false);
			}

			this.removeSystem(systemIndex);
			this.cleanUp();
		},
	},
};
</script>
