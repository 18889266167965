<template></template>

<script>
import { selectDelayed } from '../../utils';
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
	props: ['customId'],
	mounted() {
		this.openNotificationModal();
		this.disableInputs();
		this.setIsNotificationVisible(true);
		selectDelayed('close-notification', 300);
	},
	destroyed() {
		const { customId, lastSelectedId } = this;
		this.closeNotificationModal();
		this.enableInputs();
		this.setIsNotificationVisible(false);
		selectDelayed(customId ? customId : lastSelectedId, 300);
	},
	computed: mapGetters(['lastSelectedId']),

	methods: {
		...mapMutations(['openNotificationModal', 'closeNotificationModal']),
		...mapActions(['setIsNotificationVisible']),
		disableInputs() {
			let elems = document.getElementById('root').getElementsByTagName('input');
			for (let i = 0; i < elems.length; i++) {
				elems[i].disabled = true;
			}
		},
		enableInputs() {
			let elems = document.getElementById('root').getElementsByTagName('input');
			for (let i = 0; i < elems.length; i++) {
				elems[i].disabled = false;
			}
		},
	},
};
</script>
