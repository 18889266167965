export const ticketPayInMaxAmount = (systems, combinations, geoParams) => {
	let maxPayIn = 0;

	if (!geoParams) return maxPayIn;
	let numberOfEvents = 0;

	for (const system of systems) {
		let size = Object.keys(system.events).length;
		numberOfEvents += size;
	}

	const live = systems.some(({ events }) => events.some(({ live }) => live));
	const isSystemTicket = numberOfEvents > 1 && combinations > 1;
	const isMulti = systems.length > 1;

	if (live) {
		if (isSystemTicket) {
			const maxPayInPerCombination =
				geoParams.BETTING.LIVE.MAX_LIVE_DEPOSIT_PER_COMBO_FOR_SYSTEM_TICKET * combinations;
			maxPayIn =
				geoParams.BETTING.LIVE.MAX_LIVE_DEPOSIT_FOR_SYSTEM_TICKET < maxPayInPerCombination
					? geoParams.BETTING.LIVE.MAX_LIVE_DEPOSIT_FOR_SYSTEM_TICKET
					: maxPayInPerCombination;
		} else if (isMulti) {
			const maxPayInPerEvents = geoParams.BETTING.LIVE.MAX_LIVE_DEPOSIT_PER_EVENT_FOR_MULTI_TICKET * numberOfEvents;
			maxPayIn =
				geoParams.BETTING.LIVE.MAX_LIVE_DEPOSIT_FOR_MULTI_TICKET < maxPayInPerEvents
					? geoParams.BETTING.LIVE.MAX_LIVE_DEPOSIT_FOR_MULTI_TICKET
					: maxPayInPerEvents;
		} else {
			maxPayIn = geoParams.BETTING.LIVE.MAX_LIVE_DEPOSIT_FOR_SINGLE_TICKET;
		}
	} else {
		if (isSystemTicket) {
			const maxPayInPerCombination =
				geoParams.BETTING.PREMATCH.MAX_DEPOSIT_PER_COMBO_FOR_SYSTEM_TICKET * combinations;
			maxPayIn =
				geoParams.BETTING.PREMATCH.MAX_DEPOSIT_FOR_SYSTEM_TICKET < maxPayInPerCombination
					? geoParams.BETTING.PREMATCH.MAX_DEPOSIT_FOR_SYSTEM_TICKET
					: maxPayInPerCombination;
		} else if (isMulti) {
			const maxPayInPerEvents = geoParams.BETTING.PREMATCH.MAX_DEPOSIT_PER_EVENT_FOR_MULTI_TICKET * numberOfEvents;
			maxPayIn =
				geoParams.BETTING.PREMATCH.MAX_DEPOSIT_FOR_MULTI_TICKET < maxPayInPerEvents
					? geoParams.BETTING.PREMATCH.MAX_DEPOSIT_FOR_MULTI_TICKET
					: maxPayInPerEvents;
		} else {
			maxPayIn = geoParams.BETTING.PREMATCH.MAX_DEPOSIT_FOR_SINGLE_TICKET;
		}
	}

	return maxPayIn;
};
