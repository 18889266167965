<template>
	<tr
		:class="[{ selected: rowSelected, red_row: event.stopped, disabled: event.stopped }, 'table-row']"
		:id="`events-modal:${row}`"
		@click="clickedEvent"
		ref="row"
	>
		<td class="event_code strong padding_left_20">{{ event.listCode || '' }}</td>
		<td v-if="!live" class="p_0 text_right">{{ time.slice(0, 3) }}</td>
		<td v-else class="p_0 text_right">
			<i v-if="event.disabled" class="mdi mdi-pause-circle tooltip_left event_paused">
				<span class="tooltip_content red">{{ $t('matchStopped') }}</span>
			</i>
			<i v-else class="mdi mdi-play-circle tooltip_left live_event">
				<span class="tooltip_content">{{ $t('liveMatch') }}</span>
			</i>

			<span class=" padding_left_10">{{ eventStartTime }}</span>
		</td>

		<td class="strong">
			<span v-if="!live" class="border_left">{{ time.slice(3) }}</span>
			<span v-else class="border_left">{{ currentTime }}</span>
		</td>
		<td class="pr_5 strong text_right">{{ home }}</td>
		<td class="p_0 small vs text_center">{{ away && 'vs' }}</td>
		<td class="pl_5 strong text_left">{{ away }}</td>
		<td class="pr_0 sport">
			<span class="svg_icon tooltip_right">
				<!-- <svg class="sport_ico">
					<use :xlink:href="icon" />
				</svg> -->
				<span class="">{{ sportName.substring(0, 3) }}</span>
				<span class="tooltip_content">{{ sportName }}</span>
			</span>
		</td>
		<td class="league">{{ tournament }}</td>
		<td v-if="live" class="league">{{ event.disabled ? 'Stopiran' : 'Startovan' }}</td>
	</tr>
</template>

<script>
import { computedEvent } from '../../../mixins';
import { formatDate } from '../../../utils';
export default {
	mixins: [computedEvent],
	props: ['eventListCode', 'row', 'rowSelected', 'eventClick', 'selectEvent', 'live'],
	computed: {
		event() {
			return this.live
				? this.$store.getters?.liveEventData(this.eventListCode)
				: this.$store.getters?.prematchEventData(this.eventListCode);
		},
		eventStartTime() {
			const { utc_scheduled } = this.event;
			return utc_scheduled ? formatDate(utc_scheduled, 'hours:minutes')[0] : '';
		},
		currentTime() {
			const { current_time } = this.event;
			if (!current_time) return null;
			const [minutes] = current_time.split(':');
			return `${minutes}'`;
		},
	},
	methods: {
		clickedEvent() {
			this.eventClick(this.listCode, this.live);
		},
	},
};
</script>
