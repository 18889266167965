<template>
	<div class="component">
		<div class="count_row">
			<div class="label">
				<span v-if="options.calculatorEnabled">{{ $t('sumStop') }}</span>
				<span v-else>{{ $t('sumStart') }}</span>
				<strong>F8</strong>
			</div>
		</div>

		<div class="count_row">
			<div class="label">
				{{ $t('numOfTickets') }}
				<strong>{{ calculator.numOfTickets }}</strong>
			</div>
			<div class="label">
				<strong v-if="options.calculatorEnabled && calculator.sum < 0" class="red"
					>{{ $t('sum') }} : {{ calculator.sum }}</strong
				>
				<strong v-else-if="options.calculatorEnabled" class="green">{{ $t('sum') }}: {{ sum }}</strong>
				<strong v-else>{{ $t('sum') }}: {{ sum }}</strong>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { handleKey } from '../../utils';
import formatting from '../../mixins/formatting';
import { keys } from 'keycodes-map';

export default {
	mixins: [formatting],
	computed: {
		...mapGetters(['options', 'calculator']),
		sum() {
			return this.toMoneyFormat(this.calculator.sum);
		},
	},
	methods: mapMutations(['toggleCalculator']),
	created() {
		this.removeKeyListener = handleKey('keyup', keys.f8, this.toggleCalculator);
	},
	destroyed() {
		this.removeKeyListener && this.removeKeyListener();
	},
};
</script>
