<template>
	<div class="secondary_offer">
		<div class="selected_event">
			<div class="competetors">{{ home }} - {{ away }}</div>
			<div class="tournament">{{ tournament }}</div>
		</div>

		<div class="aditional_odds">
			<div v-for="odd in favorites" :key="odd.id" class="stake_wrap common_stake" @click="addOdd(odd)">
				<div class="stake_name">{{ odd.displayName }}</div>
				<div class="stake_odd">
					{{
						event.odds[odd.id] && +event.odds[odd.id].value > 1 && event.odds[odd.id].oddtype_status === 1
							? oddsFormat(+event.odds[odd.id].value)
							: event.odds[odd.idBySpecialValue] &&
							  +event.odds[odd.idBySpecialValue].value > 1 &&
							  event.odds[odd.idBySpecialValue].oddtype_status === 1
							? oddsFormat(+event.odds[odd.idBySpecialValue].value)
							: '-'
					}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { createEditEvent, computedEvent, eventNavigation, formatting } from '../../mixins';
import favoriteOddTypes from '../../utils/favoriteOddTypes';

export default {
	mixins: [createEditEvent, computedEvent, eventNavigation, formatting],
	name: 'Favorites',
	computed: {
		...mapGetters({ row: 'selectedRow', marketsMap: 'marketsMap' }),
		event() {
			const { systemIndex, eventIndex } = this;
			const { listCode, live } = this.ticketEvent(systemIndex, eventIndex) || {};
			return (
				(live ? this.$store.getters?.liveEventData(listCode) : this.$store.getters?.prematchEventData(listCode)) ??
				{}
			);
		},
		favorites() {
			if (this.event.live) return;
			const markets = favoriteOddTypes[this.sportId];
			const { marketsMap, sportId, event } = this;
			let specialValue = '';
			if (markets) {
				const favoriteOdds = [];
				for (const [id, market] of Object.entries(markets)) {
					const { oddtypes } = market;
					const hasBoundaries = market.hasBoundaries;
					if (hasBoundaries) {
						specialValue = (event.odds[oddtypes[0].id] && event.odds[oddtypes[0].id].special_value) || 0;
						if (!specialValue) {
							const validOdd = Object.values(event.odds).find((odd) => {
								const [id] = odd.oddtype_key?.split('|') ?? odd.id?.split('|') ?? '';
								return (
									id === oddtypes[0].id &&
									event.odds[odd.oddtype_key ?? odd.id ?? '']?.special_value_order === 0
								);
							});
							specialValue = validOdd ? validOdd.special_value : 0;
						}
					}
					oddtypes.forEach((oddtype) => {
						const item =
							marketsMap[sportId].markets.markets[id] &&
							marketsMap[sportId].markets.markets[id].oddtypes[oddtype.id];
						let odd = event.odds[oddtype?.id];
						if (item) {
							if (marketsMap[sportId].markets.markets[id].hasBoundaries && !odd) {
								const validOddKey = Object.keys(event.odds).find(
									(oddKey) => oddKey.startsWith(oddtype.id) && event.odds[oddKey].special_value_order === 0
								);
								item.idBySpecialValue = validOddKey ? validOddKey : '';
								item.keyboard_code = event.odds[validOddKey]?.keyboard_code_oddtype ?? item.keyboard_code;
							} else {
								item.idBySpecialValue = '';
							}
							item.displayName = hasBoundaries ? `${item.name}  (${specialValue})` : item.name;
							favoriteOdds.push(item);
						}
					});
				}
				return favoriteOdds;
			} else return [];
		},
	},
	methods: {
		addOdd(newOdd) {
			const { systemIndex, isLastRow, event, eventIndex, addNewEmptyEvent, odd } = this;
			const { id, idBySpecialValue, name, description, keyboard_code: keyboardCode, alternative_keyboard_codes } = newOdd;
			const eventOdd = event.odds?.[id] ?? event.odds[idBySpecialValue];

			if (!eventOdd || eventOdd.value <= 1 || eventOdd.oddtype_status !== 1) return;

			const [_, specialValue] = idBySpecialValue.split('|');

			const oddType = {
				...eventOdd,
				id: eventOdd.oddtype_key ?? eventOdd.id,
				stake: eventOdd.value,
				bonusType: false,
				name,
				description,
				keyboardCode,
				specialValue,
				alternative_keyboard_codes
			};

			const { isNew, isEdit, odds: eventOdds } = this.ticketEvent(systemIndex, eventIndex) || {};

			const bonus = this.isBonusOddCode(keyboardCode);

			if (!oddType) return this.noOddTypeNotify();

			if (odd.keyboardCode !== oddType.keyboardCode) {
				// Check if we played that oddType
				const exists = eventOdds.find(({ keyboardCode }) => keyboardCode === oddType.keyboardCode);
				if (exists) {
					if (Boolean(exists.bonusType) === bonus) {
						return this.oddAlreadyExists();
					} else return this.cannotCombineSame();
				}
			}

			this.setLastOdd(oddType);

			if (isNew) {
				this.replaceTicketEvent({
					eventId: event.id,
					systemIndex,
					newEvent: {
						...event,
						isNew: false,
						isEdit,
						odds: [oddType],
					},
				});
			} else {
				this.replaceEventOdd({
					newOdd: oddType,
					oddId: odd.id,
					systemIndex,
					eventIndex,
				});
			}

			if (isLastRow) addNewEmptyEvent(systemIndex);
			else this.oddRight();
		},
	},
};
</script>
