<template>
	<div class="pagination_wrap right">
		<div class="pag_item">
			<i class="mdi mdi-chevron-double-left" @click="onClickFirstPage" :disabled="isInFirstPage"></i>
		</div>

		<div class="pag_item">
			<i class="mdi mdi-chevron-left" @click="onClickPreviousPage" :disabled="isInFirstPage"></i>
		</div>

		<div
			v-for="page in pages"
			:key="page.name"
			class="pag_item"
			:disabled="page.isDisabled"
			@click="onClickPage(page.name)"
			:class="{ selected: isPageActive(page.name) }"
		>
			{{ page.name }}
		</div>

		<div class="pag_item">
			<i class="mdi mdi-chevron-right" @click="onClickNextPage" :disabled="isInLastPage"></i>
		</div>
		<div class="pag_item">
			<i class="mdi mdi-chevron-double-right" @click="onClickLastPage" :disabled="isInLastPage"></i>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Paggination',
	props: {
		maxVisibleButtons: {
			type: Number,
			required: false,
			default: 10,
		},
		totalPages: {
			type: Number,
			required: true,
		},
		perPage: {
			type: Number,
			required: true,
		},
		currentPage: {
			type: Number,
			required: true,
		},
	},

	computed: {
		startPage() {
			// When on the first page
			if (this.currentPage === 1) {
				return 1;
			}

			// When on the last page
			if (this.currentPage === this.totalPages) {
				// return this.totalPages - this.maxVisibleButtons;
				if (this.totalPages === 1) {
					return this.totalPages;
				} else if (this.totalPages === 2) {
					return this.totalPages - 1;
				} else {
					return this.totalPages - 2;
				}
			}

			// When inbetween
			return this.currentPage - 1;
		},
		pages() {
			const range = [];

			for (
				let i = this.startPage;
				i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
				i++
			) {
				range.push({
					name: i,
					isDisabled: i === this.currentPage,
				});
			}

			return range;
		},

		isInFirstPage() {
			return this.currentPage === 1;
		},
		isInLastPage() {
			return this.currentPage === this.totalPages;
		},
	},
	methods: {
		onClickFirstPage() {
			if (this.currentPage === 1) {
				return;
			}
			this.$emit('pagechanged', 1);
		},
		onClickPreviousPage() {
			if (this.currentPage === 1) {
				return;
			}
			this.$emit('pagechanged', this.currentPage - 1);
		},
		onClickPage(page) {
			if (page === this.currentPage) return;
			this.$emit('pagechanged', page);
		},
		onClickNextPage() {
			if (this.currentPage === this.totalPages) {
				return;
			}
			this.$emit('pagechanged', this.currentPage + 1);
		},
		onClickLastPage() {
			if (this.currentPage === this.totalPages) {
				return;
			}
			this.$emit('pagechanged', this.totalPages);
		},
		isPageActive(page) {
			return this.currentPage === page;
		},
	},
};
</script>
