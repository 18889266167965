import numeral from 'numeral';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const sr = 'sr';
const en = 'en';
const me = 'me';
const ba = 'ba';
const de = 'de';

// number formater based on locale
numeral.register('locale', sr, {
	delimiters: {
		thousands: '.',
		decimal: ',',
	},
});
// number formater based on locale
numeral.register('locale', me, {
	delimiters: {
		thousands: '.',
		decimal: ',',
	},
});

numeral.register('locale', ba, {
	delimiters: {
		thousands: '.',
		decimal: ',',
	},
});

numeral.register('locale', de, {
	delimiters: {
		thousands: '.',
		decimal: ',',
	},
});

numeral.defaultFormat('0,0.00');

function loadLocaleMessages() {
	const locales = require.context('./translations', true, /[A-Za-z0-9-_,\s]+\.json$/i);
	const messages = {};
	locales.keys().forEach((key) => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1) {
			const locale = matched[1];
			messages[locale] = locales(key);
		}
	});
	return messages;
}

function mapLanguage(lang) {
	lang = typeof lang != 'undefined' ? lang : 'en';
	if (lang == 'sr_sr' || lang == 'sr_rs') {
		lang = sr;
	} else if (
		lang == 'sr_me' ||
		lang == 'hr' ||
		lang == 'hr_hr' ||
		lang == 'sr_bs' ||
		lang == 'sr_ba' ||
		lang == 'bs' ||
		lang == 'ba'
	) {
		lang = sr;
	} else if (lang == 'en_EN' || lang == 'en_en') {
		lang = en;
	}

	return lang;
}

function loadLocale() {
	const saved = localStorage.getItem('lang');
	const { href } = window.location;
	let urlLang = (href.split('lang=')[1] || '').split(/\?|\/|\#/g)[0];
	urlLang = urlLang.split('&')[0];

	let lang = saved ? saved : urlLang ? mapLanguage(urlLang) : 'sr';

	if (!saved) {
		if (urlLang === 'sr_me') {
			lang = 'me';
		} else if (urlLang === 'sr_ba') {
			lang = 'ba';
		}
	}

	numeral.locale(lang);
	return lang;
}

const i18n = new VueI18n({
	locale: loadLocale(),
	fallbackLocale: en,
	messages: loadLocaleMessages(),
});

export default i18n;
