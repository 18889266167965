var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ticket_action "},[_c('div',{staticClass:"autorisation horizontal_scrollbar"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(((_vm.$t('authorization')) + ":")))]),_vm._l((_vm.authTickets),function(ticket){return _c('button',{key:ticket.id,staticClass:"btn",class:{
				red:
					ticket.status === _vm.INVALID_NUM ||
					ticket.status === _vm.REFUNDED_NUM ||
					ticket.status === _vm.EXPIRED_NUM ||
					ticket.authStatus === _vm.REJECTED_AUTH_NUM,
				green: ticket.status === _vm.PLACED_NUM,
				orange: ticket.authStatus === _vm.MODIFIED_AUTH_NUM && ticket.status !== _vm.EXPIRED_NUM,
			},on:{"click":function($event){return _vm.openAuthModal({ ticketId: ticket.id })}}},[_vm._v(" "+_vm._s(_vm.partOfId(ticket.id))+" ")])})],2),_c('div',{staticClass:"cashout horizontal_scrollbar"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(((_vm.$t('cashout')) + ":")))]),_vm._l((_vm.cashoutTickets),function(ticket){return _c('button',{key:ticket.id,staticClass:"btn",class:{
				red: ticket.authStatus === _vm.DENIED || ticket.authStatus === _vm.EXPIRED,
				green: ticket.status === _vm.ACCEPTED && ticket.authStatus === _vm.ACCEPTED,
				orange: ticket.authStatus === _vm.ACCEPTED,
			},on:{"click":function($event){return _vm.openAuthModal({ ticketId: ticket.id })}}},[_vm._v(" "+_vm._s(_vm.partOfId(ticket.id))+" ")])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }