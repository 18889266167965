<template>
	<div class="modal_cover m toggle" @keyup.esc="toggleOperatorPanelInfoModal">
		<div class="modal_content fadeInDown animated faster right_modal">
			<div class="modal_head padding_left_20">
				<h2 class="modal_title ">{{ $i18n.t('info') }}</h2>
			</div>

			<div v-if="!loading" class="modal_body content_center direction_column small_form">
				<div class="form_row direction_row padding_bottom_0">
					<p class="padding_0 text_left">{{ $i18n.t('turn') }}:</p>
					<p class="padding_0 text_left">
						{{ turn }}
					</p>
				</div>

				<div class="form_row direction_row padding_bottom_0">
					<p class="padding_0 text_left">{{ $i18n.t('operator') }}:</p>
					<p class="padding_0 text_left">
						{{ infoData.fullName }}
					</p>
				</div>
				<div class="form_row direction_row padding_bottom_0">
					<p class="padding_0 text_left">{{ $i18n.t('username') }}:</p>
					<p class="padding_0 text_left">
						{{ infoData.username }}
					</p>
				</div>
				<div class="form_row direction_row padding_bottom_0">
					<p class="padding_0 text_left">{{ $i18n.t('lastlogin') }}:</p>
					<p class="padding_0 text_left">
						{{ infoData.loginTime + ' ' + infoData.loginDate }}
					</p>
				</div>
			</div>
			<Loading v-else />
			<div v-if="!loading" data-testid="modal-footer" class="modal_footer">
				<button id="confirm" class="btn lg round" @click="toggleOperatorPanelInfoModal">
					{{ $t('close') }}
				</button>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';

import Loading from '../Loading.vue';
export default {
	name: 'OperatorPanelInfoModal',
	components: { Loading },
	data() {
		return {
			turn: '/',
			infoData: {},
			loading: false,
		};
	},

	computed: mapGetters(['offerPlans']),

	methods: {
		...mapMutations(['toggleOperatorPanelInfoModal']),

		async getInfo() {
			this.loading = true;
			try {
				const operatorData = await this.$api.getOperaterInfo();
				const operatorDataFormated = { idOfferPlan: operatorData.idOfferPlan, ...operatorData.operatorInfo };
				const loginTime = new Date(operatorData.operatorInfo.loginTime);

				operatorDataFormated.loginDate = loginTime.toLocaleDateString('en-GB', {
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
				});

				operatorDataFormated.loginTime = loginTime.toLocaleTimeString('en-GB');
				this.infoData = operatorDataFormated;

				const offerPlan = this.offerPlans[operatorData.idOfferPlan];
				if (offerPlan && offerPlan.name)
					this.turn = offerPlan.name
						.toLowerCase()
						.replace('plan', '')
						.trim();
			} catch (error) {
				this.toggleOperatorPanelInfoModal();
				console.error(error);
				let err = this.$t('error');
				if (error.response && error.response.data && error.response.data.detail)
					err = error.response.data.detail[0].msg || error.response.data.detail;

				this.$notifications.info({
					title: this.$t('error'),
					text: `${err}`,
					type: 'error',
					data: { id: 'expressCode' },
				});
			} finally {
				this.loading = false;
			}
		},
	},

	mounted() {
		this.getInfo();
	},
};
</script>
<style scoped>
.right_modal {
	position: absolute;
	top: 74px;
	right: 14px;
}
</style>
