import { EMAIL_REGEX } from '../consts';

export default {
	computed: {
		firstNameClass() {
			const { firstName } = this;
			if (firstName === null) return '';
			if (firstName === '') return 'red';
			return 'green';
		},

		lastNameClass() {
			const { lastName } = this;
			if (lastName === null) return '';
			if (lastName === '') return 'red';
			return 'green';
		},

		cityClass() {
			const { city } = this;
			if (city === null) return '';
			if (city === '') return 'red';
			return 'green';
		},

		zipClass() {
			const { zip } = this;
			if (zip === null) return '';
			if (zip === '') return 'red';
			return 'green';
		},

		addressClass() {
			const { address } = this;
			if (address === null) return '';
			if (address === '') return 'red';
			return 'green';
		},

		addressNumClass() {
			const { addressNum } = this;
			if (addressNum === null) return '';
			if (addressNum === '') return 'red';
			return 'green';
		},

		jmbgClass() {
			const { jmbg, jmbgError } = this;
			if (jmbg === null) return '';
			if (jmbg === '' || jmbgError) return 'red';
			return 'green';
		},

		documentIdClass() {
			const { documentId } = this;
			if (documentId === null) return '';
			if (documentId === '') return 'red';
			return 'green';
		},

		passportClass() {
			const { passport } = this;
			if (passport === null) return '';
			if (passport === '') return 'red';
			return 'green';
		},

		telephoneClass() {
			const { telephone } = this;
			if (telephone === null) return '';
			if (telephone === '') return 'red';
			return 'green';
		},

		emailClass() {
			const { email } = this;
			if (email === null) return '';
			if (email === '' || !email.match(EMAIL_REGEX)) return 'red';
			return 'green';
		},
	},
};
