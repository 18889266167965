import { remap } from './';

export const formatTicket = remap({
	auth_status: 'authStatus',
	dt_placed: 'placedDatetime',
	dt_requested: 'requestedDatetime',
	max_gain: 'maxGain',
	max_quota: 'maxQuota',
	min_quota: 'minQuota',
	operater_message: 'operaterMessage',
	system_message: 'systemMessage',
	risk_manager_message: 'riskMessage',
	updated_events: 'updatedEvents',
});
