export default {
	state: {
		vouchersSum: 0,
		promotionCodes: null,
		bonusVoucherModal: false,
		bonusVoucherPayIn: false,
		vouchersArr: [],
		bonusVoucherTicketIdMap: {},
	},
	getters: {
		vouchersSum: (state) => state.vouchersSum,
		promotionCodes: (state) => state.promotionCodes,
		bonusVoucherModal: (state) => state.bonusVoucherModal,
		bonusVoucherModalIsOpen: (state) => state.bonusVoucherModal,
		bonusVoucherPayIn: (state) => state.bonusVoucherPayIn,
		vouchersArr: (state) => state.vouchersArr,
		bonusVoucherTicketIdMap: (state) => state.bonusVoucherTicketIdMap,
	},
	mutations: {
		vouchersSum(state, payload) {
			state.vouchersSum = +payload.value.toFixed(2);
		},
		promotionCodes(state, payload) {
			state.promotionCodes = payload.value;
		},

		toggleBonusVoucherModal(state) {
			state.bonusVoucherModal = !state.bonusVoucherModal;
		},

		togglesVoucherPay(state, payload) {
			state.bonusVoucherPayIn = payload;
		},
		vouchersArrPush(state, payload) {
			state.vouchersArr.unshift(payload);
		},
		vouchersArrRemove(state, payload) {
			state.vouchersArr.splice(payload, 1);
		},

		vouchersArrReset(state) {
			state.vouchersArr = [];
		},
		bonusVoucherTicketIdMapAdd(state, payload) {
			state.bonusVoucherTicketIdMap[payload] = true;
		},
		bonusVoucherTicketIdMapRemove(state, payload) {
			if (state.bonusVoucherTicketIdMap[payload]) delete state.bonusVoucherTicketIdMap[payload];
		},
	},
	actions: {
		vouchersSumAction({ commit }, payload) {
			commit('vouchersSum', payload);
		},
		promotionCodesAction({ commit }, payload) {
			commit('promotionCodes', payload);
		},
		toggleBonusVoucherModalAction({ commit }) {
			commit('toggleBonusVoucherModal');
		},

		toggleVoucherPayInAction({ commit }, payload) {
			commit('togglesVoucherPay', payload);
		},
		vouchersArrPushAction({ commit }, payload) {
			commit('vouchersArrPush', payload);
		},
		vouchersArrRemoveAction({ commit }, payload) {
			commit('vouchersArrRemove', payload);
		},
		vouchersArrReset({ commit }) {
			commit('vouchersArrReset');
		},
		bonusVoucherTicketIdMapAddAction({ commit }, payload) {
			commit('bonusVoucherTicketIdMapAdd', payload);
		},
		bonusVoucherTicketIdMapRemoveAction({ commit }, payload) {
			commit('bonusVoucherTicketIdMapRemove', payload);
		},
	},
};
