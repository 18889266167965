<template>
	<div class="modal_head ticket_preview">
		<div class="ticket_info">
			<div class="info_row">
				<div class="info_item">
					<label for="">{{ $t('payment') }}</label>
					<div class="value">
						{{ toMoneyFormat(payment) }}

						<span class="currency">{{ currency }}</span>
					</div>
				</div>

				<div class="info_item ">
					<label for="">{{ $t('minOdd') }}</label>
					<div class="value">
						{{ minQuota ? oddsFormat(+minQuota) : '' }}
					</div>
				</div>

				<div class="info_item">
					<label for="">{{ $t('minWin') }}</label>
					<div class="value">
						{{
							minQuota
								? toMoneyFormat((minQuota * amount) / combinations + bonusAmount - payOutTax.min_tax_value || 0)
								: ''
						}}
						<span class="currency">{{ currency }}</span>
					</div>
				</div>
				<div class="info_item">
					<label for="">{{ $t('cashout') }}</label>
					<div class="value">
						{{ cashout ? toMoneyFormat(cashout) : '' }}
					</div>
				</div>
				<div class="info_item">
					<label for="">{{ $t('payinOperator') }}</label>
					<div class="value">{{ operator }}</div>
				</div>
				<div class="info_item">
					<label for="">{{ $t('giveaway') }}</label>
					<div class="value">NA</div>
				</div>
			</div>

			<div class="info_row">
				<!-- col_border_bottom -->
				<div class="info_item">
					<label for="">{{ $t('timePlaced') }}</label>
					<div class="value">{{ timePlaced }}</div>
				</div>

				<div class="info_item ">
					<label for="">{{ $t('maxOdd') }}</label>
					<div class="value">
						{{ maxQuota ? oddsFormat(+maxQuota) : '' }}
					</div>
				</div>

				<div class="info_item">
					<label for="">{{ $t('maxWin') }}</label>
					<div class="value">
						{{ maxGain ? toMoneyFormat(maxGain - payOutTax.max_tax_value || 0) : '' }}
						<span class="currency">{{ currency }}</span>
					</div>
				</div>

				<div class="info_item">
					<label for="">{{ $t('bonus') }} {{ `(${+parseFloat(bonus * 100).toFixed(2)} %)` }}</label>
					<div class="value">
						{{ bonusAmount ? toMoneyFormat(bonusAmount) : '0.00' }} <span class="currency">{{ currency }}</span>
					</div>
				</div>

				<div class="info_item">
					<label for="">{{ $t('affectedOdd') }}</label>
					<div class="value">NA</div>
				</div>

				<div class="info_item flex_direction_row align_center">
					<div class="margin_right_50">
						<label for="">{{ $t('minTaxPayout') }}</label>
						<div class="value">
							{{ toMoneyFormat(payOutTax.min_tax_value) }}<span class="currency">{{ currency }}</span>
						</div>
					</div>
					<div>
						<label for="">{{ $t('maxTaxPayout') }}</label>
						<div class="value">
							{{ toMoneyFormat(payOutTax.max_tax_value) }}<span class="currency">{{ currency }}</span>
						</div>
					</div>
				</div>

				<!-- <div class="info_item">
					<label for="">{{ $t('taxPayout') }}</label>
					<div class="value">
						0.00 <span class="currency">{{ currency }}</span>
					</div>
				</div> -->
			</div>
		</div>
		<!-- ticket_info -->

		<div class="status_bar" :class="ticketStatus.bgColor">
			<p class="systems">{{ systemsPreview }}</p>
			<div class="title_wrapper">
				<h2 class="status_title">{{ ticketStatus ? ticketStatus.text : $t('REQUESTED') }}</h2>
				<p class="status_info" />
			</div>
			<div class="ticket_list_toggle_icon"></div>
		</div>
	</div>
</template>

<script>
import { formatDate } from '../../../utils';
import formatting from '../../../mixins/formatting';
import { ticketStatuses } from './helpers';
import { EXPIRED_NUM, MODIFIED_AUTH_NUM, REQUESTED_NUM } from '../../../consts';
import { calculateTaxForPlayedTicket } from '../../../../src/utils/ticket/calculateTaxForPlayedTicket';

export default {
	mixins: [formatting],
	props: [
		'id',
		'bonus',
		'amount',
		'systems',
		'currency',
		'status',
		'placedDatetime',
		'requestedDatetime',
		'authStatus',
		'barcode',
		'gain',
		'maxGain',
		'convertedAmount',
		'minQuota',
		'maxQuota',
		'combinations',
		'cashout',
		'operator',
		'tax_data',
		'tax_parameters',
		'originalAmount',
		'cashoutTicketOriginaAmount',
	],
	computed: {
		systemsPreview() {
			if (!this.systems) return null;
			return this.systems
				.map(
					({ required, events }, i) =>
						`S${i + 1}: ${[...required].sort((a, b) => a - b).join(', ')} od ${events.length}`
				)
				.join('  ');
		},
		timePlaced() {
			const { placedDatetime, requestedDatetime } = this;

			const date = placedDatetime
				? new Date(placedDatetime)
				: requestedDatetime
				? new Date(requestedDatetime)
				: null;
			return date && formatDate(date, 'date.month.year. hours:minutes:seconds')[0];
		},

		bonusAmount() {
			const { maxQuota, amount, bonus } = this;
			return bonus * (maxQuota * amount);
		},

		ticketStatus() {
			const { status, authStatus } = this;
			if (authStatus === MODIFIED_AUTH_NUM && status !== EXPIRED_NUM) return ticketStatuses[MODIFIED_AUTH_NUM];
			return ticketStatuses[status] || ticketStatuses[REQUESTED_NUM];
		},

		payOutTax() {
			const { minQuota, amount, combinations, bonusAmount, maxGain } = this;
			let minGain = (minQuota * amount) / combinations + bonusAmount;
			if (minGain > maxGain) minGain = maxGain;

			let min_tax_value = 0;
			let max_tax_value = 0;

			const { minTax, maxTax } = calculateTaxForPlayedTicket(
				{ minValue: minGain, maxValue: +this.maxGain.toFixed(2) },
				amount,
				window.geoParams,
				'en',
				{
					parameterMappings: window.geoMappings,
				}
			);

			min_tax_value = minTax;
			max_tax_value = maxTax;

			return { min_tax_value, max_tax_value };
		},

		payment() {
			const test = this.tax_data?.original_amount
				? this.tax_data.original_amount
				: this.cashoutTicketOriginaAmount || this.originalAmount || 0;
			return test;
		},
	},
};
</script>

<style scoped>
.systems {
	width: 150px !important;
	margin: 0 0 0 20px !important;
	padding: 0 0px 0 0px !important;
}
</style>
