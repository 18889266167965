<template>
	<div>
		<table v-for="system in systems" :key="`system:${system.index}`" class="events">
			<!-- Events and odds -->
			<component
				v-for="row in system.rows"
				:key="`${row.uid}${row.odd && row.odd.bonusType ? 'bonus' : ''}`"
				:is="row.type"
				:system="system"
				:row="row"
				:addEventRow="addEventRow"
			/>

			<!-- System form -->
			<SystemForm :system="system" :showMinMax="systems.length - 1 === system.index" />
		</table>
		<!-- <pre>{{ JSON.stringify(systems.map(({ events }) => ({ events: events.map(({ id }) => ({ id }))})), null, 2)}}</pre> -->
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Event from './Event';
import EventOdd from './EventOdd';
import NewEvent from './NewEvent';
import NewOdd from './NewOdd';
import SystemForm from './SystemForm';

export default {
	components: {
		event: Event,
		odd: EventOdd,
		NewEvent,
		newOdd: NewOdd,
		SystemForm,
	},
	computed: mapGetters(['systems', 'newEventRow']),
	data() {
		return {
			newOddRow: false,
			lastEventId: '',
			event: null,
			rowWidth: 0,
		};
	},
	methods: {
		closeEventRow() {
			this.newEventRow = false;
			this.newOddRow = false;
			this.lastEventId = '';
		},
		addEventRow() {
			this.newEventRow = true;
		},
		preventCtrlR(e) {
			if ((e.keyCode === 82 || e.keyCode === 83) && e.ctrlKey) e.preventDefault();
		},
	},
	created() {
		document.addEventListener('keydown', this.preventCtrlR);
	},
	destroyed() {
		document.removeEventListener('keydown', this.preventCtrlR);
	},
};
</script>
